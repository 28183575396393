import * as lan from '../common/script/common_lan.js';

import React, {useState, Component, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import $ from 'jquery';

function Tab(props) {
	const tablist = [];
	const [activeNum, setactiveNum] = useState();
	const parser = new DOMParser();
	const location = useLocation();

	var defaultTopics;

	//화면전환시 실행
	useEffect(() => {
		
		if (location.state !== undefined && location.state != null)
		{
			if(location.state.nowTabTopics !== undefined && location.state.nowTabTopics != null)
			{
				defaultTopics = location.state.nowTabTopics;
			}
			else
			{
				defaultTopics = props.defaultTopics;
			}
		}
		else
		{
			defaultTopics = props.defaultTopics;
		}
		window.localStorage.setItem('tabNum', defaultTopics);
		setactiveNum(window.localStorage.getItem('tabNum'));
		
		// window.addEventListener('location', () => {
		// 	if(activeNum != window.localStorage.getItem('tabNum'))
		// 	{
		// 		setactiveNum(window.localStorage.getItem('tabNum'));
		// 	}
		// });
		
		var wrap = $(".contents");
		var headeroffset = $(".tab_button").offset();

		$(window).scroll(function () {
			
			//연습에만 검색창 없애서 예외처리
			if(!$("header").hasClass("exception1") || window.localStorage.getItem('tabNum') == 0)
			{
				if($("header").hasClass("topfix") && $(".search_form")[0])
				{
					if ($(this).scrollTop() > headeroffset.top){
						$(".search_form").addClass("fixed");
						wrap.css("margin-top","64px");
					}else {
						$(".search_form").removeClass("fixed");
						wrap.css("margin-top","0");
					}
				}
			}
		});
		
		if($("header").hasClass("topfix"))
		{
			var headeroffset = $(".topfix").offset();
			var wrap = $(".contents");
			$(window).scroll(function () {
				
			//연습에만 검색창 없애서 예외처리
				if(!$("header").hasClass("exception1") || window.localStorage.getItem('tabNum') == 0)
				{
					if($("header").hasClass("topfix") && $(".search_form")[0])
					{
						if ($(this).scrollTop() > headeroffset.top){;
							wrap.css("margin-top","48px");
						}else {
							wrap.css("margin-top","0px");
						}
					}
				}
			});
		}

    },[location]);

	useEffect(() => {
		window.dispatchEvent(new Event("storage"));
    });

	const TabChange = (e,key) => {
		e.preventDefault();
		setactiveNum(key);
		window.localStorage.setItem('tabNum', key);

		if(props.onChangefunc !== undefined)
		{
			props.onChangefunc(key);
		}
	}

	for(let i=0;i<props.topics.length;i++){
		let t = props.topics[i];
		tablist.push(<button key={i} id={"tab"+i} type="button" className={activeNum==i ? 'active':''} onClick={(e)=>{TabChange(e,i)}}>{lan.L(t.tabName)}</button>);
	}

  return (
	<div className="match_tab_button">
		{tablist}
	</div>
  );
}

export default Tab 
