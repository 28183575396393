import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

import BottomRoundset from '../popup/bottom_roundset';
import * as slide from '../popup/bottom_roundset';

var navigate, loginID, loginPass;
var nickname = 'test2';
var difficultytext, teeboxtypetext, teeHeighttext

export default function ModifyRound() {
	const location = useLocation();

	useEffect(() => {
	$('body').on('click',function(e){
		if(!$(e.target).hasClass('tooltipcon'))
		{
			$("#tooltip1").removeClass("on");
			$("#tooltip2").removeClass("on");
			$("#tooltip3").removeClass("on");
		}
	});

    }, [location]);

	difficultytext = lan.L(common.commonKeyDic['difficulty'][sessionStorage.getItem('difficulty')]);
	teeboxtypetext = lan.L(common.commonKeyDic['teeBoxType'][sessionStorage.getItem('teeBoxType')]);
	teeHeighttext = sessionStorage.getItem('teeHeight') + 'mm';

	if (sessionStorage.getItem('difficulty') == '')	difficultytext = lan.L('선택');
	if (sessionStorage.getItem('teeBoxType') == '')	teeboxtypetext = lan.L('선택');
	if (sessionStorage.getItem('teeHeight') == '')	teeHeighttext = lan.L('선택');
	
	navigate = useNavigate();
	
	const onTooltip = (e) => {
		var id = e.target.id;

		$("#tooltip1").removeClass("on");
		$("#tooltip2").removeClass("on");
		$("#tooltip3").removeClass("on");

		if(id == "help1")
		{
			$("#tooltip1").addClass("on");
		}
		else if(id == "help2")
		{
			$("#tooltip2").addClass("on");
		}
		else if(id == "help3")
		{
			$("#tooltip3").addClass("on");
		}
	}

	const onChange = () => {
		//최초에는 빈값, 수정되면 값들어옴
		if(($("#DifficultyValue").val() == sessionStorage.getItem('difficulty') || $("#DifficultyValue").val() == '')
		&& ($("#TeeBoxTypeValue").val() == sessionStorage.getItem('teeBoxType') || $("#TeeBoxTypeValue").val() == '')
		&& ($("#TeeHeightValue").val() == sessionStorage.getItem('teeHeight')  || $("#TeeHeightValue").val() == ''))
		{
			$("#btnModifyRoundSet").attr('disabled',true);
		}
		else
		{
			$("#btnModifyRoundSet").attr('disabled',false);
		}
	}

	return (
	<>
	<BottomRoundset returntype="html" returnid={{"difficulty":"Difficulty","teeboxtype":"TeeBoxType","teeheight":"TeeHeight"}} 
		returnvalueid={{"difficulty":"DifficultyValue","teeboxtype":"TeeBoxTypeValue","teeheight":"TeeHeightValue"}}
		defaultvalue={{"difficulty":sessionStorage.getItem('difficulty'),"teeboxtype":sessionStorage.getItem('teeBoxType'),"teeheight":sessionStorage.getItem('teeHeight')}}></BottomRoundset>
	<div>
	  <div className="container">
		<div className="title new-line">{lan.L('원하는 기능으로\n코스를 즐겨보세요.')}</div>
		<ul className="write_form">
		  <li>
			<span>
			  <input type="hidden" id="DifficultyValue" onChange={onChange} />
			  <label onClick={(e) => slide.slideDifficulty()} id="Difficulty">{difficultytext}</label>
			  <em>
				{lan.L('난이도')}
				<i id="help1" className="tooltipcon" onClick={onTooltip}>help</i>
				<div id="tooltip1" className="tooltip tooltipcon">
				  <a className="tooltipcon" onClick={onTooltip}>close</a>
				  <p className="new-line tooltipcon">{lan.L('라운드 콘텐츠 중 사용자의 샷에 대한 보정을 얼마나 받을지 선택 할 수 있습니다. \n투어프로로 이동하면 보정값이 감소합니다.')}</p>
				</div>
			  </em>
			</span>
		  </li>
		  <li>
			<span>
			  <input type="hidden" id="TeeBoxTypeValue" value="" onChange={onChange} />
			  <label onClick={(e) => slide.slideTeeBoxType()} id="TeeBoxType">{teeboxtypetext}</label>
			  <em>
				{lan.L('티 위치')}
				<i id="help2" className="tooltipcon" onClick={onTooltip}>help</i>
				<div id="tooltip2" className="tooltip tooltipcon">
				  <a onClick={onTooltip}>close</a>
				  <p>{lan.L('라운드 콘텐츠에서 사용할 티그라운드를 선택합니다.')}</p>
				</div>
			  </em>
			</span>
		  </li>
		  <li>
			<span>
			  <input type="hidden" id="TeeHeightValue" value="" onChange={onChange} />
			  <label onClick={(e) => slide.slideTeeheight()} id="TeeHeight">{teeHeighttext}</label>
			  <em>
				{lan.L('티 높이')}
				<i id="help3" className="tooltipcon" onClick={onTooltip}>help</i>
				<div id="tooltip3" className="tooltip tooltipcon">
				  <a className="new-line  tooltipcon"  onClick={onTooltip}>close</a>
				  <p>{lan.L('라운드 내용에서 티샷할 때 사용할 티 높이를 선택합니다.\n라운딩 중에 계측기의 티 높이를 변경 할 수 있습니다.')}</p>
				</div>
			  </em>
			</span>
		  </li>
		</ul>
	  </div>{/* container(e) */}
	  <div className="sticky_button">
	  <button type="button" className="btn_standard" id="btnModifyRoundSet" onClick={(e) => btnModifyRoundSet($("#DifficultyValue").val(), $("#TeeBoxTypeValue").val(), $("#TeeHeightValue").val())}>{lan.L('수정하기')}</button>
	  </div>
	</div>
	</>
	)
}

function btnModifyRoundSet(difficulty, teeboxtype, teeheight) {

	var myinfoJson = {"difficulty" : difficulty, "teeBoxType" : teeboxtype, "teeHeight" : teeheight, "clientDirection" : sessionStorage.getItem('clientDirection') };

	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: myinfoJson,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('회원 게임 설정 정보 수정'),
			'guid' : sessionStorage.getItem('guid'),
			'token' : sessionStorage.getItem('access_token')
		}
	}).then(function (response) {
		var data = response.data;
		console.log(response);

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			common.alertByError('라운드 설정',response.data.error.code);
			return false;
		}

		sessionStorage.setItem('nickname', myinfoJson.nickname);

		common.alertpop(lan.L('라운드 설정'),lan.L('수정되었습니다.'));
		navigate(-1);
	}).catch(error => {
		//console.log(error.response)
	});
}