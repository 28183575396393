import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import * as common from '../common/script/common';
import $ from 'jquery';
export default function Agreement04(){

	const navigate = useNavigate();
	const location = useLocation();

	const gobackWithAgree = (e) => {
		window.localStorage.setItem('join_agree04',true);
		navigate(-1);
	}
	
	//화면 이동 시
	useEffect(() => {
		
	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: {},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('약관 조회'),
			'guid' : common.commonKeyDic[sessionStorage.getItem('Language')].term,
			'guid2' : 'UserLocationInfoTerms'
		}
	}).then(function (response) {
		var data = response.data;
		console.log(response);

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			common.alertByError('회원 이용약관',response.data.error.code);
			return false;
		}

		$(".terms").html(data.settingValue);

	}).catch(error => {
		//console.log(error.response)
	});
	},[location]);

	return (
	<>
	<div className="container">
		<div className="terms">
		  {/*<span>
			(주)케이골프를 이용하는 회원님에게 서비스를 제공하고자, 방문 이용한 매장에 회원님의 개인정보를 로그인 한 경우에 한 해 아래와 같이 제공합니다.
		  </span>
		  <table className="score_chart">
			<colgroup>
			  <col style={{width: '25%'}} />
			  <col style={{width: '75%'}} />
			</colgroup>
			<tbody><tr>
				<th>이용목적</th>
				<td>매장 고객서비스 관리 및 대회 개최</td>
			  </tr>
			  <tr>
				<th>제공항목</th>
				<td>닉네임, 성별, 게임 이용 기록</td>
			  </tr>
			  <tr>
				<th>제공기간</th>
				<td>제3자 제공동의 철회 및 탈퇴시 까지</td>
			  </tr>
			</tbody></table>
		  <br /><br />
		  <span>
			(주)케이골프에서 제공하는 이벤트 및 서비스 안내 등 광고성 정보를 받으시려면 마케팅 목적 이용에 동의하여 주시기 바랍니다.<br />
			신규서비스(제품) 개발 및 특화, 맞춤형 서비스 제공, 기능 개선, 인구통계학적 특성에 따른 서비스 제공 및 광고거재, 접속 빈도파악, 이용자의 서비스 이용에 대한 통계 시 광고성 정보로 사용 됩니다.
		  </span>
		  <table className="score_chart">
			<colgroup>
			  <col style={{width: '25%'}} />
			  <col style={{width: '75%'}} />
			</colgroup>
			<tbody><tr>
				<th>이용목적</th>
				<td>맞춤형 서비스 제공에 따른 마케팅·광고 활용</td>
			  </tr>
			  <tr>
				<th>활용항목</th>
				<td>닉네임, 휴대폰번호, 주소</td>
			  </tr>
			  <tr>
				<th>제공기간</th>
				<td>마케팅·광고 철회 및 탈퇴시 까지</td>
			  </tr>
		</tbody></table>*/}
		</div>
	</div>{/* container(e) */}

	<div className="sticky_button">
	  <button type="button" className="btn_standard" onClick={gobackWithAgree}>{lan.L('확인')}</button>
	</div>
	</>

	)
}