import React, {useState, useEffect} from 'react';
import { Link, useNavigation, useLocation } from "react-router-dom";

function FullMenu(){
	const [activeFullMenu, setactiveFullMenu] = useState('');

	//최초실행
	useEffect(() => {
		window.addEventListener('storage', () => {
			setactiveFullMenu(window.localStorage.getItem('fullMenuOn'));

			if(activeFullMenu != window.localStorage.getItem('fullMenuOn'))
			{
				setactiveFullMenu(window.localStorage.getItem('fullMenuOn'));
			}
		});
	},[]);
	
	const FullMenuClose = (e) => {
		e.preventDefault();
		window.localStorage.setItem('fullMenuOn', 0);
		setactiveFullMenu(window.localStorage.getItem('fullMenuOn'));
		
		const prev = window.localStorage.getItem('prev_navigationNum');
		window.localStorage.setItem('navigationNum',prev);
	}

	return (
	<div className={activeFullMenu ==1 ? "fullMenu_wrap on":"fullMenu_wrap"}>
	  <a className="outside_close">close</a>
	  <div className="slide_container">
		<div className="header">
		  <a id="top_close" href="/" onClick={FullMenuClose}>close</a>
		  <strong>전체메뉴</strong>
		</div>
		<div className="menu_all">
		  <div className="category_menu">
			<Link className="i_record" to={"/record/record"}>나의 기록실</ Link>
			<Link className="i_shot" to="/shot/shotvideo">나의 샷 정보</Link>
			<Link className="i_statistic" to="/statistic/statistic">통계 정보</Link>
			<Link className="i_match" to="/match/game">대회</Link>
			<Link className="i_store" to="/reserve/status">매장 예약정보</Link>
			<Link className="i_customer" to="/customer/list_board">공지사항</Link>
			<Link className="i_setting" to="/setup/setting">설정</Link>
		  </div>
		</div>
	  </div>
	</div>
	)
}

export default FullMenu;