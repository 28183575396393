import * as lan from '../common/script/common_lan.js';

import React from 'react';
import DaumPostcode from "react-daum-postcode";
import { useNavigate, useLocation } from "react-router-dom";
 
const PopupDaumPost = (props) => {

	const location = useLocation();
	
	const navigate = useNavigate();

	// 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용
    const handlePostCode = (data) => {
//        let fullAddress = data.address;
//        let extraAddress = ''; 
//        if (data.addressType === 'R') {
//          if (data.bname !== '') {
//            extraAddress += data.bname;
//          }
//          if (data.buildingName !== '') {
//            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
//          }
//          fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
//        }
//        console.log(data)
//        console.log(data.address)
//        console.log(data.zonecode)
		if(location.state == 'modify')
		{
			window.localStorage.setItem('modify_zipcode',data.zonecode);
			window.localStorage.setItem('modify_address1',data.address);
			navigate(-2);
		}
		else
		{
			window.localStorage.setItem('join_zipcode',data.zonecode);
			window.localStorage.setItem('join_address1',data.address);
			navigate(-2);
		}
    }
 
    const postCodeStyle = {
        display: "block",
        position: "absolute",
        top: "48px",
        width: "100%",
        height: "100%",
      };
 
    return	(
            <div><DaumPostcode style={postCodeStyle} onComplete={handlePostCode} /></div>
    )
}
 
export default PopupDaumPost;
