
import axios from 'axios';
import stringify from 'node-stringify';
import CryptoJS from 'crypto-js';
import * as common from './common';

export function sendSMS(num, phonenum,countrycode) {

	const AccessKey = 'CB0537F9E7E13BE38CCE';
	const Timestamp = new Date().getTime();
	const SecretKey = 'D213009772F6062902D063A7BB3561A167DC9512';
	const serviceID = 'ncp:sms:kr:296243464114:kgolf_app';
	const url = 'http://localhost:3000/sms/v2/services/'+serviceID+'/messages';
	var content = '인증번호는 123456 입니다.';
	var signingKey = makeCrypto(AccessKey, SecretKey);

	console.log(url);

	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: {
			'num' : num,
			'phonenum' : phonenum,
			'countrycode' : countrycode,
		},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('네이버 메시지 발송'),
		}
	}).then(function (response) {
		console.log(response.data);
	}).catch(error => {
		console.log(error);
		return false;
	});

	//
	
	//'https://sens.apigw.ntruss.com/sms/v2',
//	axios({
//		method: 'post',
//		url: url,
//		data: {
//			type: 'SMS',
//			contentType: 'COMM',
//			countryCode:82,
//			from:'',
//			content:content,
//			messages:[
//				{
//					to:'01045229181',
//					content:content
//				}
//			]
//		},
//		headers: {
//			'Content-Type': 'application/json',
//			'x-ncp-apigw-timestamp': Timestamp,
//			'x-ncp-iam-access-key': AccessKey,
//			'x-ncp-apigw-signature-v2': Signature
//		}
}

function makeCrypto(akey, skey) {
	
	const burl = 'https://api.binance.com'
	const endPoint = '/api/v3/order'
	let dataQueryString = 'symbol=BTCUSDT&side=BUY&type=LIMIT&timeInForce=GTC&quantity=0.001&price=5000&recvWindow=60000&timestamp=' + Date.now()

	// apiKey and secretKey : from Binance API doc
	// https://binance-docs.github.io/apidocs/spot/en/#signed-trade-user_data-and-margin-endpoint-security

	const keys = {
		'akey' : akey,
		'skey' : skey
	}

	let signature = CryptoJS.HmacSHA256(dataQueryString, keys['skey']).toString(CryptoJS.enc.Hex)

	return signature;

}