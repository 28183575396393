import * as lan from './common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from './common/script/common.js';
import logo from './logo.svg';
import $ from 'jquery';
import axios from 'axios';


var sNickname;
var ProfileImg, SetProfileImg;
var InfoData, SetInfoData;
var GameData, SetGameData;
var ShotData, SetShotData;
var nearhtmllist, setnearhtmllist
var visithtmllist, setvisithtmllist
var data;
var smallactiveTab, setsmallactiveTab;

var ShotDataSend, SetShotDataSend;
var RoundDataSend, SetRoundDataSend;

const Maxnearlist = 2;
const Maxvisitlist = 2;
var navigate;
var objTimer;
var bestshotnum = 0;
var bestshotroundnum = 0;

var distance = Object.values(common.commonKeyDic['distance'])[3];

function Default() {
	const location = useLocation();
	navigate = useNavigate();
	[ProfileImg, SetProfileImg] = useState('');
	[InfoData, SetInfoData] = useState({});
	[GameData, SetGameData] = useState({});
	[ShotData, SetShotData] = useState({});
	[nearhtmllist, setnearhtmllist] = useState([]);
	[visithtmllist, setvisithtmllist] = useState([]);
	[ShotDataSend, SetShotDataSend] = useState({});
	[RoundDataSend, SetRoundDataSend] = useState({});

	var neardata, visitdata, neardatasort, visitdatasort;
	var mylati = common.commonKeyDic['defaultaddr'].lati;
	var mylong = common.commonKeyDic['defaultaddr'].long;
	
	//화면전환시 실행
	useEffect(() => {

		if(window.localStorage.getItem('mylati') !== undefined && window.localStorage.getItem('mylati') != null
		&& window.localStorage.getItem('mylong') !== undefined && window.localStorage.getItem('mylong') != null)
		{
			mylati = window.localStorage.getItem('mylati');
			mylong = window.localStorage.getItem('mylong');
		}

		data = {nickname : sessionStorage.getItem('nickname')}
		if(sessionStorage.getItem('nickname') === ''){
			navigate('/default/default',{replace:true});
		}
		$("#nickname").html(sessionStorage.getItem('nickname'));

		//회원 프로필
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {userGuid: sessionStorage.getItem('guid')},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 정보 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
			//console.log(response);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				//common.alertByError('',response.data.error.code);
				return false;
			}
			else
			{
				if(response.data.profilePath !== undefined || response.data.profilePath != null)
				{
					var profilepath = response.data.cdn + response.data.profilePath;
					SetProfileImg(profilepath);
				}
			}
		}).catch(error => {
			console.log(error.response)
		});

		//회원 통계 데이터 조회
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {userGuid: sessionStorage.getItem('guid')},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 통계 데이터 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
			console.log(response);
			console.log(process.env.REACT_APP_SERVER);
			
			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				//common.alertByError('',response.data.error.code);
				return false;
			}
			else
			{
				data = Object.assign(data,response.data);
				SetInfoData(data);
			}
		}).catch(error => {
			console.log(error.response)
		});
			
		//회원 라운드 설정
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {
				page:1,
				pagesize:100000
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('나의 기록실 목록 조회 라운드'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
				'getplus' : 'gameType=1&gameType=2'
			}
		}).then(function (response) {
			console.log(response.data);

			SetRoundDataSend(response.data);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				//common.alertByError('',response.data.error.code);
				return false;
			}

			if(response.data.list[0] !== undefined)
			{
				var gamedata = {gameType : lan.L(response.data.list[0].gameType) , score : response.data.list[0].score ,
											startTime : response.data.list[0].startTime, courseName : response.data.list[0].courseName}

				if(gamedata.courseName == null) gamedata.courseName = "null"
				
				SetGameData(gamedata);
				$(".norecord").hide();
				$(".roundinfo").show();
				$(".info_reservation").show();
			}
			else
			{
				SetGameData({});
				$(".norecord").show();
				$(".roundinfo").hide();
				$(".info_reservation").hide();
			}

			// SetInfoJson(Object.assign(InfoJson,data));
			
			//베스트 샷 조회
			axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER + '/api',
				data: {},
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'language':sessionStorage.getItem('Language'),
					'pUrl' : encodeURIComponent('베스트 샷 조회'),
					'token' : sessionStorage.getItem('access_token')
				}
			}).then(function (response2) {
				console.log(response2.data);

				if(response2.data.error !== undefined && response2.data.error.code > 0)
				{
					//common.alertByError('',response.data.error.code);
					return false;
				}

				var shotdata = {
					recommandClub: response2.data.recommandClub,
					ballFlightType: response2.data.ballFlightType,
					ballSpeed: response2.data.ballSpeed.toFixed(1),
					launchAngle: response2.data.launchAngle.toFixed(2),
					totalDistance: response2.data.totalDistance.toFixed(0),
					frontVideoThumbnailKey : response2.data.cdn+response2.data.frontVideoThumbnailKey,
					gameRoundId : response2.data.gameRoundId,
					shotId : response2.data.shotId
				}

				for(let i = 0; i < response.data.list.length; i++) //gameRoundId로 몇번째 i 가 best인지 찾는다.
				{
					if(shotdata.gameRoundId == response.data.list[i].gameRoundId)
					{
						bestshotroundnum = i;
					}
				}
				//console.log(shotdata);
				
				SetShotData(shotdata);
				
				axios({
					method: 'post',
					url: process.env.REACT_APP_SERVER + '/api',
					data: {},
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'language':sessionStorage.getItem('Language'),
						'pUrl' : encodeURIComponent('게임 라운드 샷 목록 조회'),
						'guid' : shotdata.gameRoundId,
						'token' : sessionStorage.getItem('access_token')
					}
				}).then(function (response3) {
					console.log(response3.data);
		
					if(response3.data.error !== undefined && response3.data.error.code > 0)
					{
						common.alertByError('',response.data.error.code);
						return false;
					}
					
					for(let i = 0; i < response3.data.shotList.length; i++) //gameRoundId로 몇번째 i 가 best인지 찾는다.
					{
						if(shotdata.shotId == response3.data.shotList[i].gameRoundShotId)
						{
							bestshotnum = i;
						}
					}
					
					SetShotDataSend(response3.data);

				}).catch(error => {
					console.log(error.response3)
				});

			}).catch(error => {
				console.log(error.response2)
			});
			//베스트 샷 종료

		}).catch(error => {
			console.log(error.response)
		});
		//회원 라운드 설정 종료

		objTimer = setTimeout(function(){
			var clientTypeLink = '';
			var vclientTypeLink = '';

			if (location.state !== undefined && location.state != null)
			{
				distance = location.state.distance;
				clientTypeLink = location.state.clientTypeLink;
			}
			//근처 매장 목록 조회
			axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER + '/api',
				data: {
					province:'',
					city:'',
					province:'',
					searchText:'',
					startLatitude:'',
					endLatitude:'',
					startLongitude:'',
					endLongitude:'',
					clientType:0,
					page:1,
					pagesize:2
				},
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'language':sessionStorage.getItem('Language'),
					'pUrl' : encodeURIComponent('매장 목록 조회'),
					'guid' : sessionStorage.getItem('guid'),
					'token' : sessionStorage.getItem('access_token'),
					'getplus' : clientTypeLink
				}
			}).then(function (response) {
					//console.log(response.data);

				if(response.data.error !== undefined && response.data.error.code > 0)
				{
					//common.alertByError('',response.data.error.code);
					return false;
				} 
				else
				{
					var count = 0;
					if(response.data.list[0] !== undefined)
					{
						var nearlist = response.data.list;
						var dis;
						neardata = [];
						var dislist = [];
						var dislistsort = [];

						var lengthcount = 0;
						
						//길이에 없으면 길이변경 추가
						if(distance == 30)
						{
							for(let i = 0; i < nearlist.length;i++)
							{
								dis = common.getDistance(mylati,mylong,nearlist[i].latitude,nearlist[i].longitude);
								
								if (dis - distance <= 0)
								{
									lengthcount = lengthcount + 1;
								}
							}
	
							if(lengthcount == 0) distance = 100;
							
							for(let i = 0; i < nearlist.length;i++)
							{
								dis = common.getDistance(mylati,mylong,nearlist[i].latitude,nearlist[i].longitude);
								
								if (dis - distance <= 0)
								{
									lengthcount = lengthcount + 1;
								}
							}
	
							if(lengthcount == 0) distance = 1000000; //모든거리
						}

						for (let i = 0;i < nearlist.length; i++)
						{
							dis = common.getDistance(mylati,mylong,nearlist[i].latitude,nearlist[i].longitude);

							//onsole.log(distance);

							if(nearlist[i].isShopFavorites) //즐겨찾기 유무
							{
								{
									neardata.push(
									<li key={i+'n'}>
										<a onClick={(e) => goDetail(nearlist[i].shopGuid)} >
										<p>
											{lan.L(nearlist[i].shopName)}
											{nearlist[i].isShopFavorites == true ? <i>{lan.L("즐겨찾기")}</i>:''}
											<span>{dis}Km</span>
										</p>
										<div className="keyword">
											<span style={nearlist[i].clientType.screen ? {}:{display: 'none'}}>{"#"+lan.L("스크린")}</span>
											<span style={nearlist[i].clientType.practice ? {}:{display: 'none'}}>{"#"+lan.L("연습")}</span>
											<span style={nearlist[i].clientType.lesson ? {}:{display: 'none'}}>{"#"+lan.L("레슨")}</span>
										</div>
										</a>
										<div className="btn_store">
										<button type="button" onClick={(e) => goTel(nearlist[i].shopPhone)}>{lan.L("전화하기")}</button>
										{/* {nearlist[i].lessonbookCenterCode === undefined || nearlist[i].lessonbookCenterCode == null ? '' : <button type="button" className="reserve" onClick={(e) => common.goLessonbookReserve(nearlist[i].lessonbookCenterCode,"reserve")}>{lan.L("예약하기")}</button>} */}
										</div>
									</li>
									);
									
									dislist.push(0);
									dislistsort.push(0);
									count = count + 1;
								}
							}
							else // if (dis - distance <= 0)
							{
								neardata.push(
								<li key={i+'n'}>
									<a onClick={(e) => goDetail(nearlist[i].shopGuid)} >
									<p>
										{lan.L(nearlist[i].shopName)}
										{nearlist[i].isShopFavorites == true ? <i>{lan.L("즐겨찾기")}</i>:''}
										<span>{dis}Km</span>
									</p>
									<div className="keyword">
										<span style={nearlist[i].clientType.screen ? {}:{display: 'none'}}>{"#"+lan.L("스크린")}</span>
										<span style={nearlist[i].clientType.practice ? {}:{display: 'none'}}>{"#"+lan.L("연습")}</span>
										<span style={nearlist[i].clientType.lesson ? {}:{display: 'none'}}>{"#"+lan.L("레슨")}</span>
									</div>
									</a>
									<div className="btn_store">
									<button type="button" onClick={(e) => goTel(nearlist[i].shopPhone)}>{lan.L("전화하기")}</button>
									{/* {nearlist[i].lessonbookCenterCode === undefined || nearlist[i].lessonbookCenterCode == null ? '' : <button type="button" className="reserve" onClick={(e) => common.goLessonbookReserve(nearlist[i].lessonbookCenterCode,"reserve")}>{lan.L("예약하기")}</button>} */}
									</div>
								</li>
								);
								
								dislist.push(dis);
								dislistsort.push(dis);
								count = count + 1;
							}
						}
						
						dislistsort.sort((a, b) => {
							if (a < b) {return -1;}
							if (a > b) {return 1;}
							return 0;
						});

						//console.log(dislistsort);

						neardatasort = [];
						
						for (let i = 0;i < Math.min(nearlist.length,Maxnearlist); i++)
						{
							if(i ==0 || dislistsort[i] != dislistsort[i-1])
							{
								for (let j = 0;j < nearlist.length; j++)
								{
									if(dislistsort[i] == dislist[j])
									{
										neardatasort.push(neardata[j]);
									}
								}
							}
						}
						setnearhtmllist(neardatasort);
					}

					if(count == 0)
					{
						neardata = [];
						neardata.push(
							<div className="nodata">
							<strong>{lan.L("현재 위치 근처에 매장이 없어요.")}</strong>
							<span>{lan.L("검색 가능한 거리를 넓혀보세요.")}</span>
							</div>
						);
						setnearhtmllist(neardata);
					}
				}
			}).catch(error => {
				console.log(error.response)
			});
				
			//방문 매장 목록 조회
			axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER + '/api',
				data: {
					searchText:'',
					page:1,
					pagesize:1000
				},
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'language':sessionStorage.getItem('Language'),
					'pUrl' : encodeURIComponent('방문 매장 목록 조회'),
					'guid' : sessionStorage.getItem('guid'),
					'token' : sessionStorage.getItem('access_token'),
					'getplus' : vclientTypeLink
				}
			}).then(function (response) {
				//console.log(response.data);

				if(response.data.error !== undefined && response.data.error.code > 0)
				{
					//common.alertByError('',response.data.error.code);
					return false;
				}
				else
				{
					if(response.data.list[0] !== undefined)
					{
						var visitlist = response.data.list;
						var dis;
						visitdata = [];
						var dislist = [];
						var dislistsort = [];

						var count = 0;

						for (let i = 0;i < visitlist.length; i++)
						{
							dis = common.getDistance(mylati,mylong,visitlist[i].latitude,visitlist[i].longitude);
							
							if(visitlist[i].isShopFavorites) //즐겨찾기 유무
							{
								visitdata.push(
									<li key={i+'v'}>
									<a onClick={(e) => goDetail(visitlist[i].shopGuid)}>
										<p>
											{lan.L(visitlist[i].shopName)}
											{visitlist[i].isShopFavorites == true ? <i>{lan.L("즐겨찾기")}</i>:''}
											<span>{dis}Km</span>
										</p>
										<div className="keyword">
										<span style={visitlist[i].clientType.screen ? {}:{display: 'none'}}>{"#"+lan.L("스크린")}</span>
										<span style={visitlist[i].clientType.practice ? {}:{display: 'none'}}>{"#"+lan.L("연습")}</span>
										<span style={visitlist[i].clientType.lesson ? {}:{display: 'none'}}>{"#"+lan.L("레슨")}</span>
										</div>
									</a>
									<div className="btn_store">
										<button type="button" onClick={(e) => goTel(visitlist[i].shopPhone)}>{lan.L("전화하기")}</button>
										{/* {visitlist[i].lessonbookCenterCode === undefined || visitlist[i].lessonbookCenterCode == null ? '' : <button type="button" className="reserve" onClick={(e) => common.goLessonbookReserve(visitlist[i].lessonbookCenterCode,"reserve")}>{lan.L("예약하기")}</button>} */}
									</div>
									</li>
								);
									
								dislist.push(0);	//가장 위로 오도록 dis 0 입력
								dislistsort.push(0);
								count = count + 1;
							}
							else
							{
								visitdata.push(
									<li key={i+'v'}>
									<a onClick={(e) => goDetail(visitlist[i].shopGuid)}>
										<p>
											{lan.L(visitlist[i].shopName)}
											{visitlist[i].isShopFavorites == true ? <i>{lan.L("즐겨찾기")}</i>:''}
											<span>{dis}Km</span>
										</p>
										<div className="keyword">
										<span style={visitlist[i].clientType.screen ? {}:{display: 'none'}}>{"#"+lan.L("스크린")}</span>
										<span style={visitlist[i].clientType.practice ? {}:{display: 'none'}}>{"#"+lan.L("연습")}</span>
										<span style={visitlist[i].clientType.lesson ? {}:{display: 'none'}}>{"#"+lan.L("레슨")}</span>
										</div>
									</a>
									<div className="btn_store">
										<button type="button" onClick={(e) => goTel(visitlist[i].shopPhone)}>{lan.L("전화하기")}</button>
										{/* {visitlist[i].lessonbookCenterCode === undefined || visitlist[i].lessonbookCenterCode == null ? '' : <button type="button" className="reserve" onClick={(e) => common.goLessonbookReserve(visitlist[i].lessonbookCenterCode,"reserve")}>{lan.L("예약하기")}</button>} */}
									</div>
									</li>
								);
										
								dislist.push(dis);
								dislistsort.push(dis);
								count = count + 1;
							
							}
						}
						
						dislistsort.sort((a, b) => {
							if (a < b) {return -1;}
							if (a > b) {return 1;}
							return 0;
						});
		
						visitdatasort = [];
		
						for (let i = 0;i < Math.min(visitlist.length,Maxvisitlist); i++)
						{
							if(i ==0 || dislistsort[i] != dislistsort[i-1])
							{
								for (let j = 0;j < visitlist.length; j++)
								{
									if(dislistsort[i] == dislist[j])
									{
										visitdatasort.push(visitdata[j]);
									}
								}
							}
						}
						setvisithtmllist(visitdatasort);
						}
					else
					{
						visitdata = [];
						visitdata.push(
							<div className="nodata">
							<strong>{lan.L("최근 방문한 매장이 없어요")}</strong>
							<span>{lan.L("가까운 매장을 이용해보세요.")}</span>
							</div>
						);
						setvisithtmllist(visitdata);
					}
				}
			}).catch(error => {
				console.log(error.response)
			});
	}, 250);
    },[location]);
	
	const goDetail = (shopguid) => {
		navigate('/store/detail_store', {state : {shopGuid : shopguid, mylati : mylati, mylong : mylong} });
	}
	
	const goTel = (shopPhone) => {
		document.location.href = "tel:"+shopPhone;
	}

		//<InfoReservation></InfoReservation> //예약정보 임시로 빼둠 
  return (
	<>
		<div className="main_head">
			<span>Logo</span>
		</div>
		<MemberInfo></MemberInfo>
		<Record></Record>
	<div className="main_section">
		<RoundRecord></RoundRecord>
		<MyShot></MyShot>
		<FindStore></FindStore>
	</div>
	</>
  );
}

//{ProfileImg=='' ?{backgroundImage: "url("+require('./images/sub/main_noimage.png')+")"}:{backgroundImage: "url("+require(+'./images/sub/main_noimage.png')+")"}}
//{backgroundImage: "url("+require('./images/sub/main_noimage.png')+")"}

function MemberInfo() {
  return (
		<div className="memberinfo">
			<p style={ProfileImg=='' ?{backgroundImage: "url("+require('./images/sub/main_noimage.png')+")"}:{backgroundImage: "url("+ProfileImg+")"}}></p>
			<div className="log_sec" >
				<em id="nickname"></em>
				<span onClick={(e)=>goMenu('/setup/myinfo')}>
					<b>{lan.L('My Profile')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
				</span>
			</div>
		</div>
  );
}

function Record() {
  return (
		<div className="record">
			<dl>
				<dt>{InfoData.averageScore == null || InfoData.averageScore == 0 ? '-' : InfoData.averageScore}</dt>
				<dd>{lan.L("AVG")}</dd>
			</dl>
			<dl>
				<dt>{InfoData.bestScore == null || InfoData.bestScore == 0 ? '-' : InfoData.bestScore}</dt>
				<dd>{lan.L("BEST")}</dd>
			</dl>
			{/*<dl>
				<dt>{InfoData.point == null ? '-' : InfoData.point}</dt>
				<dd>{lan.L("POINT")}</dd>
			</dl>*/}
		</div>
  );
}

function RoundRecord() {
	return (
		<section className="round_record">
		 	<div className="norecord">
		 		<p>image</p>
		 		<span className="new-line">{lan.L('아직 *')}<strong>{lan.L('플레이 기록')}</strong>{lan.L('이 없습니다.\n가까운 매장에서 친구들과 라운드를 즐겨보세요.*')}</span>
		 		<span style={{display: 'none'}}>You haven\'t <strong>played the round</strong> yet.\nEnjoy the round \with your friends at a nearby store.</span>
		 	</div>
		 	<div className="roundinfo" style={{display: 'none'}} onClick={(e)=>{goMenu('/record/record')}}>
		 		<div className="score">
		 			<em>{GameData.score === undefined ? '' : GameData.score}</em>
		 			<span>{lan.L('score')}</span>
		 		</div>
		 		<div className="recent s_arrow">
		 			<span>{GameData.startTime === undefined ? '' : common.UnixTime(GameData.startTime,"년-월-일")}</span>
		 			<strong>{GameData.courseName === undefined ? '' : GameData.courseName}</strong>
		 		<div className="game_type stroke">{GameData.gameType === undefined ? '' : GameData.gameType}</div>
		 		</div>
		 	</div>
		</section>
    );
}

/*
function MyShot() { 
	return (
	<section className="my_shot" style={{display: 'none'}} onClick={(e)=>{goMenu('/shot/shotvideo')}}>
		<p style={{backgroundImage: 'url('+ShotData.frontVideoThumbnailKey+')'}} >thumbnail</p>
		<ul>
			<li>
				<strong>{lan.L(common.commonKeyDic['recommandClub'][0])}</strong>
				<div className="game_type practice">{lan.L(common.commonKeyDic['ballFlightType'][ShotData.ballFlightType])}</div>
			</li>
			<li>
				<dl>
				<dd>Distance</dd>
				<dt>{common.Length(ShotData.totalDistance,'0')}<small>{common.Length('','m')}</small></dt>
				</dl>
				<dl>
				<dd>Ball Speed</dd>
				<dt>{ShotData.ballSpeed}<small>m/s</small></dt>
				</dl>
				<dl>
				<dd>Launch Ang</dd>
				<dt>{ShotData.launchAngle}</dt>
				</dl>
			</li>
		</ul>
	</section>
    );
}
*/

function MyShot() {
	return (
	<section className="info_reservation" style={{display: 'none'}}>
		<a className="s_arrow" onClick={(e)=>{goMenu('/shot/shotvideo')}}>
		  <em>{lan.L('샷 정보')}</em>
		</a>
		<div className="my_shot" onClick={goMenuShot}>
		  <p style={{backgroundImage: 'url('+ShotData.frontVideoThumbnailKey+')'}} >thumbnail</p>
		  <ol>
			<li>
			  <strong>{lan.L(common.commonKeyDic['recommandClub'][ShotData.recommandClub])}</strong>
			  <div className="game_type practice">{lan.L(common.commonKeyDic['ballFlightType'][ShotData.ballFlightType])}</div>
			</li>
			<li>
			  <dl>
				<dd>Distance</dd>
				<dt>{ShotData.totalDistance === undefined ? '' : Math.floor(common.Length(ShotData.totalDistance,'0'))}<small>{common.Length('','m')}</small></dt>
			  </dl>
			  <dl>
				<dd>Ball Speed</dd>
				<dt>{ShotData.ballSpeed === undefined ? '' : Math.floor(common.Speed(ShotData.ballSpeed,'0'))}<small>{common.Speed('','m/s')}</small></dt>
			  </dl>
			  <dl>
				<dd>Launch Ang</dd>
				<dt>{ShotData.launchAngle}<small>˚</small></dt>
			  </dl>
			</li>
		  </ol>
		</div>
	</section>
    );
}

// function InfoReservation() {
// 	return (
// 	<section className="info_reservation">{/* 예약당일 class="d-day" 추가 */}
// 		<a className="s_arrow">
// 		<em>예약정보</em>
// 		<span>D-7</span>
// 		<i>d-day</i>
// 		</a>
// 		<ul>
// 		<li>
// 			<span>2022.08.31(수) PM 12:00</span>
// 			<div className="category_type">스크린</div>
// 		</li>
// 		<li>
// 			<b>방배 케이골프 아카데미</b>
// 		</li>
// 		</ul>
// 		<div className="summary">
// 		<label>홍길동</label>
// 		<label>101호</label>
// 		<label>4명</label>
// 		</div>
// 		<button type="button">전화하기</button>
// 	</section>
//     );
// }

function FindStore() {
	
	const onClickSmallTab = (e) => {
		var id = e.target.id;
		if(id=="near")
		{
			$("#near").addClass("active");
			$("#visit").removeClass("active");
			setsmallactiveTab("near");
		}
		else if(id=="visit")
		{
			$("#near").removeClass("active");
			$("#visit").addClass("active");
			setsmallactiveTab("visit");
		}
	}
	
	[smallactiveTab, setsmallactiveTab] = useState('near');

	return (
<section className="find_store">
  <a className="s_arrow" onClick={(e)=>{goMenu('/store/store')}}>
    <em>{lan.L("매장찾기")}</em>
  </a>
  <div className="tab_line">
    <button type="button" onClick={(e)=>{onClickSmallTab(e)}} id="near" className="active">{lan.L("근처매장")}</button>
    <button type="button" onClick={(e)=>{onClickSmallTab(e)}} id="visit" className="">{lan.L("방문매장")}</button>
  </div>
  {/*------------------------------------ 근처매장 ------------------------------------*/}
  <div className="store_wrap" style={smallactiveTab=="near" ? {display: 'block'}:{display: 'none'}}>
    <ul className="store_list">
	 {nearhtmllist}
    </ul>
  </div>
  {/*------------------------------------//근처매장 ------------------------------------*/}
  {/*------------------------------------ 방문매장 ------------------------------------*/}
  <div className="store_wrap" style={smallactiveTab=="visit" ? {display: 'block'}:{display: 'none'}}>
    <ul className="store_list">
      {visithtmllist}
    </ul>
  </div>
  {/*------------------------------------//방문매장 ------------------------------------*/}
</section>
    );
}

function goMenu(to){
	navigate(to);
}
function goMenuShot(){
	console.log(bestshotnum, ShotDataSend);
	var holenumber = ShotDataSend.shotList[bestshotnum].holeNumber;
	var coursePar = ShotDataSend.coursePar[holenumber-1];
	navigate('/shot/detail_video', {state : {gameRoundId : RoundDataSend.list[bestshotroundnum].gameRoundId, gameRoundShotId : ShotDataSend.shotList[bestshotnum].gameRoundShotId, roundData : RoundDataSend.list[bestshotroundnum], shotData : ShotDataSend.shotList[bestshotnum], coursePar : coursePar, cdn : RoundDataSend.cdn, backTo:'/default/default'}});
}
export default Default;

