import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { commonKeyDic } from '../common/script/common.js';
import * as common from '../common/script/common.js';

function FullMenu(){
	const [activeFullMenu, setactiveFullMenu] = useState('');
	const navigate = useNavigate();
	const location = useLocation();

	//최초실행
	useEffect(() => {
		window.addEventListener('storage', () => {
			setactiveFullMenu(window.localStorage.getItem('fullMenuOn'));

			if(activeFullMenu != window.localStorage.getItem('fullMenuOn'))
			{
				setactiveFullMenu(window.localStorage.getItem('fullMenuOn'));
			}
		});
	},[]);

	
	const FullMenuClose = (e) => {
		e.preventDefault();
		window.localStorage.setItem('fullMenuOn', 0);
		setactiveFullMenu(window.localStorage.getItem('fullMenuOn'));
		
		navigate(window.localStorage.getItem('prev_navigationToList'));
	}
	
	const FullMenuTo = (e,to) => {
		e.preventDefault();
		window.localStorage.setItem('fullMenuOn', 0);
		setactiveFullMenu(window.localStorage.getItem('fullMenuOn'));
		navigate(to);
	}

	return (
	<div className={activeFullMenu ==1 ? "fullMenu_wrap on":"fullMenu_wrap"}>
	  <a className="outside_close" onClick={FullMenuClose}>close</a>
	  <div className="slide_container">
		<div className="header">
		  <a id="top_close" onClick={FullMenuClose}>close</a>
		  <strong>{lan.L('전체메뉴')}</strong>
		</div>
		<div className="menu_all">
		  <div className="category_menu">
			<a className="i_record" onClick={(e)=>{FullMenuTo(e,"/record/record")}}>{lan.L('나의 기록실')}</ a>
			<a className="i_shot" onClick={(e)=>{FullMenuTo(e,"/shot/shotvideo")}} >{lan.L('나의 샷 정보')}</a>
			<a className="i_statistic" onClick={(e)=>{FullMenuTo(e,"/statistic/statistic")}} >{lan.L('통계 정보')}</a>
			<a className="i_match" onClick={(e)=>{FullMenuTo(e,"/match/game")}} >{lan.L('대회')}</a>
			{/* <a className="i_store" onClick={(e)=>{common.goLessonbookReserve( '', "reserveStatus")}} >{lan.L('매장 예약정보')}<i id="i_store_new" style={{display: 'none'}} class="new" >new</i></a> */}
			<a className="i_customer" onClick={(e)=>{FullMenuTo(e,"/customer/list_board")}} >{lan.L('공지사항')}</a>
			<a className="i_setting" onClick={(e)=>{FullMenuTo(e,"/setup/setting")}} >{lan.L('설정')}</a>
		  </div>
		</div>
	  </div>
	</div>
	)
}

export default FullMenu;
