import * as lan from '../common/script/common_lan.js';

import React, {useState, Component, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

export default function LoginCheck(){

	const location = useLocation();
	const navigate = useNavigate();

	//화면전환시 실행
	useEffect(() => {
		if(sessionStorage.getItem('loginID')===undefined || sessionStorage.getItem('loginID')==null || sessionStorage.getItem('loginID')=='')
		{
			navigate("/login/login");
		}
    },[location]);

	return (
		<></>
	)
}