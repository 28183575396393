import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

export function slideOn(){
	$("html").addClass("overflowHidden");
	$("#filter_record").addClass("on");
}

export function slideExit(){
	$("html").removeClass("overflowHidden");
	$(".layerView").removeClass("on");
}

export default function BottomRecord(props) {
	
	const location = useLocation();
	const navigate = useNavigate();

	const [gametypehtmllist, setgametypehtmllist] = useState([]);

	var list = common.commonKeyDic['gameType']; 
	var gametype;
	useEffect(() => {
		var htmllist = [];
		for(let i =0; i < Object.keys(list).length; i++)
		{
			gametype =  lan.L(Object.values(common.commonKeyDic['gameType'])[i]);
			htmllist.push(
			  <a key={i+"gametype"}>
				<input type="radio" id={"gameType"+i} name="gameType" onClick={(e) =>goSelect(e,props.to)} value={gametype} defaultChecked={gametype == props.gameType ? true : false}/>
				<label htmlFor={"gameType"+i}>{gametype}</label>
			  </a>
			);
		}
		setgametypehtmllist(htmllist);
    },[]);

	const goSelect = (e, to) => {

		var value = e.target.value;

		if(to !== undefined)
		{
			navigate("/common/script/backnavigation", {state : {gameType : value, isTournamentView : props.isTournamentView, practiceClub : props.practiceClub, club : props.club, back : to, activeTab : props.activeTab}, replace : true});
		}
		slideExit();
	}

	return (
	<div id="filter_record" className="layerView">
	  <div className="bottom_sheet">
		<button style={{display:'none'}} onClick={(e) =>goSelect(e,props.to)} value={lan.L(Object.values(common.commonKeyDic['gameType'])[0])} id="pop_gametypeinit">{lan.L("초기화")}</button>
		<a onClick={(e) => slideExit()}>close</a>
		<div className="bs_body">
		  <em>{lan.L("경기 방식 선택")}</em>
		  <div className="scroll_container">
			<div className="list_selection">
			 {gametypehtmllist}
			</div>
		  </div>
		</div>
	  </div>
	</div>
	)
}