import * as lan from '../common/script/common_lan.js';


import { Link, useNavigate, useLocation } from "react-router-dom";
import LinkButton from '../common/component/linkButton';
import * as common from '../common/script/common.js';

import BottomLanguage from '../popup/bottom_lan';
import * as slide from '../popup/bottom_lan';


var navigate;

export default function Setting(){

	navigate = useNavigate();
	var nowlan = sessionStorage.getItem('Language');
	var lanname = common.commonKeyDic[nowlan].name;
	
	const onClick = (e) => {
		var id = e.target.id;
		var value = id == 'meter' ? 'm' : 'yd';

		sessionStorage.setItem('distanceUnit',value);
		
		if(common.isIOS()){
			common.iOSCallApp({appid:"setdistanceUnit", params:value});
		}
		else
		{
			try{common.andriodCallApp('setdistanceUnit',[value]);}
			catch (ex){}
		}

		// if(id == 'meter')
		// {
		// 	sessionStorage.setItem('distanceUnit','m');
		// 	console.log(sessionStorage.getItem('distanceUnit'));
		// }
		// else if (id == 'yard')
		// {
		// 	sessionStorage.setItem('distanceUnit','yd');
		// 	console.log(sessionStorage.getItem('distanceUnit'));
		// }
	}

	const goLoginWLogout = (e) => {

		common.goLogout();
		navigate('/login/login');
	}
	

	return (
	<>
	<BottomLanguage ></BottomLanguage>
	<div className="setting">
	  <strong>{lan.L('사용자 설정')}</strong>
	  <ul>
		<li>
		  <Link to="/setup/myinfo">
			<em>{lan.L('내 정보')}</em>
		  </Link>
		</li>
		<li>
		  <Link to="/setup/password_new">
			<em>{lan.L('비밀번호 변경')}</em>
		  </Link>
		</li>
		<li>
		  <em>{lan.L('단위(앱)')}</em>
		  <div className="wrap_check">
			<a>
			  <input type="radio" id="meter" name="unit" onClick={onClick} defaultChecked={sessionStorage.getItem('distanceUnit') == 'yd' ? false : true} />
			  <label htmlFor="meter">{lan.L('미터(m)')}</label>
			</a>
			<a>
			  <input type="radio" id="yard" name="unit" onClick={onClick} defaultChecked={sessionStorage.getItem('distanceUnit') == 'yd' ? true : false} />
			  <label htmlFor="yard">{lan.L('야드(yd)')}</label>
			</a>
		  </div>
		</li>
	  </ul>
	  <strong>{lan.L('이용약관')}</strong>
	  <ul>
		<li>
		  <Link to="/terms/agreement01" state={{from : 'setup', value: true}}>
			<em>{lan.L('회원 이용약관')}</em>
		  </Link>
		</li>
		<li>
		  <Link to="/terms/agreement02" state={{from : 'setup', value: true}}>
			<em>{lan.L('개인정보 보호정책')}</em>
		  </Link>
		</li>
		<li>
		  <Link to="/terms/agreement03" state={{from : 'setup', value: true}}>
			<em>{lan.L('제3자 정보제공 및 마케팅 수신 동의')}</em>
		  </Link>
		</li>
		<li>
		  <Link to="/terms/agreement04" state={{from : 'setup', value: true}}>
			<em>{lan.L('위치 기반 서비스 이용약관')}</em>
		  </Link>
		</li>
	  </ul>
	  <strong>{lan.L('기타')}</strong>
	  <ul>
		<li>
		  <a onClick={slide.slideOn} >
			<em>{lan.L('언어 설정')}</em>
			<span>{lanname}</span>
		  </a>
		</li>
		<li>
		  <LinkButton type="button" to="/setup/withdrawal">{lan.L('회원 탈퇴')}</LinkButton>
		</li>
		<li>
		  <button type="button" onClick={(e) => goLoginWLogout()}>{lan.L('로그아웃')}</button>
		</li>
	  </ul>
	</div>
	</>

	)
}