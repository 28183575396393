import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

import BottomCountry from '../popup/bottom_country';
import * as slide from '../popup/bottom_country';

const minmobilenum = 10;
const maxcertnum = 6;
var certnum = "";
var sendcert = "N";
var certend = "N";
var limitTimer = 180, objLimitTimer;
var WrongCnt = 0;
var MaxWrongCnt = 5;

export default function PasswordCurrent() {
	
	const location = useLocation();
	const navigate = useNavigate();
	const [InfoData, SetInfoData] = useState({loginPass : '' , loginPass2 : ''});

	//화면전환시 실행
	useEffect(() => {

    },[location]);

	const onChange = (e) => {
		  var id = e.target.id;
		  var value = e.target.value;
		  var data = InfoData;

		if(id == "newLoginpass")
		{
			data.loginPass = value;
			SetInfoData(data);
			
			if(value != '') $("#newLoginpassDel").show();
			else $("#newLoginpassDel").hide();
		}
	}
	  
	const onDelete = (e) => {
		var id = e.target.id;
	    var data = InfoData;
		
		if(id == "newLoginpassDel")
		{
			data.loginPass = '';
			$("#newLoginpass").val('');
			SetInfoData(data);
			$("#newLoginpassDel").hide();
			
			$("#newLoginpass_basic").show();
			$("#newLoginpass_error").hide();
			$("#newLoginpass_success").hide();
		}
	}

	// 체크박스 단일 선택
	const goChangePW = (e) => {

		if(InfoData.loginPass != sessionStorage.getItem('loginPass'))
		{
			common.alertpop(lan.L('비밀번호 변경'),lan.L("비밀번호가 일치하지 않습니다."));
			return false;
		}
		
		navigate('/setup/password_new',{replace:true});
	}

	return (
		<>
		<div>
		  <div className="container">
			<div className="title new-line">{lan.L('현재 비밀번호를\n입력해 주세요.')}</div>
			<ul className="write_form">
				<li>
					<span>
					<input type="password" id="newLoginpass" onChange={(e) => onChange(e)} placeholder={lan.L("비밀번호 입력")} />
					<em>{lan.L('비밀번호')}</em>
					<a id="newLoginpassDel" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
					</span>
				</li>
			</ul>
		  </div>{/* container(e) */}
		  <div className="sticky_button">
			<button type="button" id="btnChangePW" className="btn_standard" onClick={goChangePW}>{lan.L('확인')}</button>
		  </div>
		</div>
		</>
	)
}