import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

import { useDispatch, useSelector } from "react-redux";

export function slideAverage(type){
	$("html").addClass("overflowHidden");
	$("#pop_statisticAVG_"+type).addClass("on");
}

export function slideExit(){
	$("html").removeClass("overflowHidden");
	$(".layerView").removeClass("on");
}

export default function BottomStatisticAVG(prop){
    
	const selector = useSelector((state) => state);
	const dispatch = useDispatch();

	const [averagehtmllist, setaveragehtmllist] = useState([]);

	useEffect(() => {
        
		var htmllist = [];
		//평균 기록
		for(let i =0; i < Object.keys(common.commonKeyDic['average']).length; i++)
		{
			htmllist.push(
				<a key={i+"b"} >
				  <input type="radio" value={lan.L(Object.values(common.commonKeyDic['average'])[i])} id={"average"+prop.type+Object.keys(common.commonKeyDic['average'])[i]}
					data-key={Object.keys(common.commonKeyDic['average'])[i]} name={"bottomaverage"+prop.type}
					onClick={(e) => goSelect(e, lan.L(Object.values(common.commonKeyDic['average'])[i]))} defaultChecked={Object.keys(common.commonKeyDic['average'])[i] == Object.keys(common.commonKeyDic['average'])[prop.defaultvalue] ? true : false}/>
				  <label htmlFor={"average"+prop.type+Object.keys(common.commonKeyDic['average'])[i]}>{lan.L("최근 " + Object.values(common.commonKeyDic['average'])[i] + "회")}</label>
				</a>
			);
		}
		if(averagehtmllist.length == 0)
		{
			setaveragehtmllist(htmllist);
		}
    },[]);
    
	const goSelect = (e, value) => {
		if(prop.type == 'stroke')
		{
			dispatch({type:'stroke_recentGameRoundCount', value: value});
			slideExit();
		}
		else if(prop.type == 'tournament')
		{
			dispatch({type:'tournament_recentGameRoundCount', value: value});
			slideExit();
		}
	}
    
	return (
        <div className="layerView" id={"pop_statisticAVG_"+prop.type}>
            <div className="bottom_sheet">
                <a onClick={slideExit}>close</a>
                <div className="bs_body">
                <em>{lan.L('평균 기록')}</em>
                <div className="scroll_container">
                    <div className="list_selection">
                    {averagehtmllist}
                    </div>
                </div>
                </div>
            </div>
        </div>
	)
}