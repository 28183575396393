import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import LinkButton from '../common/component/linkButton';
import axios from 'axios';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common'
import $ from 'jquery';

import imageCompression from 'browser-image-compression';

	//console.log(sessionStorage.getItem('access_token'));

function Myinfo(){
		
	const [InfoJson, SetInfoJson] = useState({});
	const [ProfileImg, SetProfileImg] = useState('');
	const location = useLocation();
	const navigate = useNavigate();
	var data;
	var changedImage;
	
	const loginID = sessionStorage.getItem('showLoginID');
	
	//최초실행
	useEffect(() => {

		//회원정보조회
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {userGuid: sessionStorage.getItem('guid')},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 정보 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
			console.log(response);
			data = response.data;

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError('내 정보',response.data.error.code);
				return false;
			}
			else
			{
				if(data.email==null) data.email = '';
				//SetInfoJson(Object.assign(InfoJson,data));
				sessionStorage.setItem('address1',data.address1)
				sessionStorage.setItem('address2',data.address2)
				sessionStorage.setItem('birthday',data.birthday)
				sessionStorage.setItem('cdn',data.cdn)
				sessionStorage.setItem('city',data.city)
				sessionStorage.setItem('country',data.country)
				sessionStorage.setItem('email',data.email)
				sessionStorage.setItem('gender',data.gender)
				sessionStorage.setItem('isTermsAgreeNotificationSMS',data.isTermsAgreeNotificationSMS)
				sessionStorage.setItem('mobilePhone',data.mobilePhone)
				sessionStorage.setItem('mobilePhoneCountry',data.mobilePhoneCountry)
				sessionStorage.setItem('nickname',data.nickname)
				sessionStorage.setItem('profilePath',data.profilePath)
				sessionStorage.setItem('province',data.province)
				sessionStorage.setItem('zipcode',data.zipcode)

				//회원 라운드 설정
				axios({
					method: 'post',
					url: process.env.REACT_APP_SERVER + '/api',
					data: {userGuid: sessionStorage.getItem('guid')},
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'language':sessionStorage.getItem('Language'),
						'pUrl' : encodeURIComponent('회원 게임 설정 정보 조회'),
						'guid' : sessionStorage.getItem('guid'),
						'token' : sessionStorage.getItem('access_token')
					}
				}).then(function (response) {
					//console.log(response);
					data = Object.assign(data,response.data);
					console.log(data.province == 'null');
					var fulladdress = '';
					if(data.province !==null && data.province != 'null') fulladdress = fulladdress + data.province + ' ';
					if(data.city !==null && data.city != 'null') fulladdress = fulladdress + data.city + ' ';
					if(data.address1 !==null && data.address1 != 'null') fulladdress = fulladdress + data.address1;
					data = Object.assign(data,{fulladdress : fulladdress});
					console.log(data);

					if(response.data.error !== undefined && response.data.error.code > 0)
					{
						common.alertByError('내 정보',response.data.error.code);
						return false;
					}

					SetInfoJson(data);

					//SetInfoJson(Object.assign(InfoJson,data));
					sessionStorage.setItem('difficulty',data.difficulty)
					sessionStorage.setItem('teeBoxType',data.teeBoxType)
					sessionStorage.setItem('teeHeight',data.teeHeight)
					sessionStorage.setItem('clientDirection',data.clientDirection)

					$("#clientDirection"+data.clientDirection).attr("checked",true);
					
					if(data.profilePath !== undefined || data.profilePath != null)
					{
						var profilepath = data.cdn + data.profilePath;
						SetProfileImg(profilepath);
					}

				}).catch(error => {
					console.log(error.response)
				});
				//회원 라운드 설정 종료
			}
		}).catch(error => {
			console.log(error.response)
		});


	},[location]);

	
	const onClick = (e) => {
		var id = e.target.id;
		if(id == 'modify_address')
		{
			window.localStorage.setItem('modify_zipcode','');
			window.localStorage.setItem('modify_address1','');
			navigate("/setup/modify_address");
		}
	}

	const getPhoto = (e) => {
		
        if(common.isIOS()){
            common.iOSCallApp({appid:"getPermissionCamera", params:"", funcname:"window.NativeInterface.getPermissionCamera"});
        }
        else
        {
            try{common.andriodCallApp('getPermissionStorage','');}
            catch (ex){}
			
            try{common.andriodCallApp('getPermissionCamera','');}
            catch (ex){console.log('getPermissionCamera');}
        }
	}
	
	const changePhoto = (e) => {

		
		// if(e.target.id == 'deleteprofile')
		// {
		// 	var photoFile = document.getElementById("photodefaultfile");
		// 	const defailtfile = new File([], require('../images/file/profile_default.jpg'), {  
        //     	type: 'image/jpeg'  
        // 	});  
		
		// 	frm.append("photofile", defailtfile);
		// }
		// else
		// {
		// 	var photoFile = document.getElementById("photofile");
		// 	frm.append("photofile", photoFile.files[0]);
		// }
		
		var photoFile = document.getElementById("photofile");
		handleFileOnChange(photoFile.files[0], e);
	}

	const handleFileOnChange = async (file, e) => {

		var frm = new FormData();
		
		// 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
		const options = {
			maxSizeMB: 2, 
			maxWidthOrHeight: 1000
		}
		
		try {
		  var compressedFile = await imageCompression(file, options);
		  compressedFile = new File([compressedFile], file.name, {'type': 'image/jpeg'})

		  console.log(file);
		  console.log(compressedFile);

		frm.append("photofile", compressedFile);

		//회원 프로필 이미지 수정
		axios.post(process.env.REACT_APP_SERVER + '/api', frm, 
		{
			headers: {
				'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 프로필 이미지 수정'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token')
			}
		}
		).then(function (response) {
			console.log(response);
			//console.log(response.data);
			//data = response.data;
			
			// if(response.data.error !== undefined && response.data.error.code > 0)
			// {
			// 	common.alertByError('내 정보',response.data.error.code);
			// 	return false;
			// }
			// else
			// {
				if(e.target.id == 'deleteprofile')
				{
					navigate("/common/script/backnavigation", {state : {back:'/setup/myinfo'}, replace : true});
				}
				else
				{
					SetProfileImg(URL.createObjectURL(e.target.files[0]));
				}
			// }

		}).catch(error => {
			common.alertByError('내 정보',"회원 프로필 이미지 수정 에러 발생");
			console.log(error.response);
		});
		  
		  // resize된 이미지의 url을 받아 fileUrl에 저장
		//   const promise = imageCompression.getDataUrlFromFile(compressedFile);
		//   promise.then(result => {
		// 	//console.log(result);
		// 	return result;
		//   })
		} catch (error) {
			console.log(error);
		}
	}
	  
	const changeClientDirection = (value) => {

		var myinfoJson = {"difficulty" : InfoJson.difficulty, "teeBoxType" : InfoJson.teeBoxType, "teeHeight" : InfoJson.teeheight, "clientDirection" : value };

		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: myinfoJson,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 게임 설정 정보 수정'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
			var data = response.data;
			console.log(response);
	
			// if(response.data.error !== undefined && response.data.error.code > 0)
			// {
			// 	common.alertByError('내 정보',response.data.error.code);
			// 	return false;
			// }
	
			sessionStorage.setItem('clientDirection', myinfoJson.clientDirection);
		}).catch(error => {
			//console.log(error.response)
		});
	  }
	  
	return (
	<div className="container">
	  <div className="myinfo">
		<div className="edit_profile">
		  <form id="form01">
		  {/*<a id="deleteprofile"onClick={changePhoto} >{lan.L('사진초기화')}</a>*/}
		  <input type="file" id="photofile" onClick={getPhoto} onChange={changePhoto} accept="image/gif, image/jpeg, image/png" />
		  <p id="profilep" style={ProfileImg=='' ?{backgroundImage: "url("+require('../images/sub/main_noimage.png')+")"}:{backgroundImage: "url("+ProfileImg+")"}}>{lan.L('프로필사진')}</p>
		  </form>
		</div>
	  </div>
	  <div className="revise">
		<div className="grouping">
		  <strong>{lan.L('기본정보')}</strong>
		</div>
		<ul>
		  <li>
			<em>{lan.L('아이디')}</em>
			<span>{loginID}</span>
		  </li>
		  <li className="">
			<em>{lan.L('닉네임')}</em>
			<Link to="/setup/modify_nickname">{InfoJson.nickname}</Link>
		  </li>
		  <li>
			<em>{lan.L('휴대폰번호')}</em>
			<Link to="/setup/modify_phone">{InfoJson.mobilePhone === undefined ? '' : common.cvtPhone(InfoJson.mobilePhone.replace("+"+common.getPhoneValueBySettingValue(InfoJson.mobilePhoneCountry),'0'),'-')}</Link>
		  </li>
		  <li>
			<em>{lan.L('주소')}</em>
			<a id="modify_address" className="new-line" onClick={onClick}>{InfoJson.fulladdress == 'null' ? '' : InfoJson.fulladdress+'\n'}{InfoJson.address2 == 'null' ? '' : InfoJson.address2}</a>
		  </li>
		</ul>
		<div className="grouping">
		  <strong>{lan.L('추가정보')}</strong>
		  <LinkButton type="button" to='/setup/modify_addition'>{lan.L('수정')}</LinkButton>
		</div>
		<ul>
		  <li>
			<em>{lan.L('성별')}</em>
			<span>{InfoJson.gender == 0 ? lan.L('남성'):lan.L('여성')}</span>
		  </li>
		  <li>
			<em>{lan.L('생년월일')}</em>
			<span>{common.getStringToDate(InfoJson.birthday,'-')}</span>
		  </li>
		</ul>
		<div className="grouping">
		  <strong>{lan.L('라운드 설정')}.</strong>
		  <LinkButton type="button" to='/setup/modify_round'>{lan.L('수정')}</LinkButton>
		</div>
		<ul>
		  <li>
			<em>{lan.L('난이도')}</em>
			<span>{lan.L(common.commonKeyDic['difficulty'][InfoJson.difficulty])}</span>
		  </li>
		  <li>
			<em>{lan.L('티 위치')}</em>
			<span>{lan.L(common.getCommonKeyString(InfoJson.teeBoxType, 'teeBoxType'))}</span>
		  </li>
		  <li>
			<em>{lan.L('티 높이')}</em>
			<span>{InfoJson.teeHeight +'mm'}</span>
		  </li>
		</ul>
		<ul>
		  <li className="nocontent">
			<em>{lan.L('타석 방향')}</em>
			<div className="wrap_check">
				<a className="nocontent">
				<input type="radio" id="clientDirection1" name="clientDirection" onChange={(e)=>changeClientDirection(1)} />
				<label htmlFor="clientDirection1">{lan.L('좌타')}</label>
				</a>
				<a className="nocontent">
				<input type="radio" id="clientDirection0" name="clientDirection" onChange={(e)=>changeClientDirection(0)} />
				<label htmlFor="clientDirection0">{lan.L('우타')}</label>
				</a>
			</div>
		  </li>
		</ul>
	  </div>
	</div>
	)
}

export default Myinfo