import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common.js';
import $ from 'jquery';
import axios from 'axios';

import { useDispatch, useSelector } from "react-redux";

import Tab from '../inc/tab';

import BottomStatisticAVG from '../popup/bottom_statisticAVG.js';
import * as averageslide from '../popup/bottom_statisticAVG.js';

import BottomStatisticPrac from '../popup/bottom_statisticprac.js';
import * as practiceslide from '../popup/bottom_statisticprac.js';

function Statistic() {

	const statistictab = [{tabName:'스트로크'},{tabName:'대회'},{tabName:'연습'}]
	const [activeTab, setactiveTab] = useState('');

	const location = useLocation();
	const navigate = useNavigate();
	const selector = useSelector((state) => state);
	const dispatch = useDispatch();

	const [InfoDataStroke, SetInfoDataStroke] = useState([]);
	const [InfoDataStrokeAVG, SetInfoDataStrokeAVG] = useState([]);
	const [InfoDataTournament, SetInfoDataTournament] = useState([]);
	const [InfoDataTournamentAVG, SetInfoDataTournamentAVG] = useState([]);
	const [InfoDataPractice, SetInfoDataPractice] = useState([]);

	//최초실행
	useEffect(() => {
		window.addEventListener('storage', () => {
			setactiveTab(window.localStorage.getItem('tabNum'));

			if(activeTab != window.localStorage.getItem('tabNum'))
			{
				setactiveTab(window.localStorage.getItem('tabNum'));
			}
		});
	},[]);
	
	//selector가 변경될때 상시 실행
	useEffect(() => {
		getStroke();
		getStrokeAVG();
		getTournament();
		getTournamentAVG();
		getPractice();
	},[selector.statisticReducer]);
	//selector가 변경될때 상시 실행 종료
	
	//페이지가 변경될때 상시 실행
	useEffect(() => {
		initReducer();
		getStroke();
		getStrokeAVG();
		getTournament();
		getTournamentAVG();
		getPractice();
	},[location]);

	const getStroke = () => {
		//회원 통계 데이터 조회 API
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {
				searchText:'',
				page:1,
				pagesize:2
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 통계 데이터 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
			}
		}).then(function (response) {
			// console.log('[회원 통계] ::: ', response.data);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError(lan.L('통계 정보'),response.data.error.code);
				return false;
			}
			else
			{
				SetInfoDataStroke(response.data);
			}
		}).catch(error => {
			console.log(error.response)
		});
	}
	
	const getStrokeAVG = () => {

		//게임 라운드 평균 통계 데이터 조회 API (스트로크)
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {
				recentGameRoundCount :selector.statisticReducer.stroke_recentGameRoundCount,
				isTournament:false,
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('게임 라운드 평균 통계 데이터 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
			}
		}).then(function (response) {
			 // console.log('[스트로크 평균] ::: ', response.data);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError(lan.L('통계 정보'),response.data.error.code);
				return false;
			}
			else
			{
				SetInfoDataStrokeAVG(response.data);
			}
		}).catch(error => {
			console.log(error.response)
		});
	}

	const getTournament = () => {
		//회원 대회 통계 데이터 조회 API
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 대회 통계 데이터 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
			}
		}).then(function (response) {
			// console.log('[회원 대회 통계] ::: ', response.data);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				if(window.localStorage.getItem('tabNum') == 1)
				common.alertByError(lan.L('통계 정보'),response.data.error.code);
				return false;
			}
			else
			{
				SetInfoDataTournament(response.data);
			}
		}).catch(error => {
			console.log(error.response)
		});
	}
	
	const getTournamentAVG = () => {

		//게임 라운드 평균 통계 데이터 조회 API (대회)
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {
				recentGameRoundCount : selector.statisticReducer.tournament_recentGameRoundCount,
				isTournament:true,
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('게임 라운드 평균 통계 데이터 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
			}
		}).then(function (response) {
			// console.log('[대회 평균] ::: ', response.data);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError(lan.L('통계 정보'),response.data.error.code);
				return false;
			}
			else
			{
				SetInfoDataTournamentAVG(response.data);
			}
		}).catch(error => {
			console.log(error.response)
		});
	}

	const getPractice = () => {
		//연습장 평균 통계 데이터 조회 API
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {
				drivingRangeClub :selector.statisticReducer.drivingRangeClub,
				approachDistance:Object.values(common.commonKeyDic['approachDistance'])[selector.statisticReducer.approachDistance],
				straightPuttingDistance:Object.values(common.commonKeyDic['straightPuttingDistance'])[selector.statisticReducer.straightPuttingDistance]
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('연습장 평균 통계 데이터 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
			}
		}).then(function (response) {
			// console.log('[연습장] ::: ', response.data);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				//common.alertByError(lan.L('통계 정보'),response.data.error.code);
				return false;
			}
			else
			{
				SetInfoDataPractice(response.data);
			}
		}).catch(error => {
			console.log(error.response)
		});
	}
	
	//Reducer값을 초기화 진행(탭 변경시 초기화)
	const initReducer = () => {
		dispatch({type:'initstate', value: ''});
	}
	
	//특정 메뉴로 이동(location.href와 동일개념)
	function goMenu(to, senddata){
		if(senddata !== undefined)
		{
			initReducer();
			navigate(to, {state : {backTabTopics : 2,Data : senddata}, replace:true });
		}
	}

  return (
	<>
	<Tab topics={statistictab} defaultTopics={0} tabkey={"통계 정보"} ></Tab>  {/*탭 컴포. 특이점이 없으면 index에 있지만 onChangefunc를 prop으로 전달하기 위해 tab컴포가 여기있음*/}
	<BottomStatisticAVG defaultvalue="0" type="stroke"></BottomStatisticAVG>{/*하단팝업. 스트로크와 대회 팝업 구분을 위해 prop.type으로 구분처리*/}
	<BottomStatisticAVG defaultvalue="0" type="tournament"></BottomStatisticAVG>
	<BottomStatisticPrac defaultvalue="0"></BottomStatisticPrac>
  <div>
	<div className="contents">
	{/*------------------------------------ 스트로크 ------------------------------------*/}
	<aside style={activeTab==0 ? {display:'block'} : {display:'none'}}>
	  <ul className="section">
		<li>
		  <em>{lan.L('나의 최고 기록')}</em>
		  <div className="best_score">
			<span>
			  <b>{lan.L('베스트 스코어')}</b>
			  <strong>{InfoDataStroke.bestScore}</strong>
			  <small>{lan.L('score')}</small>
			</span>
			<span>
			  <b>{lan.L('최대 비거리')}</b>
			  <strong>{InfoDataStroke.bestDistance=== undefined || InfoDataStroke.bestDistance == null ? '' : common.Length(parseFloat(InfoDataStroke.bestDistance).toFixed(2),'0m')}</strong>
			  <small>{common.Length('','meter(m)')}</small>
			</span>
			<span>
			  <b>{lan.L('최장거리 퍼팅')}</b>
			  <strong>{InfoDataStroke.longestPutterDistance=== undefined || InfoDataStroke.longestPutterDistance == null ? '' : common.Length(parseFloat(InfoDataStroke.longestPutterDistance).toFixed(2),'0m')}</strong>
			  <small>{common.Length('','meter(m)')}</small>
			</span>
		  </div>
		</li>
		<li>
		  <em>{lan.L('평균 기록')}</em>
		  <label onClick={(e)=>averageslide.slideAverage('stroke')}>{lan.L('최근 '+selector.statisticReducer.stroke_recentGameRoundCount+'회')}</label>
		  <div className="list_average">
			<dl>
			  <dt>{lan.L('드라이버 평균 기록')}</dt>
			  <dd>
				<b>{lan.L('비거리')}</b>
				<sup>{InfoDataStrokeAVG.averageDistance=== undefined || InfoDataStrokeAVG.averageDistance == null ? '' : common.Length(parseFloat(InfoDataStrokeAVG.averageDistance).toFixed(2),'0')}<sub>{common.Length('','m')}</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('볼스피드')}</b>
				<sup>{common.Speed(InfoDataStrokeAVG.averageBallSpeed,'0')}<sub>{common.Speed('','m/s')}</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('페어웨이 안착률')}</b>
				<sup>{InfoDataStrokeAVG.drivingAccuracy}<sub>%</sub></sup>
			  </dd>
			</dl>
			<dl>
			  <dt>{lan.L('우드/아이언 평균 기록')}</dt>
			  <dd>
				<b>{lan.L('그린 적중률')}</b>
				<sup>{InfoDataStrokeAVG.gir}<sub>%</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('좌우편차(R)')}</b>
				<sup>{InfoDataStrokeAVG.lateralDeviation}<sub>{common.Length('','m')}</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('센드 세이브율')}</b>
				<sup>{InfoDataStrokeAVG.sandSaves}<sub>%</sub></sup>
			  </dd>
			</dl>
			<dl>
			  <dt>{lan.L('퍼터 평균 기록')}</dt>
			  <dd>
				<b>{lan.L('홀당 퍼팅 횟수')}</b>
				<sup>{InfoDataStrokeAVG.averagePuttingCount}<sub></sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('총 퍼팅 횟수')}</b>
				<sup>{InfoDataStrokeAVG.totalPuttingCount}<sub></sub></sup>
			  </dd>
			</dl>
			<dl>
			  <dt>{lan.L('평균 스코어')}</dt>
			  <dd>
				<b>{lan.L('스트로크 스코어')}</b>
				<sup>{InfoDataStrokeAVG.strokeScore}</sup>
			  </dd>
			  <dd>
				<b>{lan.L('신페리오 스코어')}</b>
				<sup>{InfoDataStrokeAVG.newPerioScore}</sup>
			  </dd>
			  <dd>
				<b>{lan.L('더블보기 이하')}</b>
				<sup>{InfoDataStrokeAVG.doubleBogey === undefined ? '' : InfoDataStrokeAVG.doubleBogey.toFixed(2)}<sub>%</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('파 이상')}</b>
				<sup>{InfoDataStrokeAVG.par === undefined ? '' : InfoDataStrokeAVG.par.toFixed(2)}<sub>%</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('보기')}</b>
				<sup>{InfoDataStrokeAVG.bogey === undefined ? '' : InfoDataStrokeAVG.bogey.toFixed(2)}<sub>%</sub></sup>
			  </dd>
			</dl>
		  </div>
		</li>
	  </ul>
	</aside>

	{/*------------------------------------ 대회 ------------------------------------*/}
	<aside style={activeTab==1 ? {display:'block'}: {display:'none'}}>
	  <ul className="section">
		<li>
		  <em>{lan.L('나의 베스트 랭킹')}</em>
		  <div className="best_score">
			<span>
			  <b>{lan.L('스트로크')}</b>
			  <strong>{InfoDataTournament.strokeRanking}<sub>{common.getSequenceFormatText(InfoDataTournament.strokeRanking)}</sub></strong>
			  <small>{lan.L('Ranking')}</small>
			</span>
			<span>
			  <b>{lan.L('롱기스트')}</b>
			  <strong>{InfoDataTournament.longestRanking}<sub>{common.getSequenceFormatText(InfoDataTournament.longestRanking)}</sub></strong>
			  <small>{lan.L('Ranking')}</small>
			</span>
			<span>
			  <b>{lan.L('니어핀')}</b>
			  <strong>{InfoDataTournament.nearpinRanking}<sub>{common.getSequenceFormatText(InfoDataTournament.nearpinRanking)}</sub></strong>
			  <small>{lan.L('Ranking')}</small>
			</span>
		  </div>
		</li>
		<li>
		  <em>{lan.L('나의 최고 기록')}</em>
		  <div className="best_score">
			<span>
			  <b>{lan.L('베스트 스코어')}</b>
			  <strong>{InfoDataTournament.bestScore}</strong>
			  <small>{lan.L('score')}</small>
			</span>
			<span>
			  <b>{lan.L('최대 비거리')}</b>
			  <strong>{InfoDataTournament.bestDistance=== undefined  || InfoDataTournament.bestDistance == null ? '' : common.Length(parseFloat(InfoDataStroke.bestDistance).toFixed(2),'0m')}</strong>
			  <small>{lan.L('meter(m)')}</small>
			</span>
			<span>
			  <b>{lan.L('최장거리 퍼팅')}</b>
			  <strong>{InfoDataTournament.longestPutterDistance=== undefined || InfoDataTournament.longestPutterDistance == null ? '' : common.Length(parseFloat(InfoDataStroke.longestPutterDistance).toFixed(2),'0m')}</strong>
			  <small>{lan.L('meter(m)')}</small>
			</span>
		  </div>
		</li>
		<li>
		  <em>{lan.L('평균 기록')}</em>
		  <label onClick={(e)=>averageslide.slideAverage('tournament')}>{lan.L('최근 '+selector.statisticReducer.tournament_recentGameRoundCount+'회')}</label>
		  <div className="list_average">
			<dl>
			  <dt>{lan.L('드라이버 평균 기록')}</dt>
			  <dd>
				<b>{lan.L('비거리')}</b>
				<sup>{InfoDataTournamentAVG.averageDistance=== undefined || InfoDataTournamentAVG.averageDistance == null  ? '' : common.Length(parseFloat(InfoDataTournamentAVG.averageDistance).toFixed(2),'0')}<sub>{common.Length('','m')}</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('볼스피드')}</b>
				<sup>{InfoDataTournamentAVG.averageBallSpeed}<sub>m/s</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('페어웨이 안착률')}</b>
				<sup>{InfoDataTournamentAVG.drivingAccuracy}<sub>%</sub></sup>
			  </dd>
			</dl>
			<dl>
			  <dt>{lan.L('우드/아이언 평균 기록')}</dt>
			  <dd>
				<b>{lan.L('그린 적중률')}</b>
				<sup>{InfoDataTournamentAVG.gir}<sub>%</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('좌우편차(R)')}</b>
				<sup>{InfoDataTournamentAVG.lateralDeviation}<sub>{common.Length('','m')}</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('센드 세이브율')}</b>
				<sup>{InfoDataTournamentAVG.sandSaves}<sub>%</sub></sup>
			  </dd>
			</dl>
			<dl>
			  <dt>{lan.L('퍼터 평균 기록')}</dt>
			  <dd>
				<b>{lan.L('홀당 퍼팅 횟수')}</b>
				<sup>{InfoDataTournamentAVG.averagePuttingCount}<sub>{lan.L('회')}</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('총 퍼팅 횟수')}</b>
				<sup>{InfoDataTournamentAVG.totalPuttingCount}<sub>{lan.L('회')}</sub></sup>
			  </dd>
			</dl>
			<dl>
			  <dt>{lan.L('평균 스코어')}</dt>
			  <dd>
				<b>{lan.L('스트로크 스코어')}</b>
				<sup>{InfoDataTournamentAVG.strokeScore}</sup>
			  </dd>
			  <dd>
				<b>{lan.L('신페리오 스코어')}</b>
				<sup>{InfoDataTournamentAVG.newPerioScore}</sup>
			  </dd>
			  <dd>
				<b>{lan.L('더블보기 이하')}</b>
				<sup>{InfoDataTournamentAVG.doubleBogey === undefined ? '' : InfoDataTournamentAVG.doubleBogey.toFixed(2)}<sub>%</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('파 이상')}</b>
				<sup>{InfoDataTournamentAVG.par === undefined ? '' : InfoDataTournamentAVG.par.toFixed(2)}<sub>%</sub></sup>
			  </dd>
			  <dd>
				<b>{lan.L('보기')}</b>
				<sup>{InfoDataTournamentAVG.bogey === undefined ? '' : InfoDataTournamentAVG.bogey.toFixed(2)}<sub>%</sub></sup>
			  </dd>
			</dl>
		  </div>
		</li>
	  </ul>
	</aside>

	{/*------------------------------------ 연습 ------------------------------------*/}
	<aside style={activeTab==2 ? {display:'block'}: {display:'none'}}>
	  <ul className="section">
		<li>
		  <div className="alter">
			<em>{lan.L('드라이빙 레인지')}</em>
			<label onClick={(e)=>practiceslide.slidestatisticClub()}>{lan.L(Object.values(common.commonKeyDic['recommandClub'])[selector.statisticReducer.drivingRangeClub])}</label>
			<button type="button" onClick={(e)=>goMenu('/statistic/detail_driving',InfoDataPractice.drivingRange)}>{lan.L('상세보기')}</button>
		  </div>
		  <div className="best_score">
			<span>
			  <b>{lan.L('평균 비거리')}</b>
			  <strong>{InfoDataPractice.drivingRange=== undefined ? '' : InfoDataPractice.drivingRange.averageTotalDistance}</strong>
			  <small>{lan.L('meter(m)')}</small>
			</span>
			<span>
			  <b>{lan.L('평균 볼스피드')}</b>
			  <strong>{InfoDataPractice.drivingRange=== undefined ? '' : InfoDataPractice.drivingRange.averageBallSpeed}</strong>
			  <small>{lan.L('m/s')}</small>
			</span>
			<span>
			  <b>{lan.L('평균 발사각')}</b>
			  <strong>{InfoDataPractice.drivingRange=== undefined ? '' : InfoDataPractice.drivingRange.averageLaunchAngle}</strong>
			  <small>{lan.L('angle')}( ˚ )</small>
			</span>
		  </div>
		</li>
		<li>
		  <div className="alter">
			<em>{lan.L('어프로치')}</em>
			<label onClick={(e)=>practiceslide.slidestatisticApproach()}>{common.Length(Object.values(common.commonKeyDic['approachDistance'])[selector.statisticReducer.approachDistance],'0m')}</label>
			<button type="button" onClick={(e)=>goMenu('/statistic/detail_approach',InfoDataPractice.approach)}>{lan.L('상세보기')}</button>
		  </div>
		  <div className="best_score">
			<span>
			  <b>{lan.L('퍼펙트')}</b>
			  <strong>{InfoDataPractice.approach=== undefined ? '' : InfoDataPractice.approach.perfectShotPercent}<sub>%</sub></strong>
			  <small>(<q>{InfoDataPractice.approach=== undefined ? '' : InfoDataPractice.approach.perfectShotCount}</q>/{InfoDataPractice.approach=== undefined ? '' : InfoDataPractice.approach.totalShotCount})</small>
			</span>
			<span>
			  <b>{lan.L('나이스')}</b>
			  <strong>{InfoDataPractice.approach=== undefined ? '' : InfoDataPractice.approach.niceShotPercent}<sub>%</sub></strong>
			  <small>(<q>{InfoDataPractice.approach=== undefined ? '' : InfoDataPractice.approach.niceShotCount}</q>/{InfoDataPractice.approach=== undefined ? '' : InfoDataPractice.approach.totalShotCount})</small>
			</span>
			<span>
			  <b>{lan.L('굿샷')}</b>
			  <strong>{InfoDataPractice.approach=== undefined ? '' : InfoDataPractice.approach.goodShotPercent}<sub>%</sub></strong>
			  <small>(<q>{InfoDataPractice.approach=== undefined ? '' : InfoDataPractice.approach.goodShotCount}</q>/{InfoDataPractice.approach=== undefined ? '' : InfoDataPractice.approach.totalShotCount})</small>
			</span>
		  </div>
		</li>
		<li>
		  <div className="alter">
			<em>{lan.L('직선퍼팅')}</em>
			<label onClick={(e)=>practiceslide.slidestatisticStraight()}>{common.Length(Object.values(common.commonKeyDic['straightPuttingDistance'])[selector.statisticReducer.straightPuttingDistance],'0m')}</label>
			<button type="button" onClick={(e)=>goMenu('/statistic/detail_straight',InfoDataPractice.straightPutting)}>{lan.L('상세보기')}</button>
		  </div>
		  <div className="best_score">
			<span>
			  <b>{lan.L('퍼펙트')}</b>
			  <strong>{InfoDataPractice.straightPutting=== undefined ? '' : InfoDataPractice.straightPutting.perfectShotPercent}<sub>%</sub></strong>
			  <small>(<q>{InfoDataPractice.straightPutting=== undefined ? '' : InfoDataPractice.straightPutting.perfectShotCount}</q>/{InfoDataPractice.straightPutting=== undefined ? '' : InfoDataPractice.straightPutting.totalShotCount})</small>
			</span>
			<span>
			  <b>{lan.L('나이스')}</b>
			  <strong>{InfoDataPractice.straightPutting=== undefined ? '' : InfoDataPractice.straightPutting.niceShotPercent}<sub>%</sub></strong>
			  <small>(<q>{InfoDataPractice.straightPutting=== undefined ? '' : InfoDataPractice.straightPutting.niceShotCount}</q>/{InfoDataPractice.straightPutting=== undefined ? '' : InfoDataPractice.straightPutting.totalShotCount})</small>
			</span>
			<span>
			  <b>{lan.L('굿샷')}</b>
			  <strong>{InfoDataPractice.straightPutting=== undefined ? '' : InfoDataPractice.straightPutting.goodShotPercent}<sub>%</sub></strong>
			  <small>(<q>{InfoDataPractice.straightPutting=== undefined ? '' : InfoDataPractice.straightPutting.goodShotCount}</q>/{InfoDataPractice.straightPutting=== undefined ? '' : InfoDataPractice.straightPutting.totalShotCount})</small>
			</span>
		  </div>
		</li>
		<li>
		  <div className="alter">
			<em>{lan.L('곡선퍼팅')}</em>
			<button type="button" onClick={(e)=>goMenu('/statistic/detail_curved',InfoDataPractice.curvedPutting)}>{lan.L('상세보기')}</button>
		  </div>
		  <div className="best_score">
			<span>
			  <b>{lan.L('홀인 비율')}</b>
			  <strong>{InfoDataPractice.curvedPutting=== undefined ? '' : InfoDataPractice.curvedPutting.holeInShotPercent}<sub>%</sub></strong>
			  <small>(<q>{InfoDataPractice.curvedPutting=== undefined ? '' : InfoDataPractice.curvedPutting.holeInShotCount}</q>/{InfoDataPractice.curvedPutting=== undefined ? '' : InfoDataPractice.curvedPutting.totalShotCount})</small>
			</span>
			<span>
			  <b>{lan.L('도달율')}</b>
			  <strong>{InfoDataPractice.curvedPutting=== undefined ? '' : InfoDataPractice.curvedPutting.reachPercent.toFixed(2)}<sub>%</sub></strong>
			</span>
		  </div>
		</li>
		<li>
		  <div className="alter">
			<em>{lan.L('숏 게임')}</em>
			<button type="button" onClick={(e)=>goMenu('/statistic/detail_shortgame',InfoDataPractice.shortGame)}>{lan.L('상세보기')}</button>
		  </div>
		  <div className="best_score">
			<span>
			  <b>{lan.L('퍼펙트')}</b>
			  <strong>{InfoDataPractice.shortGame=== undefined ? '' : InfoDataPractice.shortGame.perfectShotPercent}<sub>%</sub></strong>
			  <small>(<q>{InfoDataPractice.shortGame=== undefined ? '' : InfoDataPractice.shortGame.perfectShotCount}</q>/{InfoDataPractice.shortGame=== undefined ? '' : InfoDataPractice.shortGame.totalShotCount})</small>
			</span>
			<span>
			  <b>{lan.L('나이스')}</b>
			  <strong>{InfoDataPractice.shortGame=== undefined ? '' : InfoDataPractice.shortGame.niceShotPercent}<sub>%</sub></strong>
			  <small>(<q>{InfoDataPractice.shortGame=== undefined ? '' : InfoDataPractice.shortGame.niceShotCount}</q>/{InfoDataPractice.shortGame=== undefined ? '' : InfoDataPractice.shortGame.totalShotCount})</small>
			</span>
			<span>
			  <b>{lan.L('굿샷')}</b>
			  <strong>{InfoDataPractice.shortGame=== undefined ? '' : InfoDataPractice.shortGame.goodShotPercent}<sub>%</sub></strong>
			  <small>(<q>{InfoDataPractice.shortGame=== undefined ? '' : InfoDataPractice.shortGame.goodShotCount}</q>/{InfoDataPractice.shortGame=== undefined ? '' : InfoDataPractice.shortGame.totalShotCount})</small>
			</span>
		  </div>
		</li>
	  </ul>
	</aside>
	</div>{/* contents(e) */}
	<hr />
	</div>
	</>
  );
}

export default Statistic;

