import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common.js';
import ReactPlayer from "react-player";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import Vid from "../images/20221229_01_743430_1201_SF_N_Ro.mp4";
import Vidtest from "../images/9GBo-mountain-video.mp4";

import Top from '../inc/top';

var clickCnt = 0;

var create_dot_arr = [];
var ctx;
var canvas

export default function DetailVideo(props){
	
	const [InfoJson, SetInfoJson] = useState({});
	const [InfoJsonVideo, SetInfoJsonVideo] = useState({});
	const [InfoMapJson, SetMapInfoJson] = useState({});
	const [ProfileImg, SetProfileImg] = useState('');
	const [InfoCourseMap, SetInfoCourseMap] = useState({});
	const [nowMap, SetnowMap] = useState(0);

	const location = useLocation();
	const navigate = useNavigate();
	var data, mapdata;
	const loginID = sessionStorage.getItem('loginID');

	const [isView, SetisView] = useState(false);
	const [isSwingVideoArchive, SetisSwingVideoArchive] = useState(false);
	const [nowVideoLink, SetnowVideoLink] = useState('');
    
	var gameRoundId, gameRoundShotId, shotNumber, roundData, shotData, coursePar, cdn, gameRoundNum,strokeInfo, playernickname;
	var mapplayernum;
	
	useEffect(() => {
		window.dispatchEvent(new Event("storage"));
    });

	if(location.state === undefined || location.state == null)
	{
		common.alertpop(lan.L('라운드 샷 정보'),lan.L("요청하지 않은 경로입니다."));
	}
	else
	{
		gameRoundId			= location.state.gameRoundId;
		gameRoundShotId	= location.state.gameRoundShotId;
		shotNumber				= location.state.shotNumber;
		roundData				= location.state.roundData;
		shotData				= location.state.shotData;
		coursePar				= location.state.coursePar;
		cdn							= location.state.cdn;
		gameRoundNum	= location.state.gameRoundNum;
		playernickname 		= location.state.playernickname;
		if(location.state.strokeInfo !== undefined) strokeInfo = location.state.strokeInfo;
		console.log(strokeInfo);
	}
	
	//console.log(gameRoundId,gameRoundShotId,roundData,shotData,coursePar);

	//화면전환시 실행
	useEffect(() => {
			clickCnt = 0;
			create_dot_arr = [];

			$('html, body').animate({scrollTop:0},0);

			axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('게임 라운드 샷 조회'),
				'guid' : gameRoundId,
				'guid2' : gameRoundShotId,
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response2) {
			console.log(gameRoundId,gameRoundShotId)
			console.log(response2, roundData,shotData);
			shotNumber = response2.data.shotNumber;
			var tempisView = response2.data.isView;

			if(response2.data.error !== undefined && response2.data.error.code > 0)
			{
				common.alertByError('라운드 샷 정보',response2.data.error.code);
				return false;
			}
			else
			{
				var data = Object.assign(response2.data,roundData);
				data = Object.assign(data,shotData);

				//라운드 샷 정보
				//uploads/Recording/Encoding/3ahhw/Normal/20221229_01_743430_1201_SF_N_Ro.mp4
				// cdn = '';
				// data.swingVideo.frontVideoKey = '../images/9GBo-mountain-video.mp4';
				//data.swingVideo.frontVideoKey = 'uploads/Recording/Encoding/3ahhw/Normal/20221229_01_743430_1201_SF_N_Ro.mp4';
				//data.swingVideo.frontVideoThumbnailKey = 'uploads/Recording/Encoding/3ahhw/Normal/20221229_01_743430_1201_SF_N_Ro.jpg'
				//data.swingVideo.frontVideoKey ='https://uploads.codesandbox.io/uploads/user/fb7bd72f-ef17-4810-9e14-ca854fb0f56e/9GBo-mountain-video.mp4'
				//data.swingVideo.frontVideoKey = 'uploads/Recording/Encoding/rxhbh/Normal/test.mp4';
				
				//data.swingVideo.sideVideoKey = 'uploads/Recording/Encoding/rxhbh/Normal/test.mp4';
				SetInfoJson(data);
				SetInfoJsonVideo(data.swingVideo);
				$("#frontVideo").filter(':first-child').attr("poster",cdn+data.swingVideo.frontVideoThumbnailKey);
				SetnowVideoLink(cdn+data.swingVideo.frontVideoKey);
				SetisView(tempisView);
				SetisSwingVideoArchive(data.isSwingVideoArchive);

				$("#secret").prop("checked",!tempisView);

				window.localStorage.setItem('isSwingVideoArchive',data.isSwingVideoArchive);
			}
		}).catch(error => {
			console.log(error.response)
		});
		
		//맵정보
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {ScorecardType : 0},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			    'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('스코어카드 조회'),
				'guid' : gameRoundId,
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
			//console.log(response);
			mapdata = response.data;

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError('라운드 샷 정보',response.data.error.code);
				return false;
			}
			else
			{
                $("#record_background").css({'background-image': 'url('+response.data.cdn+response.data.scorecard[0].gameRoundInfo.courseInfo.intro+')'});
                SetInfoCourseMap(response.data.scorecard[0].gameRoundInfo.courseInfo.courseMapCoordinates);
                SetMapInfoJson(mapdata);

				mapplayernum = 0;

				for(let i=0;i<mapdata.scorecard[0].players.length;i++)
				{
					if(mapdata.scorecard[0].players[i].nickname == playernickname)
					{
						mapplayernum = i;
						break;
					}
				}
                
				setTimeout(function(){
                goMapSelect(null,shotData.holeNumber,mapplayernum);
				}, 150)
			}
		}).catch(error => {
			console.log(error.response)
		});
    },[location]);
	
	
	const goDownload = () => {
		
		if($(".video_container").hasClass("empty"))
		{
			common.alertpop(lan.L('라운드 샷 정보'),lan.L('동영상 파일이 없습니다.'));
		}
		else
		{
			//ios의 경우 콜앱으로 다운로드
			if(common.isIOS()){
				common.iOSCallApp({appid:"goDownload", params: nowVideoLink});
			}
			else //안드로이드같은 경우 a태그로 다운로드
			{
				var a = $("<a>")
					.attr("href", nowVideoLink)
					.attr("download", "")
					.appendTo("body")
					.attr("target","_blank");
		
				a[0].click();
				a.remove();
			}
		}
	}
	
	const goMapSelect = (e, num, player) => {
        
        //set canvas
        canvas = document.getElementById('mapImage');
    
        $("#mapImage").css({'background': "url("+cdn + "images/course/" + location.state.roundData.courseId + "/hole_"+('0'+(Number(num))).slice(-2)+".png) no-repeat" ,
        'background-size' : '100% 100%','width':'80vw', 'height':'125.44vw'});
        SetnowMap(num-1);
        $(".pscore").removeClass("active");
        $("#pscore"+num+"_"+player).addClass("active");
		//console.log(shotData);
        var shotCoordinates = mapdata.scorecard[0].players[player].holes[num-1].shotCoordinates;

		if (canvas.getContext) {
			ctx = canvas.getContext("2d");
		}

        clearCanvas();
		console.log(shotCoordinates);

		setShot(shotCoordinates[shotNumber-1].startCoordinates.x, shotCoordinates[shotNumber-1].startCoordinates.y,num-1);
		setShot(shotCoordinates[shotNumber-1].endCoordinates.x, shotCoordinates[shotNumber-1].endCoordinates.y,num-1);
		
        // for(let i=0;i<shotCoordinates.length;i++)
        // {
		// 	//console.log(shotCoordinates[0].startCoordinates.x);
        //     setShot(shotCoordinates[i].startCoordinates.x, shotCoordinates[i].startCoordinates.y,num);
        //     setShot(shotCoordinates[i].endCoordinates.x, shotCoordinates[i].endCoordinates.y,num);
        // }
	}
	
	const changeVideo = (e,id) => {

		if($("#"+id+"li").hasClass("empty"))
		{
			return false;
		}

		$("#frontVideo").hide();
		$("#sideVideo").hide();
		$("#inGameVideo").hide();
		$("#"+id).show();

		$("#frontVideo").attr('playing',false);
		$("#sideVideo").attr('playing',false);
		$("#inGameVideo").attr('playing',false);

		$("#frontVideoli").removeClass("on");
		$("#sideVideoli").removeClass("on");
		$("#inGameVideoli").removeClass("on");
		$("#"+id+"li").addClass("on");

		if(id=="frontVideo")	SetnowVideoLink(cdn + InfoJsonVideo.frontVideoKey);
		else if(id=="sideVideo")	SetnowVideoLink(cdn + InfoJsonVideo.sideVideoKey);
		else if(id=="inGameVideo")	SetnowVideoLink(cdn + InfoJsonVideo.reactionFrontVideoKey);
		
	}
	
	const changeVideoSlow = (e) => {
		var id = e.target.id;

		$("#vspeed25").removeClass("on");
		$("#vspeed50").removeClass("on");
		$("#vspeed75").removeClass("on");
		$("#vspeed100").removeClass("on");

		if(id=="vspeed100")
		{
			$("#vspeed75").addClass("on");
			document.querySelectorAll('video')[0].playbackRate = 0.75;
			document.querySelectorAll('video')[1].playbackRate = 0.75;
			document.querySelectorAll('video')[2].playbackRate = 0.75;
		}

		if(id=="vspeed75")
		{
			$("#vspeed50").addClass("on");
			document.querySelectorAll('video')[0].playbackRate = 0.5;
			document.querySelectorAll('video')[1].playbackRate = 0.5;
			document.querySelectorAll('video')[2].playbackRate = 0.5;
		}

		if(id=="vspeed50")
		{
			$("#vspeed25").addClass("on");
			document.querySelectorAll('video')[0].playbackRate = 0.25;
			document.querySelectorAll('video')[1].playbackRate = 0.25;
			document.querySelectorAll('video')[2].playbackRate = 0.25;
		}
		
		if(id=="vspeed25")
		{
			$("#vspeed100").addClass("on");
			document.querySelectorAll('video')[0].playbackRate = 1.0;
			document.querySelectorAll('video')[1].playbackRate = 1.0;
			document.querySelectorAll('video')[2].playbackRate = 1.0;
		}
		
	}
	
	const disableVideo = (id) => {
		$("#"+id).addClass("empty");

		if(id=="frontVideoli")
		{
			$("#frontVideo").hide();
			$("#playbtn").hide(); 
			$(".video_container").addClass("empty");//비디오가 숨겨져있으면 영상이 없는 상태
		}
	}
	
	const goInsta = () => {

		if($(".video_container").hasClass("empty"))
		{
			common.alertpop(lan.L('라운드 샷 정보'),lan.L('동영상 파일이 없습니다.'));
		}
		else
		{
			if(common.isIOS()){
				common.iOSCallApp({appid:"goInsta", params: nowVideoLink});
			}
			else
			{
				try{common.andriodCallApp('goInsta',[nowVideoLink]);}catch (ex){
					window.NativeInterface.getIntro('{"videourl":'+nowVideoLink+'}');
				}
			}
		}
		
	}
	
	const showVideo = () => {
		if($(".video_container").hasClass("empty"))
		{
			common.alertpop(lan.L('라운드 샷 정보'),lan.L('동영상 파일이 없습니다.'));
		}
		else
		{
			if(common.isIOS()){
				common.iOSCallApp({appid:"showVideo", params: nowVideoLink});
			}
			else
			{
				try{common.andriodCallApp('showVideo',[nowVideoLink]);}catch (ex){
					window.NativeInterface.getIntro('{"videourl":'+nowVideoLink+'}');
				}
			}
		}
	}
	
	const changeVideoArchive = (change) => {

		var data = {
			isView: change== "isView" ? !isView : isView,
			isSwingVideoArchive:change== "isSwingVideoArchive" ? !isSwingVideoArchive : isSwingVideoArchive,
		};

		console.log(data,gameRoundId,gameRoundShotId);

		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: data,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('게임 라운드 샷 업데이트'),
				'token' : sessionStorage.getItem('access_token'),
				'guid' : gameRoundId,
				'guid2' : gameRoundShotId,
			}
		}).then(function (response) {
			console.log(response);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				if(change == "isView")
				{$("#secret").prop("checked",data.isView);}
				common.alertByError('라운드 샷 정보',response.data.error.code);
				return false;
			}
			
			if(change== "isSwingVideoArchive" && isSwingVideoArchive==true) common.alertpop(lan.L('라운드 샷 정보'),lan.L('보관해제 되었습니다.'));
			else if(change== "isSwingVideoArchive" && isSwingVideoArchive==false) common.alertpop(lan.L('라운드 샷 정보'),lan.L('보관 되었습니다.'));
			else if(change== "isView" && isView==true) common.alertpop(lan.L('라운드 샷 정보'),lan.L('비공개 되었습니다.'));
			else if(change== "isView" && isView==false) common.alertpop(lan.L('라운드 샷 정보'),lan.L('공개 되었습니다.'));

			if(change== "isView") SetisView(!isView);
			else if(change== "isSwingVideoArchive") SetisSwingVideoArchive(!isSwingVideoArchive);
			
		}).catch(error => {
			console.log(error.response)
		});
	}
	
	return (
	<>
	{/* lockFunction={(e)=>changeVideoArchive("isView")} */}
	<Top class="topfix" title={"라운드 샷 정보"} lockFunction={(e)=>changeVideoArchive("isView")}  type='backwithstate' state={{activeTab : 0, strokeInfo : strokeInfo, backgameRoundId : gameRoundId, backgameRoundNum : gameRoundNum, roundData : roundData}} to={strokeInfo === undefined ? '/shot/shotvideo' : '/record/detail_stroke'} backTabTopics="0"/> 
	<div className="contents">
	  <div className="roundshot_info">
		<dl>
		  <dt>{InfoJson.courseName}</dt>
		  <dd>{common.UnixTime(InfoJson.startTime,'년-월-일')}</dd>
		</dl>
		<span>
		  <small>{InfoJson.shopName}</small>
		  <small>{InfoJson.gameType}</small>
		</span>
		<div className="basic_marks">
		{InfoJson.shotGrade != undefined && InfoJson.shotGrade != 0 && InfoJson.shotGrade != 4 ? <i className={common.commonKeyDic['roundshotGrade'][InfoJson.shotGrade].toLowerCase()}>{common.commonKeyDic['roundshotGrade'][InfoJson.shotGrade]}</i> : ''}
		  <i>{common.commonKeyDic['recommandClub'][InfoJson.recommandClub]}</i>
		  <em><b>{InfoJson.holeNumber+"H"}</b>{lan.L('Par')+coursePar}</em>
		  <div className="score_point">
			<b>{InfoJson.score}</b>
			<small>score</small>
		  </div>
		</div>
	  </div>
	  <div className="player_wrap">
		<div className="storage">
		  <button type="button" className={isSwingVideoArchive==true ? "keep on" : "keep"} onClick={(e)=>changeVideoArchive("isSwingVideoArchive")}>{isSwingVideoArchive==true ? lan.L('해제') : lan.L('보관')}</button>
		  <button type="button" className="save" onClick={goDownload}>{lan.L('저장')}</button>
		  <a onClick={goInsta}>sns</a>
		</div>
			{common.isIOS() ?
			<div className="video_container">{/* 파일없을 시 class="empty" 추가 */}
			<span>{lan.L('동영상 파일이 없습니다.')}</span>{/* class="play/stop"  */}
			<div className="speed">
			<button type="button" className id="vspeed25" onClick={changeVideoSlow}>0.25x</button>
			<button type="button" className id="vspeed50" onClick={changeVideoSlow}>0.50x</button>
			<button type="button" className id="vspeed75" onClick={changeVideoSlow}>0.75x</button>
			<button type="button" className="on" id="vspeed100" onClick={changeVideoSlow}>1x</button>
			</div>
		  <ReactPlayer
		  			id="frontVideo"
                    url={InfoJson.swingVideo===undefined ? '' : cdn+InfoJsonVideo.frontVideoKey}    // 플레이어 url
					playing={false}
					controls={true}
					loop={true}
					muted={true}
					playsinline={true}
					width={window.innerWidth}
					height={window.innerHeight / 4}
				/>
		  <ReactPlayer
		  			id="sideVideo"
                    url={InfoJson.swingVideo===undefined ? '' : cdn+InfoJsonVideo.sideVideoKey}    // 플레이어 url
					playing={false}
					controls={true}
					loop={true}
					muted={true}
					playsinline={true}
					width={window.innerWidth}
					height={window.innerHeight / 4}
					style={{'display':'none'}}
                />
		  <ReactPlayer
		  			id="inGameVideo"
                    url={InfoJson.swingVideo===undefined ? '' : cdn+InfoJsonVideo.reactionFrontVideoKey}    // 플레이어 url
					playing={false}
					controls={true}
					loop={true}
					muted={true}
					playsinline={true}
					width={window.innerWidth}
					height={window.innerHeight / 4}
					style={{'display':'none'}}
                />
		</div>
		:
		<div className="video_container">{/* 파일없을 시 class="empty" 추가 */}
			<span>{lan.L('동영상 파일이 없습니다.')}</span>{/* class="play/stop"  */}
			<a className="play"onClick={showVideo} id="playbtn">{lan.L('재생버튼')}</a>

			<img 
		  		id="frontVideo"
				src={InfoJsonVideo.frontVideoThumbnailKey === undefined ? require('../images/sub/noimage_ci_w.png') :  cdn+InfoJsonVideo.frontVideoThumbnailKey}
				style={{'width':window.innerWidth, 'height':window.innerHeight/4}}
			/>
			
			<img 
		  		id="sideVideo"
				src={InfoJsonVideo.sideVideoKey === undefined ? require('../images/sub/noimage_ci_w.png') :  cdn+InfoJsonVideo.sideVideoThumbnailKey}
				style={{'width':window.innerWidth, 'height':window.innerHeight/4, 'display':'none'}}
			/>
			
			<img 
		  		id="inGameVideo"
				src={InfoJsonVideo.reactionFrontVideoKey === undefined ? require('../images/sub/noimage_ci_w.png') :  cdn+InfoJsonVideo.reactionFrontVideoThumbnailKey}
				style={{'width':window.innerWidth, 'height':window.innerHeight/4, 'display':'none'}}
			/>
		</div>
		}
		{/*<div className="video_controls">
		  <span style={{width: '20%'}}>
			<a>{lan.L('재생 컨트롤')}</a>
		  </span>
		</div>*/}
		<ul className="video_thumbnail" style={{'padding-top': '20px'}}>
		  <li id="frontVideoli" className="on" onClick={(e)=>changeVideo(e,'frontVideo')}>{/* 선택 */}
			<img style={{'display':'none'}} src={InfoJsonVideo.frontVideoThumbnailKey === undefined ? require('../images/sub/noimage_ci_w.png') :  cdn+InfoJsonVideo.frontVideoThumbnailKey} onError={(e)=>disableVideo('frontVideoli')}/>
			<p style={{backgroundImage: 'url('+cdn+InfoJsonVideo.frontVideoThumbnailKey+'), url('+require('../images/sub/noimage_ci_w.png')+')'}} >image</p>
			<span>{lan.L('정면')}</span>
		  </li>
		  <li id="sideVideoli" className onClick={(e)=>changeVideo(e,'sideVideo')}>
			<img style={{'display':'none'}} src={InfoJsonVideo.sideVideoThumbnailKey === undefined ? require('../images/sub/noimage_ci_w.png') : cdn+InfoJsonVideo.sideVideoThumbnailKey} onError={(e)=>disableVideo('sideVideoli')}/>
			<p style={{backgroundImage: 'url('+cdn+InfoJsonVideo.sideVideoThumbnailKey+'), url('+require('../images/sub/noimage_ci_w.png')+')'}} >image</p>
			<span>{lan.L('측면')}</span>
		  </li>
		  <li id="inGameVideoli" className onClick={(e)=>changeVideo(e,'inGameVideo')}>{/* 파일없음 */}
			<img style={{'display':'none'}} src={InfoJsonVideo.reactionFrontVideoThumbnailKey === undefined ? require('../images/sub/noimage_ci_w.png') : cdn+InfoJsonVideo.reactionFrontVideoThumbnailKey} onError={(e)=>disableVideo('inGameVideoli')}/>
			<p style={{backgroundImage: 'url('+cdn+InfoJsonVideo.reactionFrontVideoThumbnailKey+'), url('+require('../images/sub/noimage_ci_w.png')+')'}} >image</p>
			<span>{lan.L('세레머니')}</span>
		  </li>
		</ul>
	  </div>
	  <ul className="section">
		<li>
		  <em>{lan.L('샷 정보')}</em>
		  <ol className="info_grade">
			<li>
			  <dl>
				<dd>{lan.L('시작지점')}</dd>
				<dt>{common.commonKeyDic['destinationLandType'][InfoJson.shotLocation]}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('도착지점')}</dd>
				<dt>{common.commonKeyDic['destinationLandType'][InfoJson.destinationLandType]}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('비거리')}</dd>
				<dt>{InfoJson.totalDistance===undefined ? '' : common.Length(InfoJson.totalDistance.toFixed(2),'0m')}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('캐리거리')}</dd>
				<dt>{InfoJson.carryDistance===undefined ? '' : common.Length(InfoJson.carryDistance.toFixed(2),'0m')}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('클럽스피드')}</dd>
				<dt>{InfoJson.clubSpeed===undefined ? '' : common.Speed(InfoJson.clubSpeed.toFixed(2),'0m/s')}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('볼스피드')}</dd>
				<dt>{InfoJson.carryDistance===undefined ? '' : common.Speed(InfoJson.ballSpeed.toFixed(2),'0m/s')}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('스매시 팩터')}</dd>
				<dt>{InfoJson.clubSpeed===undefined || InfoJson.clubSpeed == null || InfoJson.clubSpeed == 0 ? '' : (InfoJson.ballSpeed/InfoJson.clubSpeed).toFixed(2)}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('구질')}</dd>
				<dt>{lan.L(common.commonKeyDic['ballFlightType'][InfoJson.ballFlightType])}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('백스핀')}</dd>
				<dt>{InfoJson.backspin}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('사이드스핀')}</dd>
				<dt>{InfoJson.sideSpin}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('발사각')}</dd>
				<dt>{InfoJson.launchAngle===undefined ? '' : InfoJson.launchAngle.toFixed(2)}˚</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('방향각')}</dd>
				<dt>{InfoJson.directionAngle===undefined ? '' : InfoJson.directionAngle.toFixed(2)}˚</dt>
			  </dl>
			</li>
		  </ol>
		</li>
		<li>
		{InfoMapJson.scorecard === undefined || InfoMapJson.scorecard[0].players.length == 0 ? '' :
            <div className="mini_map">
            <em><b>{InfoMapJson.scorecard === undefined || InfoCourseMap[nowMap] === undefined ? '' : InfoCourseMap[nowMap].holeNumber+lan.L('H')}</b>{lan.L('Par')+InfoMapJson.scorecard[0].gameRoundInfo.courseInfo.coursePar[nowMap]}</em>
			
            <canvas id="mapImage" width='326px' height='512px' style={{'background': "url("+InfoMapJson.cdn + "images/course/" + location.state.roundData.courseId + "/hole_"+('0'+(Number(0)+1)).slice(-2)+".png) no-repeat" ,
        'background-size' : '100% 100%','width':'80vw', 'height':'125.44vw'}}></canvas>
            {/*<div className="tracing">
                <div className="draw">
                <span style={{top: '65px', left: '102px', width: '30px', transform: 'rotate(40deg)'}}>line</span>
                <em style={{top: '74px', left: '126px'}}>point</em>
                <span style={{top: '122px', left: '86px', width: '86px', transform: 'rotate(95deg)'}}>line</span>
                <em style={{top: '160px', left: '119px'}}>point</em>
                <span style={{top: '215px', left: '107px', width: '138px', transform: 'rotate(45deg)'}}>line</span>
                <em style={{top: '259px', left: '219px'}}>point</em>
                </div>
            </div>*/}
            </div>}
        {/*------------------------------------//미니맵 ------------------------------------*/}
		</li>
	  </ul>
	</div>
	</>
	)
	
    function dot(x, y){
        if (canvas.getContext) {

            var r = 5;
            var c = "rgb(255,75,118)";
            var linec = "rgb(250,204,86)";

            dotDrawing(ctx, x, y, r, c);

            clickCnt++;
            if (clickCnt % 2 == 0) {
                var beforeDot = create_dot_arr[0];
                var beforeX = beforeDot.x;
                var beforeY = beforeDot.y;
				if(beforeX != x && beforeY != y)
				{
					lineDrawing(ctx, beforeX, beforeY, x, y, linec);
				}
                //arrowDrawing(ctx, beforeX, beforeY, x, y, linec);
                create_dot_arr = [];
            } else {
                var obj = {};
                obj.color = c;
                obj.x = x;
                obj.y = y;
                obj.r = r;
                create_dot_arr.push(obj);
            }
        }
    }

    function dotDrawing(ctx, x, y, r, color) {
        if (ctx != null) {
            ctx.save();
            ctx.beginPath();
            ctx.fillStyle = color;
            ctx.arc(x, y, r, 0, Math.PI * 2, true);
            ctx.fill();
            ctx.restore();
        }
    }

    function lineDrawing(ctx, sx, sy, ex, ey, color) {
        if (ctx != null) {
            ctx.save();
            ctx.beginPath();
            ctx.strokeStyle = color;
            ctx.lineWidth = 3; 
            ctx.moveTo(sx, sy);
            ctx.lineTo(ex, ey);
            ctx.stroke();
            ctx.restore();
        }
    }

    function arrowDrawing(ctx, sx, sy, ex, ey, color) {
        if (ctx != null) {
            var aWidth = 5;
            var aLength = 12;
            var dx = ex - sx;
            var dy = ey - sy;
            var angle = Math.atan2(dy, dx);
            var length = Math.sqrt(dx * dx + dy * dy);

            //두점 선긋기
            ctx.translate(sx, sy);
            ctx.rotate(angle);
            ctx.fillStyle = color;
            ctx.beginPath();

            //화살표 모양 만들기
            // ctx.moveTo(length - aLength, -aWidth);
            // ctx.lineTo(length, 0);
            // ctx.lineTo(length - aLength, aWidth);

            ctx.fill();
            ctx.setTransform(1, 0, 0, 1, 0, 0);
        }
    }

    function setShot(PosX, PosY,num){
        var canvas = document.getElementById('mapImage');
        var imageW = canvas.width;
        var imageH = canvas.height;

        var courseXYInfo = mapdata.scorecard[0].gameRoundInfo.courseInfo.courseMapCoordinates[num];

        var worldLeftTopX = courseXYInfo.worldLeftTopX;
        var worldLeftTopY = courseXYInfo.worldLeftTopY;
        var rightX = courseXYInfo.rightX;
        var rightY = courseXYInfo.rightY;
        var worldSizeX = courseXYInfo.worldSizeX;
        var worldSizeY = courseXYInfo.worldSizeY;
        var downX =courseXYInfo.downX;
        var downY = courseXYInfo.downY;

        var U = ((PosX - worldLeftTopX) * rightX + (PosY - worldLeftTopY) * rightY) / worldSizeX;
        var V = ((PosX - worldLeftTopX) * downX + (PosY - worldLeftTopY) * downY) / worldSizeY;

        var X = U * imageW;
        var Y = V * imageH;

        dot(X, Y);
    }

    function clearCanvas(){
        var cnvs = document.getElementById('mapImage');
        var ctx = canvas.getContext('2d');

        // 픽셀 정리
        ctx.clearRect(0, 0, cnvs.width, cnvs.height);
        // 컨텍스트 리셋
        ctx.beginPath();
    }
}