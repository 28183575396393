import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import LinkButton from '../common/component/linkButton';
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';

import PopupDaumPost from '../popup/popup_daumpost';

export default function Address(){

	const location = useLocation();
	const navigate = useNavigate();
	const [InfoData, SetInfoData] = useState({});
    const [isPopupOpen, setIsPopupOpen] = useState(false);

	//화면전환시 실행
	useEffect(() => {
		if(window.localStorage.getItem('join_zipcode') === null) window.localStorage.setItem('join_zipcode','');
		if(window.localStorage.getItem('join_address1') === null) window.localStorage.setItem('join_address1','');
		
		var data = {zipcode : '', province : '', city : '', address1 : '', address2 : '' };
		data.zipcode = window.localStorage.getItem('join_zipcode');
		data.address1 = window.localStorage.getItem('join_address1');
		SetInfoData(data);
		checkAllPossible(InfoData);
    },[location]);

		// 팝업창 열기
    const openPostCode = () => {
        navigate('/popup/popup_daumpost');
    }
 
	const onChange = (e) => {
		var id = e.target.id;
		var value = e.target.value;
		var data = InfoData;

		if(id == "newZipcode")
		{
		  data.zipcode = value;
		}
		else if(id == "newProvince")
		{
		  data.province = value;
		}
		else if(id == "newCity")
		{
		  data.city = value;
		}
		else if(id == "newAddress1")
		{
		  data.address1 = value;
		}
		else if(id == "newAddress2")
		{
		  data.address2 = value;
		}
		checkAllPossible(InfoData);
	}
	
	// 체크박스 단일 선택
	const goRoundset = (e) => {

		window.localStorage.setItem('join_zipcode',InfoData.zipcode);
		window.localStorage.setItem('join_province',InfoData.province);
		window.localStorage.setItem('join_city',InfoData.city);
		window.localStorage.setItem('join_address1',InfoData.address1);
		window.localStorage.setItem('join_address2',InfoData.address2);
		
		navigate('/join/roundset',{replace:true});
	}

	// 로그아웃 후 로그인페이지 이동
	const goLoginWLogout = (e) => {
		navigate('/login/login');
	}

	//국가 코드 필요, 한국일때 별도 필요
	if(sessionStorage.getItem('Language') == 'ko')
	{
		return (
		<>
		<div>
		  <div className="container">
			<div className="process">
			  <span><b>1</b>/2</span>
			  <em>{lan.L('주소입력')}</em>
			</div>
			<ul className="write_form group_field">
			  <li>
				<span>
				  <input type="text" id="newZipcode" maxLength="50" onChange={onChange} placeholder={lan.L("우편번호")} value={InfoData.zipcode} disabled/>
				  <em>{lan.L('주소')}</em>
				</span>
				<button type="button" onClick={openPostCode}>{lan.L('주소검색')}</button>
			  </li>
			  <li><input type="text" id="newAddress1" maxLength="50" onChange={onChange} value={InfoData.address1} placeholder={lan.L("기본 주소")} disabled/></li>
			  <li><input type="text" id="newAddress2" maxLength="50" onChange={onChange} placeholder={lan.L("나머지 주소")} /></li>
			</ul>
		  </div>

		  <div className="sticky_button">
			<button type="button" id="btnRoundset" className="btn_standard" onClick={goRoundset}>{lan.L('다음')}</button>
			<div className="skip">
			  <button type="button" onClick={goLoginWLogout}>{lan.L('로그인하기')}</button>
			</div>
		  </div>

		  <hr />
		</div>
		</>
		)
	}
	else
	{
		return (
		<div>
		  <div className="container">
			<div className="process">
			  <span><b>1</b>/2</span>
			  <em>{lan.L('주소입력')}</em>
			</div>
			<ul className="write_form group_field">
			  <li><input type="text" id="newZipcode"  maxLength="50" onChange={onChange} placeholder={lan.L("Enter Zip Code")} /></li>
			  <li><input type="text" id="newProvince"  maxLength="50" onChange={onChange} placeholder={lan.L("Enter Province/City")} /></li>
			  <li><input type="text" id="newCity"  maxLength="50" onChange={onChange} placeholder={lan.L("Enter City/Country")} /></li>
			  <li><input type="text" id="newAddress1"  maxLength="50" onChange={onChange} placeholder={lan.L("Enter Address")} /></li>
			  <li><input type="text" id="newAddress2"  maxLength="50" onChange={onChange} placeholder={lan.L("Enter Detail Address")} /></li>
			</ul>
		  </div>
		  <div className="sticky_button">
			<button type="button" className="btn_standard" id="btnRoundset" onClick={goRoundset}>{lan.L('다음')}</button>
			<div className="skip">
			  <button type="button" onClick={goLoginWLogout}>{lan.L('로그인하기')}</button>
			</div>
		  </div>
		  <hr />
		</div>
		)
	}
}

function checkAllPossible(InfoData) {
	$("#btnRoundset").attr('disabled',true);
	if(sessionStorage.getItem('Language') == 'ko')
	{
		if(InfoData.zipcode != '' && InfoData.address1 != '')
		{
				$("#btnRoundset").attr('disabled',false);
		}
		else
		{
				$("#btnRoundset").attr('disabled',true);
		}
	}
	else
	{
		if(InfoData.zipcode != '' && InfoData.province != '' && InfoData.city != '' && InfoData.address1 != '')
		{
				$("#btnRoundset").attr('disabled',false);
		}
		else
		{
				$("#btnRoundset").attr('disabled',true);
		}
	}
}
