import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

import BottomCountry from '../popup/bottom_country';
import * as slide from '../popup/bottom_country';

const minmobilenum = 10;
const maxcertnum = 6;
var certnum = "";
var sendcert = "N";
var certend = "N";
var limitTimer = 180, objLimitTimer;
var WrongCnt = 0;
var MaxWrongCnt = 5;

export default function ResetPW(){
	
	const location = useLocation();
	const navigate = useNavigate();
	const [InfoData, SetInfoData] = useState({loginPass : '' , loginPass2 : ''});

	//화면전환시 실행
	useEffect(() => {
		checkAllPossible();	
    },[location]);

	const onChange = (e) => {
		  var id = e.target.id;
		  var value = e.target.value;
		  var data = InfoData;

		if(id == "newLoginpass")
		{
			data.loginPass = value;
			SetInfoData(data);
			
			if(value != '') $("#newLoginpassDel").show();
			else $("#newLoginpassDel").hide();
			
			$("#newLoginpass_basic").hide();
			$("#newLoginpass_error").hide();
			$("#newLoginpass_success").hide();
			
			if(value == '') $("#newLoginpass_basic").show();
			else if(!common.vaildChkData('pw',value)) $("#newLoginpass_error").show();
			else $("#newLoginpass_success").show();
		}
		else if(id == "newLoginpass2")
		{
			data.loginPass2 = value;
			SetInfoData(data);
			
			if(value != '') $("#newLoginpass2Del").show();
			else $("#newLoginpass2Del").hide();
			
			$("#newLoginpass2_error").hide();
			$("#newLoginpass2_success").hide();
			
			if(data.loginPass2 != data.loginPass) $("#newLoginpass2_error").show();
			else $("#newLoginpass2_success").show();
		}
		checkAllPossible();
	}
	  
	const onDelete = (e) => {
		var id = e.target.id;
	    var data = InfoData;
		
		if(id == "newLoginpassDel")
		{
			data.loginPass = '';
			$("#newLoginpass").val('');
			SetInfoData(data);
			$("#newLoginpassDel").hide();
			
			$("#newLoginpass_basic").show();
			$("#newLoginpass_error").hide();
			$("#newLoginpass_success").hide();
		}
		else if(id == "newLoginpass2Del")
		{
			data.loginPass2 = '';
			$("#newLoginpass2").val('');
			SetInfoData(data);
			$("#newLoginpass2Del").hide();
			
			$("#newLoginpass2_error").hide();
			$("#newLoginpass2_success").hide();
		}
		checkAllPossible();
	}

	// 체크박스 단일 선택
	const goChangePW = (e) => {

		if(!common.vaildChkData('pw',InfoData.loginPass))
		{
			common.alertpop(lan.L('비밀번호 찾기'),lan.L("비밀번호는 6자 이상 숫자만 입력이 가능합니다."));
			return false;
		}

		if(InfoData.loginPass != InfoData.loginPass2)
		{
			common.alertpop(lan.L('비밀번호 찾기'),lan.L("비밀번호가 일치하지 않습니다."));
			return false;
		}

		var senddata = {
									id : location.state.id,
									mobilePhoneCountry : location.state.phoneCountryValue,
									mobilePhone : location.state.phone,
									newPassword : InfoData.loginPass,
									newPasswordConfirm : InfoData.loginPass2
								}
		console.log(senddata);

		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: senddata,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 비밀번호 변경')
			}
		}).then(function (response) {
			console.log(response);
			
			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError('비밀번호 찾기',response.data.error.code);
				return false;
			}
			window.localStorage.setItem('popupnavigate','/login/login');
			common.alertpop(lan.L('비밀번호 찾기'),lan.L("비밀번호 변경이 완료되었습니다.\n로그인 화면으로 이동합니다."));

		}).catch(error => {
			console.log(error.response)
		});
	}

	return(
		<>
		<div>
		  <div className="container">
			<div className="title new-line">{lan.L('새로운 비밀번호를\n입력해 주세요.')}</div>
			<ul className="write_form">
				<li>
					<span>
					<input type="password" id="newLoginpass" onChange={(e) => onChange(e)} placeholder={lan.L("비밀번호 입력")} />
					<em>{lan.L('비밀번호')}</em>
					<a id="newLoginpassDel" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
					</span>
					<div className="display_text">
					<b className="basic" id="newLoginpass_basic">{lan.L('숫자 6자리만 입력 가능합니다.')}</b>
					<b className="error" id="newLoginpass_error" style={{display: 'none'}}>{lan.L('숫자 6자리만 입력 가능합니다.')}</b>
					<b className="success"id="newLoginpass_success" style={{display: 'none'}}>{lan.L('사용가능한 비밀번호입니다.')}</b>
					</div>
				</li>
				<li>
					<span>
					<input type="password" id="newLoginpass2" onChange={onChange} placeholder={lan.L("비밀번호 재입력")} />
					<em>{lan.L('비밀번호 확인')}</em>
					<a id="newLoginpass2Del" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
					</span>
					<div className="display_text">
					<b className="success" id="newLoginpass2_success" style={{display: 'none'}}>{lan.L('비밀번호가 일치합니다.')}</b>
					<b className="error" id="newLoginpass2_error" style={{display: 'none'}}>{lan.L('비밀번호가 일치하지 않습니다.')}</b>
					</div>
				</li>
			</ul>
		  </div>{/* container(e) */}
		  <div className="sticky_button">
			<button type="button" id="btnChangePW" className="btn_standard" onClick={goChangePW}>{lan.L('비밀번호 변경')}</button>
		  </div>
		</div>
		</>
	)
}

function checkAllPossible(){
	if($("#newLoginpass_success").css('display') == 'none')
	{
		$("#btnChangePW").attr('disabled',true);
		return false;
	}
	else if($("#newLoginpass2_success").css('display') == 'none')
	{
		$("#btnChangePW").attr('disabled',true);
		return false;
	}

	$("#btnChangePW").attr('disabled',false);
}