import React from 'react';
import * as lan from "../common/script/common_lan";

const StrokeRanking = ({InfoData, InfoRankData, matchCourseNum, selectPlayerNum}) => {
    return (
        <>
        <table className="score_chart">
            <colgroup>
                <col style={{width: '10%'}}/>
                <col style={{width: '10%'}}/>
                <col style={{width: '10%'}}/>
                <col style={{width: '10%'}}/>
                <col style={{width: '10%'}}/>
                <col style={{width: '10%'}}/>
                <col style={{width: '10%'}}/>
                <col style={{width: '10%'}}/>
                <col style={{width: '10%'}}/>
                <col style={{width: '10%'}}/>
            </colgroup>
            <tbody>
            <tr>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>Sub</th>
            </tr>
            <tr className="standard">
                <td>{InfoData.courseRounds[matchCourseNum].holes[0].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[1].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[2].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[3].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[4].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[5].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[6].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[7].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[8].par}</td>
                <td>
                    {
                        InfoData.courseRounds[matchCourseNum].holes[0].par + InfoData.courseRounds[matchCourseNum].holes[1].par +
                        InfoData.courseRounds[matchCourseNum].holes[2].par + InfoData.courseRounds[matchCourseNum].holes[3].par +
                        InfoData.courseRounds[matchCourseNum].holes[4].par + InfoData.courseRounds[matchCourseNum].holes[5].par +
                        InfoData.courseRounds[matchCourseNum].holes[6].par + InfoData.courseRounds[matchCourseNum].holes[7].par + InfoData.courseRounds[matchCourseNum].holes[8].par
                    }
                </td>
            </tr>
            <tr>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[0]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[1]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[2]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[3]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[4]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[5]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[6]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[7]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[8]}</b></td>
                <td>
                    <b>
                        {
                            InfoData.courseRounds[matchCourseNum].holes[0].par + InfoData.courseRounds[matchCourseNum].holes[1].par +
                            InfoData.courseRounds[matchCourseNum].holes[2].par + InfoData.courseRounds[matchCourseNum].holes[3].par +
                            InfoData.courseRounds[matchCourseNum].holes[4].par + InfoData.courseRounds[matchCourseNum].holes[5].par +
                            InfoData.courseRounds[matchCourseNum].holes[6].par + InfoData.courseRounds[matchCourseNum].holes[7].par +
                            InfoData.courseRounds[matchCourseNum].holes[8].par +
                            InfoRankData.list[selectPlayerNum].holeScore[0] + InfoRankData.list[selectPlayerNum].holeScore[1] + InfoRankData.list[selectPlayerNum].holeScore[2]
                            + InfoRankData.list[selectPlayerNum].holeScore[3] + InfoRankData.list[selectPlayerNum].holeScore[4] + InfoRankData.list[selectPlayerNum].holeScore[5]
                            + InfoRankData.list[selectPlayerNum].holeScore[6] + InfoRankData.list[selectPlayerNum].holeScore[7] + InfoRankData.list[selectPlayerNum].holeScore[8]}
                    </b>
                </td>
            </tr>
            <tr>
                <th>10</th>
                <th>11</th>
                <th>12</th>
                <th>13</th>
                <th>14</th>
                <th>15</th>
                <th>16</th>
                <th>17</th>
                <th>18</th>
                <th>Sub</th>
            </tr>
            <tr className="standard">
                <td>{InfoData.courseRounds[matchCourseNum].holes[9].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[10].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[11].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[12].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[13].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[14].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[15].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[16].par}</td>
                <td>{InfoData.courseRounds[matchCourseNum].holes[17].par}</td>
                <td>
                    {
                        InfoData.courseRounds[matchCourseNum].holes[9].par + InfoData.courseRounds[matchCourseNum].holes[10].par +
                        InfoData.courseRounds[matchCourseNum].holes[11].par + InfoData.courseRounds[matchCourseNum].holes[12].par +
                        InfoData.courseRounds[matchCourseNum].holes[13].par + InfoData.courseRounds[matchCourseNum].holes[14].par +
                        InfoData.courseRounds[matchCourseNum].holes[15].par + InfoData.courseRounds[matchCourseNum].holes[16].par + InfoData.courseRounds[matchCourseNum].holes[17].par
                    }
                </td>
            </tr>
            <tr>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[9]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[10]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[11]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[12]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[13]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[14]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[15]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[16]}</b></td>
                <td><b>{InfoRankData.list[selectPlayerNum].holeScore[17]}</b></td>
                <td>
                    <b>
                        {
                            InfoData.courseRounds[matchCourseNum].holes[9].par + InfoData.courseRounds[matchCourseNum].holes[10].par +
                            InfoData.courseRounds[matchCourseNum].holes[11].par + InfoData.courseRounds[matchCourseNum].holes[12].par +
                            InfoData.courseRounds[matchCourseNum].holes[13].par + InfoData.courseRounds[matchCourseNum].holes[14].par +
                            InfoData.courseRounds[matchCourseNum].holes[15].par + InfoData.courseRounds[matchCourseNum].holes[16].par +
                            InfoData.courseRounds[matchCourseNum].holes[17].par +
                            InfoRankData.list[selectPlayerNum].holeScore[9] + InfoRankData.list[selectPlayerNum].holeScore[10] + InfoRankData.list[selectPlayerNum].holeScore[11]
                            + InfoRankData.list[selectPlayerNum].holeScore[12] + InfoRankData.list[selectPlayerNum].holeScore[13] + InfoRankData.list[selectPlayerNum].holeScore[14]
                            + InfoRankData.list[selectPlayerNum].holeScore[15] + InfoRankData.list[selectPlayerNum].holeScore[16] + InfoRankData.list[selectPlayerNum].holeScore[17]}
                    </b>
                </td>
            </tr>
            </tbody>
        </table>
        {InfoData.isNewRankingSystem &&
            <table className="score_chart">
                <colgroup>
                    <col style={{width: '30%'}}/>
                    <col style={{width: '70%'}}/>
                </colgroup>
                <tbody>
                <tr>
                    <th>{lan.L('핸디캡')}</th>
                    <td className='handicap'>
                        <div className='info'>
                            <span>{lan.L('성별')}: {InfoRankData.list[selectPlayerNum].genderHandicap}</span>
                            <span>{lan.L('등급')}: {InfoRankData.list[selectPlayerNum].handicap}</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        }
        </>
    );
};

export default StrokeRanking;