import * as lan from '../common/script/common_lan.js';

import Tab from '../inc/tab';
import * as tabcommon from '../inc/tab';

import React, {useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common.js';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

import BottomStore from '../popup/bottom_store';
import * as slide from '../popup/bottom_store';

import CommonGoogleMap from '../common/script/common_googlemap';

function Store() {
	
	const storetab = [{tabName:'근처매장'},{tabName:'방문매장'}]

	const location = useLocation();
	const navigate = useNavigate();

	const [activeTab, setactiveTab] = useState('');
	const [activeMap, setactiveMap] = useState('Y');
	const [activeVisitMap, setactiveVisitMap] = useState('Y');
	const [activemarkershop, setactivemarkershop] = useState(-1);
	const [nearhtmllist, setnearhtmllist] = useState([]);
	const [visithtmllist, setvisithtmllist] = useState([]);
	
	const [googlemap, setgooglemap] = useState([]);
	const [googlevisitmap, setgooglevisitmap] = useState([]);
	var neardata, visitdata, neardatasort, visitdatasort;
	var maplatlngdata = [];
	var visitlatlngdata = [];
	var mylati = common.commonKeyDic['defaultaddr'].lati;
	var mylong = common.commonKeyDic['defaultaddr'].long;

	const [mylatiState, setmylatiState] = useState(common.commonKeyDic['defaultaddr'].lati);
	const [mylongState, setmylongState] = useState(common.commonKeyDic['defaultaddr'].long);

	
	var nearlist, visitlist;

	const [nearliststate, setnearliststate] = useState([]);
	const [visitliststate, setvisitliststate] = useState([]);
	const [visitSearchText, setvisitSearchText] = useState('');
	const [nearSearchText, setnearSearchText] = useState('');
	var objTimer;
	
	//최초실행
	useEffect(() => {

		window.addEventListener('storage', () => {
			setactivemarkershop(window.localStorage.getItem('markerclick'));
			setactiveTab(window.localStorage.getItem('tabNum'));

			if(activeTab != window.localStorage.getItem('tabNum'))
			{
				setactiveTab(window.localStorage.getItem('tabNum'));
			}

			if(activemarkershop != window.localStorage.getItem('markerclick'))
			{
				setactivemarkershop(window.localStorage.getItem('markerclick'));
			}
		});
	},[]);

	useEffect(() => {
		goData();
	}, [nearSearchText, visitSearchText]);

	//화면전환시 실행
	useEffect(() => {
		
		navigator.geolocation.getCurrentPosition((position) => {
			mylati = position.coords.latitude;
			mylong = position.coords.longitude;
			window.localStorage.setItem('mylati',mylati);
			window.localStorage.setItem('mylong',mylong);
			setmylatiState(mylati);
			setmylongState(mylong);
			goData();
		}, () => {
			goData();
		});
		
		if(window.localStorage.getItem('mylati') !== undefined && window.localStorage.getItem('mylati') != null
		&& window.localStorage.getItem('mylong') !== undefined && window.localStorage.getItem('mylong') != null)
		{
			window.localStorage.setItem('mylati',mylati);
			window.localStorage.setItem('mylong',mylong);
		}
    },[location]);
	
	const goData = () => {

        if(common.isIOS())
		{
            common.iOSCallApp({appid:"getPermissionLocation", params:"", funcname:"window.NativeInterface.getPermissionLocation"});
        }
        else
        {
            try{common.andriodCallApp('getPermissionLocation','');}
            catch (ex){}
        }

		var distance = Object.values(common.commonKeyDic['distance'])[3];
		var visitdistance = Object.values(common.commonKeyDic['distance'])[3];
		var clientTypeLink = '';
		var vclientTypeLink = '';
		
		$("#marker_point").hide();
		
		if (location.state !== undefined && location.state != null)
		{
			if (location.state.distance != null) distance = location.state.distance;
			if (location.state.visitdistance != null) visitdistance = location.state.visitdistance;
			if (location.state.clientTypeLink != null) clientTypeLink = location.state.clientTypeLink;
			if (location.state.vclientTypeLink != null) vclientTypeLink = location.state.visitclientTypeLink;
		}
		
			//근처 매장 목록 조회
			axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER + '/api',
				data: {
					province:'',
					city:'',
					searchText: nearSearchText,
					page:1,
					pagesize:10
				},
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'language':sessionStorage.getItem('Language'),
					'pUrl' : encodeURIComponent('매장 목록 조회'),
					'guid' : sessionStorage.getItem('guid'),
					'token' : sessionStorage.getItem('access_token'),
					'getplus' : clientTypeLink
				}
			}).then(function (response) {
					console.log(response.data);

				if(response.data.error !== undefined && response.data.error.code > 0)
				{
					common.alertByError('매장찾기',response.data.error.code);
					return false;
				}
				else
				{
					var count = 0;
					//console.log(response.data.list[0]);
					if(response.data.list[0] !== undefined)
					{
						window.localStorage.setItem('nowLatitude',mylati);
						window.localStorage.setItem('nowLongitude',mylong);
						nearlist = response.data.list;
						var dis;
						neardata = [];
						var dislist = [];
						var dislistsort = [];

						//nearlist[26].lessonbookCenterCode = '10000017'; //예약하기 임시 테스트

						var lengthcount = 0;

						//길이에 없으면 길이변경 추가
						if(distance == 30)
						{
							for(let i = 0; i < nearlist.length;i++)
							{
								dis = common.getDistance(mylati,mylong,nearlist[i].latitude,nearlist[i].longitude);
								
								if (dis - distance <= 0)
								{
									lengthcount = lengthcount + 1;
								}
							}
	
							if(lengthcount == 0) distance = 100;
							
							for(let i = 0; i < nearlist.length;i++)
							{
								dis = common.getDistance(mylati,mylong,nearlist[i].latitude,nearlist[i].longitude);
								
								if (dis - distance <= 0)
								{
									lengthcount = lengthcount + 1;
								}
							}
	
							if(lengthcount == 0) distance = 1000000; //모든거리
						}

						for (let i = 0;i < nearlist.length; i++)
						{
							dis = common.getDistance(mylati,mylong,nearlist[i].latitude,nearlist[i].longitude);

							if(nearlist[i].isShopFavorites) //즐겨찾기 유무
							{
								neardata.push(
									<li key={i+'n'}>
										<a onClick={(e) => goDetail(nearlist[i].shopGuid,0)} >
										<p>
											{lan.L(nearlist[i].shopName)}
											{nearlist[i].isShopFavorites == true ? <i>{lan.L("즐겨찾기")}</i>:''}
											<span>{dis}Km</span>
										</p>
										<div className="keyword">
											<span style={nearlist[i].clientType.screen ? {}:{display: 'none'}}>{"#"+lan.L("스크린")}</span>
											<span style={nearlist[i].clientType.practice ? {}:{display: 'none'}}>{"#"+lan.L("연습")}</span>
											<span style={nearlist[i].clientType.lesson ? {}:{display: 'none'}}>{"#"+lan.L("레슨")}</span>
										</div>
										</a>
										<div className="btn_store">
										<button type="button" onClick={(e) => goTel(nearlist[i].shopPhone)}>{lan.L("전화하기")}</button>
										{/* {nearlist[i].lessonbookCenterCode === undefined || nearlist[i].lessonbookCenterCode == null ? '' : <button type="button" className="reserve" onClick={(e) => common.goLessonbookReserve(nearlist[i].lessonbookCenterCode,"reserve")}>{lan.L("예약하기")}</button>} */}
										</div>
									</li>
									);
									
									dislist.push(0);
									dislistsort.push(0);
									count = count + 1;
							}
							else if (dis - distance <= 0) //거리차이 체크
							{
								neardata.push(
								<li key={i+'n'}>
									<a onClick={(e) => goDetail(nearlist[i].shopGuid,0)} >
									<p>
										{lan.L(nearlist[i].shopName)}
										{nearlist[i].isShopFavorites == true ? <i>{lan.L("즐겨찾기")}</i>:''}
										<span>{dis}Km</span>
									</p>
									<div className="keyword">
										<span style={nearlist[i].clientType.screen ? {}:{display: 'none'}}>{"#"+lan.L("스크린")}</span>
										<span style={nearlist[i].clientType.practice ? {}:{display: 'none'}}>{"#"+lan.L("연습")}</span>
										<span style={nearlist[i].clientType.lesson ? {}:{display: 'none'}}>{"#"+lan.L("레슨")}</span>
									</div>
									</a>
									<div className="btn_store">
										<button type="button" onClick={(e) => goTel(nearlist[i].shopPhone)}>{lan.L("전화하기")}</button>
										{/* {nearlist[i].lessonbookCenterCode === undefined || nearlist[i].lessonbookCenterCode == null ? '' : <button type="button" className="reserve" onClick={(e) => common.goLessonbookReserve(nearlist[i].lessonbookCenterCode,"reserve")}>{lan.L("예약하기")}</button>} */}
									</div>
								</li>
								);
								
								dislist.push(dis);	//가장 위로 오도록 dis 0 입력
								dislistsort.push(dis);
								count = count + 1;
							}
							maplatlngdata.push([lan.L(nearlist[i].shopName), nearlist[i].latitude, nearlist[i].longitude, i]);
						}

						dislistsort.sort((a, b) => {
							if (a < b) {return -1;}
							if (a > b) {return 1;}
							return 0;
						  });

						neardatasort = [];

						//dislistsort = dislist.sort(function(a,b){return a>b?1:b>a?-1});
						//console.log(dislist);
						console.log(dislist);

						for (let i = 0;i < nearlist.length; i++) //거리에 따라 재정리
						{
							if(i ==0 || dislistsort[i] != dislistsort[i-1])
							{
								for (let j = 0;j < nearlist.length; j++)
								{
									if(dislistsort[i] == dislist[j])
									{
										if(nearlist[j].isShopFavorites) // 즐겨찾기 맨 앞에 놓기
										{
											//neardatasort.unshift(neardata[j]);
											//alert(neardata[j]);
										}
										neardatasort.push(neardata[j]);
									}
								}
							}
						}

						console.log(nearlist,neardata,dislist,dislistsort)
						setnearhtmllist(neardatasort);
						setnearliststate(nearlist);
					}

					if(count == 0)
					{
						neardata = [];
						neardata.push(
							<div className="nodata">
							<p><img src={require("../images/character/nodata_2.png")} /></p>
							<strong>{lan.L("현재 위치 근처에 매장이 없어요.")}</strong>
							<span>{lan.L("검색 가능 거리를 넓혀 보세요.")}</span>
							</div>
						);

						setnearhtmllist(neardata);
					}

					setgooglemap(<CommonGoogleMap sendid="map" latitude={parseFloat(window.localStorage.getItem('nowLatitude'))} longitude={parseFloat(window.localStorage.getItem('nowLongitude'))} markers={maplatlngdata}/>);
				}
			}
			);
			
		//방문 매장 목록 조회
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {
				searchText: visitSearchText,
				page:1,
				pagesize:10
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('방문 매장 목록 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
				'getplus' : vclientTypeLink
			}
		}).then(function (response) {
			console.log(response.data);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError('매장찾기',response.data.error.code);
				return false;
			}
			else
			{
				var count = 0;
				if(response.data.list[0] !== undefined)
				{
					window.localStorage.setItem('nowLatitude',mylati);
					window.localStorage.setItem('nowLongitude',mylong);
					visitlist = response.data.list;
					var dis;
					visitdata = [];
					var dislist = [];
					var dislistsort = [];

					var lengthcount = 0;
					
					for(let i = 0; i < visitlist.length;i++)
					{
						dis = common.getDistance(mylati,mylong,visitlist[i].latitude,visitlist[i].longitude);
						
						if (dis - visitdistance <= 0)
						{
							lengthcount = lengthcount + 1;
						}
					}

					if(lengthcount == 0) visitdistance = 1000000; //모든거리

					for (let i = 0;i < visitlist.length; i++)
					{
						dis = common.getDistance(mylati,mylong,visitlist[i].latitude,visitlist[i].longitude);

						if(visitlist[i].isShopFavorites) //즐겨찾기 유무
						{
							visitdata.push(
								<li key={i+'v'}>
								<a onClick={(e) => goDetail(visitlist[i].shopGuid,1)} >
									<strong>{common.UnixTime(visitlist[i].visitTime,'년-월-일')}</strong>
									<p>
										{lan.L(visitlist[i].shopName)}
										{visitlist[i].isShopFavorites == true ? <i>{lan.L("즐겨찾기")}</i>:''}
										<span>{dis}Km</span>
									</p>
									<div className="keyword">
									<span style={visitlist[i].clientType.screen ? {}:{display: 'none'}}>{"#"+lan.L("스크린")}</span>
									<span style={visitlist[i].clientType.practice ? {}:{display: 'none'}}>{"#"+lan.L("연습")}</span>
									<span style={visitlist[i].clientType.lesson ? {}:{display: 'none'}}>{"#"+lan.L("레슨")}</span>
									</div>
								</a>
								<div className="btn_store">
									<button type="button" onClick={(e) => goTel(visitlist[i].shopPhone)}>{lan.L("전화하기")}</button>
									{/* {visitlist[i].lessonbookCenterCode === undefined || visitlist[i].lessonbookCenterCode == null ? '' : <button type="button" className="reserve" onClick={(e) => common.goLessonbookReserve(visitlist[i].lessonbookCenterCode,"reserve")}>{lan.L("예약하기")}</button>} */}
								</div>
								</li>
							);
								
							dislist.push(0);	//가장 위로 오도록 dis 0 입력
							dislistsort.push(0);
							count = count + 1;
						}
						else if (dis - visitdistance <= 0)
						{
							visitdata.push(
								<li key={i+'v'}>
								<a onClick={(e) => goDetail(visitlist[i].shopGuid,1)} >
									<strong>{common.UnixTime(visitlist[i].visitTime,'년-월-일')}</strong>
									<p>
										{lan.L(visitlist[i].shopName)}
										{visitlist[i].isShopFavorites == true ? <i>{lan.L("즐겨찾기")}</i>:''}
										<span>{dis}Km</span>
									</p>
									<div className="keyword">
									<span style={visitlist[i].clientType.screen ? {}:{display: 'none'}}>{"#"+lan.L("스크린")}</span>
									<span style={visitlist[i].clientType.practice ? {}:{display: 'none'}}>{"#"+lan.L("연습")}</span>
									<span style={visitlist[i].clientType.lesson ? {}:{display: 'none'}}>{"#"+lan.L("레슨")}</span>
									</div>
								</a>
								<div className="btn_store">
									<button type="button" onClick={(e) => goTel(visitlist[i].shopPhone)}>{lan.L("전화하기")}</button>
									{/* {visitlist[i].lessonbookCenterCode === undefined || visitlist[i].lessonbookCenterCode == null ? '' : <button type="button" className="reserve" onClick={(e) => common.goLessonbookReserve(visitlist[i].lessonbookCenterCode,"reserve")}>{lan.L("예약하기")}</button>} */}
								</div>
								</li>
							);

						dislist.push(dis);
						dislistsort.push(dis);
						count = count + 1;
						}
						visitlatlngdata.push([lan.L(visitlist[i].shopName), visitlist[i].latitude, visitlist[i].longitude, i]);
					}

					dislistsort.sort((a, b) => {
						if (a < b) {return -1;}
						if (a > b) {return 1;}
						return 0;
					  });
	
					visitdatasort = [];
	
					//dislistsort = dislist.sort(function(a,b){return a>b?1:b>a?-1});
					//console.log(dislist);
					for (let i = 0;i < visitlist.length; i++)
					{
						if(i ==0 || dislistsort[i] != dislistsort[i-1])
						{
							for (let j = 0;j < visitlist.length; j++)
							{
								if(dislistsort[i] == dislist[j])
								{
									visitdatasort.push(visitdata[j]);
								}
							}
						}
					}
					setvisithtmllist(visitdatasort);
					setvisitliststate(visitlist);
				}
				
				if(count == 0)
				{
					visitdata = [];
					visitdata.push(
						<div className="nodata">
						  <p><img src={require("../images/character/nodata_2.png")} /></p>
						  <strong>{lan.L("최근 방문한 매장이 없어요")}</strong>
						  <span>{lan.L("가까운 매장을 이용해보세요.")}</span>
						</div>
					);

					setvisithtmllist(visitdata);
				}
				setgooglevisitmap(<CommonGoogleMap sendid="visitmap" latitude={parseFloat(window.localStorage.getItem('nowLatitude'))} longitude={parseFloat(window.localStorage.getItem('nowLongitude'))} markers={visitlatlngdata}/>);
			}
		}).catch(error => {
			console.log(error.response)
		});
	}

	const goDetail = (shopguid,backTabTopics) => {
		navigate('/store/detail_store', {state : {shopGuid : shopguid, backTabTopics : backTabTopics, mylati : mylati, mylong : mylong} });
	}
	
	const goTel = (shopPhone) => {
		document.location.href = "tel:"+shopPhone;
	}

	const onChangeSearchText = (e) => {
		const value = e.target.value;
		const id = e.target.id;

		if(id === 'nearSearchText') setnearSearchText(value);
		if(id === 'visitSearchText') setvisitSearchText(value);
	}

	const onChangeMap = () => {
		if(activeMap == "Y")
		{
			setactiveMap("N");
		}
		else
		{
			setactiveMap("Y");
		}
	}
	
	const onChangeVisitMap = () => {
		if(activeVisitMap == "Y")
		{
			setactiveVisitMap("N");
		}
		else
		{
			setactiveVisitMap("Y");
		}
	}
	
	const initSearch = (num) => {
		if(num==0)
		{
			setnearSearchText('');
			$("#pop_nearinit").trigger("click");
			$("#pop_nearok").trigger("click");
		}
		else if(num==1)
		{
			setvisitSearchText('')
			$("#pop_visitinit").trigger("click");
			$("#pop_visitok").trigger("click");
		}
	}
	

  return (
	<>
	<Tab topics={storetab} defaultTopics={location.state === undefined || location.state == null ? 0 : location.state.activeTab} tabkey={"매장찾기"} onChangefunc={initSearch}></Tab> {/*검색 시 돌아가지지 않아서 여기서 처리 */}
	<BottomStore to="/store/store"></BottomStore>
	<div className="contents">
		<div id="nearsearch" className="search_form transform" style={activeTab==0 ? {}: {display:'none'}}>
			<a>검색하기</a>
			<input type="text" id="nearSearchText" placeholder={lan.L('매장명, 주소검색')} value={nearSearchText} defaultValue={nearSearchText} onChange={onChangeSearchText}/>
			<button type="button" onClick={(e) => slide.slideNear(0)}>검색조건</button>
			<label id="near" className={activeMap=="N" ? "convert":""} onClick={onChangeMap} >보기형식</label>
		</div>
		
		<div id="visitsearch" className="search_form transform" style={activeTab==1 ? {}: {display:'none'}}>
			<a>검색하기</a>
			<input type="text" id="visitSearchText" placeholder={lan.L('매장명, 주소검색')} value={visitSearchText} defaultValue={visitSearchText} onChange={onChangeSearchText} />
			<button type="button" onClick={(e) => slide.slideVisit(0)}>검색조건</button>
			<label id="near" className={activeVisitMap=="N" ? "convert":""} onClick={onChangeVisitMap} >보기형식</label>
		</div>

	{/*-------------- 근처매장 --------------*/}
	<article id="nearA" style={activeTab==0 && activeMap=="Y" ? {display:'block'}: {display:'none'}}>
	  <div className="pack_board">
		<ul className="store_list">
		  {nearhtmllist}
		</ul>
	  </div>
	</article>
	
	{/*-------------- 근처매장지도 --------------*/}
	<article id="nearB" style={activeTab==0 && activeMap=="N" ? {display:'block'}: {display:'none'}}>
	<div className="nav_store location">
		{activeMap == "Y" || nearliststate === undefined || activemarkershop == null || activemarkershop < 0 || nearliststate[activemarkershop] === undefined  ? '' :
			<div className="marker_point" id="marker_point" style={{'bottom':'4px','display':'none'}}>
				<a onClick={(e)=> goDetail(nearliststate[activemarkershop].shopGuid,0)}>
					<div className="detail_store">
						<em>{lan.L(nearliststate[activemarkershop].shopName)}<span>{common.getDistance(mylatiState,mylongState,nearliststate[activemarkershop].latitude,nearliststate[activemarkershop].longitude)} Km</span></em>
						<address>{nearliststate[activemarkershop].address1}</address> {/*nearliststate[activemarkershop].province + " " + nearliststate[activemarkershop].city + " " + */}
						<div className="keyword">
							<span style={nearliststate[activemarkershop].clientType.screen ? {}:{display: 'none'}}>{"#"+lan.L("스크린")}</span>
							<span style={nearliststate[activemarkershop].clientType.practice ? {}:{display: 'none'}}>{"#"+lan.L("연습")}</span>
							<span style={nearliststate[activemarkershop].clientType.lesson ? {}:{display: 'none'}}>{"#"+lan.L("레슨")}</span>
						</div>
					</div>
				</a>
				<div className="btn_store">
					<button type="button" onClick={(e) => goTel(nearliststate[activemarkershop].shopPhone)}>전화하기</button>
				</div>
			</div>
		}
		<div className="largemap" id="map" style={{'height': document.body.offsetHeight - 220}}>
			{googlemap}
		</div>
	</div>
	</article>

	{/*-------------- 게시판형식 --------------*/}
	<article id="visitA" style={activeTab==1&& activeVisitMap=="Y" ? {display:'block'}: {display:'none'}}>
	  <div className="pack_board">
		<ul className="store_list">
		  {visithtmllist}
		</ul>
	  </div>
	</article>


	{/*-------------- 방문매장지도 --------------*/}
	<article id="visitB" style={activeTab==1 && activeVisitMap=="N" ? {display:'block'}: {display:'none'}}>
	<div className="nav_store location">
		{activeVisitMap == "Y" || visitliststate === undefined || activemarkershop == null || activemarkershop < 0 || visitliststate[activemarkershop] === undefined  ? '' :
			<div className="marker_point" id="marker_point" style={{'bottom':'4px','display':'none'}}>
				<a onClick={(e)=> goDetail(visitliststate[activemarkershop].shopGuid,1)}>
					<div className="detail_store">
						<em>{lan.L(visitliststate[activemarkershop].shopName)}<span>{common.getDistance(mylatiState,mylongState,visitliststate[activemarkershop].latitude,visitliststate[activemarkershop].longitude)} Km</span></em>
						<address>{visitliststate[activemarkershop].address1}</address> {/*visitliststate[activemarkershop].province + " " + visitliststate[activemarkershop].city + " " + */}
						<div className="keyword">
							<span style={visitliststate[activemarkershop].clientType.screen ? {}:{display: 'none'}}>{"#"+lan.L("스크린")}</span>
							<span style={visitliststate[activemarkershop].clientType.practice ? {}:{display: 'none'}}>{"#"+lan.L("연습")}</span>
							<span style={visitliststate[activemarkershop].clientType.lesson ? {}:{display: 'none'}}>{"#"+lan.L("레슨")}</span>
						</div>
					</div>
				</a>
				<div className="btn_store">
					<button type="button" onClick={(e) => goTel(visitliststate[activemarkershop].shopPhone)}>전화하기</button>
				</div>
			</div>
		}
		<div className="largemap" id="visitmap" style={{'height': document.body.offsetHeight - 220}}>
			{googlevisitmap}
		</div>
	</div>

	</article>

	</div>
	</>

  );
}

export default Store;
