import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import * as common from '../common/script/common';
import $ from 'jquery';

var navigate, birthday, gender, location;

var myinfoJson = {};
const maxbirthnum = 8;

export default function ModifyAddition() {
	
	navigate = useNavigate();
	location = useLocation();
	
	//화면 이동 시
	useEffect(() => {
		myinfoJson = {
			"nickname": sessionStorage.getItem('nickname'),
			"mobilePhoneCountry": "KR",
			"mobilePhone": sessionStorage.getItem('mobilePhone'),
			"email": sessionStorage.getItem('email'),
			"gender": sessionStorage.getItem('gender'),
			"birthday": sessionStorage.getItem('birthday'),
			"cdn": sessionStorage.getItem('cdn'),
			"isTermsAgreeNotificationSMS": sessionStorage.getItem('isTermsAgreeNotificationSMS'),
			"country": sessionStorage.getItem('country'),
			"zipcode": sessionStorage.getItem('zipcode'),
			"province": sessionStorage.getItem('province'),
			"city": sessionStorage.getItem('city'),
			"address1": sessionStorage.getItem('address1'),
			"address2": sessionStorage.getItem('address2')
		}
	},[location]);

	birthday = sessionStorage.getItem('birthday').replace(/-/g,'');
	gender = sessionStorage.getItem('gender');

	var state = 'on'
	const onChange = (e) => {
		var id = e.target.id;
		if(id == "birthday")	birthday = e.target.value;
		else if(id == "male")	gender = 0;
		else if(id == "female")	gender = 1;
		checkAllPossible(birthday);
	}

	return (
	<>
	<div>
	  <div className="container">
		<div className="title new-line">{lan.L('아래 추가 정보를\n입력해 주세요.')}</div>
		<ul className="write_form">
		  <li>
			<span>
			  <div className="selection_tab">
				<a>
				  <input type="radio" id="male" name="gender" onChange={onChange} defaultChecked = {gender == 0 ? 'checked':''} />
				  <label htmlFor="male">{lan.L('남성')}</label>
				</a>
				<a>
				  <input type="radio" id="female" name="gender"onChange={onChange} defaultChecked = {gender == 1 ? 'checked':''} />
				  <label htmlFor="female">{lan.L('여성')}</label>
				</a>
			  </div>
			  <em>{lan.L('성별')}</em>
			</span>
		  </li>
		  <li>
			<span>
			  <input type="number" id="birthday" maxLength={maxbirthnum} onChange={onChange}  placeholder="ex) 19800101" defaultValue={birthday}/>
			  <em>{lan.L('생년월일')}</em>
			</span>
		  </li>
		</ul>
	  </div>
	  <div className="sticky_button">
	  <button type="button" id="modifybtn" className="btn_standard" onClick={btnModifyAddition}>{lan.L('수정하기')}</button>
	  </div>
	</div>
	</>

	)
}

function btnModifyAddition() {

	if (birthday == ''){
			common.alertpop(lan.L('추가정보 수정'),lan.L('생년월일을 입력해 주세요.'));
			return;
	}

	myinfoJson.birthday = birthday.substr(0,4)+'-'+birthday.substr(4,2)+'-'+birthday.substr(6,2);
	myinfoJson.gender = gender;

	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: myinfoJson,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('회원 정보 수정'),
			'guid' : sessionStorage.getItem('guid'),
			'token' : sessionStorage.getItem('access_token')
		}
	}).then(function (response) {
		var data = response.data;
		console.log(response);

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			common.alertByError(lan.L('추가정보 수정'),response.data.error.code);
			return false;
		}

		sessionStorage.setItem('birthday', myinfoJson.birthday);
		sessionStorage.setItem('gender', myinfoJson.gender);

		common.alertpop(lan.L('추가정보 수정'),lan.L('수정되었습니다.'));
		navigate(-1);
	}).catch(error => {
		//console.log(error.response)
	});
}

function checkAllPossible(birthday){
	if(birthday.length != maxbirthnum)
	{
		$("#modifybtn").attr('disabled',true);
		return false;
	}

	if(birthday.slice(0, 4) > new Date().getFullYear() || birthday.slice(4, 6) > 12 || birthday.slice(6, 8) > 31)
	{
		$("#modifybtn").attr('disabled',true);
		return false;
	}

	$("#modifybtn").attr('disabled',false);
}
