import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import * as common from '../common/script/common';
import $ from 'jquery';

var navigate, loginID, loginPass, location;
var nickname = '';
var myinfoJson = {};


export default function ModifyNickName() {

	navigate = useNavigate();
	location = useLocation();

	//화면 이동 시
	useEffect(() => {
		
		$("#btnModifyNickname").attr('disabled',true);
		
		myinfoJson = {
			"nickname": sessionStorage.getItem('nickname'),
			"mobilePhoneCountry": "KR",
			"mobilePhone": sessionStorage.getItem('mobilePhone'),
			"email": sessionStorage.getItem('email'),
			"gender": sessionStorage.getItem('gender'),
			"birthday": sessionStorage.getItem('birthday'),
			"cdn": sessionStorage.getItem('cdn'),
			"isTermsAgreeNotificationSMS": sessionStorage.getItem('isTermsAgreeNotificationSMS'),
			"country": sessionStorage.getItem('country'),
			"zipcode": sessionStorage.getItem('zipcode'),
			"province": sessionStorage.getItem('province'),
			"city": sessionStorage.getItem('city'),
			"address1": sessionStorage.getItem('address1'),
			"address2": sessionStorage.getItem('address2')
		}
	},[location]);

	var state = 'on'
	const onChange = (e) => {
		nickname = e.target.value;
		$("#newNickname_basic").hide();
		$("#newNickname_error").hide();
		$("#newNickname_success").hide();
		
		if(!common.vaildChkData('nickname',nickname)) 
		{
			$("#newNickname_error").show();
			$("#btnModifyNickname").attr('disabled',true);
		}
		else if(nickname == sessionStorage.getItem('nickname'))
		{
			$("#newNickname_basic").show();
			$("#btnModifyNickname").attr('disabled',true);
		}
		else
		{
			$("#newNickname_basic").show();
			$("#btnModifyNickname").attr('disabled',false);
		}
	}

	return (
	<>
	<div className="container">
	  <div className="title">{lan.L('닉네임 변경')}</div>
	  <ul className="write_form">
		<li>
		  <span>
			<input type="text" onChange={onChange} placeholder={lan.L("닉네임 입력")} defaultValue={sessionStorage.getItem('nickname')} />
			<em>{lan.L('닉네임')}</em>
			<a onClick="#" style={{display: 'none'}}>delete</a>
		  </span>
		  <div className="display_text">
		    <b className="basic" id="newNickname_basic">{lan.L('특수문자를 제외한 4자 이상만 가능합니다.')}</b>
			<b className="success" id="newNickname_success" style={{display: 'none'}}>{lan.L('사용 가능한 닉네임 입니다.')}</b>
			<b className="error" id="newNickname_error" style={{display: 'none'}}>{lan.L('특수문자를 제외한 4자 이상만 가능합니다.')}</b>
		  </div>
		</li>
	  </ul>
	</div>
	<div className="sticky_button">
	  <button type="button" id="btnModifyNickname" className="btn_standard" onClick={btnModifyNickname}>{lan.L('수정하기')}</button>
	</div>
	</>
	)
}

function btnModifyNickname() {

	if (nickname == '') {
			common.alertpop(lan.L('기본정보 수정'),lan.L('닉네임을 입력해 주세요.'));
			return;
	}

	myinfoJson.nickname = nickname;

	console.log(myinfoJson);

	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: myinfoJson,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('회원 정보 수정'),
			'guid' : sessionStorage.getItem('guid'),
			'token' : sessionStorage.getItem('access_token')
		}
	}).then(function (response) {
		var data = response.data;
		console.log(response);

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			common.alertByError('기본정보 수정',response.data.error.code);
			return false;
		}

		sessionStorage.setItem('nickname', myinfoJson.nickname);

		common.alertpop(lan.L('기본정보 수정'),lan.L('수정되었습니다.'));
		navigate(-1);
	}).catch(error => {
		//console.log(error.response)
	});
}