import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as common from '../common/script/common.js';
import $ from 'jquery';
import axios from 'axios';

import MatchTab from '../inc/match_tab';
import RoundSettingInformation from "./round_setting_information";
import CourseRoundsInformation from "./course_rounds_information";
import RankingBottomSheet from "./ranking_bottom_sheet";
import BottomMatchRank from '../popup/bottom_matchrank.js';
import BottomMatchAward from '../popup/bottom_matchaward.js';

export default function DetailMatch(props){
	const location = useLocation();

	const selector = useSelector((state) => state);
	const dispatch = useDispatch();

	const [InfoData, SetInfoData] = useState([]);
    const [tempInfoData, setTempInfoData] = useState([]);
	const [InfoRankData, SetInfoRankData] = useState([]);
	const [InfoAwardData, SetInfoAwardData] = useState([]);
    const [groupedByGradeType, setGroupedByGradeType] = useState([]);
	const [activeTab, setactiveTab] = useState(0);
	const [myrankingnum, setmyrankingnum] = useState(-1);
    const [myCourseData, setMyCourseData] = useState({});
    const [courseId, setCourseId] = useState('');

	const [ismyawardshow, setismyawardshow] = useState(false);
	const [isnomyward, setisnomyward] = useState(false);
	const [myawardboollist, setmyawardboollist] = useState(false);
    const [isNewRankingSystem, setIsNewRankingSystem] = useState(false);

	const [datetype, setdatetype] = useState('');
	const [datetext, setdatetext] = useState('');
    
	const [selectplayernum, setselectplayernum] = useState(0);
    
	//최초실행
	useEffect(() => {
		window.addEventListener('storage', () => {
			setactiveTab(parseInt(window.localStorage.getItem('tabNum')));
			
			if(activeTab !== parseInt(window.localStorage.getItem('tabNum')))
			{
				setactiveTab(parseInt(window.localStorage.getItem('tabNum')));
			}
		});
	},[]);
    
	//화면전환시 실행
	useEffect(() => {
		dispatch({type:'match_initstate', value: ''});
		goData(); //데이터 불러오기
        // goAward();
	},[location]);
    
	//selector가 변경될때 상시 실행
	useEffect(() => {
        setselectplayernum(0);
		// goData(); //데이터 불러오기
        if(activeTab === 1) {
            if(selector.matchRankReducer.match_recordType === 'CourseRoundPlayCount' || selector.matchRankReducer.match_recordType === 'HoleInOne') {
                goTotalRanking();
            } else {
                goRanking();
            }
        }
        if(activeTab === 2) {
            goAward();
        }
	},[activeTab, tempInfoData, selector.matchRankReducer]);
	//selector가 변경될때 상시 실행 종료

    useEffect(() => {
        if(isNewRankingSystem && (
            selector.matchRankReducer.match_recordType === 'Stroke' ||
            selector.matchRankReducer.match_recordType === 'NewPerio' ||
            selector.matchRankReducer.match_recordType === 'BirdieCount' ||
            selector.matchRankReducer.match_recordType === 'EagleCount' ||
            selector.matchRankReducer.match_recordType === 'HoleInOne' ||
            selector.matchRankReducer.match_recordType === 'CourseRoundPlayCount'
        )) {
            dispatch({type:'match_courseNum', value: 'all'});
        }else{
            dispatch({type:'match_courseNum', value: 0});
        }
    },[isNewRankingSystem]);

    useEffect(() => {
        if(myrankingnum > -1 && InfoRankData.list.length > 0) {
            const courseData = InfoData.courseRounds?.find((p) => p.courseId === InfoRankData.list[myrankingnum].courseId);
            setMyCourseData(courseData);
        }
    }, [InfoData, InfoRankData, myrankingnum]);
    
	//Reducer값을 초기화 진행(탭 변경시 초기화)
	const initReducer = () => {
		goData(); //데이터 불러오기
        goAward();
	}
    
	const goData = () => {
		//대회 상세 정보 조회
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('대회 상세 정보 조회'),
				'guid' : location.state.tournamentId,
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
				console.log('[대회 상세 정보] ',response.data);
                const grades = response.data.participationGrades;
                const group = grades.reduce((acc, curr) => {
                    const { gradeType, ...rest } = curr;
                    const foundIndex = acc.findIndex(item => item.gradeType === gradeType);
                    if (foundIndex !== -1) {
                        acc[foundIndex].data.push(rest);
                    } else {
                        acc.push({ gradeType, data: [rest] });
                    }
                    return acc;
                }, []);

                setGroupedByGradeType(group)
				if(response.data.error !== undefined && response.data.error.code > 0)
				{
					common.alertByError('대회 상세 목록',response.data.error.code);
					return false;
				}
				else
				{
                    SetInfoData(response.data);
                    setTempInfoData(response.data);
                    setIsNewRankingSystem(response.data.isNewRankingSystem);
                    if(response.data.awards[0].awardItems === 'Score') {
                        dispatch({type:'match_recordType', value: response.data.awards[0].awardType});
                    } else {
                        dispatch({type:'match_recordType', value: response.data.awards[0].awardItems});
                    }
                    
					const now = Date.now();
                    //날짜 비교해서 예정인지 종료인지 파악
                    if(now < response.data.startTime) //예정
                    {
                        setdatetext(lan.L("예정")+" D-"+common.UnixTime(response.data.startTime - now,'일자'));
                        setdatetype("expect");
                    }
                    else if(now < response.data.endTime) //진행중
                    {
                        setdatetext(lan.L("진행중")+" D-"+common.UnixTime(now - response.data.startTime,'일자'));
                        setdatetype("ongoing");
                    }
                    else //종료
                    {
                        setdatetext(lan.L("종료"));
                        setdatetype("ending");
                    }
				}
			}).catch(error => {
			console.log(error)
		});
		//대회 상세 정보 조회 종료
	}
    
	const goRanking = () => {
        let courseId;
        if(selector.matchRankReducer.match_courseNum === 'all'){
            if(selector.matchRankReducer.match_recordType === 'Stroke' || selector.matchRankReducer.match_recordType === 'NewPerio') {
                courseId = 'ALLAWARD';
            }
            else if(selector.matchRankReducer.match_recordType === 'BirdieCount' || selector.matchRankReducer.match_recordType === 'EagleCount') {
                courseId = 'ALL'
            }
            else {
                courseId = tempInfoData.courseRounds[0].courseId;
            }
        }else {
            courseId = tempInfoData.courseRounds[selector.matchRankReducer.match_courseNum].courseId;
        }
        setCourseId(courseId);
        //대회 랭킹 조회
        axios({
            method: 'post',
            url: process.env.REACT_APP_SERVER + '/api',
            data:  {page : 1 , pageSize : 200},
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'language':sessionStorage.getItem('Language'),
                'pUrl' : encodeURIComponent('대회 랭킹 조회'),
                'guid' : location.state.tournamentId,
                'guid2' : selector.matchRankReducer.match_recordType,
                'guid3' : courseId,
            }
        }).then(function (response2) {
                console.log('[대회 랭킹 조회] ', response2.data);

				if(response2.data.error !== undefined && response2.data.error.code > 0)
				{
                    if(parseInt(window.localStorage.getItem('tabNum')) === 1) {
                        if (response2.data.error.code !== 7660) {
                            common.alertByError('대회 상세 목록', response2.data.error.code);
                        }
                        else {
                            $("#noRankingData").show();
                            $("#RankingDataDiv").hide();
                            $("#ranking_list").hide();
                        }
					    return false;
                    }
				}

                if(datetype === 'ongoing' && selector.matchRankReducer.match_recordType === 'NewPerio') //신페리오 랭킹이 대회 진행중에는 숨김처리
                {
                    $("#noRankingData").show();
                    $("#RankingDataDiv").hide();
                    $("#ranking_list").hide();
                }
                else if(response2.data.list.length > 0)
                {
                    $("#noRankingData").hide();
                    $("#RankingDataDiv").show();
                    $("#ranking_list").show();
                }
                else
                {
                    $("#noRankingData").show();
                    $("#RankingDataDiv").hide();
                    $("#ranking_list").hide();
                }

                for(let i=0;i<response2.data.list.length;i++)
                {
                    if(response2.data.list[i].userNickname === sessionStorage.getItem('nickname'))
                    {
                        setmyrankingnum(i);
                        break;
                    }

                    if(i === response2.data.list.length - 1)
                    {
                        setmyrankingnum(-1);
                    }
                }
                
                SetInfoRankData(response2.data);
                
                if(response2.data.error !== undefined && response2.data.error.code > 0)
                {
                    if(response2.data.error.code !== 7660) {
                        common.alertByError('대회 상세 목록',response2.data.error.code);
                    }
                    else {
                        $("#noRankingData").show();
                        $("#RankingDataDiv").hide();
                        $("#ranking_list").hide();
                    }
                    return false;
                }
                else
                {

                }
            }).catch(error => {
            console.log(error)
        });
    }

    const goTotalRanking = () => {
        //대회 랭킹 조회
        axios({
            method: 'post',
            url: process.env.REACT_APP_SERVER + '/api',
            data:  {page : 1 , pageSize : 50},
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'language':sessionStorage.getItem('Language'),
                'pUrl' : encodeURIComponent('대회 종합 랭킹 조회'),
                'guid' : location.state.tournamentId,
                'guid2' : selector.matchRankReducer.match_recordType
            }
        }).then(function (response2) {
            console.log('[대회 종합 랭킹 조회] ', response2.data);

            if(response2.data.error !== undefined && response2.data.error.code > 0)
            {
                if(parseInt(window.localStorage.getItem('tabNum')) === 1) {
                    if (response2.data.error.code !== 7672) {
                        common.alertByError('대회 상세 목록', response2.data.error.code);
                    } else {
                        $("#noRankingData").show();
                        $("#RankingDataDiv").hide();
                        $("#ranking_list").hide();
                    }
                    return false;
                }
            }

            if(datetype === 'ongoing' && selector.matchRankReducer.match_recordType === 'NewPerio') //신페리오 랭킹이 대회 진행중에는 숨김처리
            {
                $("#noRankingData").show();
                $("#RankingDataDiv").hide();
                $("#ranking_list").hide();
            }
            else if(response2.data.list.length > 0)
            {
                $("#noRankingData").hide();
                $("#RankingDataDiv").show();
                $("#ranking_list").show();
            }
            else
            {
                $("#noRankingData").show();
                $("#RankingDataDiv").hide();
                $("#ranking_list").hide();
            }

            for(let i=0;i<response2.data.list.length;i++)
            {
                if(response2.data.list[i].userNickname === sessionStorage.getItem('nickname'))
                {
                    setmyrankingnum(i);
                    break;
                }

                if(i === response2.data.list.length - 1)
                {
                    setmyrankingnum(-1);
                }
            }

            SetInfoRankData(response2.data);

            if(response2.data.error !== undefined && response2.data.error.code > 0)
            {
                if (response2.data.error.code !== 7672) {
                    common.alertByError('대회 상세 목록', response2.data.error.code);
                } else {
                    $("#noRankingData").show();
                    $("#RankingDataDiv").hide();
                    $("#ranking_list").hide();
                }
                return false;
            }
            else
            {

            }
        }).catch(error => {
            console.log(error)
        });
    }
    
	const goAward = () => {
        //대회 시상 내역 조회
        axios({
            method: 'post',
            url: process.env.REACT_APP_SERVER + '/api',
            data:  {page : 1 , pageSize : 10},
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'language':sessionStorage.getItem('Language'),
                'pUrl' : encodeURIComponent('대회 시상 내역 조회'),
                'guid' : location.state.tournamentId
            }
        }).then(function (response) {
                console.log('[대회 시상 내역 조회] ',response.data);
                SetInfoAwardData(response.data);

                if(response.data.error !== undefined && response.data.error.code > 0)
                {
                    common.alertByError('대회 상세 목록',response.data.error.code);
                    return false;
                }
                else
                {
                    let myawardboollisttemp = [];

                    for(let i = 0; i < response.data.awardItems.length;i++)
                    {
                        const x = response.data.awardItems[i].awardIndex;
                        if(response.data.awardRankings[x].length === 0)
                        {
                            myawardboollisttemp.push(false);
                        }
                        else
                        {
                            for(let j=0; j < response.data.awardRankings[x].length; j++)
                            {
                                if(response.data.awardRankings[x][j].userGuid === sessionStorage.getItem('guid'))
                                {
                                    myawardboollisttemp.push(true);
                                    setisnomyward(true);
                                    break;
                                }
                                else if(j === response.data.awardRankings[x].length - 1)
                                {
                                    myawardboollisttemp.push(false);
                                }
                            }
                        }
                    }
                    // console.log(myawardboollisttemp);
                    setmyawardboollist(myawardboollisttemp);
                }
            }).catch(error => {
            console.log(error)
        });
    }

    const findCourseName = (id) => {

        if(InfoData.courseRounds !== undefined)
        {
            for(let i = 0 ; i < InfoData.courseRounds.length; i++)
            {
                if(id === InfoData.courseRounds[i].courseId)
                {
                    return InfoData.courseRounds[i].courseName;
                    break;
                }
            }
        }
        else
        {
            return '';
        }
    }
    
    const changemyawardshow = () => {
        setismyawardshow($("#award1").is(":checked"));
		goAward();
    }
    
    const SlideRankDetail = (recordtype, player) => {
        $("html").addClass("overflowHidden");
        setselectplayernum(player);
        if(recordtype === 'BirdieCount' || recordtype === 'EagleCount')
        {
            $("#BirdieEaglePop").addClass("on");
        }
        else if(recordtype === 'HoleInOne' || recordtype === 'CourseRoundPlayCount') {
            console.log('No Popup');
        } else
        {
            $("#"+recordtype+"Pop").addClass("on");
        }
    }

    const slideRecordType = () => {
        $("html").addClass("overflowHidden");
        $("#pop_recordType").addClass("on");
    }

    const slideCourseId = () => {
        $("html").addClass("overflowHidden");
        $("#pop_courseId").addClass("on");
    }

    const slideAwardId = () => {
        $("html").addClass("overflowHidden");
        $("#pop_awardId").addClass("on");
    }

    const slideExit = () => {
        $("html").removeClass("overflowHidden");
        $(".layerView").removeClass("on");
    }

    const awardRankingsFunc = (i) => {
        let html = [];
        const x = InfoAwardData.awardItems[i].awardIndex;
        for(let j = 0; j < InfoAwardData.awardRankings[x].length; j++)
        {
            html.push(
            <li>
                {InfoAwardData.awardItems[i].awardItems === 'HoleInOne' && <strong className={"rank"+InfoAwardData.awardRankings[x][j].ranking}>{InfoAwardData.awardRankings[x][j].ranking}</strong>}
                <div className="nickname">
                <p style={{backgroundImage: "url("+InfoRankData.cdn + InfoAwardData.awardRankings[x][j].userProfileImage+")"}}>photo</p>
                <em>{InfoAwardData.awardRankings[x][j].nickname}</em>
                </div>
                <span>
                    {InfoAwardData.awardItems[i].awardItems === 'Longest' || InfoAwardData.awardItems[i].awardItems === 'Nearpin' ? common.Length(InfoAwardData.awardRankings[x][j].finalRecord,'0') : InfoAwardData.awardItems[i].awardItems === 'HoleInOne' ? InfoAwardData.awardRankings[x][j].record : InfoAwardData.awardRankings[x][j].finalRecord}
                    <sub>
                    {/* {InfoAwardData.awardItems[i].awardType == 'Stroke' && lan.L('타')} */}
                    {InfoAwardData.awardItems[i].awardItems === 'Longest' && common.Length('','m')}
                    {InfoAwardData.awardItems[i].awardItems === 'Nearpin' && common.Length('','m')}
                    {/* {InfoAwardData.awardItems[i].awardItems == 'CourseRoundPlayCount' && lan.L('회')} */}
                    {InfoAwardData.awardItems[i].awardItems === 'HoleInOne' && lan.L('hole')}
                    {InfoAwardData.awardItems[i].awardType === 'BirdieCount' && lan.L('개')}
                    {InfoAwardData.awardItems[i].awardType === 'EagleCount' && lan.L('개')}
                    </sub>
                </span>
            </li>
            );
        }
        return html;
    }

	return (
        <>
        {/*------------------------------------ 랭킹 ------------------------------------*/}
        {!(InfoRankData.list === undefined || selectplayernum === -1  || InfoRankData.list.length === 0) &&
        <RankingBottomSheet
            id={`${InfoRankData.recordType !== 'BirdieCount' && InfoRankData.recordType !== 'EagleCount' ? InfoRankData.recordType : 'BirdieEagle'}Pop`}
            InfoData={InfoData}
            InfoRankData={InfoRankData}
            selectPlayerNum={selectplayernum}
            slideExit={slideExit}
            selector={selector}
        />
        }
        {/*------------------------------------// 랭킹 ------------------------------------*/}
        <BottomMatchRank
            defaultvalue="0"
            tournamentId={location.state.tournamentId}
            slideExit={slideExit}
            InfoData={InfoData}
        />
        <BottomMatchAward
            defaultvalue="0"
            tournamentId={location.state.tournamentId}
            slideExit={slideExit}
            InfoAwardData={InfoAwardData}
        />
		<div className="contents">
            <div className="main_info" style={{backgroundImage: `${require('../images/sub/match_bg_default.jpg')}`}}>{/* 첨부파일 없을 시 디폴트 BG */}
                <span>{InfoData.startTime === undefined  ? '' : common.UnixTime(InfoData.startTime,'년.월.일')+"~"+common.UnixTime(InfoData.endTime,'년.월.일')}</span>
                <em>{InfoData.title === undefined  ? '' : InfoData.title}</em>
                <span>
                <small>{InfoData.hostShopName === undefined  ? '' : InfoData.hostShopName}</small>
                <small>{InfoData.participantCount === undefined  ? '' : InfoData.participantCount}</small>
                </span>
                <span><i className={datetype}>{datetext}</i></span>
            </div>
            <MatchTab topics={[{tabName:'대회정보'},{tabName:'랭킹'},{tabName:'시상내역'}]} defaultTopics={0}></MatchTab>

            {/*------------------------------------ 대회정보 ------------------------------------*/}
            <aside style={activeTab === 0 ? {display:'block'}: {display:'none'}}>
                <ul className="section">
                    <CourseRoundsInformation
                        InfoData={InfoData}
                    />
                    <RoundSettingInformation
                        InfoData={InfoData}
                        groupedByGradeType={groupedByGradeType}
                    />
                </ul>
            </aside>
            {/*------------------------------------//대회정보 ------------------------------------*/}
            {/*------------------------------------ 랭킹 ------------------------------------*/}
            <aside style={activeTab === 1 ? {display: 'block'} : {display: 'none'}}>
                <div className="match_filter">
                    <span>
                        <label className="filter_index" onClick={slideRecordType}
                               id="recordType">{lan.L(selector.matchRankReducer.match_recordType)}</label>
                    </span>
                    <span>
                        <label className="filter_index" onClick={slideCourseId}>
                            {InfoData.courseRounds === undefined ? ''
                                : InfoData.isNewRankingSystem ? selector.matchRankReducer.match_courseNum === 'all' ? `${lan.L('합산')}`
                                        : `${lan.L('코스')}${selector.matchRankReducer.match_courseNum + 1}. ${InfoData.courseRounds[selector.matchRankReducer.match_courseNum].courseName}`
                                    : InfoData.courseRounds[selector.matchRankReducer.match_courseNum].courseName}
                        </label>
                    </span>
                </div>
                {/* No Data */}
                <div className="nodata" style={{display:'none'}} id="noRankingData">
                    <p><img  src={require("../images/character/nodata_5.png")} alt='No Data' /></p>
                    <strong>{lan.L('대회 랭킹 정보가 없습니다.')}</strong>
                    <span>{lan.L('대회 정보를 확인해 보세요.')}</span>
                </div>
                {/*//No Data */}
                {!(myrankingnum === -1 || InfoRankData.list.length === 0) &&
                <>
                {(selector.matchRankReducer.match_recordType !== 'NewPerio' || datetype === 'ending') && (
                <div className={`myranking ${InfoRankData.recordType.toLowerCase()} ${InfoData.isNewRankingSystem ? 'new' : ''}`} id="RankingDataDiv" onClick={(e)=>SlideRankDetail(selector.matchRankReducer.match_recordType, myrankingnum)}>{/* 팝업 종합 코스 랭킹 */}
                    {InfoRankData.recordType !== 'HoleInOne' && <strong>{InfoRankData.list[myrankingnum].totalRanking}</strong>}
                    <div className="nickname" data-new={InfoData.isNewRankingSystem}>
                        <p style={{backgroundImage: "url(" + InfoRankData.cdn + InfoRankData.list[myrankingnum].userProfileImage + ")"}}>photo</p>
                        {InfoData.isNewRankingSystem &&
                            <>
                            {(InfoRankData.recordType === 'Stroke' || InfoRankData.recordType === 'NewPerio') ?
                                <i>
                                    <img
                                        src={`${require(`/src/images/grade/ui_icon_grade_${InfoRankData.list[myrankingnum].tournamentGradeType}_${InfoRankData.list[myrankingnum].tournamentStepType}.png`)}`}
                                        alt=''/>
                                </i>
                                :
                                <i>
                                    <img
                                        src={`${require(`/src/images/grade/ui_icon_grade_${InfoRankData.list[myrankingnum].userGradeType}_${InfoRankData.list[myrankingnum].userStepType}.png`)}`}
                                        alt=''/>
                                </i>}
                            </>
                        }
                        <em>{InfoRankData.list[myrankingnum].userNickname}</em>
                    </div>
                    {InfoRankData.recordType === 'Stroke' ?
                        InfoData.isNewRankingSystem ?
                            <>
                                <span>{InfoRankData.list[myrankingnum].finalScore - (InfoRankData.list[myrankingnum].genderHandicap + InfoRankData.list[myrankingnum].handicap)}</span>
                                <span>{InfoRankData.list[myrankingnum].genderHandicap + InfoRankData.list[myrankingnum].handicap}</span>
                                <span>{InfoRankData.list[myrankingnum].finalScore}</span>
                            </>
                            : <span>{InfoRankData.list[myrankingnum].courseTotalScore}<sub>{lan.L('타')}</sub></span>
                        : ''}
                    {InfoRankData.recordType === 'NewPerio' ?
                        InfoData.isNewRankingSystem ?
                            selector.matchRankReducer.match_courseNum === 'all' ?
                                <span>{InfoRankData.list[myrankingnum].finalScore}</span> :
                                <>
                                    <span>{InfoRankData.list[myrankingnum].stroke}</span>
                                    <span>-</span>
                                    <span>{InfoRankData.list[myrankingnum].finalScore}</span>
                                </>
                            : <span>{InfoRankData.list[myrankingnum].newPerioFinalRecord}<sub>{lan.L('타')}</sub></span>
                        : ''}
                    {InfoRankData.recordType === 'BirdieCount' ? <span>{InfoRankData.list[myrankingnum].birdieCount}<sub>{lan.L('회')}</sub></span> : ''}
                    {InfoRankData.recordType === 'EagleCount' ? <span>{InfoRankData.list[myrankingnum].eagleCount}<sub>{lan.L('회')}</sub></span> : ''}
                    {InfoRankData.recordType === 'Longest' ? <span>{InfoData.courseRounds[0].longestHoleNumber + "H " + common.Length(InfoRankData.list[selectplayernum].record,'0m')}</span> : ''}
                    {InfoRankData.recordType === 'Nearpin' ? <span>{InfoData.courseRounds[0].nearpinHoleNumber + "H " + common.Length(InfoRankData.list[selectplayernum].record,'0m')}</span> : ''}
                    {InfoRankData.recordType === 'HoleInOne' ?
                        InfoData.isNewRankingSystem ?
                            <>
                                <span className='course'>{myCourseData?.courseName}</span>
                                <span className='hole'>{InfoRankData.list[myrankingnum].holeNumber}</span>
                                <span className='date'>{common.UnixTime(InfoRankData.list[myrankingnum].recordTime, '년-월-일')}</span>
                            </>
                            : <span>{InfoRankData.list[myrankingnum].holeNumber}<sub>{lan.L('Hole')}</sub></span>
                        : ''}
                    {InfoRankData.recordType === 'CourseRoundPlayCount'? <span>{InfoRankData.list[myrankingnum].totalCourseRoundPlayCount}<sub>{lan.L('회')}</sub></span> : ''}

                </div>
                )}
                </>
                }

                <ul className={`ranking_list ${InfoRankData.recordType?.toLowerCase()} ${InfoData.isNewRankingSystem ? 'new' : ''}`} id="ranking_list">
                {InfoData.isNewRankingSystem &&
                    <li className='ranking_list_head' style={{top: myrankingnum === -1 || InfoRankData.list.length === 0 ? '49px' : '122px'}}>
                        {InfoRankData.recordType === 'Stroke' && (
                            <>
                                <strong className='rank'>{lan.L('순위')}</strong>
                                <div className='nickname'>{lan.L('닉네임')}</div>
                                <span>{lan.L('스코어')}</span>
                                <span>{lan.L('핸디캡')}</span>
                                <span>Total</span>
                            </>
                        )}
                        {InfoRankData.recordType === 'NewPerio' &&
                            <>
                                <strong className='rank'>{lan.L('순위')}</strong>
                                <div className='nickname'>{lan.L('닉네임')}</div>
                                {selector.matchRankReducer.match_courseNum === 'all' ?
                                <span>Total</span> :
                                <>
                                    <span>{lan.L('스코어')}</span>
                                    <span>{lan.L('핸디캡')}</span>
                                    <span>Total</span>
                                </>}
                            </>
                        }
                        {(InfoRankData.recordType === 'BirdieCount' || InfoRankData.recordType === 'EagleCount' || InfoRankData.recordType === 'CourseRoundPlayCount') &&
                            <>
                                <strong className='rank'>{lan.L('순위')}</strong>
                                <div className='nickname'>{lan.L('닉네임')}</div>
                                <span>{lan.L('횟수')}</span>
                            </>
                        }
                        {InfoRankData.recordType === 'Longest' &&
                            <>
                                <strong className='rank'>{lan.L('순위')}</strong>
                                <div className='nickname'>{lan.L('닉네임')}</div>
                                <span>{lan.L('비거리')}</span>
                            </>
                        }
                        {InfoRankData.recordType === 'Nearpin' &&
                            <>
                                <strong className='rank'>{lan.L('순위')}</strong>
                                <div className='nickname'>{lan.L('닉네임')}</div>
                                <span>{lan.L('남은거리')}</span>
                            </>
                        }
                        {InfoRankData.recordType === 'HoleInOne' && (
                            <>
                                <div className='nickname'>{lan.L('닉네임')}</div>
                                <span className='course'>{lan.L('코스이름')}</span>
                                <span className='hole'>Hole</span>
                                <span className='date'>{lan.L('날짜')}</span>
                            </>
                        )}
                    </li>
                }
                {InfoRankData.list === undefined || InfoRankData.list.length === 0 ? '' :
                InfoRankData.list.map((url, i) => {
                    const courseData = InfoData.courseRounds?.find((p) => p.courseId === InfoRankData.list[i].courseId);
                    return (
                        <li onClick={(e) => SlideRankDetail(selector.matchRankReducer.match_recordType, i)}>{/* 팝업 스코어 랭킹 */}
                            {InfoRankData.recordType !== 'HoleInOne' &&
                                <strong
                                    className={"rank" + InfoRankData.list[i].totalRanking}>{InfoRankData.list[i].totalRanking}</strong>
                            }
                            <div className="nickname">
                                <p style={{backgroundImage: "url(" + InfoRankData.cdn + InfoRankData.list[i].userProfileImage + ")"}}>photo</p>
                                {InfoData.isNewRankingSystem &&
                                    <>
                                        {(InfoRankData.recordType === 'Stroke' || InfoRankData.recordType === 'NewPerio') ?
                                            <i>
                                                <img
                                                    src={`${require(`/src/images/grade/ui_icon_grade_${InfoRankData.list[i].tournamentGradeType}_${InfoRankData.list[i].tournamentStepType}.png`)}`}
                                                    alt=''/>
                                            </i>
                                            :
                                            <i>
                                                <img
                                                    src={`${require(`/src/images/grade/ui_icon_grade_${InfoRankData.list[i].userGradeType}_${InfoRankData.list[i].userStepType}.png`)}`}
                                                    alt=''/>
                                            </i>}
                                    </>
                                }
                                <em>{InfoRankData.list[i].userNickname}</em>
                            </div>
                            {InfoRankData.recordType === 'Stroke' ?
                                InfoData.isNewRankingSystem ?
                                    <>
                                        <span>{InfoRankData.list[i].finalScore - (InfoRankData.list[i].genderHandicap + InfoRankData.list[i].handicap)}</span>
                                        <span>{InfoRankData.list[i].genderHandicap + InfoRankData.list[i].handicap}</span>
                                        <span>{InfoRankData.list[i].finalScore}</span>
                                    </>
                                    : <span>{InfoRankData.list[i].courseTotalScore}<sub>{lan.L('타')}</sub></span>
                                : ''}
                            {InfoRankData.recordType === 'NewPerio' ?
                                InfoData.isNewRankingSystem ?
                                    selector.matchRankReducer.match_courseNum === 'all' ?
                                        <span>{InfoRankData.list[i].finalScore}</span> :
                                        <>
                                            <span>{InfoRankData.list[i].stroke}</span>
                                            <span>-</span>
                                            <span>{InfoRankData.list[i].finalScore}</span>
                                        </>
                                    : <span>{InfoRankData.list[i].newPerioFinalRecord}<sub>{lan.L('타')}</sub></span>
                                : ''}
                            {InfoRankData.recordType === 'BirdieCount' ?
                                <span>{InfoRankData.list[i].birdieCount}<sub>{lan.L('회')}</sub></span> : ''}
                            {InfoRankData.recordType === 'EagleCount' ?
                                <span>{InfoRankData.list[i].eagleCount}<sub>{lan.L('회')}</sub></span> : ''}
                            {InfoRankData.recordType === 'Longest' ?
                                <span>{InfoData.courseRounds[0].longestHoleNumber + "H " + common.Length(InfoRankData.list[i].record, '0m')}</span> : ''}
                            {InfoRankData.recordType === 'Nearpin' ?
                                <span>{InfoData.courseRounds[0].nearpinHoleNumber + "H " + common.Length(InfoRankData.list[i].record, '0m')}</span> : ''}
                            {InfoRankData.recordType === 'HoleInOne' ?
                                InfoData.isNewRankingSystem ?
                                    <>
                                        <span className='course'>{courseData.courseName}</span>
                                        <span className='hole'>{InfoRankData.list[i].holeNumber}</span>
                                        <span className='date'>{common.UnixTime(InfoRankData.list[i].recordTime, '년-월-일')}</span>
                                    </>
                                    : <span>{InfoRankData.list[i].holeNumber}<sub>{lan.L('Hole')}</sub></span>
                                : ''}
                            {InfoRankData.recordType === 'CourseRoundPlayCount' ?
                                <span>{InfoRankData.list[i].totalCourseRoundPlayCount}<sub>{lan.L('회')}</sub></span> : ''}
                        </li>
                    )
                })}
                </ul>
            </aside>
            {/*------------------------------------//랭킹 ------------------------------------*/}
            {/*------------------------------------ 시상내역 ------------------------------------*/}
            <aside style={activeTab === 2 ? {display: 'block'} : {display: 'none'}}>
                <div className="match_filter">
                    <span>
                        <a>
                        <input type="checkbox" id="award1" name="award" onChange={changemyawardshow}/>
                        <label htmlFor="award1">{lan.L('나의 시상내역')}</label>
                        </a>
                    </span>
                    <span>
                        <label className="filter_index"
                               onClick={slideAwardId}>{InfoAwardData.awardItems === undefined || InfoAwardData.awardItems.length == 0 || selector.matchRankReducer.match_awardNum == 'all' ? lan.L('전체보기') : InfoAwardData.awardItems[selector.matchRankReducer.match_awardNum].awardName}</label>
                    </span>
                </div>
                {/* No Data */}
                {InfoAwardData.awardItems === undefined || InfoAwardData.awardItems.length === 0 || (ismyawardshow && !isnomyward) &&
                    <div className="nodata">
                        <p><img src={require("../images/character/nodata_5.png")}/></p>
                        <strong>{lan.L('대회 시상 정보가 없어요.')}</strong>
                        <span>{lan.L('대회 정보를 확인해 보세요.')}</span>
                    </div>
                }
                {/*//No Data */}
                <ul className="award">
                    {!(InfoAwardData.awardItems === undefined || InfoAwardData.awardItems.length === 0) &&
                        InfoAwardData.awardItems.map((url, i) => (
                            <>
                                {ismyawardshow && !myawardboollist[i] || (selector.matchRankReducer.match_awardNum !== 'all' && selector.matchRankReducer.match_awardNum !== i) ? '' :    //나의 시상내역 체크 및 해당 값 안에 내 아이디랑 일치하는게 없으면 숨김처리
                                    <li key={i}>
                                        <em>{InfoAwardData.awardItems[i].awardName}</em>
                                        <div className="substance">
                                            <dl>
                                                <dd>{lan.L('시상타입')}</dd>
                                                <dt>{InfoData.isNewRankingSystem ? lan.L(InfoAwardData.awardItems[i].awardType) : InfoAwardData.awardItems[i].awardType}</dt>
                                            </dl>
                                            {InfoData.isNewRankingSystem ? '' :
                                                InfoAwardData.awardItems[i].awardItems === 'HoleInOne' || InfoAwardData.awardItems[i].awardItems === 'CourseRoundPlayCount' ? '' :
                                                <>
                                                    <dl>
                                                        <dd>{lan.L('코스이름')}</dd>
                                                        <dt>{findCourseName(InfoAwardData.awardItems[i].courseId)}</dt>
                                                    </dl>
                                                </>
                                            }
                                            <dl>
                                                <dd>{lan.L('시상상품명')}</dd>
                                                <dt>{InfoAwardData.awardItems[i].awardProduct}</dt>
                                            </dl>
                                        </div>
                                        <ul className="prize_rank">
                                            {awardRankingsFunc(i)}
                                        </ul>
                                    </li>
                                }
                            </>
                        ))}
                </ul>
            </aside>
            {/*------------------------------------//시상내역 ------------------------------------*/}
        </div>
            {/* contents(e) */}
        </>
    )
}
