import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import * as commonsms from '../common/script/common_sms';
import $ from 'jquery';
import axios from 'axios';

import BottomCountryPhone from '../popup/bottom_countryphone';
import * as slide from '../popup/bottom_countryphone';
import { createBrowserHistory } from 'history';

import Top from '../inc/top'; //타이머처리때문에 내부에 처리

const minmobilenum = 9;
const maxcertnum = 6;
var certnum = "";
var sendcert = "N";
var certend = "N";
var limitTimer = 10, objLimitTimer;
var WrongCnt = 0;
var MaxWrongCnt = 5;

var navigate;
var historylistener;

//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
//타이머
//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
function TikTokLimitTime(){
	var objTimer = $("#Timer");

	var sMin = parseInt(limitTimer/60);
	var sSec = limitTimer-(sMin*60);

	if(sSec<0){sSec = limitTimer;}

	var strMin = "00";
	if(sMin>0){
		strMin = "0"+sMin;
		strMin = strMin.substring(strMin.length-2,strMin.length);
	}

	var strTime = "00";
	if(sSec>0){
		strTime = "0"+sSec;
		strTime = strTime.substring(strTime.length-2,strTime.length);
	}

	objTimer.show();
	objTimer.html(strMin+":"+strTime);

	if(limitTimer >0){
		objLimitTimer = setTimeout(function(){
			limitTimer--;
			TikTokLimitTime();
		}, 1000);
	}else{
		objTimer.html("00:00");
		common.alertpop(lan.L('회원가입'),lan.L("입력시간을 초과하였습니다. \r\n인증번호를 재전송해주세요."));
		ResetCert();
		navigate("/common/script/backnavigation", {state : {back:'/join/phonenumber'}, replace : true});
	}
}

function ResetCert(){
	sendcert = 'N';
	clearTimeout(objLimitTimer);
	limitTimer = 180;
	WrongCnt = 0;

	//$("#CertNo").val("");

	$("#Timer").hide();
	certnum = "";
}

export default function Phonenumber(){
	
	const location = useLocation();
	navigate = useNavigate();
	const history = createBrowserHistory();
	const [InfoData, SetInfoData] = useState({phone : '', phoneCountryValue : '' , phoneCountry : '', certno : ''});
	const [phoneCountryValue, setPhoneCountryValue] = useState('');
	const [isDisabled, setIsDisabled] = useState(false);
	const [isCertification, setIsCertification] = useState(false);
	const [sendCert, setSendCert] = useState(false);

	//화면전환시 실행
	useEffect(() => {
		if(window.localStorage.getItem('join_phone') === null) window.localStorage.setItem('join_phone','');
		if(window.localStorage.getItem('join_phoneCountryValue') === null) window.localStorage.setItem('join_phoneCountryValue','');
		if(window.localStorage.getItem('join_phoneCountry') === null) window.localStorage.setItem('join_phoneCountry','');
		if(window.localStorage.getItem('join_phone') != '')
		{
//			SetInfoData(
//				{
//				  loginID : window.localStorage.getItem('join_phone'),
//				  loginPass : window.localStorage.getItem('join_phoneCountryValue'),
//				  loginPass2 : window.localStorage.getItem('join_phoneCountry'),
//				}
//			);
//			$("#newloginID").val(InfoData.loginID);
//			$("#newloginpass").val(InfoData.loginPass);
//			$("#newloginpass2").val(InfoData.loginPass2);
		}
		sendcert = 'N';
		checkAllPossible(InfoData.phone,InfoData.certno, phoneCountryValue, setIsDisabled, isCertification, isDisabled);
		
        if(historylistener === undefined)
        {
            historylistener = history.listen((location) => {
                if (history.action === "POP") {
					ResetCert();
                }
            });
        }
    },[location]);

	useEffect(() => {
		if(phoneCountryValue === ('jp' || 'PH' || 'VN')){
			setIsDisabled(true);
		} else {
			setIsCertification(false);
		}
	}, [phoneCountryValue]);

	useEffect(() => {
		checkAllPossible(InfoData.phone, InfoData.certno, phoneCountryValue, setIsDisabled, isCertification, isDisabled);
	}, [isCertification, isDisabled]);

	const onDelete = (e) => {
		var id = e.target.id;
	    var data = InfoData;
		
		if(id == "newPhoneDel")
		{
			setIsDisabled(true);
			data.phone = '';
			$("#newPhone").val('');
			SetInfoData(data);
			$("#newPhoneDel").hide();
		}
	}

	const onChange = (e) => {
		  var id = e.target.id;
		  var value = e.target.value;
		  var data = InfoData;

		if(id == "newPhone")
		{
			if(value != '') $("#newPhoneDel").show();
			else $("#newPhoneDel").hide();

			data.phone = value;
			SetInfoData(data);
		}
		else if(id == "newCertNo")
		{
			data.certno = value;
			SetInfoData(data);
		}
		checkAllPossible(InfoData.phone, InfoData.certno, phoneCountryValue, setIsDisabled, isCertification, isDisabled);
	}
	  
	// 버튼
	const goCompletion = (e) => {


		if(!(phoneCountryValue === 'JP' || phoneCountryValue === 'PH' || phoneCountryValue === 'VN') && $("#newCertNo").val() != certnum)
		{
			common.alertpop(lan.L('회원가입'),lan.L("인증번호가 일치하지 않습니다."));
			return false;
		}

		InfoData.phoneCountryValue = phoneCountryValue;
		InfoData.phoneCountry = $("#newPhoneCountry").html();
		
		var CountryPhoneCode = "+"+common.getPhoneValueBySettingValue(InfoData.phoneCountryValue);

		window.localStorage.setItem('join_phone',CountryPhoneCode + (InfoData.phone.substr(0,1) == 0 ? InfoData.phone.substr(1,InfoData.phone.length) : InfoData.phone));
		window.localStorage.setItem('join_phoneCountryValue',InfoData.phoneCountryValue);
		window.localStorage.setItem('join_phoneCountry',InfoData.phoneCountry);

		ResetCert();
		goJoin();
	}

	const handleCert = () => {
		setIsCertification(!isCertification);
	}
	// 버튼
	const goCert = (e) => {
		setSendCert(true);
		window.localStorage.setItem('join_certnum','');

		var CountryPhoneCode = "+"+common.getPhoneValueBySettingValue(phoneCountryValue);

		// window.localStorage.setItem('join_phone',CountryPhoneCode + (InfoData.phone.substr(0,1) == 0 ? InfoData.phone.substr(1,InfoData.phone.length) : InfoData.phone));
		// alert(CountryPhoneCode + (InfoData.phone.substr(0,1) == 0 ? InfoData.phone.substr(1,InfoData.phone.length) : InfoData.phone));

		// return false;

		if(phoneCountryValue == '')
		{
			common.alertpop(lan.L('회원가입'),lan.L("국가를 선택해주세요."));
			return false;
		}

		ResetCert();
		sendcert = "Y";
		common.alertpop(lan.L('회원가입'),lan.L('인증번호가 전송되었습니다.'));
		$("#certnoli").show();
		$("#btnCert").hide();
		$("#newCertNo").val('');

		certnum = common.generateRandomCode(6); //인증번호 통신 필요

		commonsms.sendSMS(certnum,$("#newPhone").val(),window.localStorage.getItem('phoneCountryNumber'));
		
		$("#newPhoneDel").hide();
		$("#newPhone").attr('disabled',true);
		TikTokLimitTime();
	}
	
	const goback = (e) => {
        window.localStorage.setItem('choicenavigate','-1');
        window.localStorage.setItem('choice_joinback','Y');
		common.alertchoice(lan.L('회원가입'),lan.L('회원가입 정보가 초기화됩니다. 취소하시겠습니까?'));
	}

	return (
	<>
	<header class="topfix" >
		<button type="button" id="Back" onClick={goback} >Back</button>
		<em>{lan.L('회원가입')}</em>
	</header>
	<BottomCountryPhone returntype="html" returnid="newPhoneCountry" returnvalueid="newPhoneCountryValue" setPhoneCountryValue={setPhoneCountryValue}></BottomCountryPhone>
	<div>
	  <div className="container">
		<div className="process">
		  <span><b>5</b>/5</span>
		  <em>{lan.L('전화번호 인증')}</em>
		</div>
		<ul className="write_form">
		  <li>
			<span>
			  <input type="hidden" id="newPhoneCountryValue" value="" />
			  <label onClick={(e) => {if(sendcert != "Y") {slide.slideOn()}}} id="newPhoneCountry">{lan.L('Country')}</label>
			  <em>{lan.L('국가')}</em>
			</span>
		  </li>
		  <li>
			<span>
			  <input type="number" id="newPhone" onChange={onChange} placeholder={lan.L("'-' 없이 입력")} onInput={(e) => {
				  e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');}} />
			  <em>{lan.L('휴대폰번호')}</em>
			  <a id="newPhoneDel" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
			</span>
			  {!isCertification && <button type="button" className="btn_submit" id="btnCert" disabled={isDisabled} onClick={(e) => goCert(e)}>{lan.L('인증번호 전송')}</button>}
			  {((phoneCountryValue === 'JP' || phoneCountryValue === 'PH' || phoneCountryValue === 'VN') && !sendCert) && <button type="button" className="btn_submit" disabled={isDisabled} onClick={handleCert}>{!isCertification ? lan.L('인증생략') :  lan.L('인증하기')}</button>}
			  {(isCertification && !sendCert) &&
				  <div className='display_text'>
					  <b className='error password_notFind'>
						  <i>{lan.L("인증절차 생략 시 ID 및 PW 찾기 기능을 사용할 수 없습니다.")}</i>
					  <i>{lan.L("ID 및 PW를 숙지 부탁 드립니다.")}</i>
					  <i>{lan.L("ID 및 PW 분실 시 고객 센터로 문의 바랍니다.")}</i>
				  </b>
			  </div>
		  }
		  </li>
		  <li id="certnoli" style={{display: 'none'}}>
			<span>
			  <input type="number" placeholder={lan.L("6자리 입력")}  id="newCertNo" maxLength={maxcertnum} onChange={onChange} onInput={(e) => {
				  if (e.target.value.length > e.target.maxLength)
					e.target.value = e.target.value.slice(0, e.target.maxLength);
				}}/>
			  <em>{lan.L('인증번호')}</em>
			  <sub id="Timer">02:58</sub>
			</span>
			<a className="resend" id="resend" onClick={(e) => goCert(e)}>{lan.L('인증번호 재전송')}</a>
		  </li>
		</ul>
	  </div>{/* container(e) */}
	  <div className="sticky_button">
		<button type="button" className="btn_standard" id="btnCompletion"  onClick={goCompletion}>{lan.L('다음')}</button>
	  </div>
	</div>
	</>
	)
}
	  
function checkAllPossible(phone,certno, phoneCountryValue, setIsDisabled, isCertification, isDisabled) {

	if(phone.length < minmobilenum)	setIsDisabled(true);
	else	setIsDisabled(false);

	if(phoneCountryValue === 'JP' || phoneCountryValue === 'PH' || phoneCountryValue === 'VN') {
		if(isCertification && !isDisabled) {
			$("#btnCompletion").attr('disabled',false);
		} else {
			$("#btnCompletion").attr('disabled',true);
		}
	} else {
		$("#btnCompletion").attr('disabled',true);
	}
	if(certno.length == maxcertnum)
	{
		$("#btnCompletion").attr('disabled',false);
	}
}

function goJoin() {

	if(window.localStorage.getItem('join_joinType') == 'normal')
	{
		window.localStorage.setItem('join_socialIdToken','');
	}
	else
	{
		
	}

	var joindata = {
		joinType: window.localStorage.getItem('join_joinType'),
		id: window.localStorage.getItem('join_loginID'),
		password: window.localStorage.getItem('join_loginPass'),
		nickname: window.localStorage.getItem('join_nickname'),
		mobilePhoneCountry: window.localStorage.getItem('join_phoneCountryValue'),
		mobilePhone: window.localStorage.getItem('join_phone'),
		gender: window.localStorage.getItem('join_gender'),
		birthday: window.localStorage.getItem('join_birthday'),
		isTermsAgreeNotificationSMS: window.localStorage.getItem('join_agree04'),
		country: window.localStorage.getItem('join_countryValue'),
		email: '',
		zipcode: '',
		province: '',
		city: '',
		address1: '',
		address2: '',
		difficulty: '',
		teeBoxType: '',
		teeHeight: '',
		socialIdToken: window.localStorage.getItem('join_socialIdToken')
	}

	//회원가입 체크
	if(window.localStorage.getItem('join_loginID') === null||
		window.localStorage.getItem('join_loginPass') === null||
		window.localStorage.getItem('join_nickname') === null||
		window.localStorage.getItem('join_phoneCountryValue') === null||
		window.localStorage.getItem('join_phone') === null||
		window.localStorage.getItem('join_gender') === null||
		window.localStorage.getItem('join_birthday') === null||
		window.localStorage.getItem('join_agree04') === null||
		window.localStorage.getItem('join_countryValue') === null
	)
	{
		common.alertpop(lan.L('회원가입'),lan.L('세션이 초기화되었습니다. 회원가입 절차를 다시 확인하세요.'));
		return false;
	}

	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: joindata,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('회원가입')
		}
	}).then(function (response) {
		console.log(response.data);
		var data = response.data;

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			if(response.data.error.code === 4072) {
				common.alertpop(lan.L('전화번호 인증'),lan.L('정확한 휴대폰 번호를 입력하세요.'));
			} else {
				common.alertByError('회원가입',response.data.error.code);
			}
			return false;
		}

		navigate('/login/login',{replace:true});
		navigate('/join/completion');

	}).catch(error => {
		console.log(error.response)
	});
}