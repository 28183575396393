import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import * as common from '../common/script/common';
import $ from 'jquery';

var navigate, location;

export default function ViewNotice() {

	const [InfoJson, SetInfoJson] = useState({});

	navigate = useNavigate();
	location = useLocation();

	//화면 이동 시
	useEffect(() => {
        
			axios({
                method: 'post',
                url: process.env.REACT_APP_SERVER + '/api',
                data: {},
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'language':sessionStorage.getItem('Language'),
                    'pUrl' : encodeURIComponent('게시판 상세 조회'),
                    'token' : sessionStorage.getItem('access_token'),
                    'guid' : location.state.boardId
                }
            }).then(function (response) {
                console.log(response);
    
                if(response.data.error !== undefined && response.data.error.code > 0)
                {
                    common.alertByError('게시판 상세 조회',response.data.error.code);
                    return false;
                }
                else
                {
                    var data= response.data;
                    SetInfoJson(data);
                }
            }).catch(error => {
                console.log(error.response)
            });
	},[location]);


	return (
    <div className="container">
        <div className="view_board">
        <em>{InfoJson.title ===undefined ? '':InfoJson.title}</em>
        <small>{InfoJson.regTime ===undefined ? '' : common.UnixTime(InfoJson.regTime,'년.월.일')}</small>
        <span className="new-line">
            {InfoJson.content}
        </span>
        </div>
    </div>
	)
}