import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common.js';
import ReactPlayer from "react-player";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import Vid from "../images/20221229_01_743430_1201_SF_N_Ro.mp4";
import Vidtest from "../images/9GBo-mountain-video.mp4";

import Top from '../inc/top';

var clickCnt = 0;

var create_dot_arr = [];
var ctx;
var canvas

export default function DetailVideoPractice(props){
	
	const [InfoJson, SetInfoJson] = useState({});
	const [InfoJsonVideo, SetInfoJsonVideo] = useState({});
	const [InfoMapJson, SetMapInfoJson] = useState({});
	const [ProfileImg, SetProfileImg] = useState('');
	const [InfoCourseMap, SetInfoCourseMap] = useState({});
	const [nowMap, SetnowMap] = useState(0);

	const location = useLocation();
	const navigate = useNavigate();
	var data, mapdata;
	const loginID = sessionStorage.getItem('loginID');

	const [isView, SetisView] = useState(false);
	const [isSwingVideoArchive, SetisSwingVideoArchive] = useState(false);
	const [nowVideoLink, SetnowVideoLink] = useState('');
    

	var gameRoundId, practiceShotId, roundData, shotData, cdn, practiceType, gameRoundNum;

	if(location.state === undefined || location.state == null)
	{
		common.alertpop(lan.L('연습 샷 정보'),lan.L("요청하지 않은 경로입니다."));
	}
	else
	{
		gameRoundId		  = location.state.gameRoundId;
		practiceShotId		= location.state.practiceShotId;
		roundData			  = location.state.roundData;
		shotData				= location.state.shotData;
		cdn							= location.state.cdn;
		practiceType		  = location.state.practiceType;
		gameRoundNum	= location.state.gameRoundNum;
		console.log(practiceType);
	}
	console.log(gameRoundId,practiceShotId);

	//화면전환시 실행
	useEffect(() => {
		
			$('html, body').animate({scrollTop:0},0);

			axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('연습장 샷 조회'),
				'guid' : gameRoundId,
				'guid2' : practiceShotId,
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
			console.log(response);
			var tempisView = response.data.isView;

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError('연습 샷 정보',response.data.error.code);
				return false;
			}
			else
			{
				var data = Object.assign(response.data,roundData);
				data = Object.assign(data,shotData);

				//라운드 샷 정보
				//uploads/Recording/Encoding/3ahhw/Normal/20221229_01_743430_1201_SF_N_Ro.mp4
				// cdn = '';
				// data.swingVideo.frontVideoKey = '../images/9GBo-mountain-video.mp4';
				//data.swingVideo.frontVideoKey = 'uploads/Recording/Encoding/3ahhw/Normal/20221229_01_743430_1201_SF_N_Ro.mp4';
				//data.swingVideo.frontVideoThumbnailKey = 'uploads/Recording/Encoding/3ahhw/Normal/20221229_01_743430_1201_SF_N_Ro.jpg'
				//data.swingVideo.frontVideoKey ='https://uploads.codesandbox.io/uploads/user/fb7bd72f-ef17-4810-9e14-ca854fb0f56e/9GBo-mountain-video.mp4'
				//data.swingVideo.frontVideoKey = 'uploads/Recording/Encoding/rxhbh/Normal/test.mp4';
				
				//data.swingVideo.sideVideoKey = 'uploads/Recording/Encoding/rxhbh/Normal/test.mp4';
				SetInfoJson(data);
				SetInfoJsonVideo(data.swingVideo);
				$("#frontVideo").filter(':first-child').attr("poster",cdn+data.swingVideo.frontVideoThumbnailKey);
				SetnowVideoLink(cdn+data.swingVideo.frontVideoKey);
				SetisView(tempisView);
				SetisSwingVideoArchive(data.isSwingVideoArchive);

				$("#secret").prop("checked",!tempisView);

				window.localStorage.setItem('isSwingVideoArchive',data.isSwingVideoArchive);
			}
		}).catch(error => {
			console.log(error.response)
		});

    },[location]);
	
	
	const goDownload = () => {
		
		//ios의 경우 콜앱으로 다운로드
		if(common.isIOS()){
			common.iOSCallApp({appid:"goDownload", params: nowVideoLink});
		}
		else //안드로이드같은 경우 a태그로 다운로드
		{
			var a = $("<a>")
				.attr("href", nowVideoLink)
				.attr("download", "")
				.appendTo("body")
				.attr("target","_blank");
	
			a[0].click();
			a.remove();
		}
	}
	
	const changeVideo = (id) => {
		if($("#"+id+"li").hasClass("empty"))
		{
			return false;
		}
		
		$("#frontVideo").hide();
		$("#sideVideo").hide();
		$("#inGameVideo").hide();
		$("#"+id).show();

		$("#frontVideo").attr('playing',false);
		$("#sideVideo").attr('playing',false);
		$("#inGameVideo").attr('playing',false);

		$("#frontVideoli").removeClass("on");
		$("#sideVideoli").removeClass("on");
		$("#inGameVideoli").removeClass("on");
		$("#"+id+"li").addClass("on");

		if(id=="frontVideo")	SetnowVideoLink(cdn + InfoJsonVideo.frontVideoKey);
		else if(id=="sideVideo")	SetnowVideoLink(cdn + InfoJsonVideo.sideVideoKey);
		else if(id=="inGameVideo")	SetnowVideoLink(cdn + InfoJsonVideo.inGameVideoKey);
	}
	
	const changeVideoSlow = (e) => {
		var id = e.target.id;

		$("#vspeed25").removeClass("on");
		$("#vspeed50").removeClass("on");
		$("#vspeed75").removeClass("on");
		$("#vspeed100").removeClass("on");

		if(id=="vspeed100")
		{
			$("#vspeed75").addClass("on");
			document.querySelectorAll('video')[0].playbackRate = 0.75;
			document.querySelectorAll('video')[1].playbackRate = 0.75;
			document.querySelectorAll('video')[2].playbackRate = 0.75;
		}

		if(id=="vspeed75")
		{
			$("#vspeed50").addClass("on");
			document.querySelectorAll('video')[0].playbackRate = 0.5;
			document.querySelectorAll('video')[1].playbackRate = 0.5;
			document.querySelectorAll('video')[2].playbackRate = 0.5;
		}

		if(id=="vspeed50")
		{
			$("#vspeed25").addClass("on");
			document.querySelectorAll('video')[0].playbackRate = 0.25;
			document.querySelectorAll('video')[1].playbackRate = 0.25;
			document.querySelectorAll('video')[2].playbackRate = 0.25;
		}
		
		if(id=="vspeed25")
		{
			$("#vspeed100").addClass("on");
			document.querySelectorAll('video')[0].playbackRate = 1.0;
			document.querySelectorAll('video')[1].playbackRate = 1.0;
			document.querySelectorAll('video')[2].playbackRate = 1.0;
		}
	}

	const disableVideo = (id) => {
		$("#"+id).addClass("empty");

		if(id=="frontVideoli")
		{
			$("#frontVideo").hide();
			$("#playbtn").hide();
			$(".video_container").addClass("empty");
		}
	}

	const goInsta = () => {
		
		if(common.isIOS()){
			common.iOSCallApp({appid:"goInsta", params: nowVideoLink});
		}
		else
		{
			try{common.andriodCallApp('goInsta',[nowVideoLink]);}catch (ex){
				window.NativeInterface.getIntro('{"videourl":'+nowVideoLink+'}');
			}
		}
	}
	
	const showVideo = () => {
		
		if(common.isIOS()){
			common.iOSCallApp({appid:"showVideo", params: nowVideoLink});
		}
		else
		{
			try{common.andriodCallApp('showVideo',[nowVideoLink]);}catch (ex){
				window.NativeInterface.getIntro('{"videourl":'+nowVideoLink+'}');
			}
		}
	}
	
	const changeVideoArchive = (change) => {

		var data = {
			isView: change== "isView" ? !isView : isView,
			isSwingVideoArchive:change== "isSwingVideoArchive" ? !isSwingVideoArchive : isSwingVideoArchive,
		};

		console.log(data,gameRoundId,practiceShotId);

		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: data,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('연습장 샷 업데이트'),
				'token' : sessionStorage.getItem('access_token'),
				'guid' : gameRoundId,
				'guid2' : practiceShotId,
			}
		}).then(function (response) {
			console.log(response);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				if(change == "isView")
				{$("#secret").prop("checked",data.isView);}
				common.alertByError('라운드 샷 정보',response.data.error.code);
				return false;
			}
			
			if(change== "isSwingVideoArchive" && isSwingVideoArchive==true) common.alertpop(lan.L('라운드 샷 정보'),lan.L('보관해제 되었습니다.'));
			else if(change== "isSwingVideoArchive" && isSwingVideoArchive==false) common.alertpop(lan.L('라운드 샷 정보'),lan.L('보관 되었습니다.'));
			else if(change== "isView" && isView==true) common.alertpop(lan.L('라운드 샷 정보'),lan.L('비공개 되었습니다.'));
			else if(change== "isView" && isView==false) common.alertpop(lan.L('라운드 샷 정보'),lan.L('공개 되었습니다.'));

			if(change== "isView") SetisView(!isView);
			else if(change== "isSwingVideoArchive") SetisSwingVideoArchive(!isSwingVideoArchive);

		}).catch(error => {
			console.log(error.response)
		});
	}
  
	return (
	<>
	<Top class="topfix" title={"연습 샷 정보"} lockFunction={(e)=>changeVideoArchive("isView")} type='backwithstate' state={{activeTab : 1, backpracticegameRoundId : gameRoundId, backgameRoundNum : gameRoundNum}} to='/shot/shotvideo'  backTabTopics="1"/>
	<div className="contents">
	  <div className="roundshot_info">
		<dl>
		  <dt>{lan.L(InfoJson.practiceType)}</dt>
		  <dd>{common.UnixTime(InfoJson.startTime,'년-월-일')}</dd>
		</dl>
		<span>
		  <small>{lan.L(InfoJson.shopName)}</small>
		</span>
		<div className="basic_marks">
			{InfoJson.shotGrade != undefined && InfoJson.shotGrade != 0 && InfoJson.shotGrade != 4 ? <i className={common.commonKeyDic['shotGrade'][InfoJson.shotGrade].toLowerCase()}>{common.commonKeyDic['shotGrade'][InfoJson.shotGrade]}</i> : ''}
			{practiceType != undefined && (practiceType == '2' || practiceType == '3') ? <i>{common.Length(InfoJson.targetSettingDistance,'0m')}</i> : ''}
		  <i>{common.commonKeyDic['recommandClub'][InfoJson.recommandClub]}</i>
		  <div className="score_point">
			<b>{InfoJson.score}</b>
			<small>shots</small>
		  </div>
		</div>
	  </div>
	  <div className="player_wrap">
		<div className="storage">
		  <button type="button" className={isSwingVideoArchive==true ? "keep on" : "keep"} onClick={(e)=>changeVideoArchive("isSwingVideoArchive")}>{isSwingVideoArchive==true ? lan.L('해제') : lan.L('보관')}</button>
		  <button type="button" className="save" onClick={goDownload}>{lan.L('저장')}</button>
		  <a onClick={goInsta}>sns</a>
		</div>
		{common.isIOS() ?
		<div className="video_container">{/* 파일없을 시 class="empty" 추가 */}
		  <span>{lan.L('동영상 파일이 없습니다.')}</span>
		  <div className="speed">
			<button type="button" className id="vspeed25" onClick={changeVideoSlow}>0.25x</button>
			<button type="button" className id="vspeed50" onClick={changeVideoSlow}>0.50x</button>
			<button type="button" className id="vspeed75" onClick={changeVideoSlow}>0.75x</button>
			<button type="button" className="on" id="vspeed100" onClick={changeVideoSlow}>1x</button>
			</div>
		  <ReactPlayer
		  			id="frontVideo"
                    url={InfoJson.swingVideo===undefined ? '' : cdn+InfoJsonVideo.frontVideoKey}    // 플레이어 url
					playing={false}
					controls={true}
					loop={true}
					muted={true}
					playsinline={true}
					width={window.innerWidth}
					height={window.innerHeight / 4}
					light={InfoJsonVideo.frontVideoThumbnailKey === undefined ? require('../images/sub/noimage_ci_w.png') :  cdn+InfoJsonVideo.frontVideoThumbnailKey}
                />
		  <ReactPlayer
		  			id="sideVideo"
                    url={InfoJson.swingVideo===undefined ? '' : cdn+InfoJsonVideo.sideVideoKey}    // 플레이어 url
					playing={false}
					controls={true}
					loop={true}
					muted={true}
					playsinline={true}
					style={{'display':'none'}}
                />
		  <ReactPlayer
		  			id="inGameVideo"
                    url={InfoJson.swingVideo===undefined ? '' : cdn+InfoJsonVideo.inGameVideoKey}    // 플레이어 url
					playing={false}
					controls={true}
					loop={true}
					muted={true}
					playsinline={true}
					style={{'display':'none'}}
                />
		</div>
		:
		<div className="video_container">{/* 파일없을 시 class="empty" 추가 */}
			<span>{lan.L('동영상 파일이 없습니다.')}</span>{/* class="play/stop"  */}
			<a className="play"onClick={showVideo} id="playbtn">{lan.L('재생버튼')}</a>

			<img 
		  		id="frontVideo"
				src={InfoJsonVideo.frontVideoThumbnailKey === undefined ? require('../images/sub/noimage_ci_w.png') :  cdn+InfoJsonVideo.frontVideoThumbnailKey}
				style={{'width':window.innerWidth, 'height':window.innerHeight/4}}
			/>
			
			<img 
		  		id="sideVideo"
				src={InfoJsonVideo.sideVideoKey === undefined ? require('../images/sub/noimage_ci_w.png') :  cdn+InfoJsonVideo.sideVideoThumbnailKey}
				style={{'width':window.innerWidth, 'height':window.innerHeight/4, 'display':'none'}}
			/>
			
			<img 
		  		id="inGameVideo"
				src={InfoJsonVideo.inGameVideoKey === undefined ? require('../images/sub/noimage_ci_w.png') :  cdn+InfoJsonVideo.inGameVideoThumbnailKey}
				style={{'width':window.innerWidth, 'height':window.innerHeight/4, 'display':'none'}}
			/>
		</div>
		}
		{/*<div className="video_controls">
		  <span style={{width: '20%'}}>
			<a>{lan.L('재생 컨트롤')}</a>
		  </span>
		</div>*/}
		<ul className="video_thumbnail" style={{'padding-top': '20px'}}>
		  <li id="frontVideoli" className="on" onClick={(e)=>changeVideo('frontVideo')}>{/* 선택 */}
			<img style={{'display':'none'}} src={InfoJsonVideo.frontVideoThumbnailKey === undefined ? require('../images/sub/noimage_ci_w.png') :  cdn+InfoJsonVideo.frontVideoThumbnailKey} onError={(e)=>disableVideo('frontVideoli')}/>
			<p style={{backgroundImage: 'url('+cdn+InfoJsonVideo.frontVideoThumbnailKey+'), url('+require('../images/sub/noimage_ci_w.png')+')'}}>image</p>
			<span>{lan.L('정면')}</span>
		  </li>
		  <li id="sideVideoli" className onClick={(e)=>changeVideo('sideVideo')}>
			<img style={{'display':'none'}} src={InfoJsonVideo.sideVideoThumbnailKey === undefined ? require('../images/sub/noimage_ci_w.png') :  cdn+InfoJsonVideo.sideVideoThumbnailKey} onError={(e)=>disableVideo('sideVideoli')}/>
			<p style={{backgroundImage: 'url('+cdn+InfoJsonVideo.sideVideoThumbnailKey+'), url('+require('../images/sub/noimage_ci_w.png')+')'}}>image</p>
			<span>{lan.L('측면')}</span>
		  </li>
		  <li id="inGameVideoli" className onClick={(e)=>changeVideo('inGameVideo')}>{/* 파일없음 */}
			<img style={{'display':'none'}} src={InfoJsonVideo.inGameVideoThumbnailKey === undefined ? require('../images/sub/noimage_ci_w.png') :  cdn+InfoJsonVideo.inGameVideoThumbnailKey} onError={(e)=>disableVideo('inGameVideoli')}/>
			<p style={{backgroundImage: 'url('+cdn+InfoJsonVideo.inGameVideoThumbnailKey+'), url('+require('../images/sub/noimage_ci_w.png')+')'}}>image</p>
			<span>{lan.L('인게임')}</span>
		  </li>
		</ul>
	  </div>
	  <ul className="section">
		<li>
		  <em>{lan.L('샷 정보')}</em>
		  <ol className="info_grade">
			<li>
			  <dl>
				<dd>{lan.L('비거리')}</dd>
				<dt>{InfoJson.ballSpeed===undefined ? '' : common.Length(InfoJson.totalDistance.toFixed(2),'0m')}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('캐리거리')}</dd>
				<dt>{InfoJson.carryDistance===undefined ? '' : common.Length(InfoJson.carryDistance.toFixed(2),'0m')}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('런 거리')}</dd>
				<dt>{InfoJson.carryDistance===undefined ? '' : common.Length(InfoJson.runDistance.toFixed(2),'0m')}</dt>
			  </dl>
			</li>
			{practiceType != '3' && practiceType != '4' ? 
			<li>
			  <dl>
				<dd>{lan.L('클럽스피드')}</dd>
				<dt>{InfoJson.clubSpeed===undefined ? '' : common.Speed(InfoJson.clubSpeed.toFixed(2),'0m/s')}</dt>
			  </dl>
			</li>
			: ''}
			<li>
			  <dl>
				<dd>{lan.L('볼스피드')}</dd>
				<dt>{InfoJson.ballSpeed===undefined ? '' : common.Speed(InfoJson.ballSpeed.toFixed(2),'0m/s')}</dt>
			  </dl>
			</li>
			{practiceType != '3' && practiceType != '4' ? 
			<li>
			  <dl>
				<dd>{lan.L('스매시 팩터')}</dd>
				<dt>{InfoJson.clubSpeed===undefined || InfoJson.clubSpeed == null || InfoJson.clubSpeed == 0 ? '' : (InfoJson.ballSpeed/InfoJson.clubSpeed).toFixed(2)}</dt>
			  </dl>
			</li>
			: ''}
			<li>
			  <dl>
				<dd>{lan.L('백스핀')}</dd>
				<dt>{InfoJson.backspin}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('사이드스핀')}</dd>
				<dt>{InfoJson.sideSpin}</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('발사각')}</dd>
				<dt>{InfoJson.launchAngle===undefined ? '' : InfoJson.launchAngle.toFixed(2)}˚</dt>
			  </dl>
			</li>
			<li>
			  <dl>
				<dd>{lan.L('방향각')}</dd>
				<dt>{InfoJson.launchAngle===undefined ? '' : InfoJson.directionAngle.toFixed(2)}˚</dt>
			  </dl>
			</li>
			{ practiceType != '1' ? '' : 
			<li>
			  <dl>
				<dd>{lan.L('좌우편차')}</dd>
				<dt>{InfoJson.launchAngle===undefined ? '' : common.Length(InfoJson.lateralDeviation.toFixed(2),'0m')}</dt>
			  </dl>
			</li>
			}
		  </ol>
		</li>
	  </ul>
	</div>
	</>

	)
}