import * as lan from '../common/script/common_lan.js';

import { Link, useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common.js';
import React, {useState, useEffect} from 'react';
import $ from 'jquery';

import BottomLanguage from '../popup/bottom_lan';
import * as slide from '../popup/bottom_lan';

import { googleLogout, useGoogleLogin } from '@react-oauth/google';

var navigate;
var autoYN = 'N';
var googlelogin;

export default function Login() {
	const [IdToken, setactiveIdToken] = useState('');
	const location = useLocation();
	const [qa, setQa] = useState('');


	//최초실행
	useEffect(() => {
		if(window.location.hostname.indexOf('qa') > -1) {
			setQa('qa');
		}
		
		var wrap = $(".contents");
		var headeroffset = $(".tab_button").offset();

		$("#status10").prop("checked",true);	//defaultChecked하면 css가 안먹어서 임시로 jquery로 지정
		
		//localStorage를 /inc/tab.js 에서 변경하면 해당 값 변경되면 setactiveTab으로 activeTab 저장
		// window.addEventListener('storage', () => {

		// 	setactiveIdToken(window.localStorage.getItem('join_socialIdToken'));
			

		// 	if(IdToken != window.localStorage.getItem('join_socialIdToken'))
		// 	{
		// 		if(window.localStorage.getItem('join_isJoin') == 'true')
		// 		{
		// 			common.goLogin(navigate, '/default/default', window.localStorage.getItem('join_snsToken'), window.localStorage.getItem('join_loginToken'), autoYN);
		// 		}
		// 		else
		// 		{
		// 			navigate('/join/agreement');
		// 		}
		// 		setactiveIdToken(window.localStorage.getItem('join_socialIdToken'));
		// 	}
		// });
	},[]);
	//화면전환시 실행
	useEffect(() => {
		if(sessionStorage.getItem('autoLogin') == "Y")
		{
			autoYN = "Y";
			$("#onNoff").addClass("on");
		}
    },[location]);
	
	navigate = useNavigate();

	return (
		<>
		<LoginHead></LoginHead>
		<LoginContainer qa={qa}></LoginContainer>
		</>
	)
}

var loginID = '', loginPass = '';

function btnLoginClick() {

	if (loginID == ''){
			common.alertpop(lan.L('로그인'),lan.L('아이디를 입력해주세요.'));
			return;
	}
	
	if (loginPass == ''){
			common.alertpop(lan.L('로그인'),lan.L('비밀번호를 입력해주세요.'));
			return;
	}

	common.goLogin(navigate, '/default/default', loginID, loginPass, 'normal', autoYN);
}
	
function LoginHead() {
	if(common.isIOS()){
		common.iOSCallApp({appid:"getLanguage", params:"", funcname:"window.NativeInterface.getLanguage"});
	}
	else
	{
		try{common.andriodCallApp('getLanguage','');}
		catch (ex){
			if(sessionStorage.getItem('Language') === null)  sessionStorage.setItem('Language', 'ko');}
	}

	return (
	<div className="login_head">
		<span>Logo{common.commonKeyDic[sessionStorage.getItem('Language')]['imageurl']}</span>
		<a className="language_set" onClick={slide.slideOn} style={{backgroundImage: "url("+require('../'+common.commonKeyDic[sessionStorage.getItem('Language')]['imageurl'])+")"}} >{lan.L('언어설정')}</a>
	</div>
	);
}

function LoginContainer({qa}) {
	var state = 'on'

	const onChange = (e) => {
		var id = e.target.id;
		var value = e.target.value;
		if(id == "LoginID")
		{
			loginID = value;

			if(value != '') $("#LoginIDDel").show();
			else $("#LoginIDDel").hide();
		}
		else if(id == "LoginPass")
		{
			loginPass = value;

			if(value != '') $("#LoginpassDel").show();
			else $("#LoginpassDel").hide();
		}
		else if (id == "onNoff")
		{
			if(autoYN == 'Y')
			{
				$("#onNoff").removeClass("on");
				autoYN = 'N';
			}
			else
			{
				$("#onNoff").addClass("on");
				autoYN = 'Y';
			}
		}
	}
	
	const onDelete = (e) => {
		var id = e.target.id;
		
		if(id == "LoginIDDel")
		{
			loginID = '';
			$("#LoginID").val('');
			$("#LoginIDDel").hide();
		}
		else if(id == "LoginpassDel")
		{
			loginPass = '';
			$("#LoginPass").val('');
			$("#LoginpassDel").hide();
		}
	}
	
	const goJoin = (e) => {
		window.localStorage.setItem('join_agree01',false);
		window.localStorage.setItem('join_agree02',false);
		window.localStorage.setItem('join_agree03',false);
		window.localStorage.setItem('join_agree04',false);
		window.localStorage.setItem('join_socialIdToken','');
		window.localStorage.setItem('join_joinType','normal');
		
		navigate('/join/agreement');
	}

	const goSNSResult = (e) => {
		
		window.localStorage.setItem('join_joinType ',$("#isJoinSNS").val());
		if(window.localStorage.getItem('join_isJoin') == 'true') //localStorage는 string만 가능
		{
			common.goLogin(navigate, '/default/default', window.localStorage.getItem('join_snsToken'), window.localStorage.getItem('join_loginToken'), window.localStorage.getItem('join_joinType'), autoYN);
		}
		else
		{
			navigate('/join/agreement');
		}
	}

	return (
	<>
	<BottomLanguage ></BottomLanguage>
	
	<input name="isJoinSNS" id="isJoinSNS" type="hidden" onInput={(e)=>goSNSResult()} value="" />
	<div className="container">
		<div className="login_title">
			<strong>{lan.L('로그인')}{qa}</strong>
			<span className="new-line">{lan.L('케이골프 서비스를 이용하시려면 \n 로그인이 필요합니다.')}</span>
		</div>
		<ul className="write_box_form">
			<li>
				<input type="text" id="LoginID" defaultValue={loginID} onChange={onChange} placeholder={lan.L('아이디 입력')} />
				<label>{lan.L('아이디')}</label>
			  <a id="LoginIDDel" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
			</li>
			<li>
				<input type="password" id="LoginPass" defaultValue={loginPass} onChange={onChange} placeholder={lan.L('비밀번호 입력')} />
				<label>{lan.L('비밀번호')}</label>
			  <a id="LoginpassDel" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
			</li>
		</ul>

		<button type="button" className="btn_login" onClick={btnLoginClick}>{lan.L('로그인')}</button>
		<div className="linkGroup">
		<div className="auto_login">
			<div className={autoYN=="Y" ? 'activate_button on':'activate_button'} id="onNoff" onClick={onChange}>
				<span>ON</span>
				<span>OFF</span>
			</div>
		<label>{lan.L('자동로그인')}</label>
		</div>

		<Link to='/login/find_id'>{lan.L('아이디찾기')}</Link>
		<Link to='/login/find_pw'>{lan.L('비밀번호찾기')}</Link>
		</div>
		
		<div className="simple_login">
			<a className="kakao" onClick={loginKakao}>kakao</a>
			{/* <a className="facebook" onClick={loginFacebook}>facebook</a> */}
			<a className="google" onClick={loginGoogle}>google</a>
			{common.isIOS() ? <a className="apple" onClick={loginApple}>apple</a> : ''}
		</div>

		<button type="button" onClick={goJoin} className="btn_line">{lan.L('회원가입')}</button>
	</div>
	</>
	);
}


function loginKakao() {
	
	if(common.isIOS())
	{
		common.iOSCallApp({appid:"loginkakao", params:"", funcname:"window.NativeInterface.loginkakao"});
	}
	else
	{
		try{common.andriodCallApp('loginkakao','');}
		catch (ex){
			window.NativeInterface.loginkakao("test");
			// var ev = new Event('input', { bubbles: true});
			// $("input[name='isJoinSNS']").val(true)[0].dispatchEvent(ev);
		}
	}
};

function loginGoogle() {

	// googlelogin();
	if(common.isIOS())
	{
		common.iOSCallApp({appid:"logingoogle", params:"", funcname:"window.NativeInterface.logingoogle"});
	}
	else
	{
		common.andriodCallApp('logingoogle','');
		// try{common.andriodCallApp('logingoogle','');}
		// catch (ex){
		// 	window.NativeInterface.logingoogle("test");
		// }
	}
};

function loginFacebook() {

	// googlelogin();
	if(common.isIOS())
	{
		common.iOSCallApp({appid:"loginfacebook", params:"", funcname:"window.NativeInterface.loginfacebook"});
	}
	else
	{
		try{common.andriodCallApp('loginfacebook','');}
		catch (ex){
			window.NativeInterface.loginfacebook("test");
		}
	}
};

function loginApple() {

	// googlelogin();
	if(common.isIOS())
	{
		common.iOSCallApp({appid:"loginapple", params:"", funcname:"window.NativeInterface.loginapple"});
	}
	else
	{
		try{common.andriodCallApp('loginapple','');}
		catch (ex){
			window.NativeInterface.loginapple("test","");
		}
	}
};
