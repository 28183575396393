import * as lan from '../common/script/common_lan.js';

import axios from 'axios';
function Test2() {
	return (
	<>1</>
	);
}

export default Test2;
