import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common.js';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

import BottomRecord from '../popup/bottom_record';
import * as gameTypeslide from '../popup/bottom_record';

import BottomClub from '../popup/bottom_club';
import * as clubslide from '../popup/bottom_club';


export default function PracticeShotVideo(){
	const location = useLocation();
	const navigate = useNavigate();
	var gameType, isTournamentView, club, practiceClub;
	var gameTypeVal, clubVal, practiceClubVal;
	var activeNum = 1;

	const [activeTab, setactiveTab] = useState('');
	
	var senddata = {
				page:1,
				pagesize:10,
				practiceType:true
		};
	if(location.state === undefined || location.state == null)
	{
		gameType = lan.L(Object.values(common.commonKeyDic['gameType'])[0]);
		club = lan.L(Object.values(common.commonKeyDic['club'])[0]);
		practiceClub = lan.L(Object.values(common.commonKeyDic['club'])[0]);
	}
	else
	{
		gameType = location.state.gameType;
		club = location.state.club;
		practiceClub = location.state.practiceClub;
		practiceClubVal = common.commonKeyDic['clubVal'][common.getCommonKeyByValue(lan.LTarget(practiceClub,'ko'), 'club')];

		if(practiceClubVal != '') senddata = Object.assign(senddata,{club : practiceClubVal});
	}


	//최초실행
	useEffect(() => {
		window.addEventListener('storage', () => {
			setactiveTab(window.localStorage.getItem('tabNum'));

			if(activeTab != window.localStorage.getItem('tabNum'))
			{
				setactiveTab(window.localStorage.getItem('tabNum'));
			}
		});
	},[]);

	const [htmllist, sethtmllist] = useState([]);
	const [shot_html, setshot_html] = useState([]);
	var html = [];
	var ahtml = [];
	var shothtml = [];
	var divhtml = [];
	var shotList = [];
	var datalist;

	//화면전환시 실행
	useEffect(() => {

		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: senddata,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('나의 기록실 목록 조회 라운드'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
				//console.log(response.data);
				
				if(response.data.error !== undefined && response.data.error.code > 0)
				{
					common.alertByError('연습 샷 정보',response.data.error.code);
					return false;
				}
				else
				{
					datalist = response.data.list;
					html = [];
					divhtml = [];
					ahtml = [];

					if(datalist.length)
					{
						$("#noData").hide();
						for (let i = 0;i < datalist.length; i++)
						{
							shotList.push([]); //빈값의 shotList생성
							if(datalist[i].shopName == null) datalist[i].shopName = 'null';
							if(datalist[i].courseName == null) datalist[i].courseName = 'null';

							//바깥
							var gametypeclass = common.getCommonKeyByValue(lan.LTarget(datalist[i].gameType,'ko'),'gameType');

							ahtml.push([]);
							
							for(let j = 0 ; j < 300; j++)
							{
								ahtml[i].push(<a key={"ashot"+i+"_"+j} style={{display:'none'}} id={"ashot"+i+"_"+j} onClick={(e) => goDetail(i,j)}></a>);
							}
						
							divhtml.push(<div key={i+'rounddiv'} className="course_status" id={"childround"+datalist[i].gameRoundId}>{ahtml[i]}</div>);

							html.push(
								<li key={i+'roundlist'} id={datalist[i].gameRoundId+'li'+i}>
								<label className onClick={(e) => onClickShotDetail(datalist[i].gameRoundId,i)}>
								  <div className="shot_info">
									<span>
									  <div className={"game_type "+gametypeclass}>{datalist[i].gameType}</div>
									</span>
									<em>{datalist[i].shopName}</em>
									<sub>{common.UnixTime(datalist[i].startTime,'년.월.일')}</sub>
								  </div>
								  <div className="score_point">
									<b>{datalist[i].score}</b>
									<small>{lan.L('score')}</small>
								  </div>
								</label>
								{divhtml[i]}
								</li>
							);
						}
						
						sethtmllist(html);
					}
					else
					{
						$("#noData").show();
					}
				}
			}).catch(error => {
			console.log(error.response)
		});
		
},[location]);
	
	const parentpractice = (v) => {
		$("#practice > li > label").removeClass("on");
		if (!$("#childpractice"+v).is(":visible")) {
			$(".course_status").hide();
			$("#childround"+v).show();
			$(".list_video > li > label:eq(" + v + ")").addClass("on");
		} else {
			$("#childpractice"+v).hide();
		}
	}
	
	const onClickShotDetail = (roundid, i) => {
		
			//게임 라운드 샷 목록 조회
			axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: senddata,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('게임 라운드 샷 목록 조회'),
				'guid' : datalist[i].gameRoundId,
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
			//console.log(response.data);

			shotList[i] = response.data.shotList;

			if(shotList[i].length > 0)
			{
				for(let j = 0 ; j < shotList[i].length; j++)
				{
					$("#ashot"+i+"_"+j).show();
					$("#ashot"+i+"_"+j).empty();
					shothtml = '';
					shothtml = shothtml + '<p class="empty" style="backgroundImage: \'url(\''+response.data.cdn+shotList[i][j].frontVideoThumbnailKey+'\')">thumbnail</p>'
					shothtml = shothtml + '<aside>'
					shothtml = shothtml + '  <span>'
					shothtml = shothtml + '	<input type="hidden" id="coursePar'+i+'_'+j+'" value="'+response.data.coursePar[shotList[i][j].holeNumber-1]+'">'
					shothtml = shothtml + '	<em><b>'+shotList[i][j].holeNumber+lan.L('H')+'</b>'+lan.L('Par')+response.data.coursePar[shotList[i][j].holeNumber-1]+'</em>'
					shothtml = shothtml + '	<i>'+lan.L(common.commonKeyDic['recommandClub'][shotList[i][j].recommandClub])+'</i>'
					shothtml = shothtml + '  </span>'
					shothtml = shothtml + '  <dl>'
					shothtml = shothtml + '	<dd>'+lan.L('시작위치')+'</dd>'
					shothtml = shothtml + '	<dt>'+common.commonKeyDic['destinationLandType'][shotList[i][j].shotLocation]+'</dt>'
					shothtml = shothtml + '  </dl>'
					shothtml = shothtml + '  <dl>'
					shothtml = shothtml + '	<dd>'+lan.L('비거리')+'</dd>'
					shothtml = shothtml + '	<dt>'+common.Length(shotList[i][j].totalDistance,'0(m)')+'</dt>'
					shothtml = shothtml + '  </dl>'
					shothtml = shothtml + '  <dl>'
					shothtml = shothtml + '	<dd>'+lan.L('도착위치')+'</dd>'
					shothtml = shothtml + '	<dt>'+common.commonKeyDic['destinationLandType'][shotList[i][j].destinationLandType]+'</dt>'
					shothtml = shothtml + '  </dl>'
					shothtml = shothtml + '</aside>'
					
					$("#ashot"+i+"_"+j).append(shothtml);
				}
			}
			parentpractice(roundid);
//			$(window).scrollTop($("#"+datalist[i].gameRoundId+"li"+i).offset().top);
//			$('html, body').animate($(window).scrollTop($("#"+datalist[i].gameRoundId+"li"+i).offset().top),1000);
			$('html, body').animate({scrollTop:$("#"+datalist[i].gameRoundId+"li"+i).offset().top},300);
		}).catch(error => {
			console.log(error)
		});
	}
	
	const goDetail = (i,j) => {

		var gameRoundId = datalist[i].gameRoundId;
		var gameRoundShotId = shotList[i][j].gameRoundShotId;
		var roundData = datalist[i];
		var shotData = shotList[i][j];
		var coursePar = $("#coursePar"+i+"_"+j).val();
		
		navigate('/shot/detail_video', {state : {gameRoundId : gameRoundId, gameRoundShotId : gameRoundShotId, roundData : roundData, shotData : shotData, coursePar : coursePar} });
	}

	const TabChange = (e,key) => {
		e.preventDefault();
		navigate('/shot/shotvideo', {state : {gameType : gameType,  club : club, practiceClub : practiceClub, activeTab : activeTab}, replace : true});
	}

	return (
	<>
	<div className="tab_button">
		<button key={0} type="button" className={activeNum==0 ? 'active':''} onClick={(e)=>{TabChange(e,0)}}>{lan.L('라운드')}</button>
		<button key={1} type="button" className={activeNum==1 ? 'active':''} onClick={(e)=>{TabChange(e,1)}}>{lan.L('연습')}</button>
	</div>

	<BottomClub to="/shot/shotvideo" club={club} gameType={gameType} practiceClub={practiceClub}></BottomClub>
	<BottomRecord to="/shot/shotvideo" isTournamentView={''} gameType={gameType} club={club} practiceClub={practiceClub}></BottomRecord>

	<div className="contents" style={activeTab==0 ? {display:'block'}: {display:'none'}}>
	  <div className="search_standard">
		<span>
		  <label className="filter_index" onClick={gameTypeslide.slideOn}>{gameType==common.commonKeyDic['gameType']['All'] ? lan.L('방식')+' '+ gameType : gameType}</label>
		</span>
		<span>
		  <label className="filter_index" onClick={clubslide.slideOn}>{club==common.commonKeyDic['club']['All'] ? lan.L('클럽')+' '+ club : club}</label>
		</span>
		<a>
		  <input type="checkbox" id="save1" name="save1" />
		  <label htmlFor="save1">{lan.L('보관영상만보기')}</label>
		</a>
	  </div>
	  {/* No Data */}
	  <div className="nodata" id="noData" style={{display: 'none'}}>
		<p><img src="/images/character/nodata_3.png" /></p>
		<strong>{lan.L('저장된 샷 정보가 없어요.')}</strong>
		<span>{lan.L('가까운 매장에서 플레이 해보세요.')}</span>
	  </div>
	  {/*//No Data */}
	  <div style={{display: 'block'}}>
		<ul className="list_video">{/* 리스트 reverse // 밑으로 쌓일수록 최신 게시물 */}
		  {htmllist}
		</ul>
	  </div>
	</div>
	</>
	)
}
