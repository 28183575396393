
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect} from 'react';

export default function NotFound(){
	const location = useLocation();
	const navigate = useNavigate();

	//최초실행
	useEffect(() => {
		alert("?");
        if(location.state===undefined || location.state.isnewpage != 'Y')
        {
            navigate(window.location.pathname,{state:{isnewpage:'Y'}});
        }
        else
        {
            navigate('/');
        }
	},[location]);

	return(
		<>
		</>
	)
}