import React from 'react'
import PropTypes from 'prop-types'
import {BrowserRouter, Link, Routes,Route, useNavigate} from "react-router-dom";

const LinkButton = (props) => {
	const navigate = useNavigate();
	const {
		history,
		location,
		match,
		staticContext,
		to,
		onClick,
		// ⬆ filtering out props that `button` doesn’t know what to do with.
		...rest
	} = props
  return (
    <button
      {...rest} // `children` is just another prop!
      onClick={(event) => {
        onClick && onClick(event)
        navigate(to);
      }}
    />
  )
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default LinkButton