
/*
*[Title] aes256 내장 호출 함수
*[Description] aes256 내장 호출 함수
*[Usage]
*@props mainObj {List} : 함수 내 모음 리스트
*@props initialize {Function} : 최초 실행 시 암호화 세팅
*@props encrypt {Function} : 암호화
*@props decrypt {Function} : 복호화
*@since 2023.06.07.
*@appendix 암호화 테스트 사이트 : https://jul-liet.tistory.com/129
*/

import cryptoJs from 'crypto-js';

/*
*[List] 메인 오브젝트
*@props secretKey {String} : aes256 secretKey
*@props iv {String} : aes256 iv
*/
export var mainObj = 
{
    secretKey:'cnBkbHJoZnZtZGlkZGtjbGVtZmVoc3Jq',
    iv:'10cXNrZGtzd25h04'
}

export function initialize(secretKey, iv)
{
    mainObj.secretKey = secretKey;
    mainObj.iv = iv;
}

/*
*[Function] aes256 암호화
*[Params]
*@param text(*) {String} : 암호화할 text
*/
export function encrypt(text) {
    const cipher = cryptoJs.AES.encrypt(text, cryptoJs.enc.Utf8.parse(mainObj.secretKey), {
        iv: cryptoJs.enc.Utf8.parse(mainObj.iv),
        padding: cryptoJs.pad.Pkcs7,
        mode: cryptoJs.mode.CBC,
    });
    
    return cipher.toString();
}

/*
*[Function] aes256 복호화
*[Params]
*@param encryptedText(*) {String} : 복호화할 text
*/
export function decrypt(encryptedText) {
    const decipher = cryptoJs.AES.decrypt(encryptedText, cryptoJs.enc.Utf8.parse(mainObj.secretKey), {
        iv: cryptoJs.enc.Utf8.parse(mainObj.iv),
        padding: cryptoJs.pad.Pkcs7,
        mode: cryptoJs.mode.CBC,
    })

    return decipher.toString(cryptoJs.enc.Utf8);
}
