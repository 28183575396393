import * as lan from '../common/script/common_lan.js';
import Tab from '../inc/tab';

import React, {useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common.js';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

const listboardtab = [{tabName:'공지'},{tabName:'이벤트'}]
var noticedata, eventdata, tempnoticehtmllist = [], tempeventhtmllist = [];
var pageSize  = {page : 1, eventpage : 1};

export default function ListBoard(){
	const location = useLocation();
	const navigate = useNavigate();
	var noticelist;
	var eventlist;
	var html;
	
	const [activeTab, setactiveTab] = useState('');
	const [noticehtmllist, setnoticehtmllist] = useState([]);
	const [eventhtmllist, seteventhtmllist] = useState([]);

	//최초실행
	useEffect(() => {
		window.addEventListener('storage', () => {

			setactiveTab(window.localStorage.getItem('tabNum'));
			
			if(activeTab != window.localStorage.getItem('tabNum'))
			{
				setactiveTab(window.localStorage.getItem('tabNum'));
			}
		});
	},[]);
	
	//화면전환시 실행
	useEffect(() => {
		initialize();
		goData();

		const scrollHandler = () => {
		  if ($(window).scrollTop() + $(window).height() >= $(document).height() - 30) {
			if(pageSize.page < noticedata.maxPage && window.localStorage.getItem('tabNum') == 0)
			{
				pageSize.page += 1;
				goData();
			}
			else if (pageSize.eventpage < eventdata.maxPage && window.localStorage.getItem('tabNum') == 1)
			{
				pageSize.eventpage += 1;
				goData();
			}
		  }
		}
			
		window.addEventListener("scroll", scrollHandler);
		
		// Return effect cleanup function to remove listener
		return () => {
		  window.removeEventListener("scroll", scrollHandler);
		};

	},[location]);
	
	const initialize = () => {
		html = [];
		tempnoticehtmllist = [];
		tempeventhtmllist = [];
		pageSize = {page : 1, eventpage : 1};
	}
	
	const searchData = () => { //초기화하고 데이터이동
		initialize();
		goData();
	}
	
	const goData = () => {
		//공지사항
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data:  {page : pageSize.page, pageSize: 50, postCoverage : 0, postTypeCode : 'post_type_cd:notice', searchText:$("#noticesearch").val()},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('게시판 목록 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
				'getplus' : 'postCoverage=2'
			}
		}).then(function (response) {
				console.log(response.data);
				
				if(response.data.error !== undefined && response.data.error.code > 0)
				{
					common.alertByError('공지사항',response.data.error.code);
					return false;
				}
				else
				{
					noticedata = response.data;
					noticelist = response.data.list;
					html=[];

					if(noticelist.length > 0)
					{
						$("#noNoticeData").hide();
						for (let i = 0;i < noticelist.length; i++)
						{
							html.push(
							  <a onClick={(e)=>goDetail(i,'notice')}>
								<em>{noticelist[i].title}</em>
								<small>{common.UnixTime(noticelist[i].regTime,'년.월.일')}</small>
							  </a>
							);
						}
						html = [...tempnoticehtmllist,...html];
						setnoticehtmllist(html);
						tempnoticehtmllist = html;
					}
					else
					{
						$("#noNoticeData").show();
						setnoticehtmllist(html);
					}
				}
			}).catch(error => {
			console.log(error.response)
		});
			
		//이벤트
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {page : pageSize.eventpage, pageSize: 50, postCoverage : 0, postTypeCode : 'post_type_cd:event', searchText:$("#eventsearch").val()},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('게시판 목록 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
				'getplus' : 'postCoverage=2'
			}
		}).then(function (response) {
				console.log(response.data);
				
				if(response.data.error !== undefined && response.data.error.code > 0)
				{
					common.alertByError('공지사항',response.data.error.code);
					return false;
				}
				else
				{
					eventdata = response.data;
					eventlist = response.data.list;
					html=tempeventhtmllist;

					// seteventdata(response.data);
					html=[];

					if(eventlist.length > 0)
					{
						$("#noEventData").hide();
						for (let i = 0;i < eventlist.length; i++)
						{
							html.push(
							  <a onClick={(e)=>goDetail(i,'event')}>
								<em>{eventlist[i].title}</em>
								<small>{common.UnixTime(eventlist[i].regTime,'년.월.일')}</small>
							  </a>
							);
						}
						html = [...tempeventhtmllist,...html];
						seteventhtmllist(html);
						tempeventhtmllist = html;
					}
					else
					{
						seteventhtmllist(html);
						$("#noEventData").show();
					}
				}
			}).catch(error => {
				console.log(error.response)
			});
		
		const goDetail = (i,type) => {

			if(type=="notice")
			{
				navigate('/customer/view_notice', {state : {boardId : noticelist[i].boardId, backTabTopics : 0}});
			}
			else if(type=="event")
			{
				navigate('/customer/view_event', {state : {boardId : eventlist[i].boardId, backTabTopics : 1}});
			}
		}
	}
		
	const initSearch = (i) => {
		$("#noticesearch").val("");
		$("#eventsearch").val("");
		
	}

	return (
	<>
	<Tab topics={listboardtab} defaultTopics={0} tabkey={"공지사항"} onChangefunc={initSearch}></Tab> 
	<div>
	  {/*------------------------------------ 공지사항 ------------------------------------*/}
	  <div className="contents" style={activeTab==0 ? {display:'block'}: {display:'none'}}>
		<div className="search_form">
		  <a onClick="#">{lan.L('검색하기')}</a>
		  <input type="text" id="noticesearch" name placeholder={lan.L("검색어를 입력하세요")} onChange={searchData}/>
		</div>
		{/* No Data */}
		<div className="nodata" style={{display: 'none'}} id="noNoticeData">
		  <p><img src={require("../images/character/nodata_1.png")} /></p>
		  <strong>{lan.L('현재 등록된 글이 없습니다.')}</strong>
		  <span>{lan.L('조금만 기다려주세요.')}</span>
		</div>
		{/*//No Data */}
		<div className="list_notice" style={{display: 'block'}}>
			{noticehtmllist}
		</div>
	  </div>
	  {/*------------------------------------//공지사항 ------------------------------------*/}
	  {/*------------------------------------ 이벤트 ------------------------------------*/}
	  <div className="contents" style={activeTab==1 ? {display:'block'}: {display:'none'}}>
		<div className="search_form">
		  <a onClick="#">{lan.L('검색하기')}</a>
		  <input type="text" id="eventsearch" name placeholder={lan.L("검색어를 입력하세요")} onChange={searchData}/>
		</div>
		{/* No Data */}
		<div className="nodata" style={{display: 'none'}} id="noEventData">
		  <p><img  src={require("../images/character/nodata_1.png")} /></p>
		  <strong>{lan.L('현재 등록된 글이 없습니다.')}</strong>
		  <span>{lan.L('조금만 기다려주세요.')}</span>
		</div>
		{/*//No Data */}
		<div className="list_event">
			{eventhtmllist}
		</div>
	  </div>{/* contents(e) */}
	  {/*------------------------------------//이벤트 ------------------------------------*/}
	</div>
	</>
	)
}