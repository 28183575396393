import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

export function slideOn(){
	$("html").addClass("overflowHidden");
	$("#filter_golfclub").addClass("on");
}

export function slideExit(){
	$("html").removeClass("overflowHidden");
	$(".layerView").removeClass("on");
}

export default function BottomClub(props) {
	
	const location = useLocation();
	const navigate = useNavigate();

	const [clubhtmllist, setclubhtmllist] = useState([]);

	var list = common.commonKeyDic['club']; 
	var club;
	useEffect(() => {
		
		//라운드 클럽목록
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {type : 1}, //1 게임라운드, 2 연습장
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('게임 라운드, 연습장 샷 데이터 기반 클럽 정보 조회'),
				'token' : sessionStorage.getItem('access_token'),
			}
		}).then(function (response) {
				console.log(response.data);

				var htmllist = [];
				
				club =  Object.values(common.commonKeyDic['club'])[0];
				htmllist.push(
				<a key={"allclub"}>
					<input type="radio" id={"cluball"} name="club" onClick={(e) =>goSelect(e,props.to)} value={lan.L(club)} defaultChecked={true}/>
					<label htmlFor={"cluball"}>{lan.L(club)}</label>
				</a>
				);

				for(let i =0; i < response.data.clubs.length; i++)
				{
					var j = response.data.clubs[i];
					club =  Object.values(common.commonKeyDic['club'])[j+1];
					htmllist.push(
					<a key={j+"club"}>
						<input type="radio" id={"club"+j} name="club" onClick={(e) =>goSelect(e,props.to)} value={lan.L(club)} defaultChecked={lan.LTarget(club,'en') == lan.LTarget(props.club,'en')  ? true : false}/>
						<label htmlFor={"club"+j}>{lan.L(club)}</label>
					</a>
					);
				}
				setclubhtmllist(htmllist);
				
			}).catch(error => {
			console.log(error.response)
		});

    },[]);

	const goSelect = (e, to) => {

		var value = e.target.value;

		if(to !== undefined)
		{
			navigate("/common/script/backnavigation", {state : {gameType : props.gameType,  practiceClub : props.practiceClub, club : value, back:to, activeTab : props.activeTab, backgameRoundId : window.localStorage.getItem('gameRoundId'), backgameRoundNum : window.localStorage.getItem('gameRoundShot')}, replace : true});
		}
		slideExit();
	}

	return (
	<div id="filter_golfclub" className="layerView">
	  <button style={{display:'none'}} onClick={(e) =>goSelect(e,props.to)} value={lan.L(Object.values(common.commonKeyDic['club'])[0])} id="pop_clubinit">{lan.L("초기화")}</button>
	  <div className="bottom_sheet">
		<a onClick={(e) => slideExit()}>close</a>
		<div className="bs_body">
		  <em>{lan.L("골프 클럽 선택")}</em>
		  <div className="scroll_container">
			<div className="list_selection">
			 {clubhtmllist}
			</div>
		  </div>
		</div>
	  </div>
	</div>
	)
}

// import * as lan from '../common/script/common_lan.js';
// import React, {useState, useEffect} from 'react';
// import { useNavigate, useLocation } from "react-router-dom";
// import * as common from '../common/script/common';
// import $ from 'jquery';
// import axios from 'axios';
// export function slideOn(){
// 	$("html").addClass("overflowHidden");
// 	$("#filter_golfclub").addClass("on");
// }
// export function slideExit(){
// 	$("html").removeClass("overflowHidden");
// 	$(".layerView").removeClass("on");
// }
// export default function BottomClub(props) {
	
// 	const location = useLocation();
// 	const navigate = useNavigate();
// 	const [clubhtmllist, setclubhtmllist] = useState([]);
// 	var list = common.commonKeyDic['club']; 
// 	var club;
// 	useEffect(() => {
// 		var htmllist = [];
// 		for(let i =0; i < Object.keys(list).length; i++)
// 		{
// 			club =  lan.L(Object.values(common.commonKeyDic['club'])[i]);
// 			htmllist.push(
// 			  <a key={i+"club"}>
// 				<input type="radio" id={"club"+i} name="club" onClick={(e) =>goSelect(e,props.to)} value={club} defaultChecked={club == props.club ? true : false}/>
// 				<label htmlFor={"club"+i}>{club}</label>
// 			  </a>
// 			);
// 		}
// 		setclubhtmllist(htmllist);
//     },[]);
// 	const goSelect = (e, to) => {
// 		var value = e.target.value;
// 		if(to !== undefined)
// 		{
// 			navigate("/common/script/backnavigation", {state : {gameType : props.gameType,  practiceClub : props.practiceClub, club : value, back:to, activeTab : props.activeTab}, replace : true});
// 		}
// 		slideExit();
// 	}
// 	return (
// 	<div id="filter_golfclub" className="layerView">
// 	  <div className="bottom_sheet">
// 		<a onClick={(e) => slideExit()}>close</a>
// 		<div className="bs_body">
// 		  <em>{lan.L("골프 클럽 선택")}</em>
// 		  <div className="scroll_container">
// 			<div className="list_selection">
// 			 {clubhtmllist}
// 			</div>
// 		  </div>
// 		</div>
// 	  </div>
// 	</div>
// 	)
// }