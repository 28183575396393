import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common.js';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

import Tab from '../inc/tab';

import BottomRecord from '../popup/bottom_record';
import * as gameTypeslide from '../popup/bottom_record';

import BottomClub from '../popup/bottom_club';
import * as clubslide from '../popup/bottom_club';
import BottomPracticeClub from '../popup/bottom_practiceclub';
import * as practiceclubslide from '../popup/bottom_practiceclub';

var pageSize  = {page : 1, practicepage : 1};
var data, practicedata, temphtmllist = [] , temppracticehtmllist = [];
var isVideoSaveOpt = false, isPracticeVideoSaveOpt = false;

export default function ShotVideo(){
	const location = useLocation();
	const navigate = useNavigate();
	var gameType, isTournamentView, club, practiceClub, practiceType, practiceTypeSend;
	var archiveClub, archiveType, archiveTypeSend;
	var clubSend, practiceClubSend;
	var gameTypeVal, clubVal, practiceClubVal, cdn, gameTypeLink;

	var backgameRoundId = '', backpracticegameRoundId = '', backarchivegameRoundId = '', backgameRoundNum;

	// const [isVideoSaveOpt, setisVideoSaveOpt] = useState(true);
	// const [isPracticeVideoSaveOpt, setisPracticeVideoSaveOpt] = useState(true);
	var isClickable = true, isPracticeClickable = true;

	const [activeTab, setactiveTab] = useState('');
	
	var sendpracticeclubdata = {};
				
	if(location.state === undefined || location.state == null || location.state.gameType  === undefined || location.state.gameType == null)
	{
		gameType = lan.L(Object.values(common.commonKeyDic['gameType'])[0]);
		club = lan.L(Object.values(common.commonKeyDic['club'])[0]);
		practiceClub = lan.L(Object.values(common.commonKeyDic['club'])[0]);
		gameTypeLink = 'gameType=1&gameType=2';
		practiceTypeSend = '?practiceType=0&practiceType=1&practiceType=2&practiceType=3&practiceType=4&practiceType=5';
	}
	else
	{
		gameType = location.state.gameType;
		club = location.state.club;
		practiceClub = location.state.practiceClub;
		gameTypeVal = common.commonKeyDic['gameTypeVal'][common.getCommonKeyByValue(lan.LTarget(gameType,'ko'), 'gameType')];
		clubVal = common.commonKeyDic['clubVal'][common.getCommonKeyByValue(lan.LTarget(club,'en'), 'club')];
		practiceClubVal = common.commonKeyDic['clubVal'][common.getCommonKeyByValue(lan.LTarget(practiceClub,'en'), 'club')];

		if(gameTypeVal != '') gameTypeLink = 'gameType=' + gameTypeVal;
		else gameTypeLink = 'gameType=1&gameType=2';
		
		if(clubVal === undefined || (clubVal == '' && clubVal != '0')) clubSend = '';
		// else if(clubVal == 0) clubSend = '?club=0';
		// else if(clubVal == 1) clubSend = '?club=1&club=2&club=3&club=4&club=5&club=6&club=7&club=8&club=17&club=18&club=19&club=20&club=21&club=22&club=23&club=24';
		// else if(clubVal == 2) clubSend = '?club=35';
		else clubSend = 'club='+clubVal;

		if(practiceClubVal === undefined || (practiceClubVal == '' && practiceClubVal != '0')) practiceClubSend = '';
		// else if(practiceClubVal == 0) practiceClubSend = '?club=0';
		// else if(practiceClubVal == 1) practiceClubSend = '?club=1&club=2&club=3&club=4&club=5&club=6&club=7&club=8&club=17&club=18&club=19&club=20&club=21&club=22&club=23&club=24';
		// else if(practiceClubVal == 2) practiceClubSend = '?club=35';
		else practiceClubSend = 'club='+practiceClubVal;

		practiceTypeSend = '?practiceType=0&practiceType=1&practiceType=2&practiceType=3&practiceType=4&practiceType=5';
	}

	if(location.state !== undefined && location.state != null && location.state.backgameRoundId !== undefined)
	{
		backgameRoundId = location.state.backgameRoundId;
		backgameRoundNum = location.state.backgameRoundNum;
	}
	else if(location.state !== undefined && location.state != null && location.state.backpracticegameRoundId !== undefined) 
	{
		backpracticegameRoundId = location.state.backpracticegameRoundId;
		backgameRoundNum = location.state.backgameRoundNum;
	}

	//최초실행
	useEffect(() => {
		var wrap = $(".contents");
		var headeroffset = $(".tab_button").offset();
		
		$(window).scroll(function () {
			if ($(this).scrollTop() > headeroffset.top){
				$(".search_standard").addClass("fixed");
				wrap.css("margin-top","50px");
			}else {
				$(".search_standard").removeClass("fixed");
				wrap.css("margin-top","0");
			}
		});

		window.addEventListener('storage', () => {

			setactiveTab(window.localStorage.getItem('tabNum'));
			
			if(activeTab != window.localStorage.getItem('tabNum'))
			{
				setactiveTab(window.localStorage.getItem('tabNum'));
			}
		});
	},[]);

	const [htmllist, sethtmllist] = useState([]);
	const [practicehtmllist, setpracticehtmllist] = useState([]);
	const [archivehtmllist, setarchivehtmllist] = useState([]);

	var html = [];
	var ahtml = [];
	var shothtml = [];
	var shotList = [];
	var datalist = [];
	
	var practicehtml = [];
	var practiceahtml = [];
	var practiceshothtml = [];
	var practicedivhtml = [];
	var practiceshotList = [];
	var practicedatalist = [];
	
	var archivehtml = [];
	var archiveahtml = [];
	var archiveshothtml = [];
	var archivedivhtml = [];
	var archiveshotList = [];
	var archivedatalist = [];

	//화면전환시 실행
	useEffect(() => {
		initialize();
		isClickable = true;
		isPracticeClickable = true;
		
		isVideoSaveOpt = false;
		isPracticeVideoSaveOpt = false;

		goData();
		
		const scrollHandler = () => {
			if($(window).scrollTop() + $(window).height() >= $(document).height() - 30) {
				console.log(pageSize.page, data.maxPage);
				if(pageSize.page < data.maxPage && window.localStorage.getItem('tabNum') == 0)
				{
					pageSize.page += 1;
					goData();
				}
				else if (pageSize.practicepage < practicedata.maxPage && window.localStorage.getItem('tabNum') == 1)
				{
					pageSize.practicepage += 1;
					goData();
				}
			}
		  }
			  
		  window.addEventListener("scroll", scrollHandler);
		  
		  // Return effect cleanup function to remove listener
		  return () => {
			window.removeEventListener("scroll", scrollHandler);
		  };
},[location]);

	const goData = () => {
		if(location.state !== undefined && location.state != null)
		{
			if(location.state.nowTabTopics !== undefined)
			window.localStorage.setItem('tabNum', location.state.nowTabTopics);
			setactiveTab(window.localStorage.getItem('tabNum'));
			if(activeTab != window.localStorage.getItem('tabNum'))
			{
				setactiveTab(window.localStorage.getItem('tabNum'));
			}
		}

		//라운드
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {page : pageSize.page, pageSize : 50},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('나의 기록실 목록 조회 라운드'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
				'getplus' : gameTypeLink
			}
		}).then(function (response) {
				console.log(response.data);
				console.log(gameTypeLink);
				
				if(response.data.error !== undefined && response.data.error.code > 0)
				{
					common.alertByError('샷 정보',response.data.error.code);
					return false;
				}
				else
				{
					if(pageSize.page * 50 <= temphtmllist.length)
					{
						return false;
					}

					cdn = response.data.cdn;
					datalist =  [...datalist,...response.data.list];
					data = response.data;
					html = [];

					if(datalist.length)
					{
						$("#noData").hide();
						for (let i = temphtmllist.length;i < datalist.length; i++)
						{
							shotList.push([]); //빈값의 shotList생성
							if(datalist[i].shopName == null) datalist[i].shopName = 'null';
							if(datalist[i].courseName == null) datalist[i].courseName = 'null';
							console.log(datalist.length);

							//바깥
							var gametypeclass = common.getCommonKeyByValue(lan.LTarget(datalist[i].gameType,'ko'),'gameType');
							if(gametypeclass === undefined) gametypeclass = datalist[i].gameType.toLowerCase();

							ahtml.push([]);
							console.log(ahtml);
							
							for(let j = 0 ; j < 300; j++)
							{
								ahtml[i].push(<a key={"ashot"+i+"_"+j} style={{display:'none'}} id={"ashot"+i+"_"+j} onClick={(e) => goDetail(i,j)}></a>);
							}

							html.push(
								<li key={i+'roundlist'} id={datalist[i].gameRoundId+'li'+i}>
									<label id={"label"+datalist[i].gameRoundId} onClick={(e) => onClickShotDetail(datalist[i].gameRoundId,i,'Y')}>
									  <div className="shot_info">
										<span>
										  <div className={"game_type "+gametypeclass}>{datalist[i].gameType}</div>
										</span>
										<em>{datalist[i].courseName}</em>
										<sub>{common.UnixTime(datalist[i].startTime,'년.월.일')}</sub>
									  </div>
									  <div className="score_point">
										<b>{datalist[i].score}</b>
										<small>{lan.L('score')}</small>
									  </div>
									</label>
									<div className="course_status course_round" id={"childround"+datalist[i].gameRoundId}>
									{ahtml[i]}
									</div>
									<div className="nodata nodatashot" id={"nodata"+datalist[i].gameRoundId} style={{display: 'none'}} >
										<p><img src={require("../images/icon/thumb_play.png")} /></p>
										<span>{lan.L('등록된 영상 정보가 없습니다.')}</span>
									</div>
								</li>
							);
						}
						
						html = [...temphtmllist,...html];
						console.log(html);
						sethtmllist(html);
						temphtmllist = html;

						if($("#childround"+backgameRoundId).length > 0)
						{
							if(backgameRoundId != '') //디테일 갔다가 돌아온 경우
							{
								for(let i = 0; practicedatalist.length; i++)
								{
									if(practicedatalist[i].gameRoundId == backpracticegameRoundId)
									{
										if($("#childround"+backgameRoundId).is(':visible') == false)
										{
											onClickShotDetail(backgameRoundId,backgameRoundNum,'Y');
										}
										else
										{
											onClickShotDetail(datalist[0].gameRoundId,0,'N');
											onClickShotDetail(datalist[0].gameRoundId,0,'N');
										}
										break;
									}
								}
							}
						}
						else if(pageSize.page == 1)
						{
							if($("#childround"+datalist[0].gameRoundId).is(':visible') == false)
							{
								onClickShotDetail(datalist[0].gameRoundId,0,'N');
							}
							else
							{
								onClickShotDetail(datalist[0].gameRoundId,0,'N');
								onClickShotDetail(datalist[0].gameRoundId,0,'N');
							}
						}
					}
					else
					{
						$("#noData").show();
					}
				}
			}).catch(error => {
			console.log(error.response)
		});

		console.log(sendpracticeclubdata);

		//연습
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {page : pageSize.practicepage, pageSize : 50},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('나의 기록실 목록 조회 라운드'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
				'getplus' : practiceTypeSend
			}
		}).then(function (response_p) {
				console.log(response_p.data);
				
				if(response_p.data.error !== undefined && response_p.data.error.code > 0)
				{
					common.alertByError('샷 정보',response_p.data.error.code);
					return false;
				}
				else
				{
					if(pageSize.practicepage * 50 <= temppracticehtmllist.length)
					{
						return false;
					}

					practicedatalist = [...practicedatalist,...response_p.data.list];
					practicedata = response_p.data;
					practicehtml = [];

					if(practicedatalist.length)
					{
						$("#practicenoData").hide();
						for (let i = 0;i < practicedatalist.length; i++)
						{
							practiceshotList.push([]); //빈값의 shotList생성
							if(practicedatalist[i].shopName == null) practicedatalist[i].shopName = 'null';
							if(practicedatalist[i].courseName == null) practicedatalist[i].courseName = 'null';
							//if(practicedatalist[i].practiceType == null || practicedatalist[i].practiceType == '') practicedatalist[i].practiceType = 'null';

							//바깥
							var gametypeclass = common.getCommonKeyByValue(lan.LTarget(practicedatalist[i].gameType,'ko'),'gameType');
							if(gametypeclass === undefined) gametypeclass = practicedatalist[i].gameType.toLowerCase();

							practiceahtml.push([]);
							
							for(let j = 0 ; j < 300; j++)
							{
								practiceahtml[i].push(<a key={"apracticeshot"+i+"_"+j} style={{display:'none'}} id={"apracticeshot"+i+"_"+j} onClick={(e) => goPracticeDetail(i,j)}></a>);
							}

							if(practicedatalist[i].score != 0)
							{
								practicehtml.push(
									<li key={i+'p_roundlist'} id={practicedatalist[i].gameRoundId+'p_li'+i}>
									<label id={"practicelabel"+practicedatalist[i].gameRoundId}  onClick={(e) => onClickPracticeShotDetail(practicedatalist[i].gameRoundId,i,'Y')}>
									  <div className="shot_info">
										<span>
										  <div className={"game_type practice"}>{practicedatalist[i].gameType}</div>
										</span>
										<em>{lan.L(practicedatalist[i].practiceType)}</em>
										<strong>{practicedatalist[i].shopName}</strong>
										<sub>{common.UnixTime(practicedatalist[i].startTime,'년.월.일')}</sub>
									  </div>
									  <div className="score_point">
										<b>{practicedatalist[i].score}</b>
										<small>{lan.L('shots')}</small>
									  </div>
									</label>
									<div className="course_status course_practice" id={"childround"+practicedatalist[i].gameRoundId}>
									{practiceahtml[i]}
									</div>
									<div className="nodata practicenodatashot" id={"nodata"+practicedatalist[i].gameRoundId} style={{display: 'none'}} >
										<p><img src={require("../images/icon/thumb_play.png")} /></p>
										<span>{lan.L('등록된 영상 정보가 없습니다.')}</span>
									</div>
									</li>
								);
							}
						}
						
						practicehtml = [...temppracticehtmllist,...practicehtml];
						setpracticehtmllist(practicehtml);
						temppracticehtmllist = practicehtml;

						
						if(backpracticegameRoundId != '') //디테일 갔다가 돌아온 경우
						{
							for(let i = 0; practicedatalist.length; i++)
							{
								if(practicedatalist[i].gameRoundId == backpracticegameRoundId)
								{
									if($("#childround"+backpracticegameRoundId).is(':visible') == false)
									{
										onClickPracticeShotDetail(backpracticegameRoundId,backgameRoundNum,'Y');
									}
									else
									{
										onClickPracticeShotDetail(practicedatalist[0].gameRoundId,0,'N');
										onClickPracticeShotDetail(practicedatalist[0].gameRoundId,0,'N');
									}
									break;
								}
							}
						}
						else if(pageSize.practicepage == 1)
						{
							if($("#childround"+practicedatalist[0].gameRoundId).is(':visible') == false)
							{
								onClickPracticeShotDetail(practicedatalist[0].gameRoundId,0,'N');
							}
							else
							{
								onClickPracticeShotDetail(practicedatalist[0].gameRoundId,0,'N');
								onClickPracticeShotDetail(practicedatalist[0].gameRoundId,0,'N');
							}
						}
					}
					else
					{
						$("#practicenoData").show();
					}
				}
			}).catch(error => {
			console.log(error.response)
		});
		
		//보관
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('스윙 영상 보관 목록 조회'),
				'token' : sessionStorage.getItem('access_token'),
			}
		}).then(function (response) {
				console.log(response.data);
				
				if(response.data.error !== undefined && response.data.error.code > 0)
				{
					common.alertByError('샷 정보',response.data.error.code);
					return false;
				}
				else
				{
					archivedatalist = response.data.list;
					// archivedatalist = [{
					// 	"gameRoundId": 835354,
					// 	"shotId": 835354,
					// 	"gameType": 0,
					// 	"practiceType": null,
					// 	"totalDistance": 40.73,
					// 	"recommandClub": 1
					// }]
			
					archivehtml = [];
					archiveahtml = [];

					if(archivedatalist.length)
					{
						$("#archivenoData").hide();
						for (let i = 0;i < archivedatalist.length; i++)
						{

							archivehtml.push(
								<>
								<div className="course_status" style={{display:'block'}} id={"childarchive"+i}>
									<a key={"aarchiveshot"+i} id={"aarchiveshot"+i} onClick={(e) => goArchiveDetail(i)}>
										<p style={{backgroundImage: 'url("'+response.data.cdn+archivedatalist[i].frontVideoThumbnailKey+'")'}}>thumbnail</p>
										<aside>
										<dl className="able-wrap">
											<dt>
											<span>
												<em>{archivedatalist[i].practiceType == 0  ? lan.L(common.commonKeyDic['gameType'][common.getCommonKeyByValue(archivedatalist[i].gameType,'gameTypeVal')]) : lan.L(common.commonKeyDic['practiceType'][archivedatalist[i].practiceType])}</em>
											</span>
											</dt>
										</dl>
										<dl>
											<dd>{lan.L('비거리')}</dd>
											<dt>{common.Length(archivedatalist[i].totalDistance.toFixed(2),'0(m)')}</dt>
										</dl>
										<dl><dd>&nbsp;</dd><dt>&nbsp;</dt></dl>
										<dl><dd>&nbsp;</dd><dt>&nbsp;</dt></dl>
										</aside>
									</a>
								</div>
								</>
							);
						}
						setarchivehtmllist(archivehtml);
					}
					else
					{
						$("#archivenoData").show();
					}
				}
			}).catch(error => {
			console.log(error.response)
		});
	}
	
	const parentpractice = (v, i) => {
		$(".practicenodatashot").hide();
		$("#practice > li > label").removeClass("on");
		if (!$("#childround"+v).css('display') != 'none') {
			
			$(".course_practice").hide();
			$("#childround"+v).show();
			$("#practicelabel"+v).addClass("on");
			
			if(practiceshotList[i].length==0)
			{
				$("#nodata"+v).show();
			}

			for(let j=0; j < 300;j++)
			{
				if($("#apracticeshot"+i+"_"+j).css('display') != 'none')
				break;

				if(j==299)
				{
					$("#nodata"+v).show();
				}
			}
		} else {
			$("#childpractice"+v).hide();
		}
	}
	
	const onClickPracticeShotDetail = (roundid, i, isscroll) => {
		
		if(isPracticeClickable)
		{
			isPracticeClickable = false;

			if($("#childround"+roundid).is(':visible'))
			{
				$("#childround"+roundid).hide();
				$("#nodata"+roundid).hide();
				$(".practicenodatashot").hide();
				$("#practicelabel"+roundid).removeClass("on");
				isPracticeClickable = true;
				return false;
			}
	
				//연습장 샷 목록 조회
				axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER + '/api',
				data: {},
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'language':sessionStorage.getItem('Language'),
					'pUrl' : encodeURIComponent('연습장 샷 목록 조회'),
					'guid' : practicedatalist[i].gameRoundId,
					'token' : sessionStorage.getItem('access_token'),
					'getplus' : practiceClubSend
				}
			}).then(function (response) {
				console.log(response.data);
	
				practiceType = response.data.practiceType;
	
				practiceshotList[i] = response.data.shotList;
	
				console.log(isPracticeVideoSaveOpt);
				window.localStorage.setItem('practiceGameRoundId', practicedatalist[i].gameRoundId);
				window.localStorage.setItem('practiceGameRoundShot', i);
	
				if(practiceshotList[i].length > 0)
				{
					for(let j = 0 ; j < practiceshotList[i].length; j++)
					{
						$("#apracticeshot"+i+"_"+j).show();
						$("#apracticeshot"+i+"_"+j).empty();
						$(".practicenodatashot").hide();
						practiceshothtml = '';
	
						if(isPracticeVideoSaveOpt)
						{
							practiceshothtml = practiceshothtml + '<img style="display:none" src="'+response.data.cdn+practiceshotList[i][j].frontVideoThumbnailKey+'" onError=\'$("#apracticeshot'+i+'_'+j+'").hide();\' />'
						}
	
						practiceshothtml = practiceshothtml + '<p class="" style="background-image: url(\''+response.data.cdn+practiceshotList[i][j].frontVideoThumbnailKey+'\')">thumbnail</p>'
						practiceshothtml = practiceshothtml + '<aside>'
						practiceshothtml = practiceshothtml + '  <dl class="able-wrap">'
						practiceshothtml = practiceshothtml + '  <dt>'
						practiceshothtml = practiceshothtml + '  <span>'
						practiceshothtml = practiceshothtml + '	<em>'+lan.L(practicedatalist[i].practiceType)+'</em>'
						practiceshothtml = practiceshothtml + '  </span>'
						practiceshothtml = practiceshothtml + '  </dt>'
						practiceshothtml = practiceshothtml + '  <dd>'
						practiceshothtml = practiceshothtml + '  <span>'
						if(practiceshotList[i][j].shotGrade != 0)
						{
							practiceshothtml = practiceshothtml + '	<i class="'+common.commonKeyDic['shotGrade'][practiceshotList[i][j].shotGrade].toLowerCase()+'">'+common.commonKeyDic['shotGrade'][practiceshotList[i][j].shotGrade]+'</i>'
						}
						if(practiceType == '2' || practiceType == '3') //연습장 샷 상세정보에 연습장타입이 직선퍼팅이거나, 어프로치일때 공략거리도 표기해주시기 바랍니다.
						{
							practiceshothtml = practiceshothtml + '	<i>'+common.Length(practiceshotList[i][j].targetSettingDistance,'0m')+'</i>'
						}
						practiceshothtml = practiceshothtml + '	<i>'+common.commonKeyDic['recommandClub'][practiceshotList[i][j].recommandClub]+'</i>'
						practiceshothtml = practiceshothtml + '  </span>'
						practiceshothtml = practiceshothtml + '  </dd>'
						practiceshothtml = practiceshothtml + '  </dl>'
						practiceshothtml = practiceshothtml + '  <dl>'
						practiceshothtml = practiceshothtml + '	<dd>'+lan.L('볼스피드')+'</dd>'
						practiceshothtml = practiceshothtml + '	<dt>'+common.Speed(practiceshotList[i][j].ballSpeed.toFixed(2),'0m/s')+'</dt>'
						practiceshothtml = practiceshothtml + '  </dl>'
						if(practiceType == '1') //드라이빙레인지
						{
							practiceshothtml = practiceshothtml + '  <dl>'
							practiceshothtml = practiceshothtml + '	<dd>'+lan.L('비거리')+'</dd>'
							practiceshothtml = practiceshothtml + '	<dt>'+common.Length(practiceshotList[i][j].totalDistance.toFixed(2),'0m')+'</dt>'
							practiceshothtml = practiceshothtml + '  </dl>'
						}
						else
						{
							practiceshothtml = practiceshothtml + '  <dl>'
							practiceshothtml = practiceshothtml + '	<dd>'+lan.L('남은거리')+'</dd>'
							practiceshothtml = practiceshothtml + '	<dt>'+common.Length(practiceshotList[i][j].remainDistance.toFixed(2),'0m')+'</dt>'
							practiceshothtml = practiceshothtml + '  </dl>'
						}
	
						if(practiceType == '1')
						{
							practiceshothtml = practiceshothtml + '  <dl>'
							practiceshothtml = practiceshothtml + '	<dd>'+lan.L('구질')+'</dd>'
							practiceshothtml = practiceshothtml + '	<dt>'+common.commonKeyDic['ballFlightType'][practiceshotList[i][j].ballFlightType]+'</dt>'
							practiceshothtml = practiceshothtml + '  </dl>'
						}
						else if(practiceType=='2' || practiceType=='5')
						{
							practiceshothtml = practiceshothtml + '  <dl>'
							practiceshothtml = practiceshothtml + '	<dd>'+lan.L('발사각')+'</dd>'
							practiceshothtml = practiceshothtml + '	<dt>'+practiceshotList[i][j].launchAngle.toFixed(2)+'°'+'</dt>'
							practiceshothtml = practiceshothtml + '  </dl>'
						}
						else
						{
							practiceshothtml = practiceshothtml + '  <dl>'
							practiceshothtml = practiceshothtml + '	<dd>'+lan.L('방향각')+'</dd>'
							practiceshothtml = practiceshothtml + '	<dt>'+practiceshotList[i][j].directionAngle.toFixed(2)+'°'+'</dt>'
							practiceshothtml = practiceshothtml + '  </dl>'
						}
						practiceshothtml = practiceshothtml + '</aside>'
						
						$("#apracticeshot"+i+"_"+j).append(practiceshothtml);
					}
				}
				
				if(isPracticeVideoSaveOpt)
				{
					setTimeout(function(){
						parentpractice(roundid, i);
						isPracticeClickable = true;
						if(isscroll=="Y")
						{
							$('html, body').animate({scrollTop:$("#"+practicedatalist[i].gameRoundId+"p_li"+i).offset().top - 100},300);
						}
					}, 700 + practicedatalist[i].score * 20);
				}
				else
				{
					parentpractice(roundid, i);
					isPracticeClickable = true;
					if(isscroll=="Y")
					{
						$('html, body').animate({scrollTop:$("#"+practicedatalist[i].gameRoundId+"p_li"+i).offset().top - 100},300);
					}
				}
	
			}).catch(error => {
				isPracticeClickable = true;
				console.log(error);
			});
		}
	}
	
	const parentround = (v, i) => {

		$(".nodatashot").hide();
		$(".list_video > li > label").removeClass("on");
		if (!$("#childpractice"+v).css('display') != 'none') {
			$(".course_round").hide();
			$("#childround"+v).show();
			$("#label"+v).addClass("on");

			if(shotList[i].length==0)
			{
				$("#nodata"+v).show();
			}

			for(let j=0; j < 300;j++)
			{
				if($("#ashot"+i+"_"+j).css('display') != 'none')
				break;

				if(j==299)
				{
					$("#nodata"+v).show();
				}
			}
		} else {
			$("#childpractice"+v).hide();
		}
	}
	
	const onClickShotDetail = (roundid, i,isscroll) => {

		if(isClickable)
		{
			isClickable = false;

			if($("#childround"+roundid).is(':visible'))
			{
				$("#childround"+roundid).hide();
				$("#nodata"+roundid).hide();
				$(".nodatashot").hide();
				$("#label"+roundid).removeClass("on");
				isClickable = true;
				return false;
			}
	
			console.log(clubSend);
			
				//게임 라운드 샷 목록 조회
				axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER + '/api',
				data: {},
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'language':sessionStorage.getItem('Language'),
					'pUrl' : encodeURIComponent('게임 라운드 샷 목록 조회'),
					'guid' : datalist[i].gameRoundId,
					'token' : sessionStorage.getItem('access_token'),
					'getplus' : clubSend
				}
			}).then(function (response) {
				console.log(response.data);
	
				shotList[i] = response.data.shotList;
	
				$("#childround"+datalist[i].gameRoundId).hide();
				$("#nodata"+datalist[i].gameRoundId).hide();
				$(".nodatashot").hide();
				
				window.localStorage.setItem('gameRoundId', datalist[i].gameRoundId);
				window.localStorage.setItem('gameRoundShot', i);
	
				if(shotList[i].length > 0)
				{
					for(let j = 0 ; j < shotList[i].length; j++)
					{
						//주석 처리 필요 테스트
						//shotList[i][j].frontVideoThumbnailKey = shotList[i][j].frontVideoThumbnailKey.replace('/Normal/rxhbh/','/rxhbh/Normal/');
						//shotList[i][j].frontVideoThumbnailKey = "uploads/Recording/Encoding/3ahhw/Normal/20221229_01_743430_1201_SF_N_Ro.jpg"
						//shotList[i][j].frontVideoThumbnailKey = "uploads/Recording/Encoding/rxhbh/Normal/20230102_01_743516_1_0001_SF_N_Ro.jpg"
						$("#ashot"+i+"_"+j).show();
						$("#ashot"+i+"_"+j).empty();
	
						shothtml = '';
	
						if(isVideoSaveOpt)
						{
							shothtml = shothtml + '<img style="display:none" src="'+response.data.cdn+shotList[i][j].frontVideoThumbnailKey+'" onError=\'$("#ashot'+i+'_'+j+'").hide();\' />'
						}
						shothtml = shothtml + '<p class="" style="background-image: url(\''+response.data.cdn+shotList[i][j].frontVideoThumbnailKey+'\')">thumbnail</p>'
						shothtml = shothtml + '<aside>'
						shothtml = shothtml + '  <dl class="able-wrap">'
						shothtml = shothtml + '  <dt>'
						shothtml = shothtml + '  <span>'
						shothtml = shothtml + '	<input type="hidden" id="coursePar'+i+'_'+j+'" value="'+response.data.coursePar[shotList[i][j].holeNumber-1]+'">'
						shothtml = shothtml + '	<em><b>'+shotList[i][j].holeNumber+lan.L('H')+'</b>'+lan.L('Par')+response.data.coursePar[shotList[i][j].holeNumber-1]+'</em>'
						shothtml = shothtml + '  </span>'
						shothtml = shothtml + '  </dt>'
						shothtml = shothtml + '  <dd>'
						shothtml = shothtml + '  <span>'
						if(shotList[i][j].shotGrade != 0 && shotList[i][j].shotGrade != 4)
						{
							shothtml = shothtml + '	<i class="'+common.commonKeyDic['roundshotGrade'][shotList[i][j].shotGrade].toLowerCase()+'">'+common.commonKeyDic['roundshotGrade'][shotList[i][j].shotGrade]+'</i>'
						}
						shothtml = shothtml + '	<i>'+common.commonKeyDic['recommandClub'][shotList[i][j].recommandClub]+'</i>'
						shothtml = shothtml + '  </span>'
						shothtml = shothtml + '  </dd>'
						shothtml = shothtml + '  </dl>'
	
						shothtml = shothtml + '  <dl>'
						shothtml = shothtml + '	<dd>'+lan.L('비거리')+'</dd>'
						shothtml = shothtml + '	<dt>'+common.Length(shotList[i][j].totalDistance.toFixed(2),'0(m)')+'</dt>'
						shothtml = shothtml + '  </dl>'
						shothtml = shothtml + '  <dl>'
						shothtml = shothtml + '	<dd>'+lan.L('구질')+'</dd>'
						shothtml = shothtml + '	<dt>'+common.commonKeyDic['ballFlightType'][shotList[i][j].ballFlightType]+'</dt>'
						shothtml = shothtml + '  </dl>'
						shothtml = shothtml + '  <dl>'
						shothtml = shothtml + '	<dd>'+lan.L('도착위치')+'</dd>'
						shothtml = shothtml + '	<dt>'+common.commonKeyDic['destinationLandType'][shotList[i][j].destinationLandType]+'</dt>'
						shothtml = shothtml + '  </dl>'
						shothtml = shothtml + '</aside>'
						
						$("#ashot"+i+"_"+j).append(shothtml);
	
					}
				}
	//			$(window).scrollTop($("#"+datalist[i].gameRoundId+"li"+i).offset().top);
	//			$('html, body').animate($(window).scrollTop($("#"+datalist[i].gameRoundId+"li"+i).offset().top),1000);
	
				if(isVideoSaveOpt)
				{
					setTimeout(function(){
						parentround(roundid, i);
						isClickable = true;
						if(isscroll=="Y")
						{
							$('html, body').animate({scrollTop:$("#"+datalist[i].gameRoundId+"li"+i).offset().top - 94},300);
						}
					}, 700 + datalist[i].score * 20);
				}
				else
				{
					parentround(roundid, i);
					isClickable = true;
					if(isscroll=="Y")
					{
						$('html, body').animate({scrollTop:$("#"+datalist[i].gameRoundId+"li"+i).offset().top - 94},300);
					}
				}
				
			}).catch(error => {
				isClickable = true;
				console.log(error)
			});
		}
	}
	
	const goDetail = (i,j) => {

		var gameRoundId = datalist[i].gameRoundId;
		var gameRoundShotId = shotList[i][j].gameRoundShotId;
		var roundData = datalist[i];
		var shotData = shotList[i][j];
		var coursePar = $("#coursePar"+i+"_"+j).val();
		var backTabTopics = 0;
		var shotNumber = j;
		
		navigate('/shot/detail_video', {state : {gameRoundId : gameRoundId, gameRoundShotId : gameRoundShotId, roundData : roundData, shotData : shotData, playernickname : sessionStorage.getItem('nickname'), coursePar : coursePar, activeTab : 0, cdn : cdn, backTabTopics : backTabTopics, gameRoundNum : i}, replace : true });
	}
	
	const goPracticeDetail = (i,j) => {

		var gameRoundId = practicedatalist[i].gameRoundId;
		var practiceShotId = practiceshotList[i][j].practiceShotId;
		var roundData = practicedatalist[i];
		var shotData = practiceshotList[i][j];
		var backTabTopics = 1;

		console.log(shotData);
		
		navigate('/shot/detail_video_practice', {state : {gameRoundId : gameRoundId, practiceShotId : practiceShotId, roundData : roundData, shotData : shotData, activeTab : 0, cdn : cdn, backTabTopics : backTabTopics, practiceType : practiceType, gameRoundNum : i}, replace : true });
	}
	
	const goArchiveDetail = (i) => {

		var gameRoundId = archivedatalist[i].gameRoundId;
		var ShotId = archivedatalist[i].shotId;
		var backTabTopics = 2;
		var roundData = [];
		var shotData = [];
		var x = 0; //라운드id가 일치하는 순서
		var y = 0; //샷id가 일치하는 순서
		var coursePar = 0;
		var practiceType = archivedatalist[i].practiceType

		if(practiceType == 0) //null인경우 라운드샷, 값이있으면 연습샷
		{
			for(x = 0; x < datalist.length; x++)
			{
				if(datalist[x].gameRoundId == gameRoundId)
				{
					roundData = datalist[x];
					break;
				}
			}
			
			//게임 라운드 샷 목록 조회
			axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER + '/api',
				data: {},
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'language':sessionStorage.getItem('Language'),
					'pUrl' : encodeURIComponent('게임 라운드 샷 목록 조회'),
					'guid' : archivedatalist[i].gameRoundId,
					'token' : sessionStorage.getItem('access_token'),
					'getplus' : ''
				}
			}).then(function (response) {

				for(y = 0; y < response.data.shotList.length; y++)
				{
					if(response.data.shotList[y].gameRoundShotId == ShotId)
					{
						shotData = response.data.shotList[y];
						coursePar = response.data.coursePar[y];
						break;
					}
				}

				navigate('/shot/detail_video', {state : {gameRoundId : gameRoundId, gameRoundShotId : ShotId, roundData : roundData, shotData : shotData, playernickname : sessionStorage.getItem('nickname'), coursePar : coursePar, activeTab : 0, cdn : cdn, backTabTopics : backTabTopics}, replace : true });

			}).catch(error => {
				console.log(error)
			});
		}
		else
		{
			for(x = 0; x < practicedatalist.length; x++)
			{
				if(practicedatalist[x].gameRoundId == gameRoundId)
				{
					roundData = practicedatalist[x];
					break;
				}
			}
			
			//연습장 샷 목록 조회
			axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER + '/api',
				data: {},
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'language':sessionStorage.getItem('Language'),
					'pUrl' : encodeURIComponent('연습장 샷 목록 조회'),
					'guid' : archivedatalist[i].gameRoundId,
					'token' : sessionStorage.getItem('access_token'),
					'getplus' : ''
				}
			}).then(function (response) {

				for(y = 0; y < response.data.shotList.length; y++)
				{
					if(response.data.shotList[y].practiceShotId == ShotId)
					{
						shotData = response.data.shotList[y];
						coursePar = response.data.coursePar;
						break;
					}
				}

				navigate('/shot/detail_video_practice', {state : {gameRoundId : gameRoundId, practiceShotId : ShotId, roundData : roundData, shotData : shotData, activeTab : 0, cdn : cdn, backTabTopics : backTabTopics, practiceType : practiceType, gameRoundNum : i}, replace : true });

			}).catch(error => {
				console.log(error)
			});
		}
	}
	
	const initialize = () => {
		temphtmllist = [];
		temppracticehtmllist = [];
		html = [];
		ahtml = [];
		practiceahtml = [];
		pageSize = {page : 1, practicepage : 1};
	}
	
	const initSearch = (i) => {
		if(i==0)
		{
			if(gameTypeVal!=0)
			{
				$("#pop_gametypeinit").trigger("click");
			}

			if(clubVal!='' || clubVal=='0')
			{
				$("#pop_clubinit").trigger("click");
			}
		}
		if(i==1)
		{
			if(practiceClubVal!=0)
			{
				$("#pop_practiceclubinit").trigger("click");
			}
		}
	}
	
	const changeSaveOpt = () => {
		isVideoSaveOpt = $("#save1").is(":checked");
		goData();
	}
	
	const changePracticeSaveOpt = () => {
		isPracticeVideoSaveOpt = $("#save2").is(":checked");
		goData();
	}
	
	return (
	<>
	<Tab topics={[{tabName:'라운드'},{tabName:'연습'},{tabName:'보관'}]} defaultTopics={location.state === undefined || location.state == null ? 0 : location.state.activeTab} tabkey={lan.L("샷 정보")} onChangefunc={initSearch}></Tab> 
	<BottomClub to="/shot/shotvideo" club={club} gameType={gameType} practiceClub={practiceClub} activeTab={0}></BottomClub>
	<BottomRecord to="/shot/shotvideo" isTournamentView={''} gameType={gameType} club={club} practiceClub={practiceClub} activeTab={0}></BottomRecord>
	<BottomPracticeClub to="/shot/shotvideo" club={club} gameType={gameType} practiceClub={practiceClub} activeTab={1}></BottomPracticeClub>
	<div className="contents" style={activeTab==0 ? {display:'block'}: {display:'none'}}>
	  <div className="search_standard">
		<span>
		  <label className="filter_index" onClick={gameTypeslide.slideOn}>{gameType==lan.L(common.commonKeyDic['gameType']['All']) ? lan.L('경기방식 전체') : gameType}</label>
		</span>
		<span>
		  <label className="filter_index" onClick={clubslide.slideOn}>{club==lan.L(common.commonKeyDic['club']['All']) ? lan.L('클럽 전체') : club}</label>
		</span>
		<a>
		  <input type="checkbox" id="save1" name="save1" onChange={changeSaveOpt} />
		  <label for="save1">{lan.L('영상있는샷만')}</label>
		</a>
	  </div>
	  {/* No Data */}
	  <div className="nodata" id="noData" style={{display: 'none'}}>
		<p><img src={require("../images/character/nodata_3.png")} /></p>
		<strong>{lan.L('저장된 샷 정보가 없어요.')}</strong>
		<span>{lan.L('가까운 매장에서 플레이 해보세요.')}</span>
	  </div>
	  {/*//No Data */}
	  <div style={{display: 'block'}}>
		<ul className="list_video">{/* 리스트 reverse // 밑으로 쌓일수록 최신 게시물 */}
		  {htmllist}
		</ul>
	  </div>
	</div>

	<div className="contents" style={activeTab==1 ? {display:'block'}: {display:'none'}}>
	  <div className="search_standard">
		<span>
		  <label className="filter_index" onClick={practiceclubslide.slideOn}>{practiceClub==lan.L(common.commonKeyDic['club']['All']) ? lan.L('클럽 전체') : practiceClub}</label>
		</span>
		<span/>
		<a>
		  <input type="checkbox" id="save2" name="save2" onChange={changePracticeSaveOpt} />
		  <label for="save2">{lan.L('영상있는샷만')}</label>
		</a>
	  </div>
	  {/* No Data */}
	  <div className="nodata" id="practicenoData" style={{display: 'none'}}>
		<p><img src={require("../images/character/nodata_3.png")}/></p>
		<strong>{lan.L('저장된 샷 정보가 없어요.')}</strong>
		<span>{lan.L('가까운 매장에서 플레이 해보세요.')}</span>
	  </div>
	  {/*//No Data */}
	  <div style={{display: 'block'}}>
		<ul className="list_video" id="practice">{/* 리스트 reverse // 밑으로 쌓일수록 최신 게시물 */}
			{practicehtmllist}
		</ul>
	  </div>
	</div>
	
	<div className="contents" style={activeTab==2 ? {display:'block'}: {display:'none'}}>
	  {/* No Data */}
	  <div className="nodata" id="archivenoData" style={{display: 'none'}}>
		<p><img src={require("../images/character/nodata_3.png")}/></p>
		<strong>{lan.L('저장된 샷 정보가 없어요.')}</strong>
		<span>{lan.L('가까운 매장에서 플레이 해보세요.')}</span>
	  </div>
	  {/*//No Data */}
	  <div style={{display: 'block'}}>
		<ul className="list_video" id="archive">{/* 리스트 reverse // 밑으로 쌓일수록 최신 게시물 */}
			<li>
			{archivehtmllist}
			</li>
		</ul>
	  </div>
	</div>
	</>
	)
}
