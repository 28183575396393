import React from 'react';

const NewperioRanking = ({InfoData, InfoRankData, matchCourseNum, selectPlayerNum}) => {
    const newPerioHoles = InfoData.courseRounds[matchCourseNum].newPerioHoleNumber;
    return (
        <>
            <table className="score_chart">
                <colgroup>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                    <col style={{width: '10%'}}/>
                </colgroup>
                <tbody>
                <tr>
                    <th className={newPerioHoles.indexOf(1) > -1 && 'newperio'}>1</th>
                    <th className={newPerioHoles.indexOf(2) > -1 && 'newperio'}>2</th>
                    <th className={newPerioHoles.indexOf(3) > -1 && 'newperio'}>3</th>
                    <th className={newPerioHoles.indexOf(4) > -1 && 'newperio'}>4</th>
                    <th className={newPerioHoles.indexOf(5) > -1 && 'newperio'}>5</th>
                    <th className={newPerioHoles.indexOf(6) > -1 && 'newperio'}>6</th>
                    <th className={newPerioHoles.indexOf(7) > -1 && 'newperio'}>7</th>
                    <th className={newPerioHoles.indexOf(8) > -1 && 'newperio'}>8</th>
                    <th className={newPerioHoles.indexOf(9) > -1 && 'newperio'}>9</th>
                    <th>Sub</th>
                </tr>
                <tr className="standard">
                    <td className={newPerioHoles.indexOf(1) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[0].par}</td>
                    <td className={newPerioHoles.indexOf(2) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[1].par}</td>
                    <td className={newPerioHoles.indexOf(3) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[2].par}</td>
                    <td className={newPerioHoles.indexOf(4) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[3].par}</td>
                    <td className={newPerioHoles.indexOf(5) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[4].par}</td>
                    <td className={newPerioHoles.indexOf(6) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[5].par}</td>
                    <td className={newPerioHoles.indexOf(7) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[6].par}</td>
                    <td className={newPerioHoles.indexOf(8) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[7].par}</td>
                    <td className={newPerioHoles.indexOf(9) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[8].par}</td>
                    <td>
                        {
                            InfoData.courseRounds[matchCourseNum].holes[0].par + InfoData.courseRounds[matchCourseNum].holes[1].par +
                            InfoData.courseRounds[matchCourseNum].holes[2].par + InfoData.courseRounds[matchCourseNum].holes[3].par +
                            InfoData.courseRounds[matchCourseNum].holes[4].par + InfoData.courseRounds[matchCourseNum].holes[5].par +
                            InfoData.courseRounds[matchCourseNum].holes[6].par + InfoData.courseRounds[matchCourseNum].holes[7].par + InfoData.courseRounds[matchCourseNum].holes[8].par
                        }
                    </td>
                </tr>
                <tr>
                    <td className={newPerioHoles.indexOf(1) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[0]}</b></td>
                    <td className={newPerioHoles.indexOf(2) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[1]}</b></td>
                    <td className={newPerioHoles.indexOf(3) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[2]}</b></td>
                    <td className={newPerioHoles.indexOf(4) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[3]}</b></td>
                    <td className={newPerioHoles.indexOf(5) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[4]}</b></td>
                    <td className={newPerioHoles.indexOf(6) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[5]}</b></td>
                    <td className={newPerioHoles.indexOf(7) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[6]}</b></td>
                    <td className={newPerioHoles.indexOf(8) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[7]}</b></td>
                    <td className={newPerioHoles.indexOf(9) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[8]}</b></td>
                    <td>
                        <b>
                            {
                                InfoData.courseRounds[matchCourseNum].holes[0].par + InfoData.courseRounds[matchCourseNum].holes[1].par +
                                InfoData.courseRounds[matchCourseNum].holes[2].par + InfoData.courseRounds[matchCourseNum].holes[3].par +
                                InfoData.courseRounds[matchCourseNum].holes[4].par + InfoData.courseRounds[matchCourseNum].holes[5].par +
                                InfoData.courseRounds[matchCourseNum].holes[6].par + InfoData.courseRounds[matchCourseNum].holes[7].par +
                                InfoData.courseRounds[matchCourseNum].holes[8].par +
                                InfoRankData.list[selectPlayerNum].holeScore[0] + InfoRankData.list[selectPlayerNum].holeScore[1] + InfoRankData.list[selectPlayerNum].holeScore[2]
                                + InfoRankData.list[selectPlayerNum].holeScore[3] + InfoRankData.list[selectPlayerNum].holeScore[4] + InfoRankData.list[selectPlayerNum].holeScore[5]
                                + InfoRankData.list[selectPlayerNum].holeScore[6] + InfoRankData.list[selectPlayerNum].holeScore[7] + InfoRankData.list[selectPlayerNum].holeScore[8]}
                        </b>
                    </td>
                </tr>
                <tr>
                    <th className={newPerioHoles.indexOf(10) > -1 && 'newperio'}>10</th>
                    <th className={newPerioHoles.indexOf(11) > -1 && 'newperio'}>11</th>
                    <th className={newPerioHoles.indexOf(12) > -1 && 'newperio'}>12</th>
                    <th className={newPerioHoles.indexOf(13) > -1 && 'newperio'}>13</th>
                    <th className={newPerioHoles.indexOf(14) > -1 && 'newperio'}>14</th>
                    <th className={newPerioHoles.indexOf(15) > -1 && 'newperio'}>15</th>
                    <th className={newPerioHoles.indexOf(16) > -1 && 'newperio'}>16</th>
                    <th className={newPerioHoles.indexOf(17) > -1 && 'newperio'}>17</th>
                    <th className={newPerioHoles.indexOf(18) > -1 && 'newperio'}>18</th>
                    <th>Sub</th>
                </tr>
                <tr className="standard">
                    <td className={newPerioHoles.indexOf(10) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[9].par}</td>
                    <td className={newPerioHoles.indexOf(11) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[10].par}</td>
                    <td className={newPerioHoles.indexOf(12) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[11].par}</td>
                    <td className={newPerioHoles.indexOf(13) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[12].par}</td>
                    <td className={newPerioHoles.indexOf(14) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[13].par}</td>
                    <td className={newPerioHoles.indexOf(15) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[14].par}</td>
                    <td className={newPerioHoles.indexOf(16) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[15].par}</td>
                    <td className={newPerioHoles.indexOf(17) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[16].par}</td>
                    <td className={newPerioHoles.indexOf(18) > -1 && 'newperio'}>{InfoData.courseRounds[matchCourseNum].holes[17].par}</td>
                    <td>
                        {
                            InfoData.courseRounds[matchCourseNum].holes[9].par + InfoData.courseRounds[matchCourseNum].holes[10].par +
                            InfoData.courseRounds[matchCourseNum].holes[11].par + InfoData.courseRounds[matchCourseNum].holes[12].par +
                            InfoData.courseRounds[matchCourseNum].holes[13].par + InfoData.courseRounds[matchCourseNum].holes[14].par +
                            InfoData.courseRounds[matchCourseNum].holes[15].par + InfoData.courseRounds[matchCourseNum].holes[16].par + InfoData.courseRounds[matchCourseNum].holes[17].par
                        }
                    </td>
                </tr>
                <tr>
                    <td className={newPerioHoles.indexOf(10) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[9]}</b></td>
                    <td className={newPerioHoles.indexOf(11) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[10]}</b></td>
                    <td className={newPerioHoles.indexOf(12) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[11]}</b></td>
                    <td className={newPerioHoles.indexOf(13) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[12]}</b></td>
                    <td className={newPerioHoles.indexOf(14) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[13]}</b></td>
                    <td className={newPerioHoles.indexOf(15) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[14]}</b></td>
                    <td className={newPerioHoles.indexOf(16) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[15]}</b></td>
                    <td className={newPerioHoles.indexOf(17) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[16]}</b></td>
                    <td className={newPerioHoles.indexOf(18) > -1 && 'newperio'}><b>{InfoRankData.list[selectPlayerNum].holeScore[17]}</b></td>
                    <td>
                        <b>
                            {

                                InfoData.courseRounds[matchCourseNum].holes[9].par + InfoData.courseRounds[matchCourseNum].holes[10].par +
                                InfoData.courseRounds[matchCourseNum].holes[11].par + InfoData.courseRounds[matchCourseNum].holes[12].par +
                                InfoData.courseRounds[matchCourseNum].holes[13].par + InfoData.courseRounds[matchCourseNum].holes[14].par +
                                InfoData.courseRounds[matchCourseNum].holes[15].par + InfoData.courseRounds[matchCourseNum].holes[16].par +
                                InfoData.courseRounds[matchCourseNum].holes[17].par
                                + InfoRankData.list[selectPlayerNum].holeScore[9] + InfoRankData.list[selectPlayerNum].holeScore[10] + InfoRankData.list[selectPlayerNum].holeScore[11]
                                + InfoRankData.list[selectPlayerNum].holeScore[12] + InfoRankData.list[selectPlayerNum].holeScore[13] + InfoRankData.list[selectPlayerNum].holeScore[14]
                                + InfoRankData.list[selectPlayerNum].holeScore[15] + InfoRankData.list[selectPlayerNum].holeScore[16] + InfoRankData.list[selectPlayerNum].holeScore[17]}
                        </b>
                    </td>
                </tr>
                </tbody>
            </table>
            {!InfoData.isNewRankingSystem &&
            <table className="score_chart">
                <colgroup>
                    <col style={{width: '20%'}}/>
                    <col style={{width: '20%'}}/>
                    <col style={{width: '20%'}}/>
                    <col style={{width: '20%'}}/>
                    <col style={{width: '20%'}}/>
                </colgroup>
                <tbody>
                <tr>
                    <th>12hole<br/>score(b)</th>
                    <th>(b)x1.5=(c)</th>
                    <th>(c)-ParN(d)</th>
                    <th>(d)x0.8=(e)</th>
                    <th>newperio<br/>score</th>
                </tr>
                <tr>
                    <td><b>{InfoRankData.list[selectPlayerNum].newPerioRecordB}</b></td>
                    <td><b>{InfoRankData.list[selectPlayerNum].newPerioRecordC}</b></td>
                    <td><b>{InfoRankData.list[selectPlayerNum].newPerioRecordD}</b></td>
                    <td><b>{InfoRankData.list[selectPlayerNum].newPerioRecordE}</b></td>
                    <td><b>{InfoRankData.list[selectPlayerNum].newPerioFinalRecord}</b></td>
                </tr>
                </tbody>
            </table>}
        </>
    );
};

export default NewperioRanking;