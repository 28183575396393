import { useCallback, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';


function CommonGoogleMap(props) {
	const location = useLocation();
  const mapElement = useRef(null);

  const mylocation = { lat: props.latitude, lng:  props.longitude};

  // 컴포넌트가 마운트될 때, 수동으로 스크립트를 넣어줍니다. 
  // ﻿이는 script가 실행되기 이전에 window.initMap이 먼저 선언되어야 하기 때문입니다.
  const loadScript = useCallback((url: string) => {
    const firstScript = window.document.getElementsByTagName('script')[0];
    const newScript = window.document.createElement('script');
    newScript.src = url;
    newScript.async = true;
    newScript.defer = true;
    firstScript?.parentNode?.insertBefore(newScript, firstScript);
  }, []);

  function createCenterControl(map) {
  const controlButton = document.createElement("button");

  // Set CSS for the control.
  
  controlButton.className = "current";
  controlButton.addEventListener("click", () => {
    map.setCenter(mylocation);
  });
  return controlButton;
}


  // script에서 google map api를 가져온 후에 실행될 callback 함수
  const initMap = useCallback(() => {

      console.log(window.localStorage.getItem('nowLongitude'));

    const { google } = window;
    if (!mapElement.current || !google) return;

    //
    //

    var markers = [];
    
    const myLatlng = new google.maps.LatLng(props.latitude, props.longitude);
    const mapOptions = {
      zoom: 12,
      center: myLatlng,
      mapTypeId: "roadmap",
      disableDefaultUI: true,

    };
    const map = new google.maps.Map(document.getElementById(props.sendid),
      mapOptions);

    const infoWindow = new google.maps.InfoWindow();

    const icon_checked = {
      url: require("../../images/icon/marker_point.png"),
      size: new google.maps.Size(40, 40),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(20, 40),
      scaledSize: new google.maps.Size(36, 40),
    };
    
    const icon = {
      url: require("../../images/icon/marker_store.png"),
      size: new google.maps.Size(40, 40),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(20, 40),
      scaledSize: new google.maps.Size(36, 40),
    };
    
  // Create the DIV to hold the control.
  const centerControlDiv = document.createElement("div");
  // Create the control.
  const centerControl = createCenterControl(map);
  // Append the control to the DIV.
  centerControlDiv.appendChild(centerControl);
  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv);
    

    if(props.markers !== undefined)
    {
      for(let i=0;i < props.markers.length; i++)
      {
        markers.push(new  google.maps.Marker({
          position: { lat: props.markers[i][1], lng: props.markers[i][2] },
          map,
          icon: icon,
          title: props.markers[i][0],
          zIndex: 5,
        })); 
        
        markers[i].addListener("click", () => {
          for(let j=0;j<markers.length;j++)
          {
            markers[j].setIcon(icon);
          }
          markers[i].setIcon(icon_checked);
          window.localStorage.setItem('markerclick',props.markers[i][3]);
          window.dispatchEvent(new Event("storage"));

          $("#marker_point").show();
        });
      }
    }
    else
    {
      new google.maps.Marker({
        position: mylocation,
        map,
        icon: icon_checked,
      });
    }
  }, []);

  useEffect(() => {
    const script = window.document.getElementsByTagName('script')[0];
    const includeCheck = script.src.startsWith(
      'https://maps.googleapis.com/maps/api'
    );

    // script 중복 호출 방지
    if (includeCheck) return initMap();

    window.initMap = initMap;
    loadScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDKAb65cXkmdJFmA7k1CSVZzDy5d0SM6T8&callback=initMap&language='+sessionStorage.getItem('Language')
    );
  }, [initMap, loadScript]);


  return <div ref={mapElement} style={{ minHeight: '400px' }} />;
}


export default CommonGoogleMap;