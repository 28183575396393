import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

export function slideDifficulty(){
	$("html").addClass("overflowHidden");
	$("#difficulty").addClass("on");
}

export function slideTeeBoxType(){
	$("html").addClass("overflowHidden");
	$("#teeboxtype").addClass("on");
}

export function slideTeeheight(){
	$("html").addClass("overflowHidden");
	$("#teeheight").addClass("on");
}

export function slideExit(){
	$("html").removeClass("overflowHidden");
	$(".layerView").removeClass("on");
}

export default function BottomRoundset(prop) {
	
	const [difficultyhtmllist, setdifficultyhtmllist] = useState([]);
	const [teeboxtypehtmllist, setteeboxtypehtmllist] = useState([]);
	const [teeheighthtmllist, setteeheighthtmllist] = useState([]);

	
	useEffect(() => {
		
		var htmllist = [];
		//난이도
		for(let i =0; i < Object.keys(common.commonKeyDic['difficulty']).length; i++)
		{
			console.log(Object.keys(common.commonKeyDic['difficulty'])[i], prop.defaultvalue['difficulty']);
			htmllist.push(
				<a key={i+"d"} >
				  <input type="radio" value={lan.L(Object.values(common.commonKeyDic['difficulty'])[i])} id={"difficulty"+Object.keys(common.commonKeyDic['difficulty'])[i]}
					data-key={Object.keys(common.commonKeyDic['difficulty'])[i]} name="bottomdifficulty" 
					onClick={(e) => goSelect(e, 'difficulty', prop.returntype, prop.returnid, prop.returnvalueid)} defaultChecked={Object.keys(common.commonKeyDic['difficulty'])[i] == prop.defaultvalue['difficulty'] ? true : false}
					/>
				  <label htmlFor={"difficulty"+Object.keys(common.commonKeyDic['difficulty'])[i]}>{lan.L(Object.values(common.commonKeyDic['difficulty'])[i])}</label>
				</a>
			);
		}
		if(difficultyhtmllist.length == 0)
		{
			setdifficultyhtmllist(htmllist);
		}

		htmllist = [];


		htmllist.push(
			<a key={5+"b"} >
				<input type="radio" value={lan.L(Object.values(common.commonKeyDic['teeBoxType'])[5])} id={"teeboxtype"+Object.keys(common.commonKeyDic['teeBoxType'])[5]}
					   data-key={Object.keys(common.commonKeyDic['teeBoxType'])[5]} name="bottomteeboxtype"
					   onClick={(e) => goSelect(e, 'teeboxtype', prop.returntype, prop.returnid, prop.returnvalueid)} defaultChecked={Object.keys(common.commonKeyDic['teeBoxType'])[5] == prop.defaultvalue['teeboxtype'] ? true : false}/>
				<label htmlFor={"teeboxtype"+Object.keys(common.commonKeyDic['teeBoxType'])[5]}>{lan.L(Object.values(common.commonKeyDic['teeBoxType'])[5])}</label>
			</a>
		);

		//티 위치
		for(let i =0; i < Object.keys(common.commonKeyDic['teeBoxType']).length-1; i++)
		{
			htmllist.push(
				<a key={i+"b"} >
				  <input type="radio" value={lan.L(Object.values(common.commonKeyDic['teeBoxType'])[i])} id={"teeboxtype"+Object.keys(common.commonKeyDic['teeBoxType'])[i]}
					data-key={Object.keys(common.commonKeyDic['teeBoxType'])[i]} name="bottomteeboxtype" 
					onClick={(e) => goSelect(e, 'teeboxtype', prop.returntype, prop.returnid, prop.returnvalueid)} defaultChecked={Object.keys(common.commonKeyDic['teeBoxType'])[i] == prop.defaultvalue['teeboxtype'] ? true : false}/>
				  <label htmlFor={"teeboxtype"+Object.keys(common.commonKeyDic['teeBoxType'])[i]}>{lan.L(Object.values(common.commonKeyDic['teeBoxType'])[i])}</label>
				</a>
			);
		}
		if(teeboxtypehtmllist.length == 0)
		{
			setteeboxtypehtmllist(htmllist);
		}

		htmllist = [];

		var height;

		//티 높이
		for(let i =0; i < Object.keys(common.commonKeyDic['teeHeight']).length; i++)
		{
			height = lan.L(Object.values(common.commonKeyDic['teeHeight'])[i]);
			htmllist.push(
				<a key={i+"h"} >
				  <input type="radio" value={height+'mm'} id={"teeheight"+common.Length(height,'0')} data-key={height} name="bottomteeheight"
					onClick={(e) => goSelect(e, 'teeheight', prop.returntype, prop.returnid, prop.returnvalueid)} defaultChecked={Object.values(common.commonKeyDic['teeHeight'])[i] == prop.defaultvalue['teeheight'] ? true : false}/>
				  <label htmlFor={"teeheight"+common.Length(height,'0')}>{height+'mm'}</label>
				</a>
			);
		}
		if(teeheighthtmllist.length == 0)
		{
			setteeheighthtmllist(htmllist);
		}

    },[]);
	
	const goSelect = (e, name, returntype, returnid, returnvalueid) => {
		if(name == 'difficulty')
		{
			if(returntype == 'html')
			{
				$("#"+returnid[name]).html($("input[name=bottomdifficulty]:checked").val());
			}
			$("#"+returnvalueid[name]).val($("input[name=bottomdifficulty]:checked").data("key"));
		}
		else if (name == 'teeboxtype')
		{
			if(returntype == 'html')
			{
				$("#"+returnid[name]).html($("input[name=bottomteeboxtype]:checked").val());
			}
			$("#"+returnvalueid[name]).val($("input[name=bottomteeboxtype]:checked").data("key"));
		}
		else if (name == 'teeheight')
		{
			if(returntype == 'html')
			{
				$("#"+returnid[name]).html($("input[name=bottomteeheight]:checked").val());
			}
			$("#"+returnvalueid[name]).val($("input[name=bottomteeheight]:checked").data("key"));
		}

		slideExit();
	}

	return (
	<div>
	  <div id="difficulty" className="layerView">
		<div className="bottom_sheet">
		  <a onClick={(e) => slideExit()}>close</a>
		  <div className="bs_body">
			<em>{lan.L("난이도")}</em>
			<div className="scroll_container">
			  <div className="list_selection">
				{difficultyhtmllist}
			  </div>
			</div>
		  </div>
		</div>
	  </div>

	  <div id="teeboxtype" className="layerView">
		<div className="bottom_sheet">
		  <a onClick={(e) => slideExit()}>close</a>
		  <div className="bs_body">
			<em>{lan.L("티 위치")}</em>
			<div className="scroll_container">
			  <div className="list_selection">
				{teeboxtypehtmllist}
			  </div>
			</div>
		  </div>
		</div>
	  </div>

	  <div id="teeheight" className="layerView">
		<div className="bottom_sheet">
		  <a onClick={(e) => slideExit()}>close</a>
		  <div className="bs_body">
			<em>{lan.L("티 높이")}</em>
			<div className="scroll_container">
			  <div className="list_selection">
				{teeheighthtmllist}
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	</div>

	)
}