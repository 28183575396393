import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';

var navigate;

function popchoiceExit(){
    $("html").removeClass("overflowHidden");
    $("#pop_choice_layer").removeClass("on");
    
    window.localStorage.setItem('choicenavigate','');
    window.localStorage.setItem('choice_joinback','');
    window.localStorage.setItem('choice_exit','');
}

function goExit(){
    
	if(window.localStorage.getItem('choicenavigate') !== undefined && window.localStorage.getItem('choicenavigate') != null && window.localStorage.getItem('choicenavigate') != '')
    {
        if(window.localStorage.getItem('choicenavigate') == '-1')
        {
            navigate(-1);
        }
        else
        {
            navigate(window.localStorage.getItem('choicenavigate'));
        }
        window.localStorage.setItem('choicenavigate','');
    }

    //회원가입 뒤로가기
	if(window.localStorage.getItem('choice_joinback') !== undefined && window.localStorage.getItem('choice_joinback') != null && window.localStorage.getItem('choice_joinback') != '')
    {
        window.localStorage.setItem('join_agree01',false);
        window.localStorage.setItem('join_agree02',false);
        window.localStorage.setItem('join_agree03',false);
        window.localStorage.setItem('join_agree04',false);

        window.localStorage.setItem('choice_joinback','');
    }

    //회원가입 뒤로가기
	if(window.localStorage.getItem('choice_exit') !== undefined && window.localStorage.getItem('choice_exit') != null && window.localStorage.getItem('choice_exit') != '')
    {
        //종료
        if(common.isIOS()){
            common.iOSCallApp({appid:"finish", params:''});
        }
        else
        {
            try{common.andriodCallApp('finish','');}catch (ex){window.close()}
        }
        window.localStorage.setItem('choice_exit','');
    }
    
    $("html").removeClass("overflowHidden");
    $("#pop_choice_layer").removeClass("on");
}

export default function PopupChoice(){
    navigate = useNavigate();

	return (
    <>
    <div id="pop_choice_layer" className="layerView">
        <div className="layer_body">
            <div className="popup_container">
            <em id="pop_choice_title"></em>
            <div className="alert_content" id="pop_choice_text"></div>
        </div>
        <div className="popup_button">
            <button type="button" id="popChoiceBtnCancel" className="btn_cancel" onClick={popchoiceExit}>{lan.L('취소')}</button>
            <button type="button" id="popChoiceBtn" className="btn_standard" onClick={goExit}>{lan.L('확인')}</button>
            </div>
        </div>
    </div>
    </>
	)
}