import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import * as common from '../common/script/common';
import $ from 'jquery';

var navigate;
var myinfoJson = {};


export default function ModifyAddress() {

	const location = useLocation();
	navigate = useNavigate();
	
	//화면 이동 시
	useEffect(() => {
		myinfoJson = {
			"nickname": sessionStorage.getItem('nickname'),
			"mobilePhoneCountry": "KR",
			"mobilePhone": sessionStorage.getItem('mobilePhone'),
			"email": sessionStorage.getItem('email'),
			"gender": sessionStorage.getItem('gender'),
			"birthday": sessionStorage.getItem('birthday'),
			"cdn": sessionStorage.getItem('cdn'),
			"isTermsAgreeNotificationSMS": sessionStorage.getItem('isTermsAgreeNotificationSMS'),
			"country": sessionStorage.getItem('country'),
			"zipcode": sessionStorage.getItem('zipcode'),
			"province": sessionStorage.getItem('province'),
			"city": sessionStorage.getItem('city'),
			"address1": sessionStorage.getItem('address1'),
			"address2": sessionStorage.getItem('address2')
		}
	},[location]);
	
	var zipcode = sessionStorage.getItem('zipcode') == 'null' ? '' : sessionStorage.getItem('zipcode');
	var province = sessionStorage.getItem('province') == 'null' ? '' : sessionStorage.getItem('province');
	var city = sessionStorage.getItem('city') == 'null' ? '' : sessionStorage.getItem('city');
	var address1 = sessionStorage.getItem('address1') == 'null' ? '' : sessionStorage.getItem('address1');
	var address2 = sessionStorage.getItem('address2') == 'null' ? '' : sessionStorage.getItem('address2');

	if (window.localStorage.getItem('modify_zipcode') != '')
	{
		zipcode = window.localStorage.getItem('modify_zipcode');
		address1 = window.localStorage.getItem('modify_address1');
	}

	checkAllPossible(zipcode,province,city,address1,address2);

	const onChange = (e) => {
		address2 = e.target.value;
	}
	
		// 팝업창 열기
    const openPostCode = () => {
        navigate('/popup/popup_daumpost', {state: 'modify'});
    }

	//국가 코드 필요, 한국일때 별도 필요
	if(sessionStorage.getItem('Language') == 'ko')
	{
		return (
		<>
		<div>
		  <div className="container">
			<div className="title">{lan.L('주소 변경')}</div>
			<ul className="write_form group_field">
			  <li>
				<span>
				  <input type="text" maxLength="50" placeholder={lan.L("우편번호")} id="zipcode" defaultValue={zipcode} disabled />
				  <em>{lan.L('주소')}</em>
				</span>
				<button type="button" onClick={openPostCode}>{lan.L('주소검색')}</button>
			  </li>
			  <li><input type="text" maxLength="50" placeholder={lan.L("기본 주소")} id="address1" defaultValue={address1} disabled/></li>
			  <li><input type="text" maxLength="50" placeholder={lan.L("나머지 주소")} id="address2" defaultValue={address2} /></li>
			</ul>
		  </div>{/* container(e) */}
		  <div className="sticky_button">
			<button type="button" className="btn_standard" id="btnModify" onClick={(e)=>btnModifyAddress($("#zipcode").val(),'','',$("#address1").val(),$("#address2").val())}>{lan.L('수정하기')}</button>
		  </div>
		</div>
		</>
		)
	}
	else
	{
		return (
		<>
		<div>
		  <div className="container">
			<div className="title">{lan.L('주소 변경')}</div>
			<ul className="write_form group_field">
			  <li><input type="text" maxLength="50" placeholder={lan.L("Enter Zip Code")} id="zipcode" defaultValue={zipcode}/></li>
			  <li><input type="text" maxLength="50" placeholder={lan.L("Enter Province/City")} id="province" defaultValue={province}/></li>
			  <li><input type="text" maxLength="50" placeholder={lan.L("Enter City/Country")} id="city" defaultValue={city}/></li>
			  <li><input type="text" maxLength="50" placeholder={lan.L("Enter Address")} id="address1" defaultValue={address1}/></li>
			  <li><input type="text" maxLength="50" placeholder={lan.L("Enter Detail Address")} id="address2" defaultValue={address2} /></li>
			</ul>
		  </div>{/* container(e) */}
		  <div className="sticky_button">
			<button type="button" className="btn_standard" id="btnModify" onClick={(e)=>btnModifyAddress($("#zipcode").val(),sessionStorage.getItem('Language') == 'ko' ? '':$("#province").val(),sessionStorage.getItem('Language') == 'KR' ? '':$("#city").val(),
			  $("#address1").val(),$("#address2").val())}>{lan.L('수정하기')}</button>
		  </div>
		</div>
		</>
		)
	}
}

function btnModifyAddress(zipcode,province,city,address1,address2) {

	if (zipcode == ''){
			common.alertpop(lan.L('기본정보 수정'),lan.L('주소를 입력해주세요.'));
			return false;
	}

	//if(address2 == '') address2 = ''

	myinfoJson.zipcode = zipcode;
	myinfoJson.province = province;
	myinfoJson.city = city;
	myinfoJson.address1 = address1;
	myinfoJson.address2 = address2;

	console.log(myinfoJson);

	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: myinfoJson,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('회원 정보 수정'),
			'guid' : sessionStorage.getItem('guid'),
			'token' : sessionStorage.getItem('access_token')
		}
	}).then(function (response) {
		var data = response.data;
		console.log(response);

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			common.alertByError('기본정보 수정',response.data.error.code);
			return false;
		}

		sessionStorage.setItem('zipcode', myinfoJson.zipcode);
		sessionStorage.setItem('province', myinfoJson.province);
		sessionStorage.setItem('city', myinfoJson.city);
		sessionStorage.setItem('address1', myinfoJson.address1);
		sessionStorage.setItem('address2', myinfoJson.address2);

		common.alertpop(lan.L('기본정보 수정'),lan.L('수정되었습니다.'));
		navigate(-1);
	}).catch(error => {
		//console.log(error.response)
	});
}

function checkAllPossible(zipcode,province,city,address1,address2) {
	$("#btnModify").attr('disabled',true);
	if(sessionStorage.getItem('Language') == 'ko')
	{
		if(zipcode != '' && address1 != '')
		{
				$("#btnModify").attr('disabled',false);
		}
		else
		{
				$("#btnModify").attr('disabled',true);
		}
	}
	else
	{
		if(zipcode != '' && province != '' && city != '' && address1 != '')
		{
				$("#btnModify").attr('disabled',false);
		}
		else
		{
				$("#btnModify").attr('disabled',true);
		}
	}
}
