
import { useLocation, useNavigate } from "react-router-dom";
import React, {useState, useEffect} from 'react';

export default function BackNavigation(){
	const location = useLocation();
	const navigate = useNavigate();

	//최초실행
	useEffect(() => {
		navigate(location.state.back, {state : location.state, replace : true});
	},[location]);

	return(
		<>
		</>
	)
}