import { useSelector, combineReducers } from "redux";
import * as common from '../common/script/common.js';

//statistc.js에 하단팝업 선택값
const statisticReducer = (state = {stroke_recentGameRoundCount : 5, 
    tournament_recentGameRoundCount : 5, 
    drivingRangeClub:0, 
    approachDistance:18,
    straightPuttingDistance:4}, action) => {
        switch (action.type) {
          case 'tournament_recentGameRoundCount':
            return {
              ...state,
              tournament_recentGameRoundCount: action.value
            };
          case 'stroke_recentGameRoundCount':
            return {
              ...state,
              stroke_recentGameRoundCount: action.value
            };
            case 'drivingRangeClub':
              return {
                ...state,
                drivingRangeClub: action.value
              };
            case 'approachDistance':
              return {
                ...state,
                approachDistance: action.value
              };
          case 'straightPuttingDistance':
            return {
              ...state,
              straightPuttingDistance: action.value
            };
          case 'initstate':
            return {
              stroke_recentGameRoundCount: 5,
              tournament_recentGameRoundCount: 5,
              drivingRangeClub: 0,
              approachDistance: 18,
              straightPuttingDistance: 4
            };
          default:
              return state;
        }
    };
    
//statistc.js에 하단팝업 선택값
const gameReducer = (state = {tournamentTypeCode : 'all', tournamentStatus : 0, mytournamentTypeCode : 'all', mytournamentStatus : 0}, action) => {
      switch (action.type) {
        case 'tournamentTypeCode':
          return {
            ...state,
            tournamentTypeCode: action.value
          };
        case 'tournamentStatus':
          return {
            ...state,
            tournamentStatus: action.value
          };
          case 'mytournamentTypeCode':
            return {
              ...state,
              mytournamentTypeCode: action.value
            };
          case 'mytournamentStatus':
            return {
              ...state,
              mytournamentStatus: action.value
            };
        default:
            return state;
      }
  };
  
//detail_match.js의 하단 bottom_matchrank.js 선택값
const matchRankReducer = (state = {match_recordType : '', match_courseNum : 0, match_awardNum : 'all'}, action) => {
  switch (action.type) {
    case 'match_recordType':
      return {
        ...state,
        match_recordType: action.value
      };
    case 'match_courseNum':
      return {
        ...state,
        match_courseNum: action.value
      };
      case 'match_initstate':
      return {
        match_recordType : '',
        match_courseNum : 0,
        match_awardNum : 'all'
      };
      case 'match_awardNum':
      return {
        ...state,
        match_awardNum: action.value
      };
    default:
        return state;
  }
};

export const rootReducer = combineReducers({
    statisticReducer,
    gameReducer,
    matchRankReducer
});