import * as lan from '../common/script/common_lan.js';


import LinkButton from '../common/component/linkButton';
import { Link, useNavigate, useLocation } from "react-router-dom";
import React, {useState, useEffect} from 'react';

export default function Agreement() {
	const location = useLocation();
	const navigate = useNavigate();

	// 체크된 아이템을 담을 배열
	const [agree01, setagree01] = useState(false);
	const [agree02, setagree02] = useState(false);
	const [agree03, setagree03] = useState(false);
	const [agree04, setagree04] = useState(false);
	
	//화면전환시 실행
	useEffect(() => {
		if(window.localStorage.getItem('join_agree01') === null) window.localStorage.setItem('join_agree01',false);
		if(window.localStorage.getItem('join_agree02') === null) window.localStorage.setItem('join_agree02',false);
		if(window.localStorage.getItem('join_agree03') === null) window.localStorage.setItem('join_agree03',false);
		if(window.localStorage.getItem('join_agree04') === null) window.localStorage.setItem('join_agree04',false);
		setagree01(window.localStorage.getItem('join_agree01'));
		setagree02(window.localStorage.getItem('join_agree02'));
		setagree03(window.localStorage.getItem('join_agree03'));
		setagree04(window.localStorage.getItem('join_agree04'));

    },[location]);

	// 체크박스 단일 선택
	const handleSingleCheck = (checked, key) => {
		if (checked) {
			if(key == 1) window.localStorage.setItem('join_agree01',true);
			else if(key == 2) window.localStorage.setItem('join_agree02',true);
			else if(key == 3) window.localStorage.setItem('join_agree03',true);
			else if(key == 4) window.localStorage.setItem('join_agree04',true);
			navigate('/join/agreement',{replace:true});
		} else {
			if(key == 1) window.localStorage.setItem('join_agree01',false);
			else if(key == 2) window.localStorage.setItem('join_agree02',false);
			else if(key == 3) window.localStorage.setItem('join_agree03',false);
			else if(key == 4) window.localStorage.setItem('join_agree04',false);
			navigate('/join/agreement',{replace:true});
		}
	};

	// 체크박스 전체 선택
	const handleAllCheck = (checked) => {
		if(checked) {
			window.localStorage.setItem('join_agree01',true);
			window.localStorage.setItem('join_agree02',true);
			window.localStorage.setItem('join_agree03',true);
			window.localStorage.setItem('join_agree04',true);
			navigate('/join/agreement',{replace:true});
		}
		else {
			window.localStorage.setItem('join_agree01',false);
			window.localStorage.setItem('join_agree02',false);
			window.localStorage.setItem('join_agree03',false);
			window.localStorage.setItem('join_agree04',false);
			navigate('/join/agreement',{replace:true});
		}
	}
	
	// 체크박스 단일 선택
	const goRegisterId = (e) => {

		window.localStorage.setItem('join_loginID','');
		window.localStorage.setItem('join_loginPass','');
		window.localStorage.setItem('join_loginPass2','');
		navigate("/join/register_id");
	}

	var threeCheck = window.localStorage.getItem('join_agree01') == 'true' && window.localStorage.getItem('join_agree02') == 'true' && window.localStorage.getItem('join_agree03') == 'true'
	var allCheck = window.localStorage.getItem('join_agree01') == 'true' && window.localStorage.getItem('join_agree02') == 'true' && window.localStorage.getItem('join_agree03') == 'true' && window.localStorage.getItem('join_agree04') == 'true'
	return (
	<div>
	  <div className="container">
		<div className="process">
		  <span><b>1</b>/5</span>
		  <em>{lan.L('약관동의')}</em>
		</div>
		<div className="agreement">
		  <a>
			<input type="checkbox" id="whole"   onChange={(e) => handleAllCheck(e.target.checked)} checked={allCheck} name="agreement"/>
			<label htmlFor="whole">{lan.L('전체동의')}</label>
		  </a>
		  <ul>
			<li>
			  <input type="checkbox" id="agree01" onChange={(e) => handleSingleCheck(e.target.checked, 1)} checked={agree01 == 'true' ? true : false} name="agreement" />
			  <Link to="/terms/agreement01" className="new-line">{lan.L('(필수) 회원 이용약관')}</Link>
			</li>
			<li>
			  <input type="checkbox" id="agree02" onChange={(e) => handleSingleCheck(e.target.checked, 2)} checked={agree02 == 'true' ? true : false} name="agreement" />
			  <Link to="/terms/agreement02" className="new-line">{lan.L('(필수) 회원 개인정보 보호정책')}</Link>
			</li>
			<li>
			  <input type="checkbox" id="agree03" onChange={(e) => handleSingleCheck(e.target.checked, 3)} checked={agree03 == 'true' ? true : false} name="agreement" />
			  <Link to="/terms/agreement03" className="new-line">{lan.L('(필수) 제3자 정보제공 및 마케팅 수신 동의')}</Link>
			</li>
			<li>
			  <input type="checkbox" id="agree04" onChange={(e) => handleSingleCheck(e.target.checked, 4)} checked={agree04 == 'true' ? true : false} name="agreement" />
			  <Link to="/terms/agreement04" className="new-line">{lan.L('(선택) 위치 기반 서비스 이용약관')}</Link>
			</li>
		  </ul>
		</div>
	  </div>
	  <div className="sticky_button">
		<button type="button" className="btn_standard" disabled={!threeCheck} onClick={goRegisterId}>{lan.L('다음')}</button>
	  </div>
	</div>
	)
}


