import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import LinkButton from '../common/component/linkButton';
import axios from 'axios';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';

var clickCnt = 0;

var create_dot_arr = [];
var ctx;
var canvas
var context
var tempnowplayer;

export default function DetailStroke(){

	const [InfoJson, SetInfoJson] = useState({});
	const [StaticJson, SetStaticJson] = useState({});
	const [ProfileImg, SetProfileImg] = useState('');
	const [InfoCourseMap, SetInfoCourseMap] = useState({});
	const [nowMap, SetnowMap] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();
	var data = [];
    var tempinfojson;
    var myplayernum = 0;
	const [myplayernumState, setmyplayernumState] = useState(-1);
	const loginID = sessionStorage.getItem('loginID');
    
	const [coursehalfhtmllist, setcoursehalfhtmllist] = useState([]);
	const [coursefullhtmllist, setcoursefullhtmllist] = useState([]);
	const [nowplayer, setnowplayer] = useState(0);
    var coursehalfdata = [];
    var coursefulldata = [];

    var halflcoursettotal = 0;
    var fullcoursettotal = 0;
    
	const [playerhalfhtmllist, setplayerhalfhtmllist] = useState([]);
	const [playerfullhtmllist, setplayerfullhtmllist] = useState([]);
    
	const [playerhalfhtmllist2, setplayerhalfhtmllist2] = useState([]);
	const [playerfullhtmllist2, setplayerfullhtmllist2] = useState([]);
    var playerhalfdata = [];
    var playerfulldata = [];
    
    var playershotList = [];
    var ShotDataSend = {};
	const [ShotDataSendState, setShotDataSendState] = useState(-1);
    var playershotCount = [];
	const [playershotListhtml, setplayershotListhtml] = useState([]);
    
    var halflplayertotal = 0;
    var fullplayertotal = 0;
	var html, playerhalfhtml, playerfullhtml, playerhalfhtml2, playerfullhtml2;
    var count = 0;
    var pscore, difscore;

    var strokeInfo;

    const half = common.commonKeyDic['coursesize']['half'];
    const full = common.commonKeyDic['coursesize']['full'];

    //console.log(location.state.roundData);

    var gameRoundId = location.state.roundData.gameRoundId;
    if(location.state.strokeInfo !== undefined) strokeInfo = location.state.strokeInfo;
    console.log(location.state);
    
	
	useEffect(() => {

		//회원정보조회
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {ScorecardType : 0},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			    'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('스코어카드 조회'),
				'guid' : gameRoundId,
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
			console.log(response);
			data = response.data;
            tempinfojson = response.data;
            
            for(let i=0;i<tempinfojson.scorecard[0].players.length;i++)
            {
                if(sessionStorage.getItem('guid') == tempinfojson.scorecard[0].players[i].userGuid)
                {
                    myplayernum = i;
                    setmyplayernumState(i);
                }
            }

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError('스트로크 라운드 기록',response.data.error.code);
				return false;
			}
			else
			{
                $("#record_background").css({'background-image': 'url('+response.data.cdn+response.data.scorecard[0].gameRoundInfo.courseInfo.intro+')'});
                SetInfoCourseMap(response.data.scorecard[0].gameRoundInfo.courseInfo.courseMapCoordinates);
                SetInfoJson(data);
                
                //하프 코스
				html = [];
				for (let i = 0;i < half; i++)
				{
					html.push(
                        <td key={"coursehalf"+i}>{response.data.scorecard[0].gameRoundInfo.courseInfo.coursePar[i]}</td>
					)
                    halflcoursettotal = halflcoursettotal + response.data.scorecard[0].gameRoundInfo.courseInfo.coursePar[i];
                    coursehalfdata.push(response.data.scorecard[0].gameRoundInfo.courseInfo.coursePar[i]);
				}

                html.push(
                    <td key={"coursehalftotal"}>{halflcoursettotal}</td>
                )

				setcoursehalfhtmllist(html);

                //남은 코스
				html = [];
				for (let i = half;i < full; i++)
				{
					html.push( <td key={"coursefull"+i}>{response.data.scorecard[0].gameRoundInfo.courseInfo.coursePar[i]}</td>)
                    fullcoursettotal = fullcoursettotal + response.data.scorecard[0].gameRoundInfo.courseInfo.coursePar[i];
                    coursefulldata.push(response.data.scorecard[0].gameRoundInfo.courseInfo.coursePar[i]);
				}

                html.push(
                    <td key={"coursefulltotal"}>{fullcoursettotal}</td>
                )
                
				setcoursefullhtmllist(html);

                playerhalfhtml=[];
                playerfullhtml = [];
                
                playerhalfhtml2=[];
                playerfullhtml2 = [];
                
                for (let p = 0;p < response.data.scorecard[0].players.length; p++)
                {

                    halflplayertotal=0;
                    fullplayertotal=0;
                    
                    playerhalfhtml.push([]);
                    playerhalfhtml2.push([]);

                    //플레이어 하프 코스
                    for (let i = 0;i < half; i++)
                    {
                        pscore = response.data.scorecard[0].players[p].holes[i].score;
                        difscore = pscore;
                        if(difscore == null || isNaN(difscore)) difscore = '-';

                        if(difscore < -1)
                        {
                            playerhalfhtml[p].push(<td key={"playerhalf"+i} id={"pscore"+i+"_"+p} onClick={(e)=>goMapSelect(e,i,p)} className={i==0 ? "pscore active":"pscore"}><b className="birdie_above">{difscore}</b></td>);
                            playerhalfhtml2[p].push(<td key={"playerhalf"+i} ><b className="birdie_above">{difscore}</b></td>);
                            }
                        else if(difscore < 0)
                        {
                            playerhalfhtml[p].push(<td key={"playerhalf"+i} id={"pscore"+i+"_"+p} onClick={(e)=>goMapSelect(e,i,p)} className={i==0 ? "pscore active":"pscore"}><b className="birdie">{difscore}</b></td>);
                            playerhalfhtml2[p].push(<td key={"playerhalf"+i} ><b className="birdie">{difscore}</b></td>);
                        }
                        else if(difscore > 1)
                        {
                            playerhalfhtml[p].push(<td key={"playerhalf"+i} id={"pscore"+i+"_"+p} onClick={(e)=>goMapSelect(e,i,p)} className={i==0 ? "pscore active":"pscore"}><b className="bogey_above">{difscore}</b></td>);
                            playerhalfhtml2[p].push(<td key={"playerhalf"+i} ><b className="bogey_above">{difscore}</b></td>);
                        }
                        else if(difscore > 0)
                        {
                            playerhalfhtml[p].push(<td key={"playerhalf"+i} id={"pscore"+i+"_"+p} onClick={(e)=>goMapSelect(e,i,p)} className={i==0 ? "pscore active":"pscore"}><b className="bogey">{difscore}</b></td>);
                            playerhalfhtml2[p].push(<td key={"playerhalf"+i} ><b className="bogey">{difscore}</b></td>);
                        }
                        else
                        {
                            playerhalfhtml[p].push(<td key={"playerhalf"+i} id={"pscore"+i+"_"+p} onClick={(e)=>goMapSelect(e,i,p)} className={i==0 ? "pscore active":"pscore"}><b>{difscore}</b></td>);
                            playerhalfhtml2[p].push(<td key={"playerhalf"+i} ><b>{difscore}</b></td>);
                        }

                        if(difscore != '-') halflplayertotal = halflplayertotal + difscore;
                        playerhalfdata.push(pscore);
                    }

                    playerhalfhtml[p].push(
                        <td key={"playerhalftotal"}><b>{halflcoursettotal + halflplayertotal}</b></td>
                    )
                    
                    playerhalfhtml2[p].push(
                        <td key={"playerhalftotal"}><b>{halflcoursettotal + halflplayertotal}</b></td>
                    )

                    playerfullhtml.push([]);
                    playerfullhtml2.push([]);

                    //플레이어 하프 코스
                    for (let i = half;i < full; i++)
                    {
                        pscore = response.data.scorecard[0].players[p].holes[i].score;
                        difscore = pscore;
                        if(difscore == null || isNaN(difscore)) difscore = '-';

                        if(difscore < -1)
                        {
                            playerfullhtml[p].push(<td key={"playerfull"+i} id={"pscore"+i+"_"+p} onClick={(e)=>goMapSelect(e,i,p)} className="pscore"><b className="birdie_above">{difscore}</b></td>);
                            playerfullhtml2[p].push(<td key={"playerfull"+i}><b className="birdie_above">{difscore}</b></td>);
                        }
                        else if(difscore < 0)
                        {
                            playerfullhtml[p].push(<td key={"playerfull"+i} id={"pscore"+i+"_"+p} onClick={(e)=>goMapSelect(e,i,p)} className="pscore"><b className="birdie">{difscore}</b></td>);
                            playerfullhtml2[p].push(<td key={"playerfull"+i}><b className="birdie">{difscore}</b></td>);
                        }
                        else if(difscore > 1)
                        {
                            playerfullhtml[p].push(<td key={"playerfull"+i} id={"pscore"+i+"_"+p} onClick={(e)=>goMapSelect(e,i,p)} className="pscore"><b className="bogey_above">{difscore}</b></td>);
                            playerfullhtml2[p].push(<td key={"playerfull"+i}><b className="bogey_above">{difscore}</b></td>);
                        }
                        else if(difscore > 0)
                        {
                            playerfullhtml[p].push(<td key={"playerfull"+i} id={"pscore"+i+"_"+p} onClick={(e)=>goMapSelect(e,i,p)} className="pscore"><b className="bogey">{difscore}</b></td>);
                            playerfullhtml2[p].push(<td key={"playerfull"+i}><b className="bogey">{difscore}</b></td>);
                        }
                        else
                        {
                            playerfullhtml[p].push(<td key={"playerfull"+i} id={"pscore"+i+"_"+p} onClick={(e)=>goMapSelect(e,i,p)} className="pscore"><b>{difscore}</b></td>);
                            playerfullhtml2[p].push(<td key={"playerfull"+i} className="pscore"><b>{difscore}</b></td>);
                        }
                            
                        if(difscore != '-') fullplayertotal = fullplayertotal + difscore;
                        playerfulldata.push(pscore);
                    }

                    playerfullhtml[p].push(
                        <td key={"playerfulltotal"}><b>{fullcoursettotal + fullplayertotal}</b></td>
                    )
                    
                    playerfullhtml2[p].push(
                        <td key={"playerfulltotal"}><b>{fullcoursettotal + fullplayertotal}</b></td>
                    )
                }

                setplayerhalfhtmllist(playerhalfhtml);
                setplayerfullhtmllist(playerfullhtml);

                setplayerhalfhtmllist2(playerhalfhtml2);
                setplayerfullhtmllist2(playerfullhtml2);

                setShotVideoLink();

				let gomapinterval = setInterval(function(){
                    
                    if(document.getElementById('mapImage') != null)
                    {
                        clearInterval(gomapinterval);
                    }

                    if(strokeInfo === undefined) //뒤로 돌아오지 않았다면 myplayer
                    {
                        goScoreTab(myplayernum, true);
                        goMapSelect(null,0,myplayernum);
                    }
                    else
                    {
                        goScoreTab(strokeInfo.player, true);
                        goMapSelect(null,strokeInfo.shotnum,strokeInfo.player);
                    }
                }, 150)
			}
		}).catch(error => {
			console.log(error.response)
		});
        
        //게임 통계 데이터

        axios({
            method: 'post',
            url: process.env.REACT_APP_SERVER + '/api',
            data: {},
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'language':sessionStorage.getItem('Language'),
                'pUrl' : encodeURIComponent('게임 라운드 통계 데이터 조회'),
                'guid' : gameRoundId,
                'token' : sessionStorage.getItem('access_token')
            }
        }).then(function (response) {
            var data = response.data;
            console.log(response);

            if(response.data.error !== undefined && response.data.error.code > 0)
            {
                //common.alertByError('스트로크 라운드 기록',response.data.error.code);
                return false;
            }
            else
            {
                SetStaticJson(data);
            }
            
        }).catch(error => {
            //console.log(error.response)
        });
	},[location]);
    
	const goScoreTab = (i, isfirst) => {
        if($("#"+i+"scoretab").hasClass("on") && !isfirst) //이미 켜져있으면 닫기
        {
            $(".scoretab").removeClass("on");
            $("#"+i+"scoretabaside").hide();
        }
        else
        {
            $(".scoretab").removeClass("on");
            $("#"+i+"scoretab").addClass("on");
            $("#"+i+"scoretabaside").show();
            setnowplayer(i);
            tempnowplayer = i;
    
            goMapSelect(null,0,i);
        }
	}
    
	const goMapSelect = (e, num, player) => {
        
        //set canvas
        canvas = document.getElementById('mapImage');

        if(data.cdn === undefined)
        {
            data = InfoJson;
        }
    
        $("#mapImage").css({'background': "url("+data.cdn + "images/course/" + location.state.roundData.courseId + "/hole_"+('0'+(Number(num)+1)).slice(-2)+".png) no-repeat" ,
        'background-size' : '100% 100%','width':'80vw', 'height':'125.44vw'});
        SetnowMap(num);
        $(".pscore").removeClass("active");
        $("#pscore"+num+"_"+player).addClass("active");

        var shotCoordinates = data.scorecard[0].players[player].holes[num].shotCoordinates;

		if (canvas.getContext) {
			ctx = canvas.getContext("2d");
		}

        clearCanvas();

        for(let i=0;i<shotCoordinates.length;i++)
        {
            setShot(shotCoordinates[i].startCoordinates.x, shotCoordinates[i].startCoordinates.y,num);
            setShot(shotCoordinates[i].endCoordinates.x, shotCoordinates[i].endCoordinates.y,num);
        }

        goShotVideoLink(num);
	}
    

	const goShotVideoLink = (shotnum) => {

        var temphtml = [];

        if(myplayernumState != -1)
        {
            myplayernum = myplayernumState;
        }

        console.log(playershotCount);
        for(let i=0;i<data.scorecard[0].players[tempnowplayer].holes[shotnum].shotCoordinates.length;i++)
        {
            var isView = data.scorecard[0].players[tempnowplayer].holes[shotnum].shotCoordinates[i].isView;
            if(isView || tempnowplayer == myplayernum)
            {
                temphtml.push(<a className="on" onClick={(e)=>goMenuShot(tempnowplayer, shotnum, i)}><strong>{common.getSequenceFormat(i+1)}</strong>Shot</a>);
            }
        }
        
        setplayershotListhtml(temphtml);
	}
    
	const setShotVideoLink = () => {
        
        //게임 라운드 샷 목록 조회
        axios({
            method: 'post',
            url: process.env.REACT_APP_SERVER + '/api',
            data: {},
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'language':sessionStorage.getItem('Language'),
                'pUrl' : encodeURIComponent('게임 라운드 샷 목록 조회'),
                'guid' : gameRoundId,
                'token' : sessionStorage.getItem('access_token'),
            }
        }).then(function (response) {
            console.log(response.data);

            ShotDataSend = response.data;
            setShotDataSendState(response.data);

            playershotList = [];
            playershotCount = [];
            var tempcount = 0;
            var tempholenum = 0;
           
            for (let i = 0;i < response.data.shotList.length; i++)
            {
                if(tempholenum == response.data.shotList[i].holeNumber)
                {
                    playershotList[playershotList.length-1].push(response.data.shotList[i]);
                    tempcount += 1;
                }
                else
                {
                    playershotList.push([]);
                    playershotCount.push(tempcount);
                    playershotList[playershotList.length-1].push(response.data.shotList[i]);
                    tempholenum = tempholenum + 1;
                    tempcount = 1;
                }
            }
            console.log(playershotCount);
        }).catch(error => {
            console.log(error)
        });    
}

	const goScoreCardAll = () => {
		$("html").addClass("overflowHidden");
		$("#stroke").addClass("on");
	}
    
	const goScoreCardClose = () => {
		$("html").removeClass("overflowHidden");
		$(".layerView").removeClass("on");
	}
    
	const goBottomTab = (e,i) => {
        $(".bottomtab").removeClass("active");
		$(e.target).addClass("active");

        $("#bottompanel0").hide();
        $("#bottompanel1").hide();

        $("#bottompanel"+i).show();
	}
    
function goMenuShot(player, shotnum, i) {
    var gameRoundShotId = data.scorecard[0].players[player].holes[shotnum].shotCoordinates[i].gameRoundShotId;
    var coursePar = data.scorecard[0].gameRoundInfo.courseInfo.coursePar[shotnum];
    var playernickname = data.scorecard[0].players[player].nickname;

    var temptotal = 0;
    for(let i=0;i<playershotCount.length;i++)
    {
        if(i <= shotnum)
        {
            temptotal += playershotCount[i];
        }
    }
    var tempShotDataSend
    if(ShotDataSend.shotList == undefined)
    {
        tempShotDataSend = ShotDataSendState
    }
    else
    {
        tempShotDataSend = ShotDataSend
    }

	navigate('/shot/detail_video', 
    {state : {backTabTopics : '0', 
                    gameRoundId : location.state.roundData.gameRoundId, 
                    gameRoundShotId : gameRoundShotId, 
                    roundData : location.state.roundData, 
                    shotData : tempShotDataSend.shotList[temptotal],
                    playernickname : playernickname,
                    shotData : tempShotDataSend.shotList[temptotal],
                    strokeInfo : {player : player, shotnum : shotnum, shotdetailnum : i},
                    coursePar : coursePar,
                    cdn : data.cdn},
    replace:true});
}

	return (
  <>
  <div id="stroke" className="layerView">
  <div className="bottom_sheet">
    <a onClick={goScoreCardClose}>close</a>
    <div className="bs_body">
      <em>{lan.L('스코어카드 전체보기')}</em>
      <div className="scroll_container">
        <div className="mem_stroke">
        {InfoJson.scorecard === undefined || InfoJson.scorecard[0].players.length == 0 ? '' :
            InfoJson.scorecard[0].players.map((url, i) => (
          <div className="user_record">
            <span>
              <p style={{backgroundImage: 'url('+InfoJson.cdn+InfoJson.scorecard[0].players[i].profileImage+')'}}>photo</p>
              <em>{InfoJson.scorecard[0].players[i].nickname}</em>
            </span>
            <strong>{InfoJson.scorecard[0].players[i].totalScore}</strong>
          </div>
            ))}
        </div>
        <table className="score_chart">
          <colgroup>
            <col />
            <col style={{width: '7%'}} />
            <col style={{width: '7%'}} />
            <col style={{width: '7%'}} />
            <col style={{width: '7%'}} />
            <col style={{width: '7%'}} />
            <col style={{width: '7%'}} />
            <col style={{width: '7%'}} />
            <col style={{width: '7%'}} />
            <col style={{width: '7%'}} />
            <col style={{width: '10%'}} />
          </colgroup>
          <tbody><tr>
              <th rowSpan={2}>{lan.L('Player')}</th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
              <th>7</th>
              <th>8</th>
              <th>9</th>
              <th>{lan.L('Total')}</th>
            </tr>
            <tr className="standard">
            {InfoJson.scorecard !== undefined ? coursehalfhtmllist : ""}
            </tr>
            {InfoJson.scorecard === undefined || InfoJson.scorecard[0].players.length == 0 ? '' :
                InfoJson.scorecard[0].players.map((url, i) => (
                    <tr>
                    <td><strong>{InfoJson.scorecard[0].players[i].nickname}</strong></td>
                    {playerhalfhtmllist2[i]}
                  </tr>
                ))}
            <tr>
              <th rowSpan={2}>Player</th>
              <th>10</th>
              <th>11</th>
              <th>12</th>
              <th>13</th>
              <th>14</th>
              <th>15</th>
              <th>16</th>
              <th>17</th>
              <th>18</th>
              <th>{lan.L('Total')}</th>
            </tr>
            <tr className="standard">
            {InfoJson.scorecard !== undefined ? coursefullhtmllist : ""}
            </tr>
            {InfoJson.scorecard === undefined || InfoJson.scorecard[0].players.length == 0 ? '' :
                InfoJson.scorecard[0].players.map((url, i) => (
                    <tr>
                    <td><strong>{InfoJson.scorecard[0].players[i].nickname}</strong></td>
                    {playerfullhtmllist2[i]}
                  </tr>
                ))}
          </tbody></table>
      </div>
    </div>
    <div className="bottom_sheet_button">
      <button type="button" className="btn_standard" onClick={goScoreCardClose}>{lan.L('확인')}</button>
    </div>
  </div>
</div>

    <div className="contents">
        <div className="record_round" id="record_background">
        
        <div className="field">
            <span>{common.UnixTime(location.state.roundData.startTime,'년.월.일')}</span>
            <em>{location.state.roundData.courseName}</em>
            <span>
            <small>{location.state.roundData.shopName}</small>
            <small>{lan.L('스트로크')}</small>
            </span>
            <span>
            <small>{InfoJson.scorecard !== undefined ? 
            common.commonKeyDic['difficulty'][InfoJson.scorecard[0].players[0].difficulty] : ''}</small>
            </span>
        </div>
        <div className="field_score">
            <small>score</small>
            <b>{location.state.roundData.score}</b>
        </div>
        </div>
        <div className="section_round">
        <ul className="round_grade">
        {InfoJson.scorecard === undefined || InfoJson.scorecard[0].players.length == 0 ? '' :
            InfoJson.scorecard[0].players.map((url, i) => (
                <li>
                <a className={i==0 ? "scoretab on":"scoretab"} id={i+"scoretab"} onClick={(e)=>goScoreTab(i)}>
                    <div className="user_record">
                    <span>
                        <p style={{backgroundImage: 'url('+InfoJson.cdn+InfoJson.scorecard[0].players[i].profileImage+')'}}>photo</p>
                        <em>{InfoJson.scorecard[0].players[i].nickname}</em>
                    </span>
                    <strong>{InfoJson.scorecard[0].players[i].totalScore}</strong>
                    </div>
                </a>
                <aside id={i+"scoretabaside"}>
                    <table className="score_chart">
                    <colgroup>
                        <col style={{width: '10%'}} />
                        <col style={{width: '10%'}} />
                        <col style={{width: '10%'}} />
                        <col style={{width: '10%'}} />
                        <col style={{width: '10%'}} />
                        <col style={{width: '10%'}} />
                        <col style={{width: '10%'}} />
                        <col style={{width: '10%'}} />
                        <col style={{width: '10%'}} />
                        <col style={{width: '10%'}} />
                    </colgroup>
                    <tbody><tr>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                        <th>8</th>
                        <th>9</th>
                        <th>Total</th>
                        </tr>
                        <tr className="standard">
                        {coursehalfhtmllist}
                        </tr>
                        <tr>
                        {playerhalfhtmllist[i]}
                        </tr>
                        <tr>
                        <th>10</th>
                        <th>11</th>
                        <th>12</th>
                        <th>13</th>
                        <th>14</th>
                        <th>15</th>
                        <th>16</th>
                        <th>17</th>
                        <th>18</th>
                        <th>Total</th>
                        </tr>
                        <tr className="standard">
                        {coursefullhtmllist}
                        </tr>
                        <tr>
                        {playerfullhtmllist[i]}
                        </tr>
                    </tbody></table>
                </aside>
                </li>
            ))}
        </ul>
        <button type="button" className="scorecard_all" onClick={goScoreCardAll}><b>{lan.L('스코어카드 전체보기')}</b></button>
        </div>
        <div className="detailed_record">
        <div className="tab_button">
            <button type="button" className="bottomtab active" onClick={(e)=>goBottomTab(e,0)}>{lan.L('미니맵')}</button>
            <button type="button" className="bottomtab" onClick={(e)=>goBottomTab(e,1)}>{lan.L('통계')}</button>
        </div>
        {/*------------------------------------ 미니맵 ------------------------------------*/}
        <div className="trace" id="bottompanel0">
        {InfoJson.scorecard === undefined || InfoJson.scorecard[0].players.length == 0 ? '' :
            <div className="mini_map">
            <em><b>{InfoJson.scorecard === undefined || InfoCourseMap[nowMap] === undefined ? '' : InfoCourseMap[nowMap].holeNumber+lan.L('H')}</b>{lan.L('Par')+InfoJson.scorecard[0].gameRoundInfo.courseInfo.coursePar[nowMap]}</em>
           
        <div className="video_link">
				<span>
					{playershotListhtml}
				</span>
			</div>

            <canvas id="mapImage" width='326px' height='512px' style={{'background': "url("+InfoJson.cdn + "images/course/" + location.state.roundData.courseId + "/hole_"+('0'+(Number(0)+1)).slice(-2)+".png) no-repeat" ,
        'background-size' : '100% 100%','width':'80vw', 'height':'125.44vw'}}></canvas>
            {/*<div className="tracing">
                <div className="draw">
                <span style={{top: '65px', left: '102px', width: '30px', transform: 'rotate(40deg)'}}>line</span>
                <em style={{top: '74px', left: '126px'}}>point</em>
                <span style={{top: '122px', left: '86px', width: '86px', transform: 'rotate(95deg)'}}>line</span>
                <em style={{top: '160px', left: '119px'}}>point</em>
                <span style={{top: '215px', left: '107px', width: '138px', transform: 'rotate(45deg)'}}>line</span>
                <em style={{top: '259px', left: '219px'}}>point</em>
                </div>
            </div>*/}
            </div>}
        </div>
        {/*------------------------------------//미니맵 ------------------------------------*/}
        {/*------------------------------------ 통계 ------------------------------------*/}
        <div className="trace" id="bottompanel1" style={{display: 'none'}}>
            <ol className="info_grade">
            <li>
                <dl>
                <dd>{lan.L('스코어')}</dd>
                <dt>{InfoJson.scorecard === undefined || InfoJson.scorecard[0].players.length == 0 ? '' : InfoJson.scorecard[0].players[nowplayer].totalScore}</dt>
                </dl>
            </li>
            <li>
                <dl>
                <dd>{lan.L('그린적중율')}</dd>
                <dt>{StaticJson.list === undefined || StaticJson.list[nowplayer] == 0 ? '' : StaticJson.list[nowplayer].gir.toFixed(2)+'%'}</dt>
                </dl>
            </li>
            <li>
                <dl>
                <dd>{lan.L('퍼팅수')}</dd>
                <dt>{StaticJson.list === undefined || StaticJson.list[nowplayer] == 0 ? '' : StaticJson.list[nowplayer].totalPuttingCount}</dt>
                </dl>
            </li>
            <li>
                <dl>
                <dd>{lan.L('페어웨이안착율')}</dd>
                <dt>{StaticJson.list === undefined || StaticJson.list[nowplayer] == 0 ? '' : StaticJson.list[nowplayer].drivingAccuracy.toFixed(2)+'%'}</dt>
                </dl>
            </li>
            <li>
                <dl>
                <dd>{lan.L('파3 평균타수')}</dd>
                <dt>{StaticJson.list === undefined || StaticJson.list[nowplayer] == 0 ? '' : StaticJson.list[nowplayer].par3AverageScore.toFixed(1)}</dt>
                </dl>
            </li>
            <li>
                <dl>
                <dd>{lan.L('파 이하')}</dd>
                <dt>{StaticJson.list === undefined || StaticJson.list[nowplayer] == 0 ? '' : StaticJson.list[nowplayer].par.toFixed(2)+'%'}</dt>
                </dl>
            </li>
            <li>
                <dl>
                <dd>{lan.L('파4 평균타수')}</dd>
                <dt>{StaticJson.list === undefined || StaticJson.list[nowplayer] == 0 ? '' : StaticJson.list[nowplayer].par4AverageScore.toFixed(1)}</dt>
                </dl>
            </li>
            <li>
                <dl>
                <dd>{lan.L('보기')}</dd>
                <dt>{StaticJson.list === undefined || StaticJson.list[nowplayer] == 0 ? '' : StaticJson.list[nowplayer].bogey.toFixed(2)+'%'}</dt>
                </dl>
            </li>
            <li>
                <dl>
                <dd>{lan.L('파5 평균타수')}</dd>
                <dt>{StaticJson.list === undefined || StaticJson.list[nowplayer] == 0 ? '' : StaticJson.list[nowplayer].par5AverageScore.toFixed(1)+'%'}</dt>
                </dl>
            </li>
            <li>
                <dl>
                <dd>{lan.L('더블보기이상')}</dd>
                <dt>{StaticJson.list === undefined || StaticJson.list[nowplayer] == 0 ? '' : StaticJson.list[nowplayer].doubleBogey.toFixed(2)+'%'}</dt>
                </dl>
            </li>
            </ol>
        </div>
        {/*------------------------------------//통계 ------------------------------------*/}
        </div>
    </div>
    </>
	)
    
    function dot(x, y){
        if (canvas.getContext) {

            var r = 5;
            var c = "rgb(255,75,118)";
            var linec = "rgb(250,204,86)";

            dotDrawing(ctx, x, y, r, c);

            clickCnt++;
            if (clickCnt % 2 == 0) {
                var beforeDot = create_dot_arr[0];
                var beforeX = beforeDot.x;
                var beforeY = beforeDot.y;
                lineDrawing(ctx, beforeX, beforeY, x, y, linec);
                arrowDrawing(ctx, beforeX, beforeY, x, y, linec);
                create_dot_arr = [];
            } else {
                var obj = {};
                obj.color = c;
                obj.x = x;
                obj.y = y;
                obj.r = r;
                create_dot_arr.push(obj);
            }
        }
    }

    function dotDrawing(ctx, x, y, r, color) {
        if (ctx != null) {
            ctx.save();
            ctx.beginPath();
            ctx.fillStyle = color;
            ctx.arc(x, y, r, 0, Math.PI * 2, true);
            ctx.fill();
            ctx.restore();
        }
    }

    function lineDrawing(ctx, sx, sy, ex, ey, color) {
        if (ctx != null) {
            ctx.save();
            ctx.beginPath();
            ctx.strokeStyle = color;
            ctx.lineWidth = 3; 
            ctx.moveTo(sx, sy);
            ctx.lineTo(ex, ey);
            ctx.stroke();
            ctx.restore();
        }
    }

    function arrowDrawing(ctx, sx, sy, ex, ey, color) {
        if (ctx != null) {
            var aWidth = 5;
            var aLength = 12;
            var dx = ex - sx;
            var dy = ey - sy;
            var angle = Math.atan2(dy, dx);
            var length = Math.sqrt(dx * dx + dy * dy);

            //두점 선긋기
            ctx.translate(sx, sy);
            ctx.rotate(angle);
            ctx.fillStyle = color;
            ctx.beginPath();

            //화살표 모양 만들기
            // ctx.moveTo(length - aLength, -aWidth);
            // ctx.lineTo(length, 0);
            // ctx.lineTo(length - aLength, aWidth);

            ctx.fill();
            ctx.setTransform(1, 0, 0, 1, 0, 0);
        }
    }

    function setShot(PosX, PosY,num){
        var canvas = document.getElementById('mapImage');
        var imageW = canvas.width;
        var imageH = canvas.height;

        var courseXYInfo = data.scorecard[0].gameRoundInfo.courseInfo.courseMapCoordinates[num]

        var worldLeftTopX = courseXYInfo.worldLeftTopX;
        var worldLeftTopY = courseXYInfo.worldLeftTopY;
        var rightX = courseXYInfo.rightX;
        var rightY = courseXYInfo.rightY;
        var worldSizeX = courseXYInfo.worldSizeX;
        var worldSizeY = courseXYInfo.worldSizeY;
        var downX =courseXYInfo.downX;
        var downY = courseXYInfo.downY;

        var U = ((PosX - worldLeftTopX) * rightX + (PosY - worldLeftTopY) * rightY) / worldSizeX;
        var V = ((PosX - worldLeftTopX) * downX + (PosY - worldLeftTopY) * downY) / worldSizeY;

        var X = U * imageW;
        var Y = V * imageH;

        dot(X, Y);
    }

    function clearCanvas(){
        var cnvs = document.getElementById('mapImage');
        var ctx = canvas.getContext('2d');

        // 픽셀 정리
        ctx.clearRect(0, 0, cnvs.width, cnvs.height);
        // 컨텍스트 리셋
        ctx.beginPath();
    }
}