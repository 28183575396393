import * as lan from '../common/script/common_lan.js';


export default function Status(){
	return (
	<div className="contents">
	  {/*------------------------------------ 예약현황 ------------------------------------*/}
	  <aside style={{display: 'block'}}>
		{/* No Data */}
		<div className="nodata" style={{display: 'none'}}>
		  <p><img src="/images/character/nodata_6.png" /></p>
		  <strong>최근 예약내역이 없습니다.</strong>
		  <span>가까운 매장을 검색하고 예약해 보세요.</span>
		</div>
		{/*//No Data */}
		<ul className="reserve_status">
		  <li className="d-day">{/* 예약당일 class="d-day" */}
			<div className="imminent">
			  <span>D-7</span>
			  <i>d-day</i>
			</div>
			<ul>
			  <li>
				<span>2022.08.31(수) PM 12:00</span>
				<div className="category_type">스크린</div>
			  </li>
			  <li>
				<b>방배 케이골프 아카데미</b>
			  </li>
			</ul>
			<div className="summary">
			  <dl>
				<dt>담당</dt>
				<dd>홍길동</dd>
			  </dl>
			  <dl>
				<dt>인원</dt>
				<dd>4명</dd>
			  </dl>
			  <dl>
				<dt>시설</dt>
				<dd>101호</dd>
				<dd><sub>12:00~12:59</sub></dd>
			  </dl>
			  <button type="button" onClick="#">전화하기</button>
			</div>
			<div className="action">
			  <span>예약취소가 불가능합니다.</span>
			</div>
		  </li>
		  <li className>
			<div className="imminent">
			  <span>D-7</span>
			  <i>d-day</i>
			</div>
			<ul>
			  <li>
				<span>2022.07.16(토) PM 14:00</span>
				<div className="category_type">연습</div>
			  </li>
			  <li>
				<b>케이골프아카데미 방배점</b>
			  </li>
			</ul>
			<div className="summary">
			  <dl>
				<dt>담당</dt>
				<dd>홍길동</dd>
			  </dl>
			  <dl>
				<dt>인원</dt>
				<dd>2명</dd>
			  </dl>
			  <dl>
				<dt>시설</dt>
				<dd>201호</dd>
				<dd><sub>14:00~15:59</sub></dd>
			  </dl>
			  <button type="button" onClick="#">전화하기</button>
			</div>
			<div className="action">
			  <button type="button" onClick="alert_cancellation();">예약취소</button>
			  <button type="button" onClick="#">예약변경</button>
			</div>
		  </li>
		</ul>
	  </aside>
	  {/*------------------------------------//예약현황 ------------------------------------*/}
	  {/*------------------------------------ 이용현황 ------------------------------------*/}
	  <aside style={{display: 'none'}}>
		{/* No Data */}
		<div className="nodata" style={{display: 'none'}}>
		  <p><img src="/images/character/nodata_6.png" /></p>
		  <strong>최근 예약내역이 없습니다.</strong>
		  <span>가까운 매장을 검색하고 예약해 보세요.</span>
		</div>
		{/*//No Data */}
		<ul className="reserve_status">
		  <li className>
			<div className="check">이용완료</div>
			<ul>
			  <li>
				<span>2022.07.15(금) PM 12:00</span>
				<div className="category_type">라운드</div>
			  </li>
			  <li>
				<b>블랙다이아몬드골프</b>
			  </li>
			</ul>
			<div className="summary">
			  <dl>
				<dt>담당</dt>
				<dd>홍길동</dd>
			  </dl>
			  <dl>
				<dt>인원</dt>
				<dd>4명</dd>
			  </dl>
			  <dl>
				<dt>시설</dt>
				<dd>101호</dd>
				<dd><sub>12:00~12:59</sub></dd>
			  </dl>
			  <button type="button" onClick="#">전화하기</button>
			</div>
		  </li>
		  <li className="cancel">
			<div className="check">취소완료</div>
			<ul>
			  <li>
				<span>2022.06.30(목) PM 12:00</span>
				<div className="category_type">연습</div>
			  </li>
			  <li>
				<b>청담스포피아</b>
			  </li>
			</ul>
			<div className="summary">
			  <dl>
				<dt>담당</dt>
				<dd>홍길동</dd>
			  </dl>
			  <dl>
				<dt>인원</dt>
				<dd>4명</dd>
			  </dl>
			  <dl>
				<dt>시설</dt>
				<dd>101호</dd>
				<dd><sub>12:00~12:59</sub></dd>
			  </dl>
			  <button type="button" onClick="#">전화하기</button>
			</div>
		  </li>
		</ul>
	  </aside>
	</div>

	)
}