import * as lan from '../common/script/common_lan.js';
import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common.js';
import axios from 'axios';
import $ from 'jquery';

import { useDispatch, useSelector } from "react-redux";

import BottomGame from '../popup/bottom_game.js';
import * as gameslide from '../popup/bottom_game.js';

import MyBottomGame from '../popup/bottom_mygame.js';
import * as mygameslide from '../popup/bottom_mygame.js';

import Tab from '../inc/tab';

let pageSize  = {page : 1, mypage : 1};
let matchdata, mymatchdata, tempmatchhtmllist = [] , tempmymatchhtmllist = [];

export default function Game(){
	const location = useLocation();
	const navigate = useNavigate();

	const selector = useSelector((state) => state);
	const dispatch = useDispatch();
	
	let matchlist, mymatchlist;
	let html;

	//탭값
	const [activeTab, setactiveTab] = useState(0);
	const [matchhtmllist, setmatchhtmllist] = useState([]);
	const [mymatchhtmllist, setmymatchhtmllist] = useState([]);

	
	//최초실행
	useEffect(() => {
		$("#status10").prop("checked",true);	//defaultChecked하면 css가 안먹어서 임시로 jquery로 지정
		$("#mystatus10").prop("checked",true);	//defaultChecked하면 css가 안먹어서 임시로 jquery로 지정
		
		//localStorage를 /inc/tab.js 에서 변경하면 해당 값 변경되면 setactiveTab으로 activeTab 저장
		window.addEventListener('storage', () => {

			setactiveTab(parseInt(window.localStorage.getItem('tabNum')));
			
			if(activeTab !== parseInt(window.localStorage.getItem('tabNum')))
			{
				//alert(activeTab + " " + window.localStorage.getItem('tabNum'));
				setactiveTab(parseInt(window.localStorage.getItem('tabNum')));
			}
		});
		
		const scrollHandler = () => {
			if($(window).scrollTop() + $(window).height() >= $(document).height() - 30) {
				if(pageSize.page < matchdata.maxPage && parseInt(window.localStorage.getItem('tabNum')) === 0)
				{
					pageSize.page += 1;
					goData();
				}
				else if (pageSize.mypage < mymatchdata.maxPage && parseInt(window.localStorage.getItem('tabNum')) === 1)
				{
					pageSize.mypage += 1;
					goData();
				}
			}
		  }
			  
		  window.addEventListener("scroll", scrollHandler);
		  
		  // Return effect cleanup function to remove listener
		  return () => {
			window.removeEventListener("scroll", scrollHandler);
		  };
	},[]);

	//화면전환시 실행
	useEffect(() => {
		initialize();
		goData(); //데이터 불러오기
	},[location]);
	

	//selector가 변경될때 상시 실행
	useEffect(() => {
		initialize();
		goData(); //데이터 불러오기
	},[selector, activeTab]);
	//selector가 변경될때 상시 실행 종료

	const initialize = () => {
		tempmatchhtmllist = [];
		tempmymatchhtmllist = [];
		html = [];
		pageSize = {page : 1, mypage : 1};
	}
	
	const goData = () => {
		//대회 목록 조회
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data:  {page : pageSize.page, pageSize: 50, tournamentTypeCode : selector.gameReducer.tournamentTypeCode, tournamentStatus : selector.gameReducer.tournamentStatus},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('대회 목록 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {

				if(response.data.error !== undefined && response.data.error.code > 0)
				{
					common.alertByError('대회',response.data.error.code);
					return false;
				}
				else
				{
					if(pageSize.page * 50 <= tempmatchhtmllist.length)
					{
						return false;
					}

					matchdata = response.data;
					matchlist = response.data.list;
					html=[];

					const now = Date.now();

					if(matchlist.length > 0)
					{
						$("#matchlistnodata").hide();

						// 중복 확인을 위한 세트
						const tournamentIdSet = new Set(tempmatchhtmllist.map(item => item.tournamentId));
						let html = [...tempmatchhtmllist];
						
						for (let i = 0;i < matchlist.length; i++)
						{
							const currentMatch = matchlist[i];

							if (!tournamentIdSet.has(currentMatch.tournamentId)) {
								tournamentIdSet.add(currentMatch.tournamentId);
								let datetype;
								let datetext;
								//날짜 비교해서 예정인지 종료인지 파악
								if(now < matchlist[i].startTime) //예정
								{
									datetext = lan.L("예정")+" D-"+common.UnixTime(matchlist[i].startTime - now,'일자')
									datetype = "expect"
								}
								else if(now < matchlist[i].endTime) //진행중
								{
									datetext = " D-"+common.UnixTime(now - matchlist[i].startTime,'일자')
									datetype = "ongoing"
								}
								else //종료
								{
									datetext = lan.L("종료")
									datetype = "ending"
								}
								html.push({
									element: (
									<a key={currentMatch.tournamentId} onClick={() => goDetail(currentMatch.tournamentId)}>
										<div className="game_info">
										<span>
											<strong>{matchlist[i].tournamentType}</strong>
											<i className={datetype}>{datetext}</i>
										</span>
										<em>{matchlist[i].title}</em>
										<span>
											<small>{common.UnixTime(matchlist[i].startTime,'월.일')}~{common.UnixTime(matchlist[i].endTime,'월.일')}</small>
											<small>{matchlist[i].participantCount}{lan.L('명')}</small>
											<small>{matchlist[i].hostShopName}</small>
										</span>
										</div>
										<p style={{backgroundImage: `url(${matchdata.cdn}${matchlist[i].thumbnailKey})`}}>image</p>
									</a>
									),
									startTime: currentMatch.startTime,							
									tournamentId : currentMatch.tournamentId
								}
								);
							}
						}
						html.sort((a, b) => b.startTime - a.startTime);
						const sortedHtml = html.map(item => item.element);
						setmatchhtmllist(sortedHtml);
						tempmatchhtmllist = html;
						
					}
					else
					{
						$("#matchlistnodata").show();
						setmatchhtmllist(html);
					}
				}
				
			}).catch(error => {
			console.log(error)
		});
		//대회 목록 조회 종료

		//참가한 대회 목록 조회
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data:  {page : pageSize.eventpage, pageSize: 200, tournamentTypeCode : selector.gameReducer.mytournamentTypeCode, tournamentStatus : selector.gameReducer.mytournamentStatus},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('참가한 대회 목록 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
				console.log(response.data);

				if(pageSize.mypage * 200 <= tempmymatchhtmllist.length)
				{
					return false;
				}

				if(response.data.error !== undefined && response.data.error.code > 0)
				{
					common.alertByError('대회',response.data.error.code);
					return false;
				}
				else
				{
					mymatchdata = response.data;
					mymatchlist = response.data.list;
					html=[];

					const now = Date.now();
					
					if(mymatchlist.length > 0)
					{
						$("#mymatchlistnodata").hide();

						// 중복 확인을 위한 세트
						const tournamentIdSet = new Set(tempmymatchhtmllist.map(item => item.props.tournamentId));

						for (let i = 0;i < mymatchlist.length; i++)
						{
							const currentMatch = mymatchlist[i];

							if (!tournamentIdSet.has(currentMatch.tournamentId)) {
								tournamentIdSet.add(currentMatch.tournamentId);
								let datetype;
								let datetext;
								//날짜 비교해서 예정인지 종료인지 파악
								if(now < mymatchlist[i].startTime) //예정
								{
									datetext = lan.L("예정")+" D-"+common.UnixTime(mymatchlist[i].startTime - now,'일자')
									datetype = "expect"
								}
								else if(now < mymatchlist[i].endTime) //진행중
								{
									datetext = lan.L("진행중")+" D-"+common.UnixTime(now - mymatchlist[i].startTime,'일자')
									datetype = "ongoing"
								}
								else //종료
								{
									datetext = lan.L("종료")
									datetype = "ending"
								}
								html.push(
									<a onClick={() => goMyDetail(currentMatch.tournamentId)}>
										<div className="game_info">
										<span>
											<strong>{mymatchlist[i].tournamentType}</strong>
											<i className={datetype}>{datetext}</i>
										</span>
										<em>{mymatchlist[i].title}</em>
										<span>
											<small>{common.UnixTime(mymatchlist[i].startTime,'월.일')}~{common.UnixTime(mymatchlist[i].endTime,'월.일')}</small>
											<small>{mymatchlist[i].participantCount}{lan.L('명')}</small>
											<small>{mymatchlist[i].hostShopName}</small>
										</span>
										</div>
										<p style={{backgroundImage: `url(${matchdata.cdn}${mymatchlist[i].thumbnailKey})`}}>image</p>
									</a>
								);
							}
						}
						html = [...tempmymatchhtmllist,...html];
						setmymatchhtmllist(html);
						tempmymatchhtmllist = html;
					}
					else
					{
						$("#mymatchlistnodata").show();
						setmymatchhtmllist(html);
					}
				}
			}).catch(error => {
			console.log(error)
		});
		//참가한 대회 목록 종료
	}

	const goDetail = (tournamentId) => {
		navigate('/match/detail_match', {state : {tournamentId : tournamentId, backTabTopics : window.localStorage.getItem('tabNum')}, replace : true});
	}
	
	const goMyDetail = (tournamentId) => {
		navigate('/match/detail_match', {state : {tournamentId : tournamentId, backTabTopics : window.localStorage.getItem('tabNum')}, replace : true});
	}
	
	const changetournamentStatus = (e, value) => {
        dispatch({type:'tournamentStatus', value: value});
	}
	
	const changemytournamentStatus = (e, value) => {
        dispatch({type:'mytournamentStatus', value: value});
	}
	
	return (
	<>
	<Tab topics={[{tabName:'대회리스트'},{tabName:'참가한대회'}]} defaultTopics={location.state === undefined || location.state == null ? 0 : location.state.activeTab} tabkey={lan.L("대회")}></Tab> 
	<BottomGame defaultvalue="0"></BottomGame>
	<MyBottomGame defaultvalue="0"></MyBottomGame>

	<div className="contents" style={activeTab===0 ? {display:'block'}: {display:'none'}}>
	  <div className="search_standard">
		<span onClick={(e)=>gameslide.slideMatch()}>
			<label className="filter_index" >{lan.L(common.commonKeyDic['match'][selector.gameReducer.tournamentTypeCode])}</label>
		</span>
		<div className="direct_check">
		  <a>
			<input type="radio" id="status10" name="status1" onChange={(e)=>changetournamentStatus(e,0)} />
			<label htmlFor="status10">{lan.L('전체')}</label>
		  </a>
		  <a>
			<input type="radio" id="status11" name="status1" onChange={(e)=>changetournamentStatus(e,1)} />
			<label htmlFor="status11">{lan.L('예정')}</label>
		  </a>
		  <a>
			<input type="radio" id="status12" name="status1" onChange={(e)=>changetournamentStatus(e,2)} />
			<label htmlFor="status12">{lan.L('진행')}</label>
		  </a>
		  <a>
			<input type="radio" id="status13" name="status1" onChange={(e)=>changetournamentStatus(e,3)} />
			<label htmlFor="status13">{lan.L('종료')}</label>
		  </a>
		</div>
	  </div>
	  {/* No Data */}
	  <div className="nodata" id="matchlistnodata" style={{display: 'none'}}>
		<p><img src={require("../images/character/nodata_5.png")} /></p>
		<strong>{lan.L('현재 진행중인 대회가 없어요.')}</strong>
		<span>{lan.L('대회에 참가해서 경품을 받아보세요.')}</span>
	  </div>
	  {/*//No Data */}
	  <div className="list_match" style={{display: 'block'}}>
	  	{matchhtmllist}
	  </div>
	</div>
	
	<div className="contents" style={activeTab===1 ? {display:'block'}: {display:'none'}}>
	  <div className="search_standard">
		<span onClick={(e)=>mygameslide.slideMatch()}>
			<label className="filter_index" >{lan.L(common.commonKeyDic['match'][selector.gameReducer.mytournamentTypeCode])}</label>
		</span>
		<div className="direct_check">
		  <a>
			<input type="radio" id="mystatus10" name="mystatus1" onChange={(e)=>changemytournamentStatus(e,0)} />
			<label htmlFor="mystatus10">{lan.L('전체')}</label>
		  </a>
		  <a>
			<input type="radio" id="mystatus11" name="mystatus1" onChange={(e)=>changemytournamentStatus(e,1)} />
			<label htmlFor="mystatus11">{lan.L('예정')}</label>
		  </a>
		  <a>
			<input type="radio" id="mystatus12" name="mystatus1" onChange={(e)=>changemytournamentStatus(e,2)} />
			<label htmlFor="mystatus12">{lan.L('진행')}</label>
		  </a>
		  <a>
			<input type="radio" id="mystatus13" name="mystatus1" onChange={(e)=>changemytournamentStatus(e,3)} />
			<label htmlFor="mystatus13">{lan.L('종료')}</label>
		  </a>
		</div>
	  </div>
	  {/* No Data */}
	  <div className="nodata" id="mymatchlistnodata" style={{display: 'none'}}>
		<p><img src={require("../images/character/nodata_5.png")} /></p>
		<strong>{lan.L('현재 진행중인 대회가 없어요.')}</strong>
		<span>{lan.L('대회에 참가해서 경품을 받아보세요.')}</span>
	  </div>
	  {/*//No Data */}
	  <div className="list_match" style={{display: 'block'}}>
	  	{mymatchhtmllist}
	  </div>
	</div>
	</>
	)
}
