import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

export function slideNear(){
	$("html").addClass("overflowHidden");
	$("nav").addClass("dissolve");
	$("#filter_near").addClass("on");
	returntab = 0;
}

export function slideVisit(){
	$("html").addClass("overflowHidden");
	$("nav").addClass("dissolve");
	$("#filter_visit").addClass("on");
	returntab = 1;
}

export function slideExit(){
	$("html").removeClass("overflowHidden");
	$("nav").removeClass("dissolve");
	$(".layerView").removeClass("on");
}

export function setpastExit(){
	$("html").removeClass("overflowHidden");
	$("nav").removeClass("dissolve");
	$(".layerView").removeClass("on");

	$("#distance"+distancetypehtmllist_past).prop('checked', true);

	for(let i = 0; i < clientTypehtmllist_past.length; i++)
	{
		$("#clientType"+i).prop('checked', clientTypehtmllist_past[i]);
	}
	
	//방문
	$("#visitdistance"+visitdistancetypehtmllist_past).prop('checked', true);

	for(let i = 0; i < visitclientTypehtmllist_past.length; i++)
	{
		$("#visitclientType"+i).prop('checked', visitclientTypehtmllist_past[i]);
	}
}

var navigate;
var returntab;
var clientTypehtmllist_past;
var distancetypehtmllist_past;

var visitclientTypehtmllist_past;
var visitdistancetypehtmllist_past;

export default function BottomRoundset(props) {

	const location = useLocation();
	navigate = useNavigate();
	
	const [clientTypehtmllist, setclientTypehtmllist] = useState([]);
	const [distancetypehtmllist, setdistancehtmllist] = useState([]);
	
	const [visitclientTypehtmllist, setvisitclientTypehtmllist] = useState([]);
	const [visitdistancetypehtmllist, setvisitdistancehtmllist] = useState([]);

	useEffect(() => {
		if(clientTypehtmllist_past === undefined)	clientTypehtmllist_past= [true,false,false,false];
		if(distancetypehtmllist_past === undefined)	distancetypehtmllist_past = 3;
		
		var htmllist = [];
		for(let i =0; i < Object.keys(common.commonKeyDic['clientType']).length; i++)
		{
			var j = Object.keys(common.commonKeyDic['clientType'])[i];
			htmllist.push(
				<a key={i+"c"}>
				  <input type="checkbox" id={"clientType"+i} name="typenear" onClick={(e)=>selectclientType(e,i)} defaultChecked={i == 0 ? true : false} />
				  <label htmlFor={"clientType"+i}>{lan.L(common.commonKeyDic['clientType'][j])}</label>
				</a>
			);
		}

		if(clientTypehtmllist.length == 0)
		{
			setclientTypehtmllist(htmllist);
		}
		htmllist = [];
		
		for(let i =0; i < Object.keys(common.commonKeyDic['distance']).length; i++)
		{
			htmllist.push(
				<a key={i+"d"}>
				  <input type="radio" id={"distance"+i} data-key={i} name="distance" value={common.commonKeyDic['distance'][i]} defaultChecked={i == 3 ? true : false} />
				  <label htmlFor={"distance"+i}>{lan.L(common.commonKeyDic['distance'][i]+"km 반경")}</label>
				</a>
			);
		}

		if(visitdistancetypehtmllist.length == 0)
		{
			setdistancehtmllist(htmllist);
		}

		//방문
		if(visitclientTypehtmllist_past === undefined)	visitclientTypehtmllist_past= [true,false,false,false];
		if(visitdistancetypehtmllist_past  === undefined)	visitdistancetypehtmllist_past = 3;
		
		var visithtmllist = [];
		for(let i =0; i < Object.keys(common.commonKeyDic['clientType']).length; i++)
		{

			var j = Object.keys(common.commonKeyDic['clientType'])[i];
			visithtmllist.push(
				<a key={i+"c"}>
				  <input type="checkbox" id={"visitclientType"+i} name="typevisit" onClick={(e)=>selectvisitclientType(e,i)} defaultChecked={i == 0 ? true : false} />
				  <label htmlFor={"visitclientType"+i}>{lan.L(common.commonKeyDic['clientType'][j])}</label>
				</a>
			);
		}

		if(visitclientTypehtmllist.length == 0)
		{
			setvisitclientTypehtmllist(visithtmllist);
		}
		visithtmllist = [];
		
		for(let i =0; i < Object.keys(common.commonKeyDic['distance']).length; i++)
		{
			visithtmllist.push(
				<a key={i+"d"}>
				  <input type="radio" id={"visitdistance"+i} data-key={i} name="visitdistance" value={common.commonKeyDic['distance'][i]} defaultChecked={i == 3 ? true : false} />
				  <label htmlFor={"visitdistance"+i}>{lan.L(common.commonKeyDic['distance'][i]+"km 반경")}</label>
				</a>
			);
		}

		if(visitdistancetypehtmllist.length == 0)
		{
			setvisitdistancehtmllist(visithtmllist);
		}

    },[location]);
	
	const goSelect = (e, to,tabnum) => {

		var clientTypeval = '';
		var distanceval = $("input[name=distance]:checked").val();
		distancetypehtmllist_past = $("input[name=distance]:checked").data("key");
		clientTypehtmllist_past= [false,false,false,false];

		for(let i =0; i < Object.keys(common.commonKeyDic['clientType']).length; i++)
		{
			var ckey = Object.keys(common.commonKeyDic['clientType'])[i];
			var ischecked = $("#clientType"+i).is(":checked");

			if('All'==ckey && ischecked)
			{
				clientTypehtmllist_past[0] = true;
				clientTypeval = ''
				break;
			}
			else if('screen'==ckey && ischecked)
			{
				clientTypehtmllist_past[1] = true;
				clientTypeval = clientTypeval + 'clientType=0&'
			}
			else if('practice'==ckey && ischecked)
			{
				clientTypehtmllist_past[2] = true;
				clientTypeval = clientTypeval + 'clientType=1&'
			}
			else if('lesson'==ckey && ischecked)
			{
				clientTypehtmllist_past[3] = true;
				clientTypeval = clientTypeval + 'clientType=2&'
			}
		}
		clientTypeval = clientTypeval.substr(0,clientTypeval.length-1);
		
		//방문
		var visitclientTypeval = '';
		var visitdistanceval = $("input[name=visitdistance]:checked").val();
		visitdistancetypehtmllist_past = $("input[name=visitdistance]:checked").data("key");
		visitclientTypehtmllist_past= [false,false,false,false];

		for(let i =0; i < Object.keys(common.commonKeyDic['clientType']).length; i++)
		{
			var ckey = Object.keys(common.commonKeyDic['clientType'])[i];
			var ischecked = $("#visitclientType"+i).is(":checked");
			

			if('All'==ckey && ischecked)
			{
				visitclientTypehtmllist_past[0] = true;
				visitclientTypeval = ''
				break;
			}
			else if('screen'==ckey && ischecked)
			{
				visitclientTypehtmllist_past[1] = true;
				visitclientTypeval = visitclientTypeval + 'clientType=0&'
			}
			else if('practice'==ckey && ischecked)
			{
				visitclientTypehtmllist_past[2] = true;
				visitclientTypeval = visitclientTypeval + 'clientType=1&'
			}
			else if('lesson'==ckey && ischecked)
			{
				visitclientTypehtmllist_past[3] = true;
				visitclientTypeval = visitclientTypeval + 'clientType=2&'
			}
		}
		visitclientTypeval = visitclientTypeval.substr(0,visitclientTypeval.length-1);

		if(to !== undefined)
		{
			window.localStorage.setItem('tabNum', tabnum);
			navigate(to, {state : {clientTypeLink : clientTypeval, distance : distanceval, visitclientTypeLink : visitclientTypeval, visitdistance : visitdistanceval, activeTab : tabnum},replace:true});
		}

		slideExit();
	}

	const goInit = () => {
		$("input[name=typenear]").prop("checked", false);
		$("#clientType0").prop("checked",true);
		$("#distance"+Object.keys(common.commonKeyDic['distance'])[3]).prop("checked",true);
	}
	
	const govisitInit = () => {
		$("input[name=typevisit]").prop("checked", false);
		$("#visitclientType0").prop("checked",true);
		$("#visitdistance"+Object.keys(common.commonKeyDic['distance'])[3]).prop("checked",true);
	}
	
	const selectclientType = (e,i) => {
		if(i!=0)
		{
			$("#clientType0").prop("checked",false);
		}
		else
		{
			for(let x=1;x<Object.keys(common.commonKeyDic['clientType']).length;x++)
			{
				$("#clientType"+x).prop("checked",false);
			}
		}
	}
	
	const selectvisitclientType = (e,i) => {
		if(i!=0)
		{
			$("#visitclientType0").prop("checked",false);
		}
		else
		{
			for(let x=1;x<Object.keys(common.commonKeyDic['clientType']).length;x++)
			{
				$("#visitclientType"+x).prop("checked",false);
			}
		}
	}

	return (
		<>
		<div id="filter_near" className="layerView">
		  <div className="bottom_sheet">
		   <a onClick={(e) => setpastExit()}>close</a>
			<div className="bs_body">
			  <em>{lan.L("검색조건")}</em>
			  <button type="button" onClick={goInit} id="pop_nearinit">{lan.L("초기화")}</button>
			  <ul className="search_filter">
				<li>
				  <em>{lan.L("유형")}</em>
				  <div className="filter_item">
					{clientTypehtmllist}
				  </div>
				</li>
				<li>
				  <em>{lan.L("거리별")}</em>
				  <div className="filter_item">
					{distancetypehtmllist}
				  </div>
				</li>
			  </ul>
			</div>
			<div className="bottom_sheet_button">
			  <button type="button" className="btn_cancel" onClick={(e) => setpastExit()}>{lan.L("취소")}</button>
			  <button type="button" className="btn_standard" id="pop_nearok" onClick={(e) => goSelect(e,props.to,0)}>{lan.L("확인")}</button>
			</div>
		  </div>
		</div>
		
		<div id="filter_visit" className="layerView">
		  <div className="bottom_sheet">
		   <a onClick={(e) => setpastExit()}>close</a>
			<div className="bs_body">
			  <em>{lan.L("검색조건")}</em>
			  <button type="button" onClick={govisitInit} id="pop_visitinit">{lan.L("초기화")}</button>
			  <ul className="search_filter">
				<li>
				  <em>{lan.L("유형")}</em>
				  <div className="filter_item">
					{visitclientTypehtmllist}
				  </div>
				</li>
				<li>
				  <em>{lan.L("거리별")}</em>
				  <div className="filter_item">
					{visitdistancetypehtmllist}
				  </div>
				</li>
			  </ul>
			</div>
			<div className="bottom_sheet_button">
			  <button type="button" className="btn_cancel" onClick={(e) => setpastExit()}>{lan.L("취소")}</button>
			  <button type="button" className="btn_standard" id="pop_visitok" onClick={(e) => goSelect(e,props.to,1)}>{lan.L("확인")}</button>
			</div>
		  </div>
		</div>
		</>
	)
}