import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common.js';
import $ from 'jquery';
import axios from 'axios';

import Top from '../inc/top';
import Barchart from './chart';

import BottomStatisticPrac from '../popup/bottom_statisticprac.js';
import * as practiceslide from '../popup/bottom_statisticprac.js';

import { useDispatch, useSelector } from "react-redux";

export default function DetailApproach(props){
	const selector = useSelector((state) => state);
	const dispatch = useDispatch();
	const location = useLocation();
	const [InfoData, SetInfoData] = useState([]);
    const [InfoDataAVG, SetInfoDataAVG] = useState([]);
	const [xyData1, SetxyData1] = useState([]);
	const [xyData2, SetxyData2] = useState([]);
    
	const [ymin1, Setymin1] = useState(null);
	const [ymin2, Setymin2] = useState(null);
	const [chartValue, SetchartValue] = useState(lan.L('홀인 비율'));

	//화면전환시 실행
	useEffect(() => {
        
		if (location.state !== undefined && location.state != null)
		{
            SetInfoDataAVG(location.state.Data);
            console.log(location.state.Data);
		}
    },[location]);

	const getCurvedPutting = () => {
        //alert(selector.statisticReducer.drivingRangeClub);`
		//연습장 평균 통계 데이터 조회 API
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {
				drivingRangeClub :selector.statisticReducer.drivingRangeClub,
				approachDistance:Object.values(common.commonKeyDic['approachDistance'])[selector.statisticReducer.approachDistance],
				straightPuttingDistance:Object.values(common.commonKeyDic['straightPuttingDistance'])[selector.statisticReducer.straightPuttingDistance]
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('연습장 평균 통계 데이터 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
			}
		}).then(function (response) {
			console.log(response.data);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				//common.alertByError(lan.L('통계 정보'),response.data.error.code);
				return false;
			}
			else
			{
				SetInfoDataAVG(response.data.curvedPutting);
			}
		}).catch(error => {
			console.log(error.response)
		});

		//연습장 곡선 퍼팅 통계 데이터 조회 API
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {
				page:1,
				pagesize:10,
                drivingRangeClub:null,
                approachDistance:selector.statisticReducer.approachDistance
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('연습장 곡선 퍼팅 통계 데이터 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
			}
		}).then(function (response) {
			console.log(response.data);
			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError(lan.L('곡선퍼팅'),response.data.error.code);
				return false;
			}
			else
			{
				SetInfoData(response.data);

                //홀인 비율
                var xylist = [];
                var mintemp;
                var empty = ''; //차트 상에 x값이 달라야 해서 빈 공간 추가
                for(let i=0; i < response.data.list.length; i++)
                {
                    empty = empty + ' '; 
                    xylist.unshift({x: common.UnixTime(response.data.list[i].gameRoundStartTime,'월.일')+empty ,y: response.data.list[i].holeInShotPercent});
                    if(i == 0)
                    {
                        mintemp = response.data.list[i].holeInShotPercent;
                    }
                    mintemp = Math.min(response.data.list[i].holeInShotPercent, mintemp);
                }
                Setymin1(mintemp);
                SetxyData1(xylist);

                //도달율
                xylist = [];
                empty = '';
                for(let i=0; i < response.data.list.length; i++)
                {
                    empty = empty + ' '; 
                    xylist.unshift({x: common.UnixTime(response.data.list[i].gameRoundStartTime,'월.일')+empty ,y: response.data.list[i].reachPercent.toFixed(2)});  
                    if(i == 0)
                    {
                        mintemp = response.data.list[i].reachPercent;
                    }
                    mintemp = Math.min(response.data.list[i].reachPercent, mintemp);
                }
                Setymin2(mintemp);
                SetxyData2(xylist);

			}
		}).catch(error => {
			console.log(error.response)
		});
	}
    
	//selector가 변경될때 상시 실행
	useEffect(() => {
		getCurvedPutting();
	},[selector]);
	//selector가 변경될때 상시 실행 종료

	const {...rest} = props
	return (
        <>
        <Top class="convert turn" title={lan.L('곡선퍼팅')} to="/statistic/statistic"/> 
        <div className="based">
            <div className="statistic_average" style={{backgroundImage:"url("+require('../images/sub/detail_approach.jpg')+")"}}>
                <div className="best_score">
                <span className={chartValue == lan.L('홀인 비율') ? "on" : ""} onClick={(e)=>SetchartValue(lan.L('홀인 비율'))}>
                    <b>{lan.L('홀인 비율')}</b>
                    <strong>{InfoDataAVG.holeInShotPercent+ "%"}</strong>
                    <small>(<q>{InfoDataAVG.holeInShotCount}</q>/{InfoDataAVG.totalShotCount})</small>
                </span>
                <span className={chartValue == lan.L('도달율') ? "on" : ""} onClick={(e)=>SetchartValue(lan.L('도달율'))}>
                    <b>{lan.L('도달율')}</b>
                    <strong>{InfoDataAVG.reachPercent === undefined?'':InfoDataAVG.reachPercent.toFixed(2)+ "%"}</strong>
                </span>
                </div>
            </div>
            <div className="detail_statistic">
                {/* No Data */}
                {
                    InfoData.list === undefined || InfoData.list.length == 0 ? 
                <div className="nodata">
                    <p><img src={require("../images/character/nodata_2.png")} /></p>
                    <strong>{lan.L('아직 플레이 기록이 없어요.')}</strong>
                    <span>{lan.L('가까운 매장에서 친구들과 라운드를 즐겨보세요.')}</span>
                </div>
                : ''
                }
                {/*//No Data */}

                {
                    InfoData.list === undefined ||InfoData.list.length == 0 ? '' :
                <div className="graph_wrap">
                    <em>{chartValue}</em>
                    {chartValue == lan.L('홀인 비율') ? <Barchart data={xyData1} min={ymin1}></Barchart> : ''}
                    {chartValue == lan.L('도달율') ? <Barchart data={xyData2} min={ymin2}></Barchart> : ''}
                </div>
                }
                <ul className="status_by" style={{marginBottom: '48px'}}>
                {InfoData.list === undefined || InfoData.list.length == 0 ? '' :
            InfoData.list.map((url, i) => (
                <li>
                    <em>{common.UnixTime(InfoData.list[i].gameRoundStartTime,'년.월.일 (요일)')}</em>
                    <article>
                    <div className="data_score">
                        <span>
                            <b>{lan.L('홀인 비율')}</b>
                            <strong>{InfoData.list[i].holeInShotPercent+ "%"}</strong>
                            <small>(<q>{InfoData.list[i].holeInShotCount}</q>/{InfoData.list[i].totalShotCount})</small>
                        </span>
                        <span>
                            <b>{lan.L('도달율')}</b>
                            <strong>{InfoData.list[i].reachPercent.toFixed(2)+ "%"}</strong>
                        </span>
                    </div>
                    </article>
                </li>
                ))}
                </ul>
            </div>
        </div>
        </>
	)
}