import React from 'react';
import * as lan from "../common/script/common_lan";
import ScoreRank from "./score_rank";
import StrokeRanking from "./stroke_ranking";
import StrokeTotalRanking from "./stroke_total_ranking";
import {useSelector} from "react-redux";
import NewperioRanking from "./newperio_ranking";
import NewperioTotalRanking from "./newperio_total_ranking";
import * as common from "../common/script/common";

const RankingBottomSheet = ({id, InfoData, InfoRankData, selectPlayerNum, slideExit, selector}) => {
    return (
        <div id={id} className="layerView">
            <div className="bottom_sheet">
                <a onClick={slideExit}>close</a>
                <div className="bs_body">
                    {
                        {
                            'Stroke': InfoData.isNewRankingSystem ?
                                        <em>{selector.matchRankReducer.match_courseNum !== 'all' && InfoData.courseRounds[selector.matchRankReducer.match_courseNum].courseName}</em>
                                        : <em>{lan.L('스트로크')} {lan.L('랭킹')}</em>,
                            'NewPerio': <em>
                                            {InfoData.isNewRankingSystem ?
                                                selector.matchRankReducer.match_courseNum !== 'all' && InfoData.courseRounds[selector.matchRankReducer.match_courseNum].courseName
                                                : <>{lan.L('신페리오 랭킹')}</>
                                            }
                                        </em>,
                            'Longest': <em>{lan.L('롱기스트 랭킹')}</em>,
                            'Nearpin': <em>{lan.L('니어핀 랭킹')}</em>,
                            'BirdieCount': <em>{lan.L('버디 횟수') + ' ' + lan.L('랭킹')}</em>,
                            'EagleCount': <em>{lan.L('이글 횟수') + ' ' + lan.L('랭킹')}</em>,
                            'HoleInOne': <em>{lan.L('홀인원') + ' ' + lan.L('랭킹')}</em>,
                            'CourseRoundPlayCount': <em>{lan.L('라운딩 횟수') + ' ' + lan.L('랭킹')}</em>,
                        }[InfoRankData.recordType]
                    }
                    <div className="scroll_container">
                        <ScoreRank InfoData={InfoData} InfoRankData={InfoRankData} selectPlayerNum={selectPlayerNum}/>
                        {{
                            'Stroke': selector.matchRankReducer.match_courseNum !== 'all' ?
                                InfoRankData.list[selectPlayerNum].holeScore &&
                                <StrokeRanking
                                    InfoData={InfoData}
                                    InfoRankData={InfoRankData}
                                    matchCourseNum={selector.matchRankReducer.match_courseNum}
                                    selectPlayerNum={selectPlayerNum}
                                />
                                : <StrokeTotalRanking
                                    InfoRankData={InfoRankData}
                                    selectPlayerNum={selectPlayerNum}
                                />,
                            'NewPerio': selector.matchRankReducer.match_courseNum !== 'all' ?
                                InfoRankData.list[selectPlayerNum].holeScore &&
                                <NewperioRanking
                                    InfoData={InfoData}
                                    InfoRankData={InfoRankData}
                                    matchCourseNum={selector.matchRankReducer.match_courseNum}
                                    selectPlayerNum={selectPlayerNum}
                                />
                                : <NewperioTotalRanking
                                    InfoRankData={InfoRankData}
                                    selectPlayerNum={selectPlayerNum}
                                />,
                            'Longest': <table className="score_chart">
                                <colgroup>
                                    <col style={{width: '30%'}}/>
                                    <col style={{width: '22.5%'}}/>
                                    <col style={{width: '22.5%'}}/>
                                    <col style={{width: '25%'}}/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>{lan.L('Course')}</th>
                                    <th>{lan.L('전장거리')}</th>
                                    <th>{lan.L('비거리')}</th>
                                    <th>{lan.L('달성일')}</th>
                                </tr>
                                <tr>
                                    <td>{InfoData.courseRounds[selector.matchRankReducer.match_courseNum === 'all' ? 0 : selector.matchRankReducer.match_courseNum].courseName}</td>
                                    <td>
                                        <b>{common.Length(InfoData.courseRounds[selector.matchRankReducer.match_courseNum === 'all' ? 0 : selector.matchRankReducer.match_courseNum].totalDistance, '0m')}</b>
                                    </td>
                                    <td><b>{common.Length(InfoRankData.list[selectPlayerNum].record, '0m')}</b></td>
                                    <td>{common.UnixTime(InfoRankData.list[selectPlayerNum].recordTime, '년.월.일')}<br/>{common.UnixTime(InfoRankData.list[selectPlayerNum].recordTime, '시:분:초')}
                                    </td>
                                </tr>
                                </tbody>
                            </table>,
                            'Nearpin': <table className="score_chart">
                                <colgroup>
                                    <col style={{width: '30%'}}/>
                                    <col style={{width: '15%'}}/>
                                    <col style={{width: '15%'}}/>
                                    <col style={{width: '15%'}}/>
                                    <col style={{width: '25%'}}/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>{lan.L('Course')}</th>
                                    <th>{lan.L('Hole')}</th>
                                    <th>{lan.L('전장거리')}</th>
                                    <th>{lan.L('남은거리')}</th>
                                    <th>{lan.L('달성일')}</th>
                                </tr>
                                <tr>
                                    <td>{InfoData.courseRounds[selector.matchRankReducer.match_courseNum === 'all' ? 0 : selector.matchRankReducer.match_courseNum].courseName}</td>
                                    <td>
                                        <b>{InfoData.courseRounds[selector.matchRankReducer.match_courseNum === 'all' ? 0 : selector.matchRankReducer.match_courseNum].nearpinHoleNumber}</b>
                                    </td>
                                    <td>
                                        <b>{common.Length(InfoData.courseRounds[selector.matchRankReducer.match_courseNum === 'all' ? 0 : selector.matchRankReducer.match_courseNum].totalDistance, '0m')}</b>
                                    </td>
                                    <td><b>{common.Length(InfoRankData.list[selectPlayerNum].record, '0m')}</b></td>
                                    <td>{common.UnixTime(InfoRankData.list[selectPlayerNum].recordTime, '년.월.일')}<br/>{common.UnixTime(InfoRankData.list[selectPlayerNum].recordTime, '시:분:초')}
                                    </td>
                                </tr>
                                </tbody>
                            </table>,
                            'BirdieCount': <table className="score_chart">
                                <colgroup>
                                    <col style={{width: '50%'}}/>
                                    <col style={{width: '50%'}}/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>{lan.L('Course')}</th>
                                    <th>{lan.L('Count')}</th>
                                </tr>
                                <tr>
                                    <td>{InfoData.courseRounds[0].courseName}</td>
                                    <td>
                                        <b>{InfoRankData.list[selectPlayerNum].birdieCount}</b>
                                    </td>
                                </tr>
                                </tbody>
                            </table>,
                            'EagleCount': <table className="score_chart">
                                <colgroup>
                                    <col style={{width: '50%'}}/>
                                    <col style={{width: '50%'}}/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>{lan.L('Course')}</th>
                                    <th>{lan.L('Count')}</th>
                                </tr>
                                <tr>
                                    <td>{InfoData.courseRounds[0].courseName}</td>
                                    <td>
                                        <b>{InfoRankData.list[selectPlayerNum].eagleCount}</b>
                                    </td>
                                </tr>
                                </tbody>
                            </table>,
                            'HoleInOne': <table className="score_chart">
                                <colgroup>
                                    <col style={{width: '30%'}}/>
                                    <col style={{width: '15%'}}/>
                                    <col style={{width: '15%'}}/>
                                    <col style={{width: '40%'}}/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>{lan.L('Course')}</th>
                                    <th>{lan.L('Hole')}</th>
                                    <th>{lan.L('전장거리')}</th>
                                    <th>{lan.L('달성일')}</th>
                                </tr>
                                <tr>
                                    <td>{InfoData.courseRounds[selector.matchRankReducer.match_courseNum === 'all' ? 0 : selector.matchRankReducer.match_courseNum].courseName}</td>
                                    <td><b>{InfoRankData.list[selectPlayerNum].holeNumber}</b></td>
                                    <td>
                                        <b>{common.Length(InfoData.courseRounds[selector.matchRankReducer.match_courseNum === 'all' ? 0 : selector.matchRankReducer.match_courseNum].holes[InfoRankData.list[selectPlayerNum].holeNumber - 1]?.blackTeeDistance, '0m')}</b>
                                    </td>
                                    <td>{common.UnixTime(InfoRankData.list[selectPlayerNum].recordTime, '년.월.일')}<br/>{common.UnixTime(InfoRankData.list[selectPlayerNum].recordTime, '시:분:초')}
                                    </td>
                                </tr>
                                </tbody>
                            </table>,
                            'CourseRoundPlayCount': <table className="score_chart">
                                <colgroup>
                                    <col style={{width: '50%'}}/>
                                    <col style={{width: '50%'}}/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>{lan.L('Course')}</th>
                                    <th>{lan.L('Count')}</th>
                                </tr>
                                <tr>
                                    <td>{InfoData.courseRounds[0].courseName}</td>
                                    <td><b>{InfoRankData.list[selectPlayerNum].totalCourseRoundPlayCount}</b></td>
                                </tr>
                                </tbody>
                            </table>,
                        }[InfoRankData.recordType]}
                    </div>
                </div>
                <div className="bottom_sheet_button">
                    <button type="button" className="btn_standard" onClick={slideExit}>{lan.L('확인')}</button>
                </div>
            </div>
        </div>
    );
};

export default RankingBottomSheet;