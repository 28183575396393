import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

import LinkButton from '../common/component/linkButton';

export default function ResultID(){

	const location = useLocation();

	return(
		<>
		<div>
		  <div className="container">
			<div className="find_id">
			  <em className="new-line">{lan.L('회원님의 정보로 가입된\n아이디가 있습니다.')}</em>
			  <dl>
				<dt>{location.state.id}</dt>
				<dd>{common.UnixTime(location.state.joinTime, '년.월.일')+ ' ' +  lan.L('가입')}</dd>
			  </dl>
			</div>
		  </div>{/* container(e) */}
		  <div className="password_link">
			<span>{lan.L('비밀번호가 기억나지 않으세요?')}</span>
			<Link to="/login/find_pw">{lan.L('비밀번호 찾기')}</Link>
		  </div>
		  <div className="sticky_button">
			<LinkButton type="button" className="btn_standard" to='/login/login'>{lan.L('로그인하러 가기')}</LinkButton>
		  </div>
		  <hr />
		</div>
		</>
	)
}