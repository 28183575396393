import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common.js';
import $ from 'jquery';
import axios from 'axios';

import Top from '../inc/top';
import Barchart from './chart';

import BottomStatisticPrac from '../popup/bottom_statisticprac.js';
import * as practiceslide from '../popup/bottom_statisticprac.js';

import { useDispatch, useSelector } from "react-redux";

export default function DetailDriving(props){
	const selector = useSelector((state) => state);
	const dispatch = useDispatch();
	const location = useLocation();
	const [InfoData, SetInfoData] = useState([]);
    const [InfoDataAVG, SetInfoDataAVG] = useState([]);
	const [xyData1, SetxyData1] = useState([]);
	const [xyData2, SetxyData2] = useState([]);
	const [xyData3, SetxyData3] = useState([]);
	const [xyData4, SetxyData4] = useState([]);
	const [xyData5, SetxyData5] = useState([]);
	const [xyData6, SetxyData6] = useState([]);
    
	const [ymin1, Setymin1] = useState(null);
	const [ymin2, Setymin2] = useState(null);
	const [ymin3, Setymin3] = useState(null);
	const [ymin4, Setymin4] = useState(null);
	const [ymin5, Setymin5] = useState(null);
	const [ymin6, Setymin6] = useState(null);
	const [chartValue, SetchartValue] = useState(lan.L('평균 비거리'));

	//화면전환시 실행
	useEffect(() => {
        
		if (location.state !== undefined && location.state != null)
		{
            SetInfoDataAVG(location.state.Data);
            console.log(location.state.Data);
		}
    },[location]);

	const getDrivingRange = () => {
        
		//연습장 평균 통계 데이터 조회 API
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {
				drivingRangeClub :selector.statisticReducer.drivingRangeClub,
				approachDistance:Object.values(common.commonKeyDic['approachDistance'])[selector.statisticReducer.approachDistance],
				straightPuttingDistance:Object.values(common.commonKeyDic['straightPuttingDistance'])[selector.statisticReducer.straightPuttingDistance]
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('연습장 평균 통계 데이터 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
			}
		}).then(function (response) {
			console.log(response.data);

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				//common.alertByError(lan.L('통계 정보'),response.data.error.code);
				return false;
			}
			else
			{
				SetInfoDataAVG(response.data.drivingRange);
			}
		}).catch(error => {
			console.log(error.response)
		});

        //alert(selector.statisticReducer.drivingRangeClub);`

		//연습장 드라이빙 레인지 통계 데이터 조회 API
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {
				page:1,
				pagesize:10,
                drivingRangeClub:selector.statisticReducer.drivingRangeClub
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('연습장 드라이빙 레인지 통계 데이터 조회'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token'),
			}
		}).then(function (response) {
			console.log(response.data);
			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError(lan.L('드라이빙 레인지'),response.data.error.code);
				return false;
			}
			else
			{
				SetInfoData(response.data);

                //평균 비거리
                var xylist = [];
                var mintemp;
                var empty = ''; //차트 상에 x값이 달라야 해서 빈 공간 추가
                for(let i=0; i < response.data.list.length; i++)
                {
                    empty = empty + ' '; 
                    xylist.unshift({x: common.UnixTime(response.data.list[i].gameRoundStartTime,'월.일')+empty ,y: response.data.list[i].averageTotalDistance});
                    if(i == 0)
                    {
                        mintemp = response.data.list[i].averageTotalDistance;
                    }
                    mintemp = Math.min(response.data.list[i].averageTotalDistance, mintemp);
                }
                Setymin1(mintemp);
                SetxyData1(xylist);

                //평균 볼스피드
                xylist = [];
                empty = '';
                for(let i=0; i < response.data.list.length; i++)
                {
                    empty = empty + ' '; 
                    xylist.unshift({x: common.UnixTime(response.data.list[i].gameRoundStartTime,'월.일')+empty ,y: common.Speed(response.data.list[i].averageBallSpeed,'0')});  
                    if(i == 0)
                    {
                        mintemp = common.Speed(response.data.list[i].averageBallSpeed,'0');
                    }
                    mintemp = Math.min(common.Speed(response.data.list[i].averageBallSpeed,'0'), mintemp);
                }
                Setymin2(mintemp); 
                SetxyData2(xylist);
                
                //평균 발사각
                xylist = [];
                empty = '';
                for(let i=0; i < response.data.list.length; i++)
                {
                    empty = empty + ' '; 
                    xylist.unshift({x: common.UnixTime(response.data.list[i].gameRoundStartTime,'월.일')+empty ,y: response.data.list[i].averageLaunchAngle});   
                    if(i == 0)
                    {
                        mintemp = response.data.list[i].averageLaunchAngle;
                    }
                    mintemp = Math.min(response.data.list[i].averageLaunchAngle, mintemp);
                }
                Setymin3(mintemp); 
                SetxyData3(xylist);
                
                //방향각
                xylist = [];
                empty = '';
                for(let i=0; i < response.data.list.length; i++)
                {
                    empty = empty + ' '; 
                    xylist.unshift({x: common.UnixTime(response.data.list[i].gameRoundStartTime,'월.일')+empty ,y: response.data.list[i].averageDirectionAngle});   
                    if(i == 0)
                    {
                        mintemp = response.data.list[i].averageDirectionAngle;
                    }
                    mintemp = Math.min(response.data.list[i].averageDirectionAngle, mintemp);
                }
                Setymin4(mintemp); 
                SetxyData4(xylist);
                
                //사이드 스핀
                xylist = [];
                empty = '';
                for(let i=0; i < response.data.list.length; i++)
                {
                    empty = empty + ' '; 
                    xylist.unshift({x: common.UnixTime(response.data.list[i].gameRoundStartTime,'월.일')+empty ,y: common.Speed(response.data.list[i].averageSideSpin,'0')});   
                    if(i == 0)
                    {
                        mintemp = common.Speed(response.data.list[i].averageSideSpin,'0');
                    }
                    mintemp = Math.min(common.Speed(response.data.list[i].averageSideSpin,'0'), mintemp);
                }
                Setymin5(mintemp); 
                SetxyData5(xylist);
                
                //백스핀
                xylist = [];
                empty = '';
                for(let i=0; i < response.data.list.length; i++)
                {
                    empty = empty + ' '; 
                    xylist.unshift({x: common.UnixTime(response.data.list[i].gameRoundStartTime,'월.일')+empty ,y: common.Speed(response.data.list[i].averageBackSpin,'0')});   
                    if(i == 0)
                    {
                        mintemp = common.Speed(response.data.list[i].averageBackSpin,'0');
                    }
                    mintemp = Math.min(common.Speed(response.data.list[i].averageBackSpin,'0'), mintemp);
                }
                Setymin6(mintemp); 
                SetxyData6(xylist);
			}
		}).catch(error => {
			console.log(error.response)
		});
	}
    
	//selector가 변경될때 상시 실행
	useEffect(() => {
		getDrivingRange();
	},[selector]);
	//selector가 변경될때 상시 실행 종료

	const {...rest} = props
	return (
        <>
        <Top class="convert turn" title={"드라이빙 레인지"}  labelText={lan.L(Object.values(common.commonKeyDic['recommandClub'])[selector.statisticReducer.drivingRangeClub])} labelFunc = {practiceslide.slidestatisticClub} to="/statistic/statistic"/> 
	    <BottomStatisticPrac defaultvalue="0"></BottomStatisticPrac>
        <div className="based">
            <div className="statistic_average" style={{backgroundImage:"url("+require('../images/sub/detail_driving.jpg')+")"}}>
                <div className="best_score">
                <span className={chartValue == lan.L('평균 비거리') ? "on" : ""} onClick={(e)=>SetchartValue(lan.L('평균 비거리'))}>
                    <b>{lan.L('평균 비거리')}</b>
                    <strong>{common.Length(InfoDataAVG.averageTotalDistance,'0')}</strong>
                    <small>{common.Length(0,'meter(m)')}</small>
                </span>
                <span className={chartValue == lan.L('평균 볼스피드') ? "on" : ""} onClick={(e)=>SetchartValue(lan.L('평균 볼스피드'))}>
                    <b>{lan.L('평균 볼스피드')}</b>
                    <strong>{common.Speed(InfoDataAVG.averageBallSpeed,'0')}</strong>
                    <small>{common.Speed(0,'m/s')}</small>
                </span>
                <span className={chartValue == lan.L('평균 발사각') ? "on" : ""} onClick={(e)=>SetchartValue(lan.L('평균 발사각'))}>
                    <b>{lan.L('평균 발사각')}</b>
                    <strong>{InfoDataAVG.averageLaunchAngle}</strong>
                    <small>{lan.L('angle( ˚ )')}</small>
                </span>
                </div>
                <div className="best_score">
                <span className={chartValue == lan.L('방향각') ? "on" : ""} onClick={(e)=>SetchartValue(lan.L('방향각'))}>
                    <b>{lan.L('방향각')}</b>
                    <strong>{InfoDataAVG.averageDirectionAngle}</strong>
                    <small>{lan.L('angle( ˚ )')}</small>
                </span>
                <span className={chartValue == lan.L('사이드 스핀') ? "on" : ""} onClick={(e)=>SetchartValue(lan.L('사이드 스핀'))}>
                    <b>{lan.L('사이드 스핀')}</b>
                    <strong>{InfoDataAVG.averageSideSpin}</strong>
                    <small></small>
                </span>
                <span className={chartValue == lan.L('백스핀') ? "on" : ""} onClick={(e)=>SetchartValue(lan.L('백스핀'))}>
                    <b>{lan.L('백스핀')}</b>
                    <strong>{InfoDataAVG.averageBackSpin}</strong>
                    <small></small>
                </span>
                </div>
            </div>
            <div className="detail_statistic">
                {/* No Data */}
                {
                    InfoData.list === undefined || InfoData.list.length == 0 ? 
                <div className="nodata">
                    <p><img src={require("../images/character/nodata_2.png")} /></p>
                    <strong>{lan.L('아직 플레이 기록이 없어요.')}</strong>
                    <span>{lan.L('가까운 매장에서 친구들과 라운드를 즐겨보세요.')}</span>
                </div>
                : ''
                }
                {/*//No Data */}

                {
                    InfoData.list === undefined ||InfoData.list.length == 0 ? '' :
                <div className="graph_wrap">
                    <em>{chartValue}</em>
                    {chartValue == lan.L('평균 비거리') ? <Barchart data={xyData1} min={ymin1}></Barchart> : ''}
                    {chartValue == lan.L('평균 볼스피드') ? <Barchart data={xyData2} min={ymin2}></Barchart> : ''}
                    {chartValue == lan.L('평균 발사각') ? <Barchart data={xyData3}min={ymin3}></Barchart> : ''}
                    {chartValue == lan.L('방향각') ? <Barchart data={xyData4} min={ymin4}></Barchart> : ''}
                    {chartValue == lan.L('사이드 스핀') ? <Barchart data={xyData5} min={ymin5}></Barchart> : ''}
                    {chartValue == lan.L('백스핀') ? <Barchart data={xyData6} min={ymin6}></Barchart> : ''}
                </div>
                }
                <ul className="status_by" style={{marginBottom: '48px'}}>
                {InfoData.list === undefined || InfoData.list.length == 0 ? '' :
            InfoData.list.map((url, i) => (
                <li>
                    <em>{common.UnixTime(InfoData.list[i].gameRoundStartTime,'년.월.일 (요일)')}</em>
                    <article>
                    <div className="data_score">
                        <span>
                        <b>{lan.L('비거리')}</b>
                        <strong>{common.Length(InfoData.list[i].averageTotalDistance,'0')}</strong>
                        <small>{common.Length(0,'meter(m)')}</small>
                        </span>
                        <span>
                        <b>{lan.L('볼스피드')}</b>
                        <strong>{common.Speed(InfoData.list[i].averageBallSpeed,'0')}</strong>
                        <small>{common.Speed(0,'m/s')}</small>
                        </span>
                        <span>
                        <b>{lan.L('발사각')}</b>
                        <strong>{InfoData.list[i].averageLaunchAngle}</strong>
                        <small>{lan.L('angle( ˚ )')}</small>
                        </span>
                    </div>
                    <div className="data_score">
                        <span>
                        <b>{lan.L('방향각')}</b>
                        <strong>{InfoData.list[i].averageDirectionAngle}</strong>
                        <small>{lan.L('angle( ˚ )')}</small>
                        </span>
                        <span>
                        <b>{lan.L('사이드 스핀')}</b>
                        <strong>{InfoData.list[i].averageSideSpin}</strong>
                        <small></small>
                        </span>
                        <span>
                        <b>{lan.L('백스핀')}</b>
                        <strong>{InfoData.list[i].averageBackSpin}</strong>
                        <small></small>
                        </span>
                    </div>
                    </article>
                </li>
                ))}
                </ul>
            </div>
        </div>
        </>
	)
}