import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

import BottomCountryPhone from '../popup/bottom_countryphone';
import * as slide from '../popup/bottom_countryphone';

import * as commonsms from '../common/script/common_sms.js';
import { createBrowserHistory } from 'history';

const minmobilenum = 10;
const maxcertnum = 6;
var certnum = "";
var sendcert = "N";
var certend = "N";
var limitTimer = 180, objLimitTimer;
var WrongCnt = 0;
var MaxWrongCnt = 5;

var navigate;
var historylistener;

//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
//타이머
//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
function TikTokLimitTime(){
	var objTimer = $("#Timer");

	var sMin = parseInt(limitTimer/60);
	var sSec = limitTimer-(sMin*60);

	if(sSec<0){sSec = limitTimer;}

	var strMin = "00";
	if(sMin>0){
		strMin = "0"+sMin;
		strMin = strMin.substring(strMin.length-2,strMin.length);
	}

	var strTime = "00";
	if(sSec>0){
		strTime = "0"+sSec;
		strTime = strTime.substring(strTime.length-2,strTime.length);
	}

	objTimer.show();
	objTimer.html(strMin+":"+strTime);

	if(limitTimer >0){
		objLimitTimer = setTimeout(function(){
			limitTimer--;
			TikTokLimitTime();
		}, 1000);
	}else{
		objTimer.html("00:00");
		common.alertpop(lan.L('비밀번호 찾기'),lan.L("입력시간을 초과하였습니다. \r\n인증번호를 재전송해주세요."));
		ResetCert();
		navigate("/common/script/backnavigation", {state : {back:'/login/find_pw'}, replace : true});
	}
}

function ResetCert(){
	sendcert = 'N';
	clearTimeout(objLimitTimer);
	limitTimer = 180;
	WrongCnt = 0;

	//$("#CertNo").val("");

	$("#Timer").hide();
	certnum = "";
}


export default function FindPW(){
	
	const location = useLocation();
	navigate = useNavigate();
	const history = createBrowserHistory();
	const [InfoData, SetInfoData] = useState({loginID : '', phone : '', phoneCountryValue : '' , phoneCountry : '', certno : ''});

	//화면전환시 실행
	useEffect(() => {
		checkAllPossible('82'+InfoData.phone,InfoData.certno);	

        if(historylistener === undefined)
        {
            historylistener = history.listen((location) => {
                if (history.action === "POP") {
					ResetCert();
                }
            });
        }
    },[location]);

	const onDelete = (e) => {
		var id = e.target.id;
	    var data = InfoData;
		
		if(id == "loginIDDel")
		{
			data.loginID = '';
			$("#loginID").val('');
			SetInfoData(data);
			$("#loginIDDel").hide();
		}
		else if(id == "phoneDel")
		{
			data.phone = '';
			$("#phone").val('');
			SetInfoData(data);
			$("#phoneDel").hide();
		}
	}

	const onChange = (e) => {
		  var id = e.target.id;
		  var value = e.target.value;
		  var data = InfoData;

		if(id == "phone")
		{
			if(value != '') $("#phoneDel").show();
			else $("#phoneDel").hide();

			data.phone = value;
			SetInfoData(data);
		}
		else if(id == "loginID")
		{
			if(value != '') $("#loginIDDel").show();
			else $("#loginIDDel").hide();

			data.loginID = value;
			SetInfoData(data);
		}
		else if(id == "CertNo")
		{
			data.certno = value;
			SetInfoData(data);
		}
		checkAllPossible(InfoData.phone, InfoData.certno);
	}
	  
	// 버튼
	const goFindPassword = (e) => {

		if($("#CertNo").val() != certnum)
		{
			common.alertpop(lan.L('비밀번호 찾기'),lan.L("인증번호가 일치하지 않습니다."));
			return false;
		}
		
		InfoData.phoneCountryValue = $("#phoneCountryValue").val();
		InfoData.phoneCountry = $("#phoneCountry").html();

		var CountryPhoneCode = "+"+common.getPhoneValueBySettingValue(InfoData.phoneCountryValue);

		ResetCert();
		navigate('/login/reset_pw',{state : {id : InfoData.loginID, phone : CountryPhoneCode + (InfoData.phone.substr(0,1) == 0 ? InfoData.phone.substr(1,InfoData.phone.length) : InfoData.phone), phoneCountry : InfoData.phoneCountry, phoneCountryValue : InfoData.phoneCountryValue},replace:true});

	}

	// 버튼
	const goCert = (e) => {
		window.localStorage.setItem('join_certnum','');
		

		if($("#phoneCountryValue").val() == '')
		{
			common.alertpop(lan.L('비밀번호 찾기'),lan.L("국가를 선택해주세요."));
			return false;
		}

		var CountryPhoneCode = "+"+common.getPhoneValueBySettingValue( $("#phoneCountryValue").val());

		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 ID 휴대폰 번호 매칭 검증'),
				'getplus' : 'id='+InfoData.loginID + '&mobilePhoneNumber=' + encodeURIComponent(CountryPhoneCode + (InfoData.phone.substr(0,1) == 0 ? InfoData.phone.substr(1,InfoData.phone.length) : InfoData.phone))
			}
		}).then(function (response) {
			console.log(response.data);
			var data = response.data;

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError('비밀번호 찾기',response.data.error.code);
				return false;
			}

			if(data.isMatchingVerification == 'false'|| data.isMatchingVerification == false)
			{
				common.alertpop(lan.L('비밀번호 찾기'),lan.L("회원정보를 확인 할 수 없습니다.")+lan.L("다시 확인해주세요."));
				return false;
			}
			
			ResetCert();
			sendcert = "Y";
			common.alertpop(lan.L('비밀번호 찾기'),lan.L('인증번호가 전송되었습니다.'));
			$("#certnoli").show();
			$("#btnCert").hide();
			$("#CertNo").val('');

			certnum = common.generateRandomCode(6); //인증번호 통신 필요

			commonsms.sendSMS(certnum,$("#phone").val(),window.localStorage.getItem('phoneCountryNumber'));
			
			$("#phoneDel").hide();
			$("#phone").attr('disabled',true);
			TikTokLimitTime();
			
		}).catch(error => {
			console.log(error.response)
		});
	}

	const goback = (e) => {

		ResetCert();
		navigate(-1);
	}
	
	return(
		<>
		<header class="topfix" >
			<button type="button" id="Back" onClick={goback} >Back</button>
			<em>{lan.L('비밀번호 찾기')}</em>
		</header>
		<BottomCountryPhone returntype="html" returnid="phoneCountry" returnvalueid="phoneCountryValue"></BottomCountryPhone>
		<div>
		  <div className="container">
			<div className="guidance">
			  <span>{lan.L('회원가입 시 등록한 전화번호가 아닐 경우 비밀번호를 찾을 수 없습니다.')}</span>
			</div>
			<ul className="write_form">
			  <li>
				<span>
				  <input type="text" id="loginID" onChange={onChange} placeholder={lan.L("아이디 입력")} />
				  <em>{lan.L('아이디')}</em>
				  <a id="loginIDDel" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
				</span>
			  </li>
			  <li>
				<span>
				  <input type="hidden" id="phoneCountryValue" value=""/>
				  <label onClick={(e) => {if(sendcert != "Y") {slide.slideOn()}}} id="phoneCountry">{lan.L('Country')}</label>
				  <em>{lan.L('국가')}</em>
				</span>
			  </li>
			  <li>
				<span>
				  <input type="number" id="phone" onChange={onChange} placeholder={lan.L("'-' 없이 입력")} onInput={(e) => {
				  e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');}}/>
				  <em>{lan.L('휴대폰번호')}</em>
				  <a id="phoneDel" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
				</span>
				<button type="button" className="btn_submit" id="btnCert" onClick={(e) => goCert(e)}>{lan.L('인증번호 전송')}</button>
			  </li>
			  <li id="certnoli" style={{display: 'none'}}>
				<span>
				  <input type="number" placeholder={lan.L("6자리 입력")} id="CertNo" maxLength={maxcertnum} onChange={onChange} onInput={(e) => {
					  if (e.target.value.length > e.target.maxLength)
						e.target.value = e.target.value.slice(0, e.target.maxLength);
					}}/>
				  <em>{lan.L('인증번호')}</em>
				  <sub id="Timer">03:00</sub>
				</span>
				<a className="resend" id="resend" onClick={(e) => goCert(e)}>{lan.L('인증번호 재전송')}</a>
			  </li>
			</ul>
		  </div>{/* container(e) */}
		  <div className="sticky_button">
			<button type="button" className="btn_standard" id="btnFindId"  onClick={goFindPassword}>{lan.L('확인N')}</button>
		  </div>
		</div>
		</>
	)
}

function checkAllPossible(phone,certno) {

	if(phone.length < minmobilenum)	$("#btnCert").attr('disabled',true);
	else	$("#btnCert").attr('disabled',false);

	$("#btnFindId").attr('disabled',true);
	if(certno.length == maxcertnum)
	{
		$("#btnFindId").attr('disabled',false);
	}
}