import * as lan from '../common/script/common_lan.js';

import * as common from '../common/script/common.js';

import $ from 'jquery';

import LinkButton from '../common/component/linkButton';
import React, {useState, Component, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

function Navigation(props) {
	const htmllist = [];
	const tolist = ['/default/default','/store/store','/cs/test','/statistic/statistic','']; //원래는 '/customer/list_board
	const namelist = [lan.L('홈'),lan.L('예약'),lan.L('QR'),lan.L('통계'),lan.L('전체')]; //통계
	const [activeNum, setactiveNum] = useState('');
	const location = useLocation();
	const navigate = useNavigate();
	
	//화면전환시 실행
	useEffect(() => {
		window.localStorage.setItem('fullMenuOn', 0);
		setactiveNum(props.toggle);
		window.localStorage.setItem('navigationNum',props.toggle);
		
		window.addEventListener('location', () => {
			if(activeNum != window.localStorage.getItem('navigationNum'))
			{
				setactiveNum(window.localStorage.getItem('navigationNum'));
			}
		});
    },[location]);
	
	useEffect(() => {
		window.dispatchEvent(new Event("storage"));
    });

	
	const onClickFullMenu = (e) => {
		if(window.localStorage.getItem('fullMenuOn') == 1)
		{
			FullMenuClose(e);
		}
		else
		{
			const prev = window.localStorage.getItem('navigationNum');
			window.localStorage.setItem('fullMenuOn', 1);
			window.localStorage.setItem('prev_navigationToList',tolist[prev]);
			window.localStorage.setItem('navigationNum',4);
			setactiveNum(window.localStorage.getItem('navigationNum'));
		}
	}
	
	const FullMenuClose = (e) => {
		e.preventDefault();
		window.localStorage.setItem('fullMenuOn', 0);
		navigate(window.localStorage.getItem('prev_navigationToList'));
	}
	
	const goQRScan = (e) => {

		var string1 = lan.L('안내선 안에 QR코드를 맞추면\n자동으로 인식됩니다.');
		var string2 = lan.L('본인인증이 확인된 휴대폰으로\n이용이 가능합니다.');
		var string1_and = lan.L('안내선 안에 QR코드를 맞추면<br>자동으로 인식됩니다.');
		var string2_and = lan.L('본인인증이 확인된 휴대폰으로<br>이용이 가능합니다.');

		if(common.isIOS()){
			common.iOSCallApp({appid:"qrScan", params:string1+'|@|'+string2});
		}
		else
		{
			try{common.andriodCallApp('qrScan',[string1_and,string2_and]);}catch (ex){}
		}
	}

	for(let i=0;i<5;i++)	{
		if(i===2)
		{
			htmllist.push(<a key={i.toString()} onClick={goQRScan} >QR</a>)
		}
		else if(i===4)	//전체는 슬라이드를 띄움
		{
			// if(sessionStorage.getItem('LessonbookCount') > 0)
			// {
			// 	htmllist.push(<button key={i.toString()} onClick={onClickFullMenu} type="button" className={activeNum==i ? "on new":"new"}>{namelist[i]}</button>);
			// }
			// else
			// {
				htmllist.push(<button key={i.toString()} onClick={onClickFullMenu} type="button" className={activeNum==i ? "on":""}>{namelist[i]}</button>);
			// }
		}
		else
		{
			htmllist.push(<LinkButton key={i.toString()} to={tolist[i]} type="button" className={activeNum==i ? "on":""}>{namelist[i]}</LinkButton>);
		}
	}

	// if(sessionStorage.getItem('lessonbookCountCheck') == "Y")
	// {
	// 	//취소했다가 오기 때문에 setLessonbookUser를 작동
	// 	if(common.isIOS()){
	// 		common.iOSCallApp({appid:"getLessonbookUserYN", params: "", funcname:"window.NativeInterface.getLessonbookUserYN"});
	// 	}
	// 	else
	// 	{
	// 		try{common.andriodCallApp('getLessonbookUserYN','');}
	// 		catch (ex){common.getLessonbookCount();}
	// 	}
	// 	sessionStorage.setItem('lessonbookCountCheck',"N");
	// }
	// else
	// {
	// 	if(sessionStorage.getItem('LessonbookCount') == null || sessionStorage.getItem('LessonbookCount') === undefined || sessionStorage.getItem('LessonbookCount') == 0)
	// 	{
	// 		$("#navigation").children().last().removeClass("new");
	// 		$("#i_store_new").hide();
	// 	}
	// 	else
	// 	{
	// 		$("#navigation").children().last().addClass("new");
	// 		$("#i_store_new").show();
	// 	}
	// }

	return (
	<nav id="navigation">
		{htmllist}
	</nav>
	);
}

export default Navigation