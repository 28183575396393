
import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

import { useDispatch, useSelector } from "react-redux";

//팝업 실행(/statistic/statistic.js에서 실행함)
export function slidestatisticClub(){
	$("html").addClass("overflowHidden");
	$("#pop_statisticclub").addClass("on");
}

export function slidestatisticApproach(){
	$("html").addClass("overflowHidden");
	$("#pop_statisticapproach").addClass("on");
}

export function slidestatisticStraight(){
	$("html").addClass("overflowHidden");
	$("#pop_statisticstraight").addClass("on");
}

//팝업 종료
export function slideExit(){
	$("html").removeClass("overflowHidden");
	$(".layerView").removeClass("on");
}

export default function BottomStatisticpractice(prop){
    
	const selector = useSelector((state) => state);
	const dispatch = useDispatch();

	const [statisticClubhtmllist, setstatisticClubhtmllist] = useState([]);
	const [statisticApproachhtmllist, setstatisticApproachhtmllist] = useState([]);
	const [statisticStraighthtmllist, setstatisticStraighthtmllist] = useState([]);

    //최초 실행
	useEffect(() => {
		var htmllist = [];
		//평균 기록 a태그를 htmllist로 넣어둠
		//드라이빙 레인지 a태그 추가
		for(let i =0; i < Object.keys(common.commonKeyDic['recommandClub']).length; i++)
		{
			if(Object.keys(common.commonKeyDic['recommandClub'])[i] == 35)
			{
				break; //퍼터는 제외됨
			}
			htmllist.push(
				<a key={i+"b"} >
				  <input type="radio" value={i} id={"statisticClub"+Object.keys(common.commonKeyDic['recommandClub'])[i]}
					data-key={Object.keys(common.commonKeyDic['recommandClub'])[i]} name={"bottomstatisticClub"}
					onClick={(e) => goSelectClub(e,i)} defaultChecked={Object.keys(common.commonKeyDic['recommandClub'])[i] == Object.keys(common.commonKeyDic['recommandClub'])[prop.defaultvalue] ? true : false}/>
				  <label htmlFor={"statisticClub"+Object.keys(common.commonKeyDic['recommandClub'])[i]}>{lan.L(Object.values(common.commonKeyDic['recommandClub'])[i])}</label>
				</a>
			);
		}
		if(statisticClubhtmllist.length == 0)
		{
			setstatisticClubhtmllist(htmllist);
		}
		//드라이빙 레인지 a태그 추가 종료

		//어프로치 a태그 추가
		htmllist = [];
		for(let i =0; i < Object.keys(common.commonKeyDic['approachDistance']).length; i++)
		{
			htmllist.push(
				<a key={i+"b"} >
				  <input type="radio" value={i} id={"statisticApproach"+Object.keys(common.commonKeyDic['approachDistance'])[i]}
					data-key={Object.keys(common.commonKeyDic['approachDistance'])[i]} name={"bottomstatisticApproach"}
					onClick={(e) => goSelectApproach(e,i)} defaultChecked={Object.keys(common.commonKeyDic['approachDistance'])[i] == Object.keys(common.commonKeyDic['approachDistance'])[18] ? true : false}/>
				  <label htmlFor={"statisticApproach"+Object.keys(common.commonKeyDic['approachDistance'])[i]}>{common.Length(Object.values(common.commonKeyDic['approachDistance'])[i],'0m')}</label>
				</a>
			);
		}
		if(statisticApproachhtmllist.length == 0)
		{
			setstatisticApproachhtmllist(htmllist);
		}
		//어프로치 a태그 추가 종료

		//직선퍼팅 a태그 추가
		htmllist = [];
		for(let i =0; i < Object.keys(common.commonKeyDic['straightPuttingDistance']).length; i++)
		{
			htmllist.push(
				<a key={i+"b"} >
				  <input type="radio" value={i} id={"statisticStraight"+Object.keys(common.commonKeyDic['straightPuttingDistance'])[i]}
					data-key={Object.keys(common.commonKeyDic['straightPuttingDistance'])[i]} name={"bottomstatisticStraight"}
					onClick={(e) => goSelectStraight(e,i)} defaultChecked={Object.keys(common.commonKeyDic['straightPuttingDistance'])[i] == Object.keys(common.commonKeyDic['straightPuttingDistance'])[4] ? true : false}/>
				  <label htmlFor={"statisticStraight"+Object.keys(common.commonKeyDic['straightPuttingDistance'])[i]}>{common.Length(Object.values(common.commonKeyDic['straightPuttingDistance'])[i],'0m')}</label>
				</a>
			);
		}
		if(statisticStraighthtmllist.length == 0)
		{
			setstatisticStraighthtmllist(htmllist);
		}
		//직선퍼팅 a태그 추가 종료
    },[]);
    //최초 실행 종료
    
    //클럽 라디오 선택
	const goSelectClub = (e, value) => {
		dispatch({type:'drivingRangeClub', value: value});
		slideExit();
	}
	
    //어프로치 선택
	const goSelectApproach = (e, value) => {
		dispatch({type:'approachDistance', value: value});
		slideExit();
	}
	
    //직선퍼팅 선택
	const goSelectStraight = (e, value) => {
		dispatch({type:'straightPuttingDistance', value: value});
		slideExit();
	}
    
	return (
		<>
        <div className="layerView" id="pop_statisticclub">
            <div className="bottom_sheet">
                <a onClick={slideExit}>close</a>
                <div className="bs_body">
                <em>{lan.L('클럽 선택')}</em>
                <div className="scroll_container">
                    <div className="list_selection">
                    {statisticClubhtmllist}
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="layerView" id="pop_statisticapproach">
            <div className="bottom_sheet">
                <a onClick={slideExit}>close</a>
                <div className="bs_body">
                <em>{lan.L('거리 선택')}</em>
                <div className="scroll_container">
                    <div className="list_selection">
                    {statisticApproachhtmllist}
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="layerView" id="pop_statisticstraight">
            <div className="bottom_sheet">
                <a onClick={slideExit}>close</a>
                <div className="bs_body">
                <em>{lan.L('거리 선택')}</em>
                <div className="scroll_container">
                    <div className="list_selection">
                    {statisticStraighthtmllist}
                    </div>
                </div>
                </div>
            </div>
        </div>
		</>
	)
}