import * as lan from '../common/script/common_lan.js';

import axios from 'axios';


function Test() {
	
/*
axios.get('http://localhost:5000',{
	params: {id:'test2'},
	headers: {
		'pUrl' : encodeURIComponent('회원 아이디 중복확인')
	},
}).then(function (response) {
	console.log(response);
});

*/

/*
axios({
	method: 'post',
	url: 'https://qa-auth.thekgolf.com/connect/token',
	data: {
		client_id:'kgolf_app_qa',
		client_secret:'kgolf_QAApp123!',
		grant_type:'password',
		scope:'API_Auth User_Auth offline_access openid',
		username:'test2',
		password:'1234',
		LoginPath:'app',
		LoginType:'normal'
	},
	headers: {
		'x-thekgolf-timezone' : '+09:00',
		'x-thekgolf-language' : 'ko-kr',
		'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
		'Content-Length': '195',
		'pUrl' : encodeURIComponent('로그인')
	}
}).then(function (response) {
	console.log(response);
}).catch(error => {
    console.log(error.response)
});
*/

/*
axios({
	method: 'post',
	url: process.env.REACT_APP_SERVER + '/api',
	data: {
		client_id:'kgolf_app_qa',
		client_secret:'kgolf_QAApp123!',
		grant_type:'password',
		scope:'API_Auth User_Auth offline_access openid',
		username:'test2',
		password:'1234',
		LoginPath:'app',
		LoginType:'normal'
	},
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
		'pUrl' : encodeURIComponent('로그인')
	}
}).then(function (response) {
	console.log(response);
}).catch(error => {
    console.log(error.response)
});
*/

axios({
	method: 'post',
	url: process.env.REACT_APP_SERVER + '/api',
	data: {id:'test2'},
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		'pUrl' : encodeURIComponent('회원 아이디 중복확인')
	}
}).then(function (response) {
	console.log(response);
}).catch(error => {
    console.log(error.response)
});


//axios.post('http://localhost:5000', 
//{
//	params: 
//	{
//		client_id:'kgolf_app_qa',
//		client_secret:'kgolf_QAApp123!',
//		grant_type:'password',
//		scope:'API_Auth User_Auth offline_access openid',
//		username:'test2',
//		password:'1234',
//		LoginPath:'app',
//		LoginType:'normal',
//	},
//	headers: {
//		'Content-Type' : 'x-www-form-urlencoded'
//	},
//}).then(function (response) {
//	console.log(response);
//});

	return (
	<></>
	);
}

export default Test;
