
const LanguageDic = {};

LanguageDic['케이골프 서비스를 이용하시려면 \n 로그인이 필요합니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['아이디'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['아이디 입력'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['비밀번호'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['비밀번호 입력'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}

LanguageDic['Beginner'] = {ko : '비기너', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['Amateur'] = {ko : '아마추어', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['SemiPro'] = {ko : '세미프로', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['Pro'] = {ko : '프로', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['TourPro'] = {ko : '투어프로', en : '', zh : '' , vi : '', ja : ''}

LanguageDic['Red'] = {ko : '레드', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['Yellow'] = {ko : '엘로우', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['White'] = {ko : '화이트', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['Blue'] = {ko : '블루티', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['Black'] = {ko : '블랙티', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['Junior'] = {ko : '주니어티', en : '', zh : '' , vi : '', ja : ''}

LanguageDic['Country'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}

LanguageDic['회원님의 상세정보를 추가로 등록 하시려면\n상세정보입력 버튼을 눌러주세요.\n로그인을 누르시면 회원가입이 완료되며\n로그인 화면으로 이동합니다.\n회원님의 상세정보는 로그인 후 회원정보수정을\n이용하여 등록하실 수 있습니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}

LanguageDic['닉네임은 특수문자를 제외한 4자 이상만 가능합니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['세션이 초기화되었습니다. 회원가입 절차를 다시 확인하세요.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['입력시간을 초과하였습니다. \r\n인증번호를 재전송해주세요.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['인증번호가 일치하지 않습니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['인증번호가 전송되었습니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['이미 사용중인 아이디 입니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['비밀번호는 6자 이상 숫자만 입력이 가능합니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['비밀번호는 숫자 6자리만 입력 가능합니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['숫자 6자리만 입력 가능합니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['로그인은 영문 4자 ~ 20자 이하만 입력가능합니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['세션이 초기화되었습니다. 상세정보입력을 다시 확인하세요.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['닉네임을 입력해 주세요.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['생년월일을 입력해 주세요.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['회원가입 정보가 초기화됩니다. 취소하시겠습니까?'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['아직 *'] = {ko : '아직 ', en : 'There is no ', zh : '还没有' , vi : 'Vẫn chưa có ', ja : 'まだ'}
LanguageDic['플레이 기록'] = {ko : '', en : 'play record', zh : '打球信息' , vi : 'kỷ lục', ja : 'プレイ記録'}
LanguageDic['이 없습니다.\n가까운 매장에서 친구들과 라운드를 즐겨보세요.*'] = {ko : '이 없습니다.\n가까운 매장에서 친구들과 라운드를 즐겨보세요.', en : ' yet. Enjoy the round with your friends at a nearby store.', zh : '。\n请在附近门店与朋友享受下场的快了吧。' , vi : '. \nHãy tận hưởng vòng đấu với bạn bè của bạn tại một cửa hàng gần đó.', ja : 'はありません。近くのお店で友達と一緒にラウンドを楽しんでください。'}

LanguageDic['비밀번호 변경이 완료되었습니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}

LanguageDic['아이디를 찾을 수 없습니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['회원정보를 확인 할 수 없습니다. 다시 확인해주세요.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['로그인화면으로 이동하시겠습니까?'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['선택'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['저장되었습니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['국가를 선택해주세요.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['회원정보를 확인 할 수 없습니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['4자 이상 20자 이하 영문, 숫자만 가능합니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['로그인은 영문, 숫자 4자 ~ 20자 이하만 입력가능합니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['회원님의 정보로 가입된 아이디가 있습니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['가입'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}
LanguageDic['사용가능합니다.'] = {ko : '', en : '', zh : '' , vi : '', ja : ''}

LanguageDic['로그인'] = {ko : '', en : 'Login', zh : '登录', vi : 'Đăng nhập', ja : 'ログイン'}
LanguageDic['케이골프 서비스를 이용하시려면 \n 로그인이 필요합니다.'] = {ko : '', en : 'You need to log in \n to use the K-Golf service.', zh : '使用K高尔夫前需要登录', vi : 'Bạn cần đăng nhập để sử dụng dịch vụ K-Golf.', ja : 'kgolfサービスを利用するにはログインが必要です。'}
LanguageDic['아이디'] = {ko : '', en : 'ID', zh : 'ID', vi : 'ID', ja : 'ID'}
LanguageDic['아이디 입력'] = {ko : '', en : 'Enter ID', zh : '请输入ID', vi : 'Nhập ID', ja : 'ID入力'}
LanguageDic['비밀번호'] = {ko : '', en : 'Password', zh : '密码', vi : 'Mật khẩu', ja : 'パスワード'}
LanguageDic['비밀번호 입력'] = {ko : '', en : 'Enter password', zh : '请输入密码', vi : 'Nhập mật khẩu', ja : 'パスワード入力'}
LanguageDic['자동로그인'] = {ko : '', en : 'Remember me', zh : '自动登录', vi : 'Đăng nhập tự động', ja : '自動ログイン'}
LanguageDic['아이디찾기'] = {ko : '', en : 'Find ID', zh : '查找 ID', vi : 'Tìm ID', ja : 'ID検索'}
LanguageDic['비밀번호찾기'] = {ko : '', en : 'Find Password', zh : '查找密码', vi : 'Tìm mật khẩu', ja : 'パスワード検索'}
LanguageDic['회원가입'] = {ko : '', en : 'Sign Up', zh : '加入会员', vi : 'Đăng ký', ja : '会員登録'}
LanguageDic['로그인은 영문 4자~20자 이하만 입력가능합니다.'] = {ko : '', en : 'Use 4-20 characters', zh : '登录限英文字母4~20字内', vi : 'Bạn chỉ có thể nhập từ 4 đến 20 ký tự', ja : 'ログインは英文4~20文字以下のみ入力可能です'}
LanguageDic['언어설정'] = {ko : '', en : 'Language', zh : '设置语言', vi : 'Ngôn ngữ', ja : '言語設定'}
LanguageDic['회원가입 시 등록한 전화번호가 아닐 경우 아이디를 찾을 수 없습니다.'] = {ko : '', en : 'Unable to find ID. Please check the phone number you registered with.', zh : '加入会员不是注册的电话号码时，无法查找ID', vi : 'Không thể tìm thấy ID của bạn. Vui lòng kiểm tra lại số điện thoại đã đăng ký', ja : '会員登録時に入力した電話番号ではない場合、IDが見つかりません'}
LanguageDic['국가'] = {ko : '', en : 'Country', zh : '国家', vi : 'quốc gia', ja : '国家'}
LanguageDic['휴대폰번호'] = {ko : '', en : 'Phone number', zh : '手机号码', vi : 'Số điện thoại', ja : '携帯番号'}
LanguageDic['\'-\' 없이 입력'] = {ko : '', en : 'Enter without \'-\'', zh : '缺\'-\'输入', vi : '\'-\' Vào mà không có', ja : '\'-\' 無しで入力'}
LanguageDic['인증번호 전송'] = {ko : '', en : 'Send authentication number', zh : '发送验证码', vi : 'Gửi mã xác thực', ja : '認証番号転送'}
LanguageDic['인증하기'] = {ko : '', en : 'Authentication', zh : '验证', vi : 'Xác thực', ja : '認証'}
LanguageDic['인증생략'] = {ko : '', en : 'Skip authentication', zh : '跳过身份验证', vi : 'Bỏ qua xác thực', ja : '認証省略'}
LanguageDic['확인'] = {ko : '', en : 'Next', zh : '确认', vi : 'Tiếp tục', ja : '確認'}
LanguageDic['인증번호가 전송되었습니다.'] = {ko : '', en : 'Authentication code has been sent.', zh : '已发送验证码', vi : 'Mã xác thực đã được gửi.', ja : '認証番号が送信されました'}
LanguageDic['인증번호 재전송'] = {ko : '', en : 'Send code again', zh : '重新发送验证码', vi : 'Gửi lại mã xác thực', ja : '認証番号の再送信'}
LanguageDic['정확한 휴대폰 번호를 입력하세요.'] = {ko : '', en : 'Please enter the correct mobile phone number.', zh : '请输入正确的手机号码', vi : 'Vui lòng nhập đúng số điện thoại di động.', ja : '正しい携帯電話番号を入力してください。'}
LanguageDic['인증절차 생략 시 ID 및 PW 찾기 기능을 사용할 수 없습니다.'] = {ko : '', en : 'If you skip the authentication process, you will not be able to use the ID and PW search function.', zh : '如果跳过认证过程，您将无法使用ID和PW搜索功能。', vi : 'Nếu bỏ qua quá trình xác thực, bạn sẽ không thể sử dụng chức năng tìm kiếm ID và PW.', ja : '認証手続きを省略するとID・PW検索機能が利用できなくなります。'}
LanguageDic['ID 및 PW를 숙지 부탁 드립니다.'] = {ko : '', en : 'Please remember your ID and PW.', zh : '请记下您的ID和密码。', vi : 'Hãy nhớ ID và PW của bạn.', ja : 'IDとPWを覚えてお願いします。'}
LanguageDic['ID 및 PW 분실 시 고객 센터로 문의 바랍니다.'] = {ko : '', en : 'If you cannot remember your ID and PW, please contact customer service.', zh : '如果您丢失ID或密码，请联系客服。', vi : 'Nếu bạn không thể nhớ ID và PW của mình, vui lòng liên hệ với bộ phận dịch vụ khách hàng.', ja : 'IDとPWを覚えていない場合は、カスタマーセンターにお問い合わせください。'}
LanguageDic['국가 선택'] = {ko : '', en : 'Select a country', zh : '选择国家', vi : 'Chọn quốc gia', ja : '国家選択'}
LanguageDic['취소'] = {ko : '', en : 'No', zh : '取消', vi : 'Huỷ bỏ', ja : 'キャンセル'}
LanguageDic['아이디찾기 결과'] = {ko : '', en : 'Find ID Results', zh : 'ID寻找结果', vi : 'Kết quả tìm kiếm ID', ja : 'ID検索結果'}
LanguageDic['회원님의 정보로 가입된\n아이디가 있습니다.'] = {ko : '', en : 'You already have\nan account with us.', zh : '您的信息已被加入会员', vi : 'Có một ID được đăng ký với thông tin của bạn.', ja : '会員様の情報で登録されたIDがあります'}
LanguageDic['가입'] = {ko : '', en : 'Joined', zh : '加入', vi : 'Tham gia', ja : '加入'}
LanguageDic['비밀번호가 기억나지 않으세요?'] = {ko : '', en : 'Forgot password?', zh : '请问忘记密码了吗？', vi : 'Quên mật khẩu?', ja : 'パスワードをお忘れの方'}
LanguageDic['비밀번호 찾기'] = {ko : '', en : 'Find Password', zh : '查找密码', vi : 'Tìm mật khẩu', ja : 'パスワード検索'}
LanguageDic['로그인하러 가기'] = {ko : '', en : 'Back to log in', zh : '转至登录', vi : 'Tới Đăng nhập', ja : 'ログインしに行く'}
LanguageDic['회원가입 시 등록한 전화번호가 아닐 경우 비밀번호를 찾을 수 없습니다.'] = {ko : '', en : 'Unable to find password. Please check the phone number you registered with.', zh : '加入会员不是注册的电话号码时，无法查找ID', vi : 'Không tìm thấy mật khẩu. Vui lòng kiểm tra lại số điện thoại đã đăng ký.', ja : '会員登録時に登録した電話番号ではない場合、パスワードが見つかりません'}
LanguageDic['비밀번호 재설정'] = {ko : '', en : 'Change Password', zh : '重置密码', vi : 'Đổi mật khẩu', ja : 'パスワード再設定'}
LanguageDic['새로운 비밀번호를\n입력해 주세요.'] = {ko : '', en : 'Please enter\na new password.', zh : '加入会员不是注册的电话号码时，无法查找ID', vi : 'Vui lòng nhập mật khẩu mới.', ja : '新しいパスワードを入力してください'}
LanguageDic['숫자 6자리만 입력 가능합니다.'] = {ko : '', en : 'Use at least 6 digits', zh : '限6位数字', vi : 'Nhập ít nhất 6 chữ số.', ja : '数字6桁のみ入力可能です'}
LanguageDic['비밀번호 확인'] = {ko : '', en : 'Confirm password', zh : '确认密码', vi : 'Xác nhận mật khẩu', ja : 'パスワード確認'}
LanguageDic['비밀번호 재입력'] = {ko : '', en : 'Re-enter password', zh : '重新输入密码', vi : 'Nhập lại mật khẩu', ja : 'パスワード再入力'}
LanguageDic['비밀번호 변경'] = {ko : '', en : 'Change Password', zh : '更改密码', vi : 'Đổi mật khẩu', ja : 'パスワード変更'}
LanguageDic['비밀번호가 일치합니다.'] = {ko : '', en : 'Passwords match', zh : '密码一致', vi : 'Mật khẩu khớp.', ja : 'パスワードが一致します'}
LanguageDic['비밀번호 변경 완료'] = {ko : '', en : 'Your password has been changed', zh : '更改密码完成', vi : 'Đổi mật khẩu hoàn tất', ja : 'パスワード変更完了'}
LanguageDic['비밀번호 변경이 완료되었습니다.'] = {ko : '', en : 'Password change is complete.', zh : '密码更改已完成', vi : 'Đổi mật khẩu đã hoàn tất.', ja : 'パスワードの変更が完了しました。'}
LanguageDic['비밀번호 변경이 완료되었습니다.\n로그인 화면으로 이동합니다.'] = {ko : '', en : 'Your password has been changed. Go to log in.', zh : '已完成更改密码，移动至登录页面。', vi : 'Đổi mật khẩu đã hoàn tất. Tới màn hình đăng nhập', ja : 'パスワードの変更が完了しました。 ログインページに移動します'}
LanguageDic['약관동의'] = {ko : '', en : 'Terms and Conditions', zh : '同意条款', vi : 'Điều khoản và điều kiện', ja : '規約同意'}
LanguageDic['전체동의'] = {ko : '', en : 'Agree All', zh : '全部同意', vi : 'Đồng ý', ja : '全体同意'}
LanguageDic['(필수) 회원 이용약관'] = {ko : '', en : '(Required) Membership Terms and Conditions', zh : '（必要）会员使用条款', vi : '(Cần thiết) Điều khoản và điều kiện thành viên', ja : '(必須)会員利用規約'}
LanguageDic['회원 이용약관 상세 영어 버전'] = {ko : '', en : 'Terms and Conditions', zh : '会员使用条款详细英文版', vi : 'Điều khoản và điều kiện thành viên', ja : '会員利用規約の詳細英語版'}
LanguageDic['(필수) 회원 개인정보 보호정책'] = {ko : '', en : '(Required) Personal Information Protection Policy', zh : '（必要）会员个人资料保护政策', vi : '(Cần thiết) Chính sách bảo mậtthông tin', ja : '（必須）プライバシーポリシー'}
LanguageDic['회원 개인정보 보호정책 상세 영어 버전'] = {ko : '', en : 'Privacy Policy', zh : '会员个人资料保护政策详细英文版', vi : 'Chính sách bảo mật quyền riêng tư', ja : 'プライバシーポリシー詳細 英語バージョン'}
LanguageDic['(필수) 제3자 정보제공 및 마케팅 수신 동의'] = {ko : '', en : '(Required) Third-party data provision & receive marketing message.', zh : '（必要）第三方信息提供及接受推送信息同意', vi : '(Yêu cầu) Đồng ý cung cấp thông tin của bên thứ ba và nhận thông tin quảng cáo', ja : '（必須）第三者情報提供及びメールマガジン受信同意'}
LanguageDic['제3자 정보제공 및 마케팅 수신동의 영어 버전'] = {ko : '', en : 'Third-party data provision & marketing message', zh : '第三方信息提供及接受推送信息同意英文版', vi : 'Cung cấp thông tin của bên thứ ba và nhận thông tin quảng cáo', ja : '第三者情報提供及びメールマガジン受信同意の英語版'}
LanguageDic['(선택) 위치 기반 서비스 이용약관'] = {ko : '', en : '(Optional) Location-based service terms and conditions', zh : '（可选）定位服务使用条款', vi : '(Tùy chọn) Điều khoản và điều kiện dịch vụ dựa trên vị trí', ja : '(選択）位置基盤サービス利用規約'}
LanguageDic['위치기반서비스 이용약관 영어버전'] = {ko : '', en : 'English version of location-based service terms and conditions', zh : '定位服务使用条款英文版', vi : 'Điều khoản và điều kiện dịch vụ dựa trên địa điểm', ja : '位置基盤サービス利用規約 英語バージョン'}
LanguageDic['다음'] = {ko : '', en : 'Next', zh : '下一步', vi : 'Tiếp tục', ja : '次へ'}
LanguageDic['동의하기'] = {ko : '', en : 'Agree', zh : '同意', vi : 'đồng ý', ja : '同意する'}
LanguageDic['아이디 등록하기'] = {ko : '', en : 'Register ID', zh : '注册ID', vi : 'Đăng ký ID', ja : 'ID登録'}
LanguageDic['4자 이상 20자 이하 영문만 가능합니다.'] = {ko : '', en : 'Use 4-20 characters and digits', zh : '限4字以上20字以下英文', vi : 'Nhập 4-20 ký tự', ja : '4文字以上20文字以下の英文のみ可能です'}
LanguageDic['6자 이상 숫자만 입력이 가능합니다.'] = {ko : '', en : 'Use at least 6 digits', zh : '限6字以上数字', vi : 'Nhập ít nhất 6 chữ số', ja : '6文字以上の数字のみ入力可能です'}
LanguageDic['영문 4자 이상 20자 이하만 입력 가능합니다.'] = {ko : '', en : 'Use 4-20 characters', zh : '限英文4字以上20字以下', vi : 'Nhập 4-20 ký tự', ja : '英文4文字以上20文字以下のみ入力可能です'}
LanguageDic['비밀번호는 6자리 이상 숫자만 입력이 가능합니다.'] = {ko : '', en : 'Use at least 6 digits', zh : '密码只限6字以上数字', vi : 'Nhập ít nhất 6 chữ số', ja : 'パスワードは6桁以上の数字のみ入力できます'}
LanguageDic['이미 사용중인 아이디입니다.'] = {ko : '', en : 'The ID is already in use.', zh : '已被使用的ID', vi : 'ID đã được sử dụng', ja : '既に使用中のIDです'}
LanguageDic['사용가능한 아이디입니다.'] = {ko : '', en : 'Available', zh : '可以使用的ID', vi : 'ID có sẵn', ja : '使用可能なIDです'}
LanguageDic['비밀번호가 일치하지 않습니다.'] = {ko : '', en : 'Passwords don’t match', zh : '密码不一致', vi : 'Mật khẩu không khớp', ja : 'パスワードが一致しません'}
LanguageDic['닉네임 등록하기'] = {ko : '', en : 'Create Nickname', zh : '注册昵称', vi : 'Đăng ký biệt danh', ja : 'ニックネーム登録'}
LanguageDic['닉네임을 입력해주세요.'] = {ko : '', en : 'Please enter your nickname.', zh : '请输入昵称', vi : 'Xin vui lòng nhập biệt danh của bạn', ja : 'ニックネームを入力してください'}
LanguageDic['닉네임'] = {ko : '', en : 'Nickname', zh : '昵称', vi : 'Biệt danh', ja : 'ニックネーム'}
LanguageDic['닉네임 입력'] = {ko : '', en : 'Enter Nickname', zh : '输入昵称', vi : 'Nhập biệt danh', ja : 'ニックネーム入力'}
LanguageDic['특수문자를 제외한 4자 이상만 가능합니다.'] = {ko : '', en : 'Use at least 4 characters. No special characters.', zh : '除特殊字符限4字以上', vi : 'Nhập ít nhất 4 ký tự. Không dùng ký tự đặc biệt', ja : '特殊文字を除く4文字以上のみ可能で'}
LanguageDic['이미 사용중인 닉네임입니다.'] = {ko : '', en : 'Nickname already in use.', zh : '已被使用的昵称', vi : 'Biệt danh đã được sử dụng', ja : '既に使用中のニックネームです'}
LanguageDic['사용가능한 닉네임입니다.'] = {ko : '', en : 'Available', zh : '可用的昵称。', vi : 'Biệt danh sẵn có', ja : '使用可能なニックネームです'}
LanguageDic['추가정보입력'] = {ko : '', en : 'Enter additional information', zh : '输入补充资料', vi : 'Nhập thêm thông tin', ja : '追加情報入力'}
LanguageDic['성별'] = {ko : '', en : 'Gender', zh : '性别', vi : 'Giới tính', ja : '性別'}
LanguageDic['남성'] = {ko : '', en : 'Male', zh : '男性', vi : 'Nam', ja : '男性'}
LanguageDic['여성'] = {ko : '', en : 'Female', zh : '女性', vi : 'Nữ', ja : '女性'}
LanguageDic['생년월일'] = {ko : '', en : 'Date of birth', zh : '出生日期', vi : 'Ngày tháng năm sinh', ja : '生年月日'}
LanguageDic['생년월일을 입력해주세요.'] = {ko : '', en : 'Please enter your date of birth.', zh : '请输入出生日期', vi : 'Xin vui lòng nhập ngày sinh của bạn', ja : '生年月日を入力してください。'}
LanguageDic['전화번호 인증'] = {ko : '', en : 'Confirm your phone', zh : '验证电话号码', vi : 'Xác thực số điện thoại', ja : '電話番号認証'}
LanguageDic['세션이 초기화되었습니다. 회원가입 절차를 다시 확인하세요.'] = {ko : '', en : 'Session timeout. Retry sign up.', zh : '会话初始化，请再确认会员加入程序', vi : 'Thời gian đã hết. Vui lòng đăng ký lại', ja : 'セッションが初期化されました。 会員登録の手続きをもう一度確認してください'}
LanguageDic['입력시간을 초과하였습니다. \r\n인증번호를 재전송해주세요.'] = {ko : '', en : 'The input time has been exceeded.\nPlease send the code again.', zh : '输入时间超时， \r\n再发送验证码', vi : 'Vượt quá thời gian.\nVui lòng gửi lại số xác thực.', ja : '入力時間を超えました。 \r\n認証番号を再送信してください。'}
LanguageDic['인증번호가 일치하지 않습니다.'] = {ko : '', en : 'Check your code and try again.', zh : '验证码不一致', vi : 'Kiễm tra mã xác thực và thử lại', ja : '認証番号が一致しません。'}
LanguageDic['국가를 선택하세요.'] = {ko : '', en : 'Please select a country.', zh : '请选择国家', vi : 'Vui lòng chọn một quốc gia', ja : '国を選択してください'}
LanguageDic['회원가입 정보가 초기화됩니다. 취소하시겠습니까?'] = {ko : '', en : 'Registration in progress. Are you sure you want to cancel?', zh : '加入会员信息初始化，确定要取消吗？', vi : 'Thông tin thành viên đã được khởi tạo. Anh có chắc là muốn hủy không?', ja : '会員登録情報が初期化されます。 キャンセルしますか'}
LanguageDic['로그인 페이지로 이동하시겠습니까?'] = {ko : '', en : 'Are you sure you want to go to the log in page?', zh : '确定移动至登录页面？', vi : 'Bạn có chắc muốn vào trang đăng nhập không?', ja : 'ログインページに移動しますか'}
LanguageDic['스크린 골프의 한계를 넘다'] = {ko : '', en : 'Go beyond the limits of screen golf', zh : '超过室内高尔夫上限', vi : 'Hơn cả một trận đấu golf giả lập', ja : 'スクリーンゴルフの限界を越える'}
LanguageDic['회원님의 상세정보를 추가로 등록 하시려면\n상세정보입력 버튼을 눌러주세요.\n로그인을 누르시면 회원가입이 완료되며\n로그인 화면으로 이동합니다.\n회원님의 상세정보는 로그인 후 회원정보수정을\n이용하여 등록하실 수 있습니다.'] = {ko : '', en : 'Please press Enter Details to input your further details. If you click on Login, your registration will be completed and you will be taken to the log in screen. You can still edit them after registration.', zh : '补充详细信息，请按详细信息输入键，按登录键完成会员加入并移动至登录画面。您的详细信息是登录以后使用会员信息修改登记。', vi : 'Vui lòng nhấn nút Nhập Chi tiết để đăng ký thêm thông tin chi tiết của bạn. Nếu bạn nhấp vào Đăng nhập, tư cách thành viên của bạn sẽ được hoàn tất và bạn sẽ được đưa đến màn hình đăng nhập. Thông tin chi tiết của bạn có thể được đăng ký bằng cách sử dụng sửa đổi thông tin thành viên sau khi đăng nhập.', ja : '会員様の詳細情報を追加で登録するには、詳細情報入力ボタンを押してください。 ログインをクリックすると会員登録が完了し、ログイン画面に移動します。 会員様の詳細情報はログイン後、会員情報修正を利用して登録することができます'}
LanguageDic['상세정보입력'] = {ko : '', en : 'Enter Details', zh : '请输入详细资料', vi : 'Nhập thông tin chi tiết', ja : '詳細情報入力'}
LanguageDic['회원가입을 완료 하시겠어요?'] = {ko : '', en : 'Would you like to complete your registration?', zh : '确定加入会员？', vi : 'Bạn có muốn hoàn thành đăng ký thành viên của mình không?', ja : '会員登録を完了しますか？'}
LanguageDic['로그인하기'] = {ko : '', en : 'Log in', zh : '登录', vi : 'đăng nhập', ja : 'ログインする'}
LanguageDic['세션이 초기화되었습니다. 상세정보입력을 다시 확인하세요.'] = {ko : '', en : 'Session timeout. Please check the details again.', zh : '会话初始化，请再次确认详细信息输入。', vi : 'Thời gian đã hết. Vui lòng kiểm tra lại các chi tiết một lần nữa.', ja : 'セッションが初期化されました。 詳細情報の入力をもう一度確認してください。'}
LanguageDic['주소입력'] = {ko : '', en : 'Enter Address', zh : '输入地址', vi : 'Nhập địa chỉ', ja : '住所入力'}
LanguageDic['주소'] = {ko : '', en : 'Address', zh : '地址', vi : 'Địa chỉ', ja : '住所'}
LanguageDic['우편번호'] = {ko : '', en : 'Postal code', zh : '邮政编号', vi : 'Mã bưu điện', ja : '郵便番号'}
LanguageDic['기본 주소'] = {ko : '', en : 'Address line 1', zh : '默认地址', vi : 'Địa chỉ mặc định', ja : '基本住所'}
LanguageDic['주소검색'] = {ko : '', en : 'Search Address', zh : '地址搜索', vi : 'Tìm kiếm địa chỉ', ja : '住所検索'}
LanguageDic['나머지 주소'] = {ko : '', en : 'Address line 2', zh : '其他地址', vi : 'Địa chỉ còn lại', ja : '残りの住所'}
LanguageDic['라운드 설정'] = {ko : '', en : 'Round Settings', zh : '下场设置', vi : 'Thiết lập vòng chơi', ja : 'ラウンド設定'}
LanguageDic['난이도'] = {ko : '', en : 'Difficulty', zh : '难易度', vi : 'Độ khó', ja : '難易度'}
LanguageDic['티 위치'] = {ko : '', en : 'Tee Box', zh : '发球台位置', vi : 'Vị trí tee', ja : 'ティー位置'}
LanguageDic['티 높이'] = {ko : '', en : 'Tee Height', zh : '发球台高度', vi : 'Chiều cao tee', ja : 'ティーの高さ'}
LanguageDic['저장'] = {ko : '', en : 'Save', zh : '保存', vi : 'Lưu trữ', ja : '保存'}
LanguageDic['저장되었습니다.'] = {ko : '', en : 'Saved', zh : '已保存', vi : 'Đã lưu', ja : '保存されました'}
LanguageDic['라운드 콘텐츠 중 사용자의 샷에 대한 보정을 얼마나 받을지 선택 할 수 있습니다. \n투어프로로 이동하면 보정값이 감소합니다.'] = {ko : '', en : 'You can choose how much of the round content you want to be compensated for your shots. The calibration value decreases as you move to the Tour Pro.', zh : '下场设置中可根据使用者对打球校准的需要选择。\n移动至参加巡回赛职业选手时，校准值会降低。', vi : 'Bạn có thể chọn số lượng nội dung mà bạn muốn được đền bù cho những cú đánh của mình.\nGiá trị hiệu chuẩn giảm dần khi bạn chuyển sang Tour Pro.', ja : 'ラウンドコンテンツの中から、ユーザーのショットに対する補正をどれくらい受けるかを選択できます。 \nツアー プロに移動すると、補正値が減少します。'}
LanguageDic['라운드 콘텐츠에서 사용할 티그라운드를 선택합니다.'] = {ko : '', en : 'Under Round Content, select the T-ground to use.', zh : '下场设置中可选择要使用的发球台。', vi : 'Trong Nội dung vòng chơi, chọn T-ground để sử dụng', ja : 'ラウンドコンテンツで使用するティーグラウンドを選択します'}
LanguageDic['라운드 내용에서 티샷할 때 사용할 티 높이를 선택합니다.\n라운딩 중에 계측기의 티 높이를 변경 할 수 있습니다.'] = {ko : '', en : 'Under Round Content, select the tee height you want to use when teeing.\nYou can change the tee height of the instrument during rounding.', zh : '下场设置中选择发球时的发球台高度。下场过程中可更改计测器的发球台高度。', vi : 'Trong Nội dung vòng chơi, vui lòng chọn độ cao của tee.\nBạn có thể thay đổi chiều cao của tee trong quá trình chơi', ja : 'ラウンド内容からティーショットするときに使用するティーの高さを選択します。\nラウンド中に計測器のティー高さを変更できます'}
LanguageDic['My Profile'] = {ko : '', en : 'My Profile', zh : '我的资料', vi : 'Thông tin Thành viên', ja : 'My Profile'}
LanguageDic['AVG'] = {ko : '', en : 'AVG', zh : 'AVG', vi : 'AVG', ja : 'AVG'}
LanguageDic['BEST'] = {ko : '', en : 'BEST', zh : '最高', vi : 'BEST', ja : 'BEST'}
LanguageDic['POINT'] = {ko : '', en : 'POINT', zh : '点', vi : 'POINT', ja : 'POINT'}
LanguageDic['score'] = {ko : '', en : 'score', zh : '积分', vi : 'Điểm số', ja : 'score'}
LanguageDic['스트로크'] = {ko : '', en : 'Stroke', zh : '比杆', vi : 'Gậy', ja : 'ストローク'}
LanguageDic['드라이버'] = {ko : '', en : 'Driver', zh : '木杆', vi : 'Gậy Driver', ja : 'ドライバー'}
LanguageDic['스트레이트'] = {ko : '', en : 'Straight', zh : '直线', vi : 'Thẳng', ja : 'ストレート'}
LanguageDic['Distance'] = {ko : '', en : 'Distance', zh : '距离', vi : 'Khoảng cách', ja : '距离'}
LanguageDic['Ball Speed'] = {ko : '', en : 'Ball Speed', zh : '球速', vi : 'Tốc độ Bóng', ja : 'ボールスピード'}
LanguageDic['Launch Ang'] = {ko : '', en : 'Launch Ang', zh : '发射角', vi : 'Góc phóng', ja : 'ランチアングル'}
LanguageDic['예약정보'] = {ko : '', en : 'Scheduled Appointments', zh : '预订信息', vi : 'Thông tin đặt chỗ', ja : '予約情報'}
LanguageDic['스크린'] = {ko : '', en : 'Game', zh : '屏幕', vi : 'Màn hình', ja : 'スクリーン'}
LanguageDic['연습'] = {ko : '', en : 'Practice', zh : '练习', vi : 'Tập luyện', ja : '練習'}
LanguageDic['레슨'] = {ko : '', en : 'Lesson', zh : '上课', vi : 'bài học', ja : 'レッスン'}

LanguageDic['screen'] = {ko : '스크린', en : 'Screen', zh : '屏幕', vi : 'Màn hình', ja : 'スクリーン'}
LanguageDic['practice'] = {ko : '연습', en : 'Practice', zh : '实践', vi : 'Thực hành', ja : '練習'}
LanguageDic['lesson'] = {ko : '레슨', en : 'Lesson', zh : '教训', vi : 'bài học', ja : 'レッスン'}

LanguageDic['매장찾기'] = {ko : '', en : 'Find a store', zh : '查找门店', vi : 'Tìm cửa hàng', ja : '店舗情報'}
LanguageDic['근처매장'] = {ko : '', en : 'Nearby store', zh : '附近门店', vi : 'Cửa hàng gần nhất', ja : '近くの店舗'}
LanguageDic['방문매장'] = {ko : '', en : 'Visited store', zh : '访问门店', vi : 'Tham quan cửa hàng', ja : '訪問売場'}
LanguageDic['전체메뉴'] = {ko : '', en : 'Menu', zh : '全部菜单', vi : 'Thực đơn', ja : '全メニュー'}
LanguageDic['나의 기록실'] = {ko : '', en : 'My Archives', zh : '我的记录室', vi : 'Kho lưu trữ của tôi', ja : '私の記録室'}
LanguageDic['나의 샷 정보'] = {ko : '', en : 'My Shots', zh : '我的打球信息', vi : 'Cú đánh của tôi', ja : '私のショット情報'}
LanguageDic['통계 정보'] = {ko : '', en : 'Statistics', zh : '统计信息', vi : 'Thống kê', ja : '統計情報'}
LanguageDic['대회'] = {ko : '', en : 'Tournament', zh : '比萨', vi : 'Giải đấu', ja : '大会'}
LanguageDic['매장 예약정보'] = {ko : '', en : 'Store Reservation Information', zh : '门店预订信息', vi : 'Lưu trữ thông tin đặt chỗ', ja : '店舗 予約情報'}
LanguageDic['공지사항'] = {ko : '', en : 'Notice', zh : '公告内容', vi : 'Thông báo', ja : 'お知らせ'}
LanguageDic['설정'] = {ko : '', en : 'Settings', zh : '设置', vi : 'Cài đặt', ja : '設定'}
LanguageDic['안내선 안에 QR코드를 맞추면<br>자동으로 인식됩니다.'] = {ko : '', en : 'Scan QR code', zh : '对准框内的二维码自动识别', vi : 'Quét mã QR', ja : '案内線の中にQRコードを合わせると自動的に認識されます'}
LanguageDic['안내선 안에 QR코드를 맞추면\n자동으로 인식됩니다.'] = {ko : '', en : 'It will be automatically recognized\nif you match the QR code inside the guide line.', zh : '对准框内的二维码自动识别', vi : 'Quét mã QR', ja : '案内線の中にQRコードを合わせると自動的に認識されます'}
LanguageDic['본인인증이 확인된 휴대폰으로<br>이용이 가능합니다.'] = {ko : '', en : 'Log in with your phone after verification.', zh : '需使用本人验证过的手机', vi : 'Đăng nhập bằng điện thoại sau khi xác thực thành công', ja : '本人認証が確認された携帯電話で利用できます'}
LanguageDic['본인인증이 확인된 휴대폰으로\n이용이 가능합니다.'] = {ko : '', en : 'Log in with your phone after verification.', zh : '需使用本人验证过的手机', vi : 'Đăng nhập bằng điện thoại sau khi xác thực thành công', ja : '本人認証が確認された携帯電話で利用できます'}

LanguageDic['QR로그인 완료'] = {ko : '', en : 'QR Login Completed', zh : '二维码登录完毕', vi : 'Hoàn tất đăng nhập QR', ja : 'QRログイン完了'}
LanguageDic['QR 로그인되었습니다.\n게임을 시작하세요.'] = {ko : '', en : 'You are logged in. Start game.', zh : '二维码登录完毕，请开始游戏。', vi : 'Bạn đã đăng nhập. Bắt đầu trò chơi.', ja : 'ORログインされました。 ゲームを始めてください。'}
LanguageDic['QR로그인 실패'] = {ko : '', en : 'QR Login Failed', zh : '二维码登录失败', vi : 'Lỗi đăng nhập QR', ja : 'QRログイン失敗'}
LanguageDic['로그인정보를 확인 할 수 없습니다. 다시 확인해주세요.'] = {ko : '', en : 'Unable to verify login information. Please check and try again.', zh : '无法验证登录信息，请再确认一下。', vi : 'Không thể xác minh thông tin đăng nhập. Vui lòng kiểm tra lại.', ja : 'ログイン情報を確認できません. もう一度確認をお願いします.'}
LanguageDic['전체'] = {ko : '', en : 'All', zh : '全部', vi : 'Tất cả', ja : '全体'}
LanguageDic['일반'] = {ko : '', en : 'General', zh : '一般', vi : 'Chung', ja : '一般'}
LanguageDic['포섬'] = {ko : '', en : 'Foursomes', zh : '4人2球', vi : 'Foursome', ja : 'フォーサム'}
LanguageDic['스트로크 라운드 기록'] = {ko : '', en : 'Stroke Round Record', zh : '比杆回合记录', vi : 'Kết quả đấu gậy', ja : 'ストロークラウンド記録'}
LanguageDic['포섬 라운드 기록'] = {ko : '', en : 'Foursomes Round Record', zh : '4人2球回合记录', vi : 'Kết quả Foursome', ja : 'フォーサム·ラウンド記録'}
LanguageDic['스코어카드 전체보기'] = {ko : '', en : 'Full Score Card', zh : '计分卡查找全部', vi : 'Xem bảng điểm', ja : 'スコアカード全体を見る'}
LanguageDic['미니맵'] = {ko : '', en : 'Mini Map', zh : '小地图', vi : 'Sơ đồ', ja : 'ミニマップ'}
LanguageDic['스코어'] = {ko : '', en : 'Score', zh : '成绩', vi : 'Điểm số', ja : 'スコア'}
LanguageDic['그린적중율'] = {ko : '', en : 'Greens In Regulation %', zh : '果岭命中率', vi : 'Tỉ lệ vào green', ja : 'パーオン率'}
LanguageDic['퍼팅수'] = {ko : '', en : 'Putting Average', zh : '推杆数', vi : 'Điểm Putt trung bình', ja : 'パッティング数'}
LanguageDic['페어웨이안착율'] = {ko : '', en : 'Fairways In Regulation %', zh : '上球道率', vi : 'Tỉ lệ vào Fairway', ja : 'フェアウェイ安着率'}
LanguageDic['파3 평균타수'] = {ko : '', en : 'Par 3 Avg', zh : '标准杆3平均杆数', vi : 'Điểm số trung bình par 3', ja : 'パー3平均打数'}
LanguageDic['파 이하'] = {ko : '', en : 'Par or less', zh : '标准杆以下', vi : 'Par hoặc ít gậy hơn', ja : 'パー以下'}
LanguageDic['파4 평균타수'] = {ko : '', en : 'Par 4 Avg', zh : '标准杆4平均杆数', vi : 'Điểm số trung bình par 4', ja : 'パー4平均打数'}
LanguageDic['보기'] = {ko : '', en : 'Bogey', zh : '超一杆', vi : 'Bogey', ja : 'ボギー'}
LanguageDic['파5 평균타수'] = {ko : '', en : 'Par 5 Avg', zh : '标准杆5平均杆数', vi : 'Điểm số trung bình par 5', ja : 'パー5平均打数'}
LanguageDic['더블보기이상'] = {ko : '', en : 'Double bogey or more', zh : '双柏忌以上', vi : 'Double Bogey hoặc nhiều gậy hơn', ja : 'ダブルボギー以上'}
LanguageDic['매장명, 주소검색'] = {ko : '', en : 'Search with store or address', zh : '搜索门店名、地址', vi : 'Tên cửa hàng, địa chỉ tìm kiếm', ja : '店舗名、住所検索'}
LanguageDic['검색조건'] = {ko : '', en : 'Filter', zh : '搜索条件', vi : 'Điều kiện tìm kiếm', ja : '検索条件'}
LanguageDic['유형'] = {ko : '', en : 'Type', zh : '类型', vi : 'đánh máy', ja : 'タイプ'}
LanguageDic['거리별'] = {ko : '', en : 'Distance', zh : '按距离', vi : 'Khoảng cách', ja : '距離別'}
LanguageDic['반경'] = {ko : '', en : ' ', zh : '公里', vi : 'bán kính', ja : '半径'}
LanguageDic['5km 반경'] = {ko : '', en : '5 km', zh : '5 km', vi : '5 km', ja : '5km'}
LanguageDic['10km 반경'] = {ko : '', en : '10 km', zh : '10 km', vi : '10 km', ja : '10km'}
LanguageDic['20km 반경'] = {ko : '', en : '20 km', zh : '20 km', vi : '20 km', ja : '20km'}
LanguageDic['30km 반경'] = {ko : '', en : '30 km', zh : '30 km', vi : '30 km', ja : '30km'}
LanguageDic['최근 방문한 매장이 없어요'] = {ko : '', en : 'No recently visited store', zh : '没有近期访问的门店', vi : 'Gần đây tôi không đến cửa hàng nào cả', ja : '最近訪れた店舗がありません。'}
LanguageDic['가까운 매장을 이용해보세요.'] = {ko : '', en : 'Try a nearby store', zh : '轻查找附近门店', vi : 'Thử tìm ở cửa hàng gần đó xem.', ja : '近くの店舗をご利用ください。'}
LanguageDic['전화하기'] = {ko : '', en : 'Call', zh : '电话', vi : 'Gọi điện', ja : '電話する'}
LanguageDic['예약하기'] = {ko : '', en : 'Book', zh : '约定', vi : 'đặt chỗ', ja : '予約する'}
LanguageDic['편의시설 및 서비스'] = {ko : '', en : 'Facilities and Services', zh : '便利设施及服务', vi : 'tiện nghi và dịch vụ', ja : '便益施設及びサービス'}
LanguageDic['스윙\n플레이트'] = {ko : '', en : 'Swing\nplate', zh : '挥杆垫', vi : 'Bàn mô\nphỏng địa hình', ja : 'スイングプレート'}
LanguageDic['스윙 플레이트'] = {ko : '', en : 'Swing plate', zh : '挥杆垫', vi : 'Bàn mô phỏng địa hình', ja : 'スイングプレート'}
LanguageDic['좌타우타'] = {ko : '', en : 'Left-handed plate', zh : '左撇子右撇子', vi : 'Bàn mô phỏng địa hình tay trái', ja : '左打右打'}
LanguageDic['주차가능'] = {ko : '', en : 'Parking', zh : '可供停车', vi : 'Bãi đậu xe', ja : '駐車可能'}
LanguageDic['장비보관'] = {ko : '', en : 'Locker', zh : '保存装备', vi : 'Locker', ja : '装備保管'}
LanguageDic['프로레슨'] = {ko : '', en : 'Lesson', zh : '专业课', vi : 'Lớp học', ja : 'プロレッスン'}
LanguageDic['운영시간'] = {ko : '', en : 'Opening Hours', zh : '营业时间', vi : 'Giờ làm việc', ja : '営業時間'}
LanguageDic['이용요금'] = {ko : '', en : 'Fee', zh : '使用费', vi : 'Lệ phí', ja : '利用料金'}
LanguageDic['매장전경'] = {ko : '', en : 'Panoramic view of the store', zh : '门店全景', vi : 'toàn cảnh cửa hàng', ja : '店舗全景'}
LanguageDic['매장위치'] = {ko : '', en : 'Location', zh : '门店位置', vi : 'Địa điểm', ja : '店舗位置'}
LanguageDic['매장 위치'] = {ko : '', en : 'Location', zh : '店铺位置', vi : 'Địa điểm lưu trữ', ja : '店舗位置'}
LanguageDic['주소복사'] = {ko : '', en : 'Copy address', zh : '复制地址', vi : 'Sao chép địa chỉ', ja : '住所コピー'}
LanguageDic['길찾기'] = {ko : '', en : 'Finding a way', zh : '查找路线', vi : 'Định vị', ja : 'アクセス'}
LanguageDic['위치공유'] = {ko : '', en : 'Location sharing', zh : '共享位置', vi : 'Chia sẻ vị trí', ja : '位置共有'}
LanguageDic['주차안내'] = {ko : '', en : 'Parking information', zh : '停车说明', vi : 'Thông tin đỗ xe', ja : '駐車案内'}
LanguageDic['매장소개'] = {ko : '', en : 'About', zh : '门店介绍', vi : 'Giới thiệu cửa hàng', ja : '店舗紹介'}
LanguageDic['매장사진'] = {ko : '', en : 'Overview', zh : '门店照片', vi : 'Tổng quan', ja : '店舗写真'}
LanguageDic['샷 정보'] = {ko : '', en : 'My Shots', zh : '打球信息', vi : 'Cú đánh của tôi', ja : 'ショット情報'}
LanguageDic['라운드'] = {ko : '', en : 'Round', zh : '下场', vi : 'Vòng đấu', ja : 'ラウンド'}
LanguageDic['경기 방식 선택'] = {ko : '', en : 'Select Game', zh : '选择比赛方式', vi : 'Lựa chọn vòng chơi', ja : '競技方式選択'}
LanguageDic['방식 선택'] = {ko : '', en : 'Select Game', zh : '选择方式', vi : 'Lựa chọn vòng chơi', ja : '方式選択'}
LanguageDic['골프 클럽 선택'] = {ko : '', en : 'Select Club', zh : '选择高尔夫球杆', vi : 'Lựa chọn gậy', ja : 'ゴルフクラブ選択'}
LanguageDic['클럽 선택'] = {ko : '', en : 'Select a club', zh : '选择球杆', vi : 'Lựa chọn gậy', ja : 'クラブ選択'}
LanguageDic['우드/아이언'] = {ko : '', en : 'Wood/Iron', zh : '木杆/铁杆', vi : 'Gậy gỗ/gậy sắt', ja : 'ウッド/アイアン'}
LanguageDic['퍼터'] = {ko : '', en : 'Putter', zh : '推杆', vi : 'Gậy Putt', ja : 'パター'}
LanguageDic['시작위치'] = {ko : '', en : 'Start position', zh : '始点', vi : 'Vị trí xuất phát', ja : '開始位置'}
LanguageDic['비거리'] = {ko : '', en : 'Distance', zh : '到达点', vi : 'khoảng cách', ja : '飛距離'}
LanguageDic['도착위치'] = {ko : '', en : 'Arrival Location', zh : '抵达地点', vi : 'Địa điểm đến', ja : '到着位置'}
LanguageDic['저장영상만보기'] = {ko : '', en : 'View only saved images', zh : '只查看已保存的影像', vi : 'Chỉ xem ảnh đã lưu', ja : '保存映像だけを見る'}
LanguageDic['저장된 샷 정보가 없어요.'] = {ko : '', en : 'There is no saved shot information.', zh : '没有保存的打球信息', vi : 'Không có cú đánh nào được lưu trữ', ja : '保存されたショット情報がありません。'}
LanguageDic['가까운 매장에서 플레이 해보세요.'] = {ko : '', en : 'Try playing at a nearby store.', zh : '请使用附近门店', vi : 'Thử chơi ở cửa hàng gần nhất', ja : '近くの店舗でプレイしてみてください。'}
LanguageDic['라운드 샷 정보'] = {ko : '', en : 'Round Shot', zh : '下场打球信息', vi : 'Thông tin vòng đấu', ja : 'ラウンドショット情報'}
LanguageDic['보관'] = {ko : '', en : 'Save', zh : '保存', vi : 'Lưu trữ', ja : '保管'}
LanguageDic['정면'] = {ko : '', en : 'Front', zh : '正面', vi : 'Mặt trận', ja : '正面'}
LanguageDic['측면'] = {ko : '', en : 'Side', zh : '侧面', vi : 'khía cạnh', ja : '側面'}
LanguageDic['인게임'] = {ko : '', en : 'In game', zh : '游戏内', vi : 'Điểm bắt đầu', ja : 'ゲーム内'}
LanguageDic['세레머니'] = {ko : '', en : 'Ceremony', zh : '仪式', vi : 'lễ', ja : 'セレモニー'}
LanguageDic['시작지점'] = {ko : '', en : 'Starting spot', zh : '始点', vi : 'Điểm bắt đầu', ja : 'スタート地点'}
LanguageDic['도착지점'] = {ko : '', en : 'Landing spot', zh : '终点', vi : 'Điểm rơi', ja : '到着地点'}
LanguageDic['캐리거리'] = {ko : '', en : 'Carry', zh : '飞行距离', vi : 'Khoảng cách bóng bay', ja : 'キャリー距離'}
LanguageDic['볼스피드'] = {ko : '', en : 'Ball speed', zh : '球速', vi : 'Tốc độ bóng', ja : 'ボールスピード'}
LanguageDic['구질'] = {ko : '', en : 'Ball Flight', zh : '球质量', vi : 'Đường bóng', ja : '球質'}
LanguageDic['백스핀'] = {ko : '', en : 'Back Spin', zh : '后旋', vi : 'Độ xoáy', ja : 'バックスピン'}
LanguageDic['사이드스핀'] = {ko : '', en : 'Side Spin', zh : '侧旋', vi : 'Độ xoáy ngang', ja : 'サイドスピン'}
LanguageDic['발사각'] = {ko : '', en : 'Launch Angle', zh : '发射角', vi : 'góc phóng', ja : '発射角'}
LanguageDic['방향각'] = {ko : '', en : 'Direction Angle', zh : '方向角', vi : 'Góc tấn công', ja : '方向角'}
LanguageDic['동영상 파일이 없습니다.'] = {ko : '', en : 'No video file', zh : '没有视频文件', vi : 'Video không tồn tại', ja : '動画ファイルがありません。'}
LanguageDic['드라이빙 레인지'] = {ko : '', en : 'Driving Range', zh : '高尔夫球练习场', vi : 'Sân tập', ja : 'ドライビングレンジ'}
LanguageDic['드라이빙레인지'] = {ko : '', en : 'Driving Range', zh : '高尔夫球练习场', vi : 'Sân tập', ja : 'ドライビングレンジ'}
LanguageDic['어프로치'] = {ko : '', en : 'Approach', zh : '切球', vi : 'Cú đánh công cờ', ja : 'アプローチ'}
LanguageDic['남은거리'] = {ko : '', en : 'Remaining Distance', zh : '剩余距离', vi : 'Khoảng cách còn lại', ja : '残りの距離'}
LanguageDic['직선퍼팅'] = {ko : '', en : 'Straight Putting', zh : '只限推杆', vi : 'Putt thẳng', ja : '直線パッティング'}
LanguageDic['곡선퍼팅'] = {ko : '', en : 'Curved Putting', zh : '弧线推杆', vi : 'Putt địa hình', ja : '曲線パッティング'}
LanguageDic['숏 게임'] = {ko : '', en : 'Short Game', zh : '短球游戏', vi : 'Short game', ja : 'ショートゲーム'}
LanguageDic['연습 샷 정보'] = {ko : '', en : 'Practice Information', zh : '练习打球信息', vi : 'Thông tin tập luyện', ja : '練習ショット情報'}
LanguageDic['대회리스트'] = {ko : '', en : 'List of Tournaments', zh : '比赛清单', vi : 'Danh sách các giải đấu', ja : '大会リスト'}
LanguageDic['참가한대회'] = {ko : '', en : 'Completed Tournaments', zh : '参加过的比赛', vi : 'Các giải đấu đã tham gia', ja : '参加した大会'}
LanguageDic['대회 선택'] = {ko : '', en : 'Tournament Type', zh : '选择比赛', vi : 'Lựa chọn giải đấu', ja : '大会選択'}
LanguageDic['매장 대회'] = {ko : '', en : 'Store Tournaments', zh : '门店比赛', vi : 'Giải đấu trong cửa hàng', ja : '埋葬大会'}
LanguageDic['글로벌 대회'] = {ko : '', en : 'Global Tournaments', zh : '全球比赛', vi : 'Giải đấu thế giới', ja : 'グローバル大会'}
LanguageDic['예정'] = {ko : '', en : 'Schedule', zh : '日程表', vi : 'Thời gian', ja : '予定'}
LanguageDic['진행'] = {ko : '', en : 'Ongoing', zh : '进行', vi : 'Đang diễn ra', ja : '進行'}
LanguageDic['종료'] = {ko : '', en : 'Completed', zh : '结束', vi : 'Hoàn thành', ja : '終了'}
LanguageDic['진행중'] = {ko : '', en : 'Ongoing', zh : '进行中', vi : 'đang diễn ra', ja : '進行中'}
LanguageDic['명'] = {ko : '', en : ' people', zh : '人', vi : 'người', ja : '名'}
LanguageDic['인'] = {ko : '', en : ' people', zh : '人', vi : 'người', ja : '名'}
LanguageDic['대회정보'] = {ko : '', en : 'Information', zh : '比赛信息', vi : 'Thông tin giải đấu', ja : '大会情報'}
LanguageDic['랭킹'] = {ko : '', en : 'Ranking', zh : '排名', vi : 'Xếp hạng', ja : 'ランキング'}
LanguageDic['시상내역'] = {ko : '', en : 'My Awards', zh : '奖项内容', vi : 'Giải thưởng của tôi', ja : '受賞履歴'}
LanguageDic['코스정보'] = {ko : '', en : 'Course information', zh : '球场信息', vi : 'Thông tin sân', ja : 'コース情報'}
LanguageDic['코스'] = {ko : '', en : 'Course', zh : '课程', vi : 'Khóa', ja : 'コース'}
LanguageDic['합산'] = {ko : '', en : 'Summation', zh : '课程', vi : 'sự tính tổng', ja : '合計'}
LanguageDic['순위'] = {ko : '', en : 'Rank', zh : '排名', vi : 'Rank', ja : '順位'}
LanguageDic['횟수'] = {ko : '', en : 'Number', zh : '数字', vi : 'Number', ja : '回数'}
LanguageDic['날짜'] = {ko : '', en : 'Date', zh : '日期', vi : 'Date', ja : '日付'}
LanguageDic['코스 난이도'] = {ko : '', en : 'Course difficulty', zh : '球场难度', vi : 'Độ khó của sân', ja : 'コース難易度'}
LanguageDic['그린 난이도'] = {ko : '', en : 'Green difficulty', zh : '果岭难度', vi : 'Độ khó của green', ja : 'グリーン難易度'}
LanguageDic['롱기스트홀'] = {ko : '', en : 'Longest hole', zh : '长打王洞', vi : 'Hố dài nhất', ja : 'ロングギストホール'}
LanguageDic['니어핀홀'] = {ko : '', en : 'Near pin hole', zh : '短打王洞', vi : 'Hố ngắn nhất', ja : 'ニアピンホール'}
LanguageDic['라운드 설정 정보'] = {ko : '', en : 'Round Settings', zh : '下场设置信息', vi : 'Cài đặt vòng chơi', ja : 'ラウンド設定情報'}
LanguageDic['참가대상'] = {ko : '', en : 'Targeted Participants', zh : '参加对象', vi : 'Mục tiêu tham gia', ja : '参加対象'}
LanguageDic['사용자 난이도'] = {ko : '', en : 'User difficulty', zh : '使用难度', vi : 'Mức độ khó cho người chơi', ja : 'ユーザ難易度'}
LanguageDic['멀리건 횟수'] = {ko : '', en : 'Mulligan count', zh : '加击数', vi : 'Mulligan', ja : 'マリガン回数'}
LanguageDic['바람세기'] = {ko : '', en : 'Wind speed', zh : '风力', vi : 'Tốc độ gió', ja : '風の強さ'}
LanguageDic['홀컵 위치'] = {ko : '', en : 'Hole Position', zh : '洞位置', vi : 'Vị trí lỗ', ja : 'ホールカップの位置'}
LanguageDic['그린 위치'] = {ko : '', en : 'Green Location', zh : '果岭位置', vi : 'Vị trí green', ja : 'グリーンの位置'}
LanguageDic['컨시드'] = {ko : '', en : 'Concede', zh : '算一杆', vi : 'Gimmie', ja : 'コンシード'}
LanguageDic['그린 빠르기'] = {ko : '', en : 'Green Speed', zh : '果岭速', vi : 'Tốc độ green', ja : 'グリーンの速さ'}
LanguageDic['퍼팅라인횟수'] = {ko : '', en : 'Putting line count', zh : '推杆', vi : 'Đường bóng', ja : 'パッティングライン回数'}
LanguageDic['티위치'] = {ko : '', en : 'Tee Box', zh : '发球位置', vi : 'Vị trí của Tee', ja : 'ティーの位置'}
LanguageDic['남자'] = {ko : '', en : 'Male', zh : '男性', vi : 'Nam', ja : '男'}
LanguageDic['여자'] = {ko : '', en : 'Female', zh : '女性', vi : 'Nữ', ja : '女'}
LanguageDic['참가대상 및 보정정보'] = {ko : '', en : 'Participation target and correction information', zh : '参加对象及补正信息', vi : 'Participation target and correction information', ja : '参加対象および補正情報'}
LanguageDic['핸디캡'] = {ko : '', en : 'Handicap', zh : '差点', vi : 'Handicap', ja : 'ハンディキャップ'}
LanguageDic['등급'] = {ko : '', en : 'Grade', zh : '等级', vi : 'Grade', ja : '等級'}
LanguageDic['보정치'] = {ko : '', en : 'Corrected value', zh : '修正值', vi : 'Corrected value', ja : '補正値'}
LanguageDic['브론즈']= {ko : '', en : 'Bronze', zh : 'Bronze', vi : 'Bronze', ja : 'ブロンズ'}
LanguageDic['실버']= {ko : '', en : 'Silver', zh : 'Silver', vi : 'Silver', ja : 'シルバー'}
LanguageDic['골드']= {ko : '', en : 'Gold', zh : 'Gold', vi : 'Gold', ja : 'ゴールド'}
LanguageDic['플래티넘']= {ko : '', en : 'Platinum', zh : 'Platinum', vi : 'Platinum', ja : 'プラチナ'}
LanguageDic['다이아몬드']= {ko : '', en : 'Diamond', zh : 'Diamond', vi : 'Diamond', ja : 'ダイヤモンド'}
LanguageDic['참가대상별 보정'] = {ko : '', en : 'Calibration by participation target', zh : '按照参加对象校准', vi : 'Hiệu chuẩn theo mục tiêu tham gia', ja : '参加対象別補正'}
LanguageDic['타'] = {ko : '', en : 'strokes', zh : '杆', vi : 'gậy', ja : '打'}
LanguageDic['랭킹항목'] = {ko : '', en : 'Ranking Items', zh : '排名项目', vi : 'Hạng mục xếp hạng', ja : 'ランキング項目'}
LanguageDic['롱기스트'] = {ko : '', en : 'Longest', zh : '长打王', vi : 'dài nhất', ja : 'ロンギスト'}
LanguageDic['Longest'] = {ko : '롱기스트', en : '', zh : '长打王', vi : 'dài nhất', ja : 'ロンギスト'}
LanguageDic['니어핀'] = {ko : '', en : 'Near pin', zh : '短打王', vi : 'Gần hố nhất', ja : 'ニアピン'}
LanguageDic['Nearpin'] = {ko : '니어핀', en : '', zh : '短打王', vi : 'Gần hố nhất', ja : 'ニアピン'}
LanguageDic['홀인원'] = {ko : '', en : 'Hole in one', zh : '一杆进洞', vi : 'Hole in one', ja : 'ホールインワン'}
LanguageDic['HoleInOne'] = {ko : '홀인원', en : 'Hole in one', zh : '一杆进洞', vi : 'Hole in one', ja : 'ホールインワン'}
LanguageDic['라운딩 횟수'] = {ko : '', en : 'Number of rounds', zh : '下场次数', vi : 'Số vòng đấu', ja : 'ラウンド回数'}
LanguageDic['CourseRoundPlayCount'] = {ko : '라운딩 횟수', en : 'Number of rounds', zh : '下场次数', vi : 'Số vòng đấu', ja : 'ラウンド回数'}
LanguageDic['버디 횟수'] = {ko : '', en : 'Birdie Count', zh : '柏忌次数', vi : 'Số lượng Birdie', ja : 'バーディー回数'}
LanguageDic['BirdieCount'] = {ko : '버디 횟수', en : '', zh : '柏忌次数', vi : 'Số lượng Birdie', ja : 'バーディー回数'}
LanguageDic['이글 횟수'] = {ko : '', en : 'Eagle Count', zh : '老鹰球次数', vi : 'Số lượng Eagle', ja : 'イーグル回数'}
LanguageDic['EagleCount'] = {ko : '이글 횟수', en : '', zh : '老鹰球次数', vi : 'Số lượng Eagle', ja : 'イーグル回数'}
LanguageDic['신페리오'] = {ko : '', en : 'New Perio', zh : '新贝利亚', vi : 'Xem chỉ số', ja : 'シンペリオ'}
LanguageDic['NewPerio'] = {ko : '신페리오', en : '', zh : '新贝利亚', vi : 'Xem chỉ số', ja : 'シンペリオ'}
LanguageDic['스코어 랭킹'] = {ko : '', en : 'Score ranking', zh : '分数排名', vi : 'Xếp hạng điểm số', ja : 'スコア·ランキング'}
LanguageDic['전체순위'] = {ko : '', en : 'Overall\nRanking', zh : '全部排名', vi : 'Xếp hạng chung', ja : '全体順位'}
LanguageDic['남자순위'] = {ko : '', en : 'Men\'s\nRanking', zh : '男性排名', vi : 'bảng xếp hạng nam', ja : '男子順位'}
LanguageDic['여자순위'] = {ko : '', en : 'Women\'s\nRanking', zh : '女性排名', vi : 'Bảng xếp hạng nữ', ja : '女子順位'}
LanguageDic['마지막 라운드 시작 시간'] = {ko : '', en : 'Last round start time', zh : '最后一轮开始时间', vi : 'Thời gian bắt đầu vòng cuối cùng', ja : '最終ラウンド開始時間'}
LanguageDic['니어핀 랭킹'] = {ko : '', en : 'Near pin Ranking', zh : '短打王排名', vi : 'bảng xếp hạng gần cờ', ja : 'ニアピンランキング'}
LanguageDic['전장거리'] = {ko : '', en : 'Yardage', zh : '全场距离', vi : 'Yardage', ja : '全長距離'}
LanguageDic['달성일'] = {ko : '', en : 'the day of accomplishment', zh : '达成日', vi : 'ngày hoàn thành', ja : '達成日'}
LanguageDic['버디 랭킹'] = {ko : '', en : 'Birdie Ranking', zh : '柏忌排名', vi : 'Xếp hạng birdie', ja : 'バーディー·ランキング'}
LanguageDic['신페리오 랭킹'] = {ko : '', en : 'New Perio Ranking', zh : '新贝利亚排名', vi : 'Xếp hạng chỉ số', ja : 'シンフェリオ·ランキング'}
LanguageDic['롱기스트 랭킹'] = {ko : '', en : 'Longest Ranking', zh : '长打王排名', vi : 'Xếp hạng longest', ja : 'ロンギスト·ランキング'}
LanguageDic['시상명'] = {ko : '', en : 'Award name', zh : '奖项名', vi : 'Danh hiệu', ja : '授賞名'}
LanguageDic['전체보기'] = {ko : '', en : 'Full View', zh : '全部查看', vi : 'Xem đầy đủ', ja : '全体を見る'}
LanguageDic['나의 시상내역'] = {ko : '', en : 'My Award History', zh : '我的奖项内容', vi : 'Lịch sử giải thưởng của tôi', ja : '私の授賞内容'}
LanguageDic['시상타입'] = {ko : '', en : 'Award Type', zh : '奖项类型', vi : 'Kiểu trao giải thưởng', ja : '授賞タイプ'}
LanguageDic['코스이름'] = {ko : '', en : 'Course Name', zh : '球场名', vi : 'Tên khóa học', ja : 'コース名'}
LanguageDic['코스항목'] = {ko : '', en : 'Course Name', zh : '球场名', vi : 'Tên khóa học', ja : 'コース名'}
LanguageDic['시상상품명'] = {ko : '', en : 'an award-winning product', zh : '奖项品名', vi : 'sản phẩm đoạt giải thưởng', ja : '授賞商品名'}
LanguageDic['회'] = {ko : '', en : 'times', zh : '次', vi : 'thời gian', ja : '回'}
LanguageDic['참가한 대회가 없어요'] = {ko : '', en : 'I didn\'t participate in any competition', zh : '没有参加过的比赛', vi : 'Tôi đã không tham gia bất kỳ cuộc thi nào', ja : '参加した大会がありません'}
LanguageDic['대회에 참가해서 경품을 받아보세요.'] = {ko : '', en : 'Join the contest and win a prize.', zh : '请参加比赛获取奖品', vi : 'Tham gia cuộc thi và nhận giải thưởng.', ja : '大会に参加して景品をゲットしましょう'}
LanguageDic['나의 최고 기록'] = {ko : '', en : 'My Best Record', zh : '我的最高纪录', vi : 'Kỷ lục của tôi', ja : '私の最高記録'}
LanguageDic['베스트 스코어'] = {ko : '', en : 'Best Score', zh : '最高分', vi : 'Số điểm tốt nhất', ja : 'ベストスコア'}
LanguageDic['최대 비거리'] = {ko : '', en : 'Longest', zh : '最长飞距离', vi : 'khoảng cách tối đa', ja : '最大飛距離'}
LanguageDic['최장거리 퍼팅'] = {ko : '', en : 'Longest Putt', zh : '最长推杆', vi : 'cú putt dài nhất', ja : '最長距離パッティング'}
LanguageDic['평균 기록'] = {ko : '', en : 'Average Record', zh : '平均记录', vi : 'kỷ lục trung bình', ja : '平均記録'}
LanguageDic['최근 5회'] = {ko : '', en : 'Last 5 rounds', zh : '近期5次', vi : '5 lần cuối cùng', ja : '最近5回'}
LanguageDic['최근 10회'] = {ko : '', en : 'Last 10 rounds', zh : '近期10次', vi : '10 lần cuối cùng', ja : '最近10回'}
LanguageDic['최근 15회'] = {ko : '', en : 'Last 15 rounds', zh : '近期15次', vi : '15 lần cuối cùng', ja : '最近15回'}
LanguageDic['최근 30회'] = {ko : '', en : 'Last 30 rounds', zh : '最近30次', vi : '30 lần cuối cùng', ja : '最近30回'}
LanguageDic['드라이버 평균 기록'] = {ko : '', en : 'Driver Average History', zh : '木杆平均记录', vi : 'Lịch sử trung bình gậy Driver', ja : 'ドライバー平均記録'}
LanguageDic['페어웨이 안착률'] = {ko : '', en : 'Fairway settling rate', zh : '上球道率', vi : 'Tỷ lệ vào Fairway', ja : 'フェアウェイキープ率'}
LanguageDic['우드/아이언 평균 기록'] = {ko : '', en : 'Wood/Iron Average Record', zh : '木/铁杆平均记录', vi : 'Trung bình gậy gỗ/gậy sắt', ja : 'ウッド/アイアン平均記録'}
LanguageDic['그린 적중률'] = {ko : '', en : 'Green Hit Ratio', zh : '果岭命中率', vi : 'Tỷ lên vào Green', ja : 'パーオン率'}
LanguageDic['좌우편차(R)'] = {ko : '', en : 'Left and right deviation (R)', zh : '左右偏差（R)', vi : 'Độ lệch trái và phải', ja : '左右偏差(R)'}
LanguageDic['센드 세이브율'] = {ko : '', en : 'Send save rate', zh : '沙漏率', vi : 'Tỷ lệ cứu bẫy cát', ja : 'サンドセーブ率'}
LanguageDic['퍼터 평균 기록'] = {ko : '', en : 'Putting Average Record', zh : '推杆平均记录', vi : 'Trung bình gậy Putt', ja : 'パター平均記録'}
LanguageDic['홀당 퍼팅 횟수'] = {ko : '', en : 'Putting per hole', zh : '每洞推杆数', vi : 'Putt mỗi hỗ', ja : 'ホール当たりのパッティング回数'}
LanguageDic['총 퍼팅 횟수'] = {ko : '', en : 'Total number of putts', zh : '总推杆数', vi : 'Tổng số lần Putt', ja : '総パッティング回数'}
LanguageDic['평균 스코어'] = {ko : '', en : 'Average Score', zh : '平均分', vi : 'điểm trung bình', ja : '平均スコア'}
LanguageDic['스트로크 스코어'] = {ko : '', en : 'Stroke score', zh : '比杆成绩', vi : 'Điểm số', ja : 'ストローク·スコア'}
LanguageDic['신페리오 스코어'] = {ko : '', en : 'New Perio score', zh : '新贝利亚成绩', vi : 'Bảng điểm các chỉ số', ja : 'シンペリオ·スコア'}
LanguageDic['더블보기 이하'] = {ko : '', en : 'Double Bogey or Less', zh : '双柏忌以下', vi : 'Double Bogey hoặc ít gậy hơn', ja : 'ダブルボギー以下'}
LanguageDic['파 이상'] = {ko : '', en : 'More than par', zh : '标准杆以上', vi : 'Nhiều hơn Par', ja : 'パー以上'}
LanguageDic['나의 베스트 랭킹'] = {ko : '', en : 'My Best Ranking', zh : '我的最高排名', vi : 'Thứ hạng cao nhất của tôi', ja : '私のベストランキング'}
LanguageDic['평균 비거리'] = {ko : '', en : 'Average range', zh : '平均飞距离', vi : 'tầm trung bình', ja : '平均飛距離'}
LanguageDic['평균 볼스피드'] = {ko : '', en : 'Average ball speed', zh : '平均球数', vi : 'Tốc độ trung bình của bóng', ja : '平均ボールスピード'}
LanguageDic['평균 발사각'] = {ko : '', en : 'Average launch angle', zh : '平均发射角', vi : 'góc phóng trung bình', ja : '平均発射角'}
LanguageDic['사이드 스핀'] = {ko : '', en : 'Side Spin', zh : '侧旋球', vi : 'Xoáy ngang', ja : 'サイドスピン'}
LanguageDic['평백스핀'] = {ko : '', en : 'a flat-back spin', zh : '平后旋球', vi : 'Xoáy ngược', ja : '平白スピン'}
LanguageDic['거리 선택'] = {ko : '', en : 'Select Distance', zh : '选择距离', vi : 'Chọn khoảng cách', ja : '距離選択'}
LanguageDic['곡선 퍼팅'] = {ko : '', en : 'Curved putting', zh : '曲线推杆', vi : 'Putt địa hình', ja : '曲線パッティング'}
LanguageDic['도달율'] = {ko : '', en : 'Reach rate', zh : '到达率', vi : 'Tỷ lệ đạt được', ja : '到達率'}
LanguageDic['숏게임'] = {ko : '', en : 'Short game', zh : '短距离比赛', vi : 'Short game', ja : 'ショートゲーム'}
LanguageDic['사용자 설정'] = {ko : '', en : 'User Settings', zh : '用户设置', vi : 'Thiết lập người dùng', ja : 'ユーザー設定'}
LanguageDic['내 정보'] = {ko : '', en : 'My Profile', zh : '我的资料', vi : 'Thông tin của tôi', ja : '私の情報マイページ'}
LanguageDic['단위(앱)'] = {ko : '', en : 'Unit (App)', zh : '单位（APP）', vi : 'Đơn vị (App)', ja : '単位(アップ)'}
LanguageDic['미터(m)'] = {ko : '', en : 'Meter (m)', zh : '米(m)', vi : 'Mét (m)', ja : 'メートル(m)'}
LanguageDic['야드(yd)'] = {ko : '', en : 'Yard (yd)', zh : '码(yd)', vi : 'Yard (yd)', ja : 'ヤード(yd)'}
LanguageDic['이용약관'] = {ko : '', en : 'Terms and Conditions', zh : '使用条款', vi : 'Điều khoản và điều kiện', ja : '利用規約'}
LanguageDic['회원 이용약관'] = {ko : '', en : 'Terms and Conditions', zh : '会员使用条款', vi : 'Điều khoản và điều kiện thành viên', ja : '会員利用規約'}
LanguageDic['개인정보 보호정책'] = {ko : '', en : 'Privacy Policy', zh : '个人信息保护政策', vi : 'Chính sách bảo mật', ja : 'プライバシーポリシー'}
LanguageDic['위치 기반 서비스 이용약관'] = {ko : '', en : 'Location-Based Service Terms and Conditions', zh : '定位服务使用条款', vi : 'Điều khoản và điều kiện dịch vụ dựa trên địa điểm', ja : 'ロケーションベースのサービス利用規約'}
LanguageDic['제3자 정보제공 및 마케팅 수신 동의'] = {ko : '', en : 'Third-party data provision & marketing message', zh : '第三方信息提供及接受推送信息同意', vi : 'Đồng ý cung cấp thông tin của bên thứ ba và nhận tiếp thị', ja : '第三者情報提供及びマーケティング受信同意'}
LanguageDic['동의함'] = {ko : '', en : 'Agree', zh : '同意', vi : 'Đồng ý', ja : '同意する'}
LanguageDic['동의안함'] = {ko : '', en : 'Disagree', zh : '不同意', vi : 'Không đồng ý', ja : '同意しない'}
LanguageDic['기타'] = {ko : '', en : 'Etc.', zh : '其他', vi : 'v.v..', ja : 'その他'}
LanguageDic['언어 설정'] = {ko : '', en : 'Language', zh : '设置语言', vi : 'Ngôn ngữ', ja : '言語設定'}
LanguageDic['로그아웃'] = {ko : '', en : 'Sign out', zh : '退出', vi : 'Đăng xuất', ja : 'ログアウト'}
LanguageDic['회원 탈퇴'] = {ko : '', en : 'Delete Account', zh : '会员退出', vi : 'Xóa tài khoản', ja : '会員退会'}
LanguageDic['기본정보'] = {ko : '', en : 'Basic Information', zh : '基本信息', vi : 'Thông tin cơ bản', ja : '基本情報'}
LanguageDic['추가정보'] = {ko : '', en : 'Additional Information', zh : '补充信息', vi : 'Thông tin bổ sung', ja : '追加情報'}
LanguageDic['수정'] = {ko : '', en : 'Modify', zh : '修改', vi : 'Chỉnh sửa', ja : '修正'}
LanguageDic['수정되었습니다.'] = {ko : '', en : 'It has been modified.', zh : '已完成修改', vi : 'Đã được chỉnh sửa', ja : '修正されました。'}
LanguageDic['닉네임 변경'] = {ko : '', en : 'Change Nickname', zh : '更改昵称', vi : 'Thay đổi biệt danh', ja : 'ニックネーム変更'}
LanguageDic['수정하기'] = {ko : '', en : 'Next', zh : '修改', vi : 'Chỉnh sửa', ja : '修正する'}
LanguageDic['휴대폰번호 변경'] = {ko : '', en : 'Change your phone number', zh : '更改手机号', vi : 'Đổi số điện thoại', ja : '携帯番号変更'}
LanguageDic['주소 변경'] = {ko : '', en : 'Change Address', zh : '更改地址', vi : 'Đổi địa chỉ', ja : '住所変更'}
LanguageDic['아래 추가 정보를\n입력해 주세요.'] = {ko : '', en : 'Please enter\nadditional information below.', zh : '请输入下面补充信息', vi : 'Vui lòng nhập thêm thông tin bên dưới.', ja : '以下の追加情報を入力してください。'}
LanguageDic['원하는 기능으로\n코스를 즐겨보세요.'] = {ko : '', en : 'Enjoy the course\nwith the features you want.', zh : '请选择功能享受球场吧', vi : 'Hãy thưởng thức khóa học với những tính năng bạn muốn.', ja : 'お好みの機能でコースをお楽しみください。'}
LanguageDic['현재 비밀번호를\n입력해 주세요.'] = {ko : '', en : 'Please enter\nyour current password.', zh : '请输入当前密码', vi : 'Vui lòng nhập mật khẩu hiện tại của bạn.', ja : '現在のパスワードを入力してください。'}
LanguageDic['공지'] = {ko : '', en : 'Notice', zh : '公告', vi : 'Thông báo', ja : 'お知らせ'}
LanguageDic['검색어를 입력하세요.'] = {ko : '', en : 'Search', zh : '请输入搜索词', vi : 'Tìm kiếm.', ja : '検索ワードを入力してください。'}
LanguageDic['검색어를 입력하세요'] = {ko : '', en : 'Search', zh : '请输入搜索词', vi : 'Tìm kiếm', ja : '検索ワードを入力してください'}
LanguageDic['종료된 이벤트입니다.'] = {ko : '', en : 'Ended event.', zh : '已经结束的活动', vi : 'Sự kiện kết thúc.', ja : '終了したイベントです。'}
LanguageDic['예약현황'] = {ko : '', en : 'Reservation status', zh : '近期没有预订内容', vi : 'Trạng thái đặt chỗ', ja : '予約状況'}
LanguageDic['최근 예약내역이 없습니다.'] = {ko : '', en : 'There are no recent reservations.', zh : '近期没有预订内容', vi : 'Không có đặt trước gần đây.', ja : '最近の予約履歴がありません。'}
LanguageDic['가까운 매장을 검색하고 예약해보세요.'] = {ko : '', en : 'Search for a nearby store and make a reservation.', zh : '请搜索最近门店后预订', vi : 'Tìm cửa hàng gần nhất và đặt chỗ', ja : '近くの店舗を検索して予約してみてください。'}
LanguageDic['담당'] = {ko : '', en : 'Pro', zh : '负责', vi : 'Pro', ja : '担当'}
LanguageDic['시설'] = {ko : '', en : 'Room', zh : '设施', vi : 'Phòng', ja : '施設'}
LanguageDic['인원'] = {ko : '', en : 'Personnel', zh : '人员', vi : 'nhân sự', ja : '人員'}
LanguageDic['예약취소가 불가능합니다.'] = {ko : '', en : 'Cancellation of reservation is not possible.', zh : '不能取消预订', vi : 'Không thể hủy đặt chỗ.', ja : '予約のキャンセルはできません。'}
LanguageDic['예약취소'] = {ko : '', en : 'Reservation cancellation', zh : '取消预订', vi : 'Hủy đặt chỗ', ja : '予約キャンセル'}
LanguageDic['예약변경'] = {ko : '', en : 'Reservation change', zh : '更改预订', vi : 'Thay đổi đặt chỗ', ja : '予約変更'}
LanguageDic['이용현황'] = {ko : '', en : 'History', zh : '使用情况', vi : 'Trạng thái sử dụng', ja : '利用状況'}
LanguageDic['최근 이용내역이 없습니다.'] = {ko : '', en : 'There is no recent usage history.', zh : '近期没有使用内容', vi : 'Không có lịch sử sử dụng gần đây.', ja : '最近の利用履歴がありません。'}
LanguageDic['이용완료'] = {ko : '', en : 'Used complete', zh : '使用完毕', vi : 'Đã dùng xong', ja : '利用完了'}
LanguageDic['취소완료'] = {ko : '', en : 'Cancellation completed', zh : '取消完毕', vi : 'Hoàn tất việc hủy bỏ', ja : '取り消し完了'}
LanguageDic['일간'] = {ko : '', en : 'Day', zh : '一天', vi : 'Hàng ngày', ja : '日刊'}
LanguageDic['월간'] = {ko : '', en : 'Month', zh : '一个月', vi : 'Hàng tháng', ja : '月刊'}
LanguageDic['주간'] = {ko : '', en : 'Week', zh : '一周', vi : 'Hàng tuần', ja : '週刊'}
LanguageDic['예약가능'] = {ko : '', en : 'available', zh : '可以预订', vi : 'Đặt trước có sẵn', ja : '予約可能'}
LanguageDic['예약마감'] = {ko : '', en : 'reservation closed', zh : '预订结束', vi : 'chỗ đặt trước đã đóng cửa', ja : '予約締切'}
LanguageDic['예약이 있는 경우 해당 요일에 예약건수가 표시됩니다.'] = {ko : '', en : 'If you have a reservation, the number of reservations will be displayed on that day.', zh : '如果有预订时，该日期会显示预订数', vi : 'Nếu bạn đã đặt chỗ trước, số lượng đặt chỗ sẽ được hiển thị vào ngày hôm đó.', ja : '予約がある場合は、その曜日の予約件数が表示されます。'}
LanguageDic['예약신청'] = {ko : '', en : 'Application for reservation', zh : '预订申请', vi : 'Đơn xin đặt chỗ', ja : '予約申し込み'}
LanguageDic['대기신청'] = {ko : '', en : 'Waiting application', zh : '等候申请', vi : 'Ứng dụng đang chờ', ja : '待機申請'}
LanguageDic['예약중'] = {ko : '', en : 'Making a reservation', zh : '预定中', vi : 'Đặt chỗ trước', ja : '予約中'}
LanguageDic['일자'] = {ko : '', en : 'Date', zh : '日期', vi : 'Ngày', ja : '日付'}
LanguageDic['시간'] = {ko : '', en : 'Time', zh : '时间', vi : 'Thời gian', ja : '時間'}
LanguageDic['시설 이용시작시간 및 레슨시설을 선택하세요.'] = {ko : '', en : 'Please select the start time of the facility and the lesson facility.', zh : '设施使用开始时间及上课设施', vi : 'Vui lòng chọn thời gian bắt đầu sử dụng cơ sở và thời gian lớp học', ja : '施設の利用開始時間とレッスン施設を選択してください。'}
LanguageDic['시설 이용시작시간'] = {ko : '', en : 'Facility use start time', zh : '设施使用开始时间', vi : 'Thời gian bắt đầu sử dụng cơ sở', ja : '施設利用開始時間'}
LanguageDic['시설 이용시작을 선택하세요.'] = {ko : '', en : 'Please select Start using the facility.', zh : '请选择开始使用设施', vi : 'Vui lòng chọn Bắt đầu sử dụng cơ sở.', ja : '施設の利用開始を選択してください。'}
LanguageDic['레슨시설'] = {ko : '', en : 'Lesson facilities', zh : '上课设施', vi : 'Lớp học', ja : 'レッスン施設'}
LanguageDic['레슨시설을 선택하세요.'] = {ko : '', en : 'Please select a lesson facility.', zh : '请选择上课设施', vi : 'Vui lòng chọn lớp học', ja : 'レッスン施設を選択してください。'}
LanguageDic['레슨 10분전'] = {ko : '', en : '10 minutes before the lesson', zh : '上课前10分钟', vi : '10 phút trước giờ học', ja : 'レッスン10分前'}
LanguageDic['레슨 20분전'] = {ko : '', en : '20 minutes before lesson', zh : '上课前20分钟', vi : '20 phút trước giờ học', ja : 'レッスン20分前'}
LanguageDic['레슨 30분전'] = {ko : '', en : '30 minutes before the lesson', zh : '上课前30分钟', vi : '30 phút trước giờ học', ja : 'レッスン30分前'}
LanguageDic['레슨 정시'] = {ko : '', en : 'On time for lessons', zh : '上课暂停', vi : 'Đúng giờ học', ja : 'レッスン定時'}
LanguageDic['예약일자'] = {ko : '', en : 'Reservation date', zh : '预订日期', vi : 'Ngày đặt trước', ja : '予約日'}
LanguageDic['예약시간'] = {ko : '', en : 'Booking time', zh : '约定时间', vi : 'Thời gian đặt chỗ', ja : '予約時間'}
LanguageDic['예약하시겠습니까?'] = {ko : '', en : 'Would you like to make a reservation?', zh : '您确定预订吗？', vi : 'Bạn có muốn đặt chỗ trước không?', ja : '予約しますか？'}
LanguageDic['예약완료'] = {ko : '', en : 'Reservation completed', zh : '预订完毕', vi : 'Việc đặt chỗ đã hoàn tất', ja : '予約完了'}

LanguageDic['All'] = {ko : '전체', en : 'All', zh : '全部', vi : 'Tất cả', ja : '全体'}
LanguageDic['全部'] = {ko : '전체', en : 'All', zh : '全部', vi : 'Tất cả', ja : '全体'}
LanguageDic['Tất cả'] = {ko : '전체', en : 'All', zh : '全部', vi : 'Tất cả', ja : '全体'}
LanguageDic['全体'] = {ko : '전체', en : 'All', zh : '全部', vi : 'Tất cả', ja : '全体'}

LanguageDic['스트로크'] = {ko : '스트로크', en : 'Stroke', zh : '行程', vi : 'Nét chữ', ja : 'ストローク'}
LanguageDic['Stroke'] = {ko : '스트로크', en : 'Stroke', zh : '行程', vi : 'Nét chữ', ja : 'ストローク'}
LanguageDic['行程'] = {ko : '스트로크', en : 'Stroke', zh : '行程', vi : 'Nét chữ', ja : 'ストローク'}
LanguageDic['Nét chữ'] = {ko : '스트로크', en : 'Stroke', zh : '行程', vi : 'Nét chữ', ja : 'ストローク'}
LanguageDic['ストローク'] = {ko : '스트로크', en : 'Stroke', zh : '行程', vi : 'Nét chữ', ja : 'ストローク'}

LanguageDic['포섬'] = {ko : '포섬', en : 'Foursome', zh : '富尔瑟姆', vi : 'Bốn người', ja : 'フォーサム'}
LanguageDic['FourSome'] = {ko : '포섬', en : 'FourSome', zh : '富尔瑟姆', vi : 'Bốn người', ja : 'フォーサム'}
LanguageDic['Foursome'] = {ko : '포섬', en : 'Foursome', zh : '富尔瑟姆', vi : 'Bốn người', ja : 'フォーサム'}
LanguageDic['富尔瑟姆'] = {ko : '포섬', en : 'Foursome', zh : '富尔瑟姆', vi : 'Bốn người', ja : 'フォーサム'}
LanguageDic['Bốn người'] = {ko : '포섬', en : 'Foursome', zh : '富尔瑟姆', vi : 'Bốn người', ja : 'フォーサム'}
LanguageDic['フォーサム'] = {ko : '포섬', en : 'Foursome', zh : '富尔瑟姆', vi : 'Bốn người', ja : 'フォーサム'}

LanguageDic['Driver'] = {ko : '드라이버', en : 'Driver', zh : '司机', vi : 'Trình điều khiển', ja : 'ドライバー'}
LanguageDic['司机'] = {ko : '드라이버', en : 'Driver', zh : '司机', vi : 'Trình điều khiển', ja : 'ドライバー'}
LanguageDic['Trình'] = {ko : '드라이버', en : 'Driver', zh : '司机', vi : 'Trình điều khiển', ja : 'ドライバー'}
LanguageDic['ドライバー'] = {ko : '드라이버', en : 'Driver', zh : '司机', vi : 'Trình điều khiển', ja : 'ドライバー'}

LanguageDic['Wood/Iron'] = {ko : '우드/아이언', en : 'Wood/Iron', zh : '木/铁', vi : 'Gỗ/ sắt', ja : 'ウッド/アイアン'}
LanguageDic['木/铁'] = {ko : '우드/아이언', en : 'Wood/Iron', zh : '木/铁', vi : 'Gỗ/ sắt', ja : 'ウッド/アイアン'}
LanguageDic['Gỗ/ sắt'] = {ko : '우드/아이언', en : 'Wood/Iron', zh : '木/铁', vi : 'Gỗ/ sắt', ja : 'ウッド/アイアン'}
LanguageDic['ウッド/アイアン'] = {ko : '우드/아이언', en : 'Wood/Iron', zh : '木/铁', vi : 'Gỗ/ sắt', ja : 'ウッド/アイアン'}

LanguageDic['Putter'] = {ko : '퍼터', en : 'Putter', zh : '推杆', vi : 'người đánh lừa gạt', ja : 'パター'}
LanguageDic['推杆'] = {ko : '퍼터', en : 'Putter', zh : '推杆', vi : 'người đánh lừa gạt', ja : 'パター'}
LanguageDic['người đánh lừa gạt'] = {ko : '퍼터', en : 'Putter', zh : '推杆', vi : 'người đánh lừa gạt', ja : 'パター'}
LanguageDic['パター'] = {ko : '퍼터', en : 'Putter', zh : '推杆', vi : 'người đánh lừa gạt', ja : 'パター'}

LanguageDic['아이디를 입력해주세요.'] = {ko : '', en : 'Please enter your ID.', zh : '请输入ID', vi : 'Vui lòng nhập ID của bạn.', ja : 'IDを入力してください。'}
LanguageDic['비밀번호를 입력해주세요.'] = {ko : '', en : 'Please enter your password.', zh : '请输入密码', vi : 'Vui lòng nhập mật khẩu.', ja : 'パスワードを入力してください。'}
LanguageDic['존재하지 않는 아이디입니다.'] = {ko : '', en : 'This ID does not exist.', zh : '此ID不存在', vi : 'ID này không tồn tại.', ja : 'このIDは存在しません。'}
LanguageDic['언어선택'] = {ko : '', en : 'language selection', zh : '选择语言', vi : 'Lựa chọn ngôn ngữ', ja : '言語選択'}
LanguageDic['인증번호'] = {ko : '', en : 'Authentication number', zh : '验证码', vi : 'Số xác thực', ja : '認証番号'}
LanguageDic['6자리 입력'] = {ko : '', en : 'Enter 6 digits', zh : '请输入6位数', vi : 'Nhập 6 chữ số', ja : '6桁を入力します。'}
LanguageDic['입력시간을 초과하였습니다. 인증번호를 재전송해주세요.'] = {ko : '', en : 'Input Timeout. Please resend the authentication code.', zh : '输入时间已过，请重新发送验证码', vi : 'Đã vượt quá thời gian. Vui lòng gửi lại số xác thực.', ja : '入力タイムアウトを超過しました。 認証番号を再送してください。'}
LanguageDic['회원정보를 확인 할 수 없습니다.'] = {ko : '', en : 'Unable to verify user information', zh : '无法确认会员信息', vi : 'Không thể xác minh thông tin thành viên.', ja : 'メンバーシップ情報を確認することができません。'}
LanguageDic['다시 확인해주세요.'] = {ko : '', en : 'Please check again.', zh : '请在确认一下', vi : 'Vui lòng kiểm tra lại.', ja : 'もう一度確認してください。'}
LanguageDic['사용가능한 비밀번호입니다.'] = {ko : '', en : 'Available', zh : '可用的密码', vi : 'Mật khẩu sẵn có.', ja : '使用可能なパスワード。'}
LanguageDic['비밀번호 변경이 완료되었습니다.'] = {ko : '', en : 'Password change is completed', zh : '已完成密码更改', vi : 'Đổi mật khẩu đã hoàn tất.', ja : 'パスワードの変更が完了しました。'}
LanguageDic['로그인 화면으로 이동합니다.'] = {ko : '', en : 'Go to the login screen.', zh : '移动至登录页面', vi : 'Tới màn hình đăng nhập.', ja : 'ログイン画面に移動します。'}
LanguageDic['4자 이상 20자 이하 영문, 숫자만 가능합니다.'] = {ko : '', en : 'Only English and numbers between 4 and 20 characters are allowed.', zh : '只允许4字以上20字以下英文、数字', vi : 'Nhập 4-20 ký tự', ja : '英語と4~20文字の数字のみ使用できます。'}
LanguageDic['선택'] = {ko : '', en : 'select', zh : '选择', vi : 'lựa chọn', ja : 'チョイス'}
LanguageDic['저장되었습니다.'] = {ko : '', en : 'Saved.', zh : '已保存', vi : 'Lưu', ja : '保存しました。'}
LanguageDic['현재 위치 근처에 매장이 없어요.'] = {ko : '', en : 'There is no store near the current location.', zh : '在当前位置附近没有商店。', vi : 'Không có cửa hàng nào gần vị trí hiện tại.', ja : '現在地の近くに店舗はありません。'}
LanguageDic['검색 가능한 거리를 넓혀보세요.'] = {ko : '', en : 'Expand your searchable distance.', zh : '请扩大可以搜索的距离', vi : 'Mở rộng khoảng cách có thể tìm kiếm của bạn.', ja : '検索可能な距離を広げます。'}
LanguageDic['주소가 복사되었습니다.'] = {ko : '', en : 'The address has been copied.', zh : '已复制地址', vi : 'Địa chỉ đã được sao chép.', ja : 'アドレスがコピーされました。'}
LanguageDic['전화'] = {ko : '', en : 'Telephone.', zh : '电话', vi : 'Điện thoại.', ja : '電話。'}
LanguageDic['현재 등록된 글이 없습니다.'] = {ko : '', en : 'There are currently no posts registered.', zh : '目前没有登记的文章', vi : 'Hiện tại không có bài đăng nào được đăng ký.', ja : '現在登録されている投稿はありません。'}
LanguageDic['조금만 기다려주세요.'] = {ko : '', en : 'Hold on a minute, please.', zh : '请稍后', vi : 'Xin chờ một chút.', ja : '少々お待ちください。'}
LanguageDic['이벤트'] = {ko : '', en : 'Event', zh : '活动', vi : 'Sự kiện', ja : 'イベント'}
LanguageDic['경기방식'] = {ko : '', en : 'the way in which the game is played', zh : '比赛方式', vi : 'cách chơi', ja : 'ゲームのやり方'}
LanguageDic['경기방식 선택'] = {ko : '', en : 'Choosing the way to play', zh : '选择比赛方式', vi : 'Lựa chọn cách chơi', ja : '遊び方の選択'}
LanguageDic['통계'] = {ko : '', en : 'statistics', zh : '统计', vi : 'thống kê', ja : '統計学'}
LanguageDic['연습장'] = {ko : '', en : 'practice range', zh : '练习场', vi : 'phạm vi tập luyện', ja : '練習範囲'}
LanguageDic['첨부파일 다운로드'] = {ko : '', en : 'Download Attachment', zh : '下载附件', vi : 'Tải về tập tin đính kèm', ja : '添付ファイルのダウンロード'}
LanguageDic['프로필 사진 수정을 위해 앨범을 사용합니다.'] = {ko : '', en : 'Use the album to modify profile pictures.', zh : '为了替换个人头像访问相册', vi : 'Dùng tập ảnh để sửa ảnh hồ sơ.', ja : 'アルバムを使用してプロファイル写真を変更します。'}
LanguageDic['이미 동일한 값을 가지고 있습니다.'] = {ko : '', en : 'It already has the same value.', zh : '已经是相同值', vi : 'Nó đã có cùng giá trị rồi.', ja : 'すでに同じ値になっています。'}
LanguageDic['기본정보 수정'] = {ko : '', en : 'Modify basic information', zh : '修改基本信息', vi : 'Chỉnh sửa thông tin cơ bản', ja : '基本情報の変更'}
LanguageDic['추가정보 수정'] = {ko : '', en : 'Modify additional information', zh : '修改补充信息', vi : 'Chỉnh sửa thông tin bổ sung', ja : '追加情報の変更'}
LanguageDic['주소를 입력해주세요.'] = {ko : '', en : 'Please enter your address.', zh : '请输入地址', vi : 'Xin vui lòng nhập địa chỉ của bạn.', ja : '住所を入力してください。'}
LanguageDic['현재 비밀번호와 새로운 비밀번호가 일치합니다.'] = {ko : '', en : 'The current password matches the new password.', zh : '当前密码与新密码一致', vi : 'Mật khẩu hiện tại khớp với mật khẩu mới.', ja : '現在のパスワードが新しいパスワードと一致します。'}
LanguageDic['비밀번호가 일치하지 않습니다.'] = {ko : '', en : 'Passwords do not match.', zh : '密码不一致', vi : 'Mật khẩu không khớp.', ja : 'パスワードが一致しません。'}
LanguageDic['비밀번호 변경이 완료되었습니다.'] = {ko : '', en : 'Password change is complete.', zh : '已完成更改密码', vi : 'Đổi mật khẩu đã hoàn tất.', ja : 'パスワードの変更が完了しました。'}
LanguageDic['KGOLF이(가) 카메라에 접근하려고 합니다.'] = {ko : '', en : '"Kgolf" would like to access to the camera', zh : 'K高尔夫要使用相机', vi : 'Cho phép KGOLF sử dụng chế độ máy ảnh', ja : 'KGOLFがカメラに近づこうとしています。'}
LanguageDic['QR코드 인식을 위해 카메라를 사용합니다.'] = {ko : '', en : 'Use the built-in Camera to scan a QR Code', zh : '为了识别二维码使用相机', vi : 'Nhận diện QR Code bằng camera.', ja : 'QRコード認識にカメラを使用します。'}
LanguageDic['가까운 매장을 찾기 위해 위치정보를 사용합니다.'] = {ko : '', en : 'Use location information to find a nearby store.', zh : '为了查找附近门店使用定位服务', vi : 'Sử dụng thông tin vị trí để tìm một cửa hàng gần nhất.', ja : '位置情報を使用して、近くの店舗を検索します。'}
LanguageDic['앱에서 요구하는 권한 설정이 필요합니다.'] = {ko : '', en : 'The permission settings required by the app are required.', zh : '需要APP要求的权限设置', vi : 'Các xác lập quyền được yêu cầu bởi ứng dụng là bắt buộc.', ja : 'アプリで必要な権限設定が必要です。'}
LanguageDic['[설정] > [권한]에서 사용으로 활성화해주세요.'] = {ko : '', en : 'Please enable the function from Settings > Permissions to Enable.', zh : '[设置] > [权限]中激活使用', vi : 'Vui lòng bật chức năng trong mục Cài đặt', ja : '[設定]>[権限]から[有効にする]の機能を有効にしてください。'}
LanguageDic['경기방식 전체'] = {ko : '', en : 'All', zh : '全部', vi : 'Tất cả', ja : '全体'}
LanguageDic['클럽 전체'] = {ko : '', en : 'All clubs', zh : '全部球杆', vi : 'Tất cả các gậy', ja : 'すべてのクラブ'}
LanguageDic['대회 전체'] = {ko : '', en : 'All', zh : '全部比赛方式', vi : 'Tất cả các giải đấu', ja : 'すべての大会'}

LanguageDic['인증번호'] = {ko : '', en : 'Confirm the authentication code', zh : '验证码', vi : 'Xác nhận mã xác thực', ja : '認証コードを確認する'}
LanguageDic['6자리 입력'] = {ko : '', en : 'Enter the authentication code', zh : '输入6位数', vi : 'Nhập mã xác thực', ja : '認証コードを入力してください'}
LanguageDic['현재 비밀번호와 새로운 비밀번호가 일치합니다.'] = {ko : '', en : 'Create a new password that isn’t your current password.', zh : '当前密码与新密码一致', vi : 'Tạo một mật khẩu mới không phải là mật khẩu hiện tại của bạn.', ja : '現在のパスワードではない新しいパスワードを作成します。'}
LanguageDic['선택'] = {ko : '', en : 'Select', zh : '选择', vi : 'lựa chọn', ja : '選択'}
LanguageDic['예약현황'] = {ko : '', en : 'Reservation status', zh : '预订状态', vi : 'tình trạng đặt phòng', ja : '予約状況'}
LanguageDic['통계'] = {ko : '', en : 'Statistics', zh : '统计', vi : 'thống kê', ja : '統計学'}
LanguageDic['기본 정보 수정'] = {ko : '', en : 'Modify Basic Information', zh : '修改基本信息', vi : 'Chỉnh sửa thông tin cơ bản', ja : '基本情報の変更'}
LanguageDic['이벤트'] = {ko : '', en : 'Event', zh : '活动', vi : 'Sự kiện', ja : 'イベント'}
LanguageDic['현재 등록된 글이 없습니다.'] = {ko : '', en : 'There are currently no registered articles.', zh : '目前没有登记的文章', vi : 'Hiện tại không có bài viết đăng ký.', ja : '現在登録されている記事はありません。'}
LanguageDic['등록된 영상 정보가 없습니다.'] = {ko : '', en : 'There are currently no registered videos.', zh : '目前没有登记的视频', vi : 'Hiện tại không có bài video đăng ký.', ja : '現在登録されているビデオはありません。'}
LanguageDic['조금만 기다려 주세요.'] = {ko : '', en : 'Please wait a minute.', zh : '请稍后', vi : 'Vui lòng đợi một phút.', ja : 'ちょっと待ってください。'}
LanguageDic['확인N'] = {ko : '확인', en : 'Next', zh : '确认', vi : 'Kế tiếp', ja : 'Next'}
LanguageDic['확인'] = {ko : '', en : 'OK', zh : '确认', vi : 'OK', ja : 'OK'}
LanguageDic['취소'] = {ko : '', en : 'Cancel', zh : '取消', vi : 'Hủy bỏ', ja : 'キャンセル'}
LanguageDic['종료하시겠습니까?'] = {ko : '', en : 'Do you want to quit?', zh : '确定结束吗?', vi : 'Bạn có chắc bạn muốn thoát?', ja : '終了しますか？'}
LanguageDic['라운드 기록이 없어요.'] = {ko : '', en : 'There is no round record.', zh : '没有下场记录', vi : 'Không có thành tích vòng đấu.', ja : 'ラウンドレコードはありません。'}
LanguageDic['인터넷이 연결되지 않았습니다. 인터넷 연결 확인 후 다시 시도해주세요.'] = {ko : '', en : 'The Internet is not connected. Please check the internet connection and try again.', zh : '网络连接失败，请确认网络再次使用。', vi : 'Internet không được kết nối. Vui lòng kiểm tra kết nối internet và thử lại.', ja : 'インターネットに接続されていません。インターネット接続を確認して、もう一度お試しください。'}
LanguageDic['요청시간이 만료되었습니다. 잠시 후 다시 시도해주세요.'] = {ko : '', en : 'Your session has timedout. Try again.', zh : '请求时间已过，请稍后再试。', vi : 'Thời gian yêu cầu đã hết. Vui lòng thử lại sau.', ja : 'リクエストの期限が切れました。後でもう一度やり直してください。'}
LanguageDic['데이터가 존재하지 않습니다.'] = {ko : '', en : 'Data does not exist.', zh : '数据不存在。', vi : 'Dữ liệu không tồn tại.', ja : 'データが存在しません。'}
LanguageDic['서버가 응답하지 않습니다. 잠시 후 다시 시도해주세요.'] = {ko : '', en : 'Unable to connect to server. Please try again later.', zh : '服务器没有响应，请稍后再试。', vi : 'Máy chủ không phản hồi. Vui lòng thử lại sau.', ja : 'サーバーが応答していません。後でもう一度やり直してください。'}

LanguageDic['KGOLF 회원탈퇴안내'] = {ko : '', en : 'KGOLF Membership Withdrawal Information', zh : 'K高尔夫会员退出介绍', vi : 'Thông tin rút tư cách thành viên KGOLF', ja : 'KGOLF会員退会のご案内'}
LanguageDic['회원탈퇴 전에 유의사항을 꼭 확인해주세요.'] = {ko : '', en : 'Please check the precautions before canceling membership.', zh : '退出会员前请确认注意事项。', vi : 'Vui lòng kiểm tra các thông tin trước khi hủy gói hội viên..', ja : '退会前に注意事項をご確認ください。'}
LanguageDic['유의사항'] = {ko : '', en : 'Notice', zh : '注意事项', vi : 'Lưu ý', ja : '注意事項'}
LanguageDic['회원탈퇴시, KGOLF 서비스 이용이 불가합니다.'] = {ko : '', en : 'KGOLF service is not available when you withdraw from the membership.', zh : '退出会员时，不能使用K高尔夫服务', vi : 'Dịch vụ KGOLF không khả dụng khi bạn hủy gói hội viên.', ja : '退会するとKGOLFサービスはご利用いただけなくなります。'}
LanguageDic['탈퇴한 아이디 및 닉네임은 다시 사용할 수 없습니다.'] = {ko : '', en : 'Your withdrawn ID and nickname cannot be used again.', zh : '不能再次使用退出的ID及昵称', vi : 'Không thể sử dụng lại ID và biệt hiệu đã rút của bạn.', ja : '退会したIDとニックネームは再利用できません。'}
LanguageDic['참여중인 대회가 있는 경우 대회 종료 후 회원탈퇴가 가능합니다.'] = {ko : '', en : 'If there is a competition you are participating in, you can withdraw from the membership after the competition.', zh : '您如果正在参赛，比赛结束后可以退出会员。', vi : 'Nếu có một cuộc thi mà bạn đang tham gia, bạn có thể rút hủy gói hội viên sau cuộc thi.', ja : '出場中の大会がある場合は、大会終了後に退会することができます。'}
LanguageDic['회원정보 및 라운드기록, 샷정보, 대회정보 등 서비스 이용기록은 모두 삭제되며, 삭제된 데이터는 복구되지 않습니다.'] = {ko : '', en : 'All service usage records such as member information, round record, shot information, and competition information will be deleted, and deleted data will not be recovered.', zh : '会员信息及下场记录，打球信息等使用过的服务将会全部删除，删除过的数据将无法恢复。', vi : 'Tất cả các lịch sử như thông tin thành viên, lịch sử vòng đấu, thông tin cú đánh và thông tin thi đấu sẽ bị xóa và dữ liệu đã xóa sẽ không được khôi phục.', ja : '会員情報、ラウンド記録、ショット情報、競技情報などのサービス利用記録はすべて削除し、削除したデータは復旧しません。'}
LanguageDic['향후 재가입시 게임정보 등 회원정보는 복원되지 않습니다.'] = {ko : '', en : 'Membership information such as game information will not be restored if you re-register in the future.', zh : '再次加入时，游戏信息等会员信息无法恢复。', vi : 'Thông tin thành viên như thông tin trò chơi sẽ không được khôi phục nếu bạn đăng ký lại trong tương lai.', ja : '今後再登録を行った場合、ゲーム情報などの会員情報は復元されません。'}
LanguageDic['개인정보 처리방침 제5조(개인정보의 보유 및 이용기간)에 따른 특별한 경우, 한시적으로 개인정보를 보존 유지 합니다.'] = {ko : '', en : 'In special cases under Article 5 of the Personal Information Processing Policy (Personal Information Retention and Use Period), personal information is temporarily preserved and maintained.', zh : '根据个人信息处理政策第5条（个人信息的保留及使用时间），特殊情况下，个人信息将会临时保存。', vi : 'Trong các trường hợp đặc biệt theo Điều 5 của Chính sách xử lý thông tin cá nhân (Thời gian lưu giữ và sử dụng thông tin cá nhân), thông tin cá nhân được bảo quản và duy trì tạm thời.', ja : '個人情報処理方針第5条（個人情報の保有及び利用期間）による特別な場合において、個人情報を一時的に保存・保管します。'}
LanguageDic['문의사항은 1577-0564 KGOLF 고객센터로 문의 주시기 바랍니다.'] = {ko : '', en : 'For more information, please contact 822-1577-0564 KGOLF Customer Center.', zh : '如需提问请拨打 1577-0564 K高尔夫客服。', vi : 'Để biết thêm thông tin, vui lòng liên hệ Trung tâm khách hàng 822-1577-0564 KGOLF.', ja : '詳細については、822-1577-0564 KGOLF カスタマー センターにお問い合わせください。'}
LanguageDic['위 내용을 확인 하였으며,\n회원탈퇴 시 모든 정보가 복구 불가능함을 동의합니다.'] = {ko : '', en : 'I have checked the above information and agree that\nall information cannot be recovered when the membership is withdrawn.', zh : '已确认以上信息，同意退出会员时所有信息将无法恢复。', vi : 'Tôi đã kiểm tra các thông tin trên và đồng ý rằng tất cả thông tin không thể được phục hồi khi tư cách thành viên bị thu hồi.', ja : '私は上記の情報を確認し、メンバーシップを退会した場合、すべての情報を復元できないことに同意します。'}
LanguageDic['회원 탈퇴되었습니다.'] = {ko : '', en : 'Membership has been withdrawn.', zh : '已经退出会员', vi : 'Tư cách thành viên đã bị thu hồi.', ja : 'メンバーシップは撤回されました。'}

LanguageDic['최신버전 앱으로 업데이트를 위해 스토어로 이동합니다.'] = {ko : '', en : 'Go to the store to update to the latest version of the app.', zh : 'Go to the store to update to the latest version of the app.', vi : 'Go to the store to update to the latest version of the app.', ja : 'Go to the store to update to the latest version of the app.'}

LanguageDic['탈퇴하기'] = {ko : '', en : 'Delete Account', zh : '会员退出', vi : 'Rút khỏi hội viên', ja : '会員退会'}

LanguageDic['영상있는샷만'] = {ko : '', en : 'Video Only', zh : '挥杆视频', vi : 'Ảnh chụp kèm video', ja : 'ビデオ付きショット'}
LanguageDic['타석 방향'] = {ko : '', en : 'Batting Stance', zh : '击球方向', vi : 'Tư thế đánh bóng', ja : '打撃姿勢'}
LanguageDic['좌타'] = {ko : '', en : 'Left Handed', zh : '左出', vi : 'Trái', ja : '左手'}
LanguageDic['우타'] = {ko : '', en : 'Right Handed', zh : '右出', vi : 'Tay trái', ja : '右利き'}
LanguageDic['에러 발생'] = {ko : '', en : 'Error', zh : '发生错误', vi : 'Lỗi', ja : 'エラー'}
LanguageDic['에러코드'] = {ko : '', en : 'Error Code', zh : '错误代码', vi : 'Mã lỗi', ja : 'エラーコード'}
LanguageDic['업데이트'] = {ko : '', en : 'Update', zh : '更新', vi : 'Cập nhật', ja : '更新'}
LanguageDic['최신버전 앱으로 업데이트를 위해 스토어로 이동합니다.'] = {ko : '', en : 'Go to the store to update to the latest version of the app.', zh : '进入商店以更新到最新版本的应用程序。', vi : 'Vào cửa hàng để cập nhật phiên bản mới nhất của ứng dụng.', ja : 'ストアに移動して最新バージョンのアプリにアップデートします。'}
LanguageDic['지정되지 않은 오류가 발생했습니다.'] = {ko : '', en : 'An unspecified error occurred.', zh : '发生未知错误', vi : 'Đã xảy ra lỗi', ja : '指定されていないエラーが発生しました。'}
LanguageDic['모델 바인딩 중 오류발생'] = {ko : '', en : 'Error binding model', zh : '绑定模型中发生错误', vi : 'Mô hình ràng buộc lỗi', ja : 'エラーバインディングモデル'}
LanguageDic['매장 즐겨찾기는 1개만 할 수 있습니다.'] = {ko : '', en : 'You can only do one store favorite.', zh : '门店收藏夹只能保存1个', vi : 'Bạn chỉ có thể chọn một cửa hàng yêu thích.', ja : 'お気に入りのお店は1つだけです。'}
LanguageDic['Access Token의 claims값이 잘못되었습니다.'] = {ko : '', en : 'The Claims value of the Access Token is invalid.', zh : '注册密钥无效。', vi : 'Giá trị không hợp lệ.', ja : 'アクセストークンのクレーム値が無効です。'}
LanguageDic['조회되는 회원이 없습니다.'] = {ko : '', en : 'No members are being inquired.', zh : '无法查询到会员信息', vi : 'Không có thành viên nào được hỏi.', ja : 'メンバーは問われていない。'}
LanguageDic['휴대폰 번호 유효성 검사 실패'] = {ko : '', en : 'Cell phone number validation failed', zh : '手机号码有效性检查失败', vi : 'Xác thực bằng số điện thoại thất bại', ja : '携帯電話番号の検証に失敗しました'}
LanguageDic['이미 존재하는 휴대폰 번호입니다.'] = {ko : '', en : 'The mobile phone number already exists.', zh : '已存在的手机号码', vi : 'Số điện thoại đã tồn tại', ja : '携帯電話番号は既に存在します。'}
LanguageDic['본인 회원 정보만 수정가능합니다 .'] = {ko : '', en : 'Only your membership information can be modified.', zh : '只能修改本人的会员信息。', vi : 'Chỉ có thể sửa đổi thông tin thành viên của bạn.', ja : '変更できるのは、会員情報のみです。'}
LanguageDic['보관할 수 있는 영상의 최대 개수를 초과하였습니다.'] = {ko : '', en : 'You have exceeded the maximum number of images that can be archived.', zh : '已超过可保存的视频的最大数量。', vi : 'Bạn đã vượt quá số lượng ảnh tối đa có thể được lưu trữ.', ja : 'アーカイブできるイメージの最大数を超えました。'}
LanguageDic['QR코드 로그인'] = {ko : '', en : 'QR Code Login', zh : '二维码登录/QR码登录', vi : 'Đăng nhập bằng mã QR', ja : 'QRコードログイン'}
LanguageDic['매장정보 공유하기'] = {ko : '', en : 'Sharing Store Information', zh : '共享门店信息', vi : 'Chia sẻ thông tin cửa hàng', ja : '店舗情報の共有'}
LanguageDic['메시지'] = {ko : '', en : 'Message', zh : '短信', vi : 'Thông điệp', ja : 'メッセージ'}
LanguageDic['카카오톡'] = {ko : '', en : 'Kakao Talk', zh : 'KakaoTalk', vi : 'Kakao Talk', ja : 'カカオトーク'}
LanguageDic['인스타그램'] = {ko : '', en : 'Instagram', zh : 'Instagram', vi : 'Instagram', ja : 'インスタグラム'}
LanguageDic['페이스북'] = {ko : '', en : 'Facebook', zh : 'Facebook', vi : 'Facebook', ja : 'フェイスブック'}
LanguageDic['URL 복사'] = {ko : '', en : 'Copy URL', zh : 'URL 复制', vi : 'Sao chép URL', ja : 'URL のコピー'}
LanguageDic['클럽스피드'] = {ko : '', en : 'Club Speed', zh : '杆头速度', vi : 'Tốc độ đầu gậy', ja : 'クラブスピード'}
LanguageDic['스매시 팩터'] = {ko : '', en : 'Smash Factor', zh : '击球效率', vi : 'Hệ số Smash', ja : 'スマッシュファクター'}
LanguageDic['런 거리'] = {ko : '', en : 'Run', zh : '滚动距离', vi : 'Khoảng cách lăn', ja : '走行距離'}
LanguageDic['좌우편차'] = {ko : '', en : 'Side Total', zh : '左右偏差', vi : 'Độ lệch trái và phải', ja : '左右偏差'}
LanguageDic['비공개 되었습니다.'] = {ko : '', en : 'It\'s closed to the public.', zh : '已非公开', vi : 'Đóng cửa cho công chúng.', ja : 'それは一般に公開されています。'}
LanguageDic['공개 되었습니다.'] = {ko : '', en : 'It\'s been revealed.', zh : '已公开', vi : 'Đã được công bố', ja : '公開されました。'}
LanguageDic['보관해제 되었습니다.'] = {ko : '', en : 'Unstored.', zh : '已解除存档', vi : 'Không lưu trữ', ja : '未保存。'}
LanguageDic['보관 되었습니다.'] = {ko : '', en : 'It has been archived.', zh : '已存档', vi : 'Đã được lưu giữ lại.', ja : 'アーカイブされました。'}
LanguageDic['매장대회'] = {ko : '', en : 'Store Tournaments', zh : '门店比赛', vi : 'một cuộc thi trong cửa hàng', ja : '店内コンテスト'}
LanguageDic['글로벌대회'] = {ko : '', en : 'Global Tournaments', zh : '全球比赛', vi : 'một giải đấu thế giới', ja : '世界的な競争'}
LanguageDic['현재 진행중인 대회가 없어요.'] = {ko : '', en : 'There\'s no competition going on right now.', zh : '目前无正在进行中的比赛', vi : 'Không có cuộc thi nào đang diễn ra vào lúc này.', ja : '現在、競争は行われていません。'}
LanguageDic['대회 상세 보기'] = {ko : '', en : 'View competition details', zh : '查看比赛详情', vi : 'Xem chi tiết cuộc thi', ja : '競合製品の詳細を表示する'}
LanguageDic['제한없음'] = {ko : '', en : 'Unlimited', zh : '无限制', vi : 'Không giới hạn', ja : '無制限'}
LanguageDic['제한'] = {ko : '', en : 'Limit', zh : '限制', vi : 'Giới hạn', ja : '制限'}
LanguageDic['고정'] = {ko : '', en : 'fixation', zh : '固定', vi : 'Không di chuyển', ja : '固定'}
LanguageDic['아마추어'] = {ko : '', en : 'Amateur', zh : '业余', vi : 'nghiệp dư', ja : '素人'}
LanguageDic['프로'] = {ko : '', en : 'Professional', zh : '职业', vi : 'chuyên nghiệp', ja : 'プロの'}
LanguageDic['18홀(OUT,IN)'] = {ko : '', en : '18 holes (OUT, IN)', zh : '18洞(OUT,IN)', vi : '18 lỗ (OUT, IN)', ja : '18ホール（OUT、IN）'}
LanguageDic['18홀(IN,OUT)'] = {ko : '', en : '18 holes (IN,OUT)', zh : '18洞(IN,OUT)', vi : '18 lỗ (IN, OUT)', ja : '18ホール（IN、OUT）'}
LanguageDic['9홀(OUT)'] = {ko : '', en : '9 holes (OUT)', zh : '9洞(OUT)', vi : '9 lỗ (OUT)', ja : '9ホール（OUT）'}
LanguageDic['9홀 (IN)'] = {ko : '', en : '9-hole (IN)', zh : '9洞 (IN)', vi : '9 lỗ (IN)', ja : '9 穴 (IN)'}
LanguageDic['수동'] = {ko : '', en : 'Manual', zh : '手动', vi : 'Thủ công', ja : 'マニュアル'}
LanguageDic['쉬움'] = {ko : '', en : 'Easy', zh : '简易', vi : 'Dễ', ja : '簡単'}
LanguageDic['보통'] = {ko : '', en : 'normal', zh : '普通', vi : 'bình thường', ja : '普通の'}
LanguageDic['어려움'] = {ko : '', en : 'Difficult', zh : '困难', vi : 'Khó', ja : '難儀'}
LanguageDic['매우느림'] = {ko : '', en : 'Very Slow', zh : '非常慢', vi : 'Rất chậm', ja : '非常に遅い'}
LanguageDic['느림'] = {ko : '', en : 'Slow', zh : '缓慢', vi : 'Chậm', ja : '遅さ'}
LanguageDic['보통'] = {ko : '', en : 'Normal', zh : '普通', vi : 'bình thường', ja : '普通の'}
LanguageDic['약간 빠름'] = {ko : '', en : 'Fast', zh : '稍快', vi : 'Nhanh', ja : 'より速く'}
LanguageDic['매우빠름'] = {ko : '', en : 'Very Fast', zh : '非常快', vi : 'Rất nhanh', ja : '非常に速い'}
LanguageDic['랜덤'] = {ko : '', en : 'Random', zh : '随机', vi : 'Ngẫu nhiên', ja : 'ランダム'}
LanguageDic['앞'] = {ko : '', en : 'Front', zh : '前', vi : 'phía trước', ja : '前線'}
LanguageDic['중간'] = {ko : '', en : 'Middle', zh : '中', vi : 'ở giữa', ja : '真ん中の'}
LanguageDic['뒤'] = {ko : '', en : 'Back', zh : '后', vi : 'phía sau', ja : 'バック'}
LanguageDic['랜덤'] = {ko : '', en : 'Random', zh : '随机', vi : 'Ngẫu nhiên', ja : 'ランダム'}
LanguageDic['왼쪽'] = {ko : '', en : 'Left', zh : '左方', vi : 'Trái', ja : '左'}
LanguageDic['오른쪽'] = {ko : '', en : 'Right', zh : '右方', vi : 'Phải', ja : '右'}
LanguageDic['없음'] = {ko : '', en : 'None', zh : '无', vi : 'Không', ja : 'ありません。'}
LanguageDic['약함'] = {ko : '', en : 'Weak', zh : '弱', vi : 'Nhẹ', ja : '弱い'}
LanguageDic['보통'] = {ko : '', en : 'Normal', zh : '普通', vi : 'Trung bình', ja : '普通の'}
LanguageDic['강함'] = {ko : '', en : 'Strong', zh : '强', vi : 'Mạnh', ja : '強い'}
LanguageDic['매우 강함'] = {ko : '', en : 'Very Strong', zh : '非常强', vi : 'Rất mạnh', ja : '非常に強い'}
LanguageDic['대회 랭킹 정보가 없습니다.'] = {ko : '', en : 'There is no competition ranking information.', zh : '无赛事排名信息', vi : 'Không có thông tin xếp hạng giải đấu', ja : '競争ランキングの情報はありません。'}
LanguageDic['대회 정보를 확인해 보세요.'] = {ko : '', en : 'Check the competition information.', zh : '请确认比赛信息', vi : 'Kiểm tra thông tin giải đấu.', ja : '競合情報を確認します。'}
LanguageDic['대회 시상 정보가 없어요.'] = {ko : '', en : 'There\'s no contest award information.', zh : '无大赛奖项信息', vi : 'Không có thông tin giải thưởng cuộc thi.', ja : 'コンテストの受賞情報はありません。'}
LanguageDic['생년월일을 입력해 주세요.'] = {ko : '', en : 'Please enter your date of birth.', zh : '请输入出生日期', vi : 'Xin vui lòng nhập ngày sinh của bạn.', ja : '生年月日を入力してください。'}
LanguageDic['퍼팅게임'] = {ko : '', en : 'Putting Game', zh : '推杆游戏', vi : 'Trò chơi putting', ja : 'パッティング·ゲーム'}
LanguageDic['샷게임'] = {ko : '', en : 'Shot Game', zh : '挥杆游戏', vi : 'Trò chơi Shotgame', ja : 'ショットゲーム'}
LanguageDic['퍼펙트'] = {ko : '', en : 'Perfect', zh : '完美', vi : 'Tuyệt vời', ja : '完璧な'}
LanguageDic['나이스'] = {ko : '', en : 'Nice', zh : '非常棒', vi : 'Đẹp lắm', ja : 'いい'}
LanguageDic['굿샷'] = {ko : '', en : 'Good Shot', zh : '好球', vi : 'Tốt lắm', ja : 'グッドショット'}
LanguageDic['홀인 비율'] = {ko : '', en : 'Hole-in rate', zh : '入洞率', vi : 'tỷ lệ vào lỗ', ja : '穴の開いた比率'}
LanguageDic['아직 플레이 기록이 없어요.'] = {ko : '', en : 'There\'s no play record yet.', zh : '目前没有比赛记录', vi : 'Chưa có thông tin vòng đấu', ja : 'まだプレー記録はない。'}
LanguageDic['가까운 매장에서 친구들과 라운드를 즐겨보세요.'] = {ko : '', en : 'Enjoy a round with your friends at a nearby store.', zh : '在附近的门店和朋友们一起享受比赛吧', vi : 'Hãy tận hưởng một vòng với bạn bè của bạn tại một cửa hàng gần đó.', ja : 'お友達と近くのお店でお楽しみください。'}
LanguageDic['월'] = {ko : '', en : 'Monday', zh : '一', vi : 'Thứ hai', ja : '月曜'}
LanguageDic['화'] = {ko : '', en : 'Tuesday', zh : '二', vi : 'Thứ ba', ja : '火曜日'}
LanguageDic['수'] = {ko : '', en : 'Wednesday', zh : '三', vi : 'Thứ tư', ja : '水曜日'}
LanguageDic['목'] = {ko : '', en : 'Thursday', zh : '四', vi : 'thứ năm', ja : '木曜日'}
LanguageDic['금'] = {ko : '', en : 'Friday', zh : '五', vi : 'Thứ sáu', ja : '金曜日'}
LanguageDic['토'] = {ko : '', en : 'Saturday', zh : '六', vi : 'thứ bảy', ja : 'サタデー'}
LanguageDic['일'] = {ko : '', en : 'Sunday', zh : '日', vi : 'Chủ nhật', ja : '日曜日'}
LanguageDic['앱이 설치되어 있지 않습니다. 마켓으로 이동합니다.'] = {ko : '', en : 'The app is not installed. Go to Market.', zh : '应用程序未安装。 去市场。', vi : 'Chưa cài đặt ứng dụng. Đến khu chợ.', ja : 'アプリがインストールされていません。 マーケットに移動します。'}
LanguageDic['현재 비밀번호'] = {ko : '', en : 'Current Password', zh : '当前密码', vi : 'Mật khẩu hiện thời', ja : '現在のパスワード'}
LanguageDic['현재 비밀번호와\n새로운 비밀번호를\n입력해 주세요.'] = {ko : '', en : 'Enter your current password and new password.', zh : '输入当前密码和新密码。', vi : 'Nhập mật khẩu hiện tại và mật khẩu mới.', ja : '現在のパスワードと新しいパスワードを入力します。'}
LanguageDic['보관할 수 있는 스윙 영상의 최대 개수를 초과하였습니다.'] = {ko : '', en : 'The maximum number of swing videos that can be stored has been exceeded.', zh : '已到达最大挥杆视频储存量。', vi : 'Số lượng video swing tối đa có thể được lưu trữ đã vượt quá.', ja : '保存できるスイング動画の最大数を超えました。'}



export function L(language)
{
var nowlan = sessionStorage.getItem('Language');
if(LanguageDic[language] === undefined || LanguageDic[language] == null )
{
return language;
}
else if(LanguageDic[language][nowlan] == '')
{
return language;
}
else
{
return LanguageDic[language][nowlan];
}
}

export function LTarget(language, getlan)
{
if(LanguageDic[language] === undefined || LanguageDic[language] == null )
{
return language;
}
else if(LanguageDic[language][getlan] == '')
{
return language;
}
else
{
return LanguageDic[language][getlan];
}
}
