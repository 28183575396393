import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

export function slideOn(){
	$("html").addClass("overflowHidden");
	$("#language").addClass("on");
}

export function slideExit(){
	$("html").removeClass("overflowHidden");
	$(".layerView").removeClass("on");
}

export default function BottomLangauge() {
	
	const [languagehtmllist, setlanguagehtmllist] = useState([]);

	var list = common.commonKeyDic['Language']; 
	var lan_value;
	useEffect(() => {
		var htmllist = [];
		for(let i =0; i < Object.keys(list).length; i++)
		{
			lan_value =  Object.values(common.commonKeyDic['Language'])[i];
			htmllist.push(
			  <a key={i+"l"}>
				<p style={{backgroundImage: "url("+require('../'+common.commonKeyDic[lan_value]['imageurl'])+")"}}>image</p>
				<input type="radio" id={common.commonKeyDic[lan_value]['name']} name="language" value={lan_value} defaultChecked={sessionStorage.getItem('Language') == lan ? true : false} 
					onClick={goSelect}/>
				<label htmlFor={common.commonKeyDic[lan_value]['name']}>{common.commonKeyDic[lan_value]['name']}</label>
			  </a>
			);
		}
		setlanguagehtmllist(htmllist);
    },[]);

	const goSelect = (e) => {
		var value = e.target.value;
		common.setNowLan(value);
		window.location.href='/';
	}

	return (
	<div id="language" className="layerView">
	  <div className="bottom_sheet">
		<a onClick={slideExit}>close</a>
		<div className="bs_body">
		  <em>{lan.L("언어선택")}</em>
		  <div className="scroll_container">
			<div className="list_image">
				{languagehtmllist}
			</div>
		  </div>
		</div>
	  </div>
	</div>
	)
}