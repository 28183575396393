import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';

var navigate;

function popAlertExit(){
    $("html").removeClass("overflowHidden");
    $("#pop_alert_layer").removeClass("on");
	$("#pop_alert_title").html('');
	$("#pop_alert_text").html('');
    $("#pop_alert_text").removeClass("alignCenter");
    
	if(window.localStorage.getItem('popupnavigate') !== undefined && window.localStorage.getItem('popupnavigate') != null && window.localStorage.getItem('popupnavigate') != '')
    {
        if(window.localStorage.getItem('popupnavigate') == '-1')
        {
            navigate(-1);
        }
        else
        {
            navigate(window.localStorage.getItem('popupnavigate'));
        }
        window.localStorage.setItem('popupnavigate','');
    }
    //앱실행시 실행하는 common의 getIntro에서 강제업데이트를 실행하는 경우 처리
    else if(window.localStorage.getItem('popupOpenMarket') == 'Y')
    {
        window.localStorage.setItem('popupOpenMarket','');
        if(common.isIOS()){
            common.iOSCallApp({appid:"openMarket", params:""});
        }
        else
        {
            // try{common.andriodCallApp('openMarket','');}
            try{common.andriodCallApp('openBrowser',['https://play.google.com/store/apps/details?id=com.thekgolf.Kgolf']);}
            catch (ex){}
        }
        window.localStorage.setItem('popupOpenMarket','O'); //O인경우 Y로 팝업이 실행되지는 않지만, Intro에서는 다음화면으로 안넘어가게 처리
        window.localStorage.setItem('popupnavigate','');
    }
}

export default function PopupAlert(){
    navigate = useNavigate();

	return (
    <>
    <div id="pop_alert_layer" className="layerView">
        <div className="layer_body">
            <div className="popup_container">
            <em id="pop_alert_title"></em>
            <div className="alert_content new-line" id="pop_alert_text">
            </div>
            </div>
            <div className="popup_button">
                <button type="button" id="popupAlertBtn" className="btn_standard" onClick={popAlertExit}>{lan.L('확인')}</button>
            </div>
        </div>
    </div>
    </>
	)
}