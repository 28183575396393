import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

import * as common from '../common/script/common';


export default function Top(props) {

	const location = useLocation();
	const [backTo, SetbackTo] = useState('none');
	const [backTabTopics, SetbackTabTopics] = useState('none');
	
	//화면전환시 실행
	useEffect(() => {
		window.addEventListener('storage', () => {
			if(props.lockFunction !== undefined)
			{
				$("#secret").attr("checked",window.localStorage.getItem('isView')=='true' ? false : true);
			}
		});
		
		$(".wrap").css("padding-top","0px");
		$(".contents").css("margin-top","0px");
			
		if(location.state != null && location.state.isnewpage !== undefined)
		{
			sessionStorage.setItem('Language','ko');
		}
		
		if(location.state != null && location.state.backTabTopics !== undefined)
		{
			SetbackTabTopics(location.state.backTabTopics);
		}
		else
		{
			SetbackTabTopics('none');
		}
		
		if(location.state != null && location.state.backTo !== undefined)
		{
			SetbackTo(location.state.backTo);
		}
		
		if($("header").hasClass("topfix"))
		{
			var headeroffset = $(".topfix").offset();
			var wrap = $(".contents");
			
			if($("header").hasClass("topfix"))
			{
				$(window).scroll(function () {
					
					if($("header").hasClass("topfix"))
					{
						if ($(this).scrollTop() > headeroffset.top){
							$(".wrap").css("padding-top","48px");
							$(".topfix").addClass("fixed");
						}else {
							$(".wrap").css("padding-top","0px");
							$(".topfix").removeClass("fixed");
						}
					}
				});
			}
		}
		
		if($("header").hasClass("convert"))
		{
			var headeroffset = $(".convert").offset();
			var wrap = $(".contents");
			$(window).scroll(function () {
				if ($(this).scrollTop() > headeroffset.top){
					$(".convert").removeClass("turn");
				}else {
					$(".convert").addClass("turn");
				}
			});
		}
			
	},[location]);

	const navigate = useNavigate();
	
	const goback = (e) => {
		if(backTo != 'none')
		{
			navigate(backTo,{replace:true});
		}
		else if(props.type == 'join')
		{
			window.localStorage.setItem('choicenavigate','-1');
			window.localStorage.setItem('choice_joinback','Y');
			common.alertchoice(lan.L('회원가입'),lan.L('회원가입 정보가 초기화됩니다. 취소하시겠습니까?'));
		}
		else if(props.type == 'joinaddition')
		{
			window.localStorage.setItem('choicenavigate','-1');
			common.alertchoice(lan.L('상세정보입력'),lan.L('로그인 페이지로 이동하시겠습니까?'));
		}
		else if(props.type == 'backwithstate' && backTabTopics != 'none') //뒤로가기인데 state를 가지고 감
		{
			navigate(props.to,{state:Object.assign(props.state,{nowTabTopics:backTabTopics}), replace:true});
			SetbackTabTopics('none');
		}
		else if(backTabTopics != 'none' )
		{
			navigate(props.to,{state:{nowTabTopics:backTabTopics}, replace:true});
			SetbackTabTopics('none');
		}
		else
		{
			navigate(-1);
		}
	}
	
	const goPopExit = (e) => {
        window.localStorage.setItem('choice_exit','Y');
		common.alertchoice(lan.L('종료'),lan.L('종료하시겠습니까?'));
	}

	if(props.title == -1)
	{
		return (
			<header></header>
		)
	}
	else if(props.exit == "Y")
	{
		return (
			<button type="button" id="Back" onClick={goPopExit} style={{'display':'none'}}>Back</button>
		)
	}
	else if(location.state != null && location.state.isnewpage !== undefined)
	{
		return (
			<header>
				<em>{lan.L(props.title)}</em>
			</header>
		)
	}
	else
	{
		return (
		<header className={props.class} >
			<button type="button" id="Back" onClick={goback} >Back</button>
			{
				props.class != "convert turn" ? 
				<em className={props.spanClass}>{lan.L(props.title)}</em> : 
				<span>{lan.L(props.title)}</span>
			}
			{
				props.labelText === undefined ? '' :
				<label class="top_selection" onClick={props.labelFunc}>{props.labelText}</label>
			}
			{
				props.lockFunction === undefined ? '' :
				<div className="secret">
					<input type="checkbox" id="secret" name="secret" onClick={props.lockFunction}/>
					<label htmlFor="secret">{lan.L('공개여부')}</label>
				</div>
			}
		</header>
		);
	}
}