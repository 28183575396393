import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';


export default function Nickname(){
	
	const location = useLocation();
	const navigate = useNavigate();
	const [InfoData, SetInfoData] = useState({nickname : ''});

	//화면전환시 실행
	useEffect(() => {
		if(window.localStorage.getItem('join_nickname') === null) window.localStorage.setItem('join_nickname','');
		if(window.localStorage.getItem('join_nickname') != '')
		{
			SetInfoData(
				{
				  nickname : window.localStorage.getItem('join_nickname'),
				}
			);
			$("#newNickname").val(InfoData.nickname);
		}
		else
		{
			$("#btnAddition").attr('disabled',true);
		}
    },[location]);

	const onChange = (e) => {
		  var id = e.target.id;
		  var value = e.target.value;
		  var data = InfoData;

		if(id == "newNickname")
		{
			data.nickname = value;

			if(value != '') $("#newNicknameDel").show();
			else $("#newNicknameDel").hide();

			$("#newNickname_basic").hide();
			$("#newNickname_error").hide();
			$("#newNickname_error2").hide();
			$("#newNickname_success").hide();
			$("#btnAddition").attr('disabled',true);
			
			if(value == '') $("#newNickname_basic").show();
			else if(!common.vaildChkData('nickname',value)) $("#newNickname_error").show();
			else {
				axios({
					method: 'post',
					url: process.env.REACT_APP_SERVER + '/api',
					data: {nickname: value},
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'language':sessionStorage.getItem('Language'),
						'pUrl' : encodeURIComponent('회원 닉네임 중복확인')
					}
				}).then(function (response) {
					console.log(response);
					if(response.data.isInquiry == false)
					{
						$("#newNickname_basic").hide();
						$("#newNickname_error").hide();
						$("#newNickname_error2").hide();
						$("#newNickname_success").hide();
						$("#newNickname_success").show();
						$("#btnAddition").attr('disabled',false);
					}
					else if(response.data.isInquiry == true)
					{
						$("#newNickname_basic").hide();
						$("#newNickname_error").hide();
						$("#newNickname_error2").hide();
						$("#newNickname_success").hide();
						$("#newNickname_error2").show();
						$("#btnAddition").attr('disabled',true);
					}
					else
					{
						//에러 시 처리 필요
					}
				}).catch(error => {
					console.log(error.response)
				});
			}
		}
	}
	  
	const onDelete = (e) => {
		var id = e.target.id;
	    var data = InfoData;
		
		if(id == "newNicknameDel")
		{
			data.nickname = '';
			$("#newNickname").val('');
			SetInfoData(data);
			$("#newNicknameDel").hide();
			
			$("#newNickname_basic").show();
			$("#newNickname_error").hide();
			$("#newNickname_error2").hide();
			$("#newNickname_success").hide();
		}
	}

	// 체크박스 단일 선택
	const goAddition = (e) => {

		if(!common.vaildChkData('nickname',InfoData.nickname))
		{
			common.alertpop(lan.L('회원가입'),lan.L('닉네임은 특수문자를 제외한 4자 이상만 가능합니다.'));
			return false;
		}

		window.localStorage.setItem('join_nickname',InfoData.nickname);

		navigate('/join/addition',{replace:true});
	}

	return (
	<div>
	  <div className="container">
		<div className="process">
		  <span><b>3</b>/5</span>
		  <em>{lan.L('닉네임 등록하기')}</em>
		</div>
		<ul className="write_form">
		  <li>
			<span>
			  <input type="text" id="newNickname" onChange={(e) => onChange(e)} placeholder={lan.L("닉네임 입력")} />
			  <em>{lan.L('닉네임')}</em>
			  <a id="newNicknameDel" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
			</span>
			<div className="display_text">
			  <b className="basic" id="newNickname_basic">{lan.L('특수문자를 제외한 4자 이상만 가능합니다.')}</b>
			  <b className="success" id="newNickname_success" style={{display: 'none'}}>{lan.L('사용가능한 닉네임입니다.')}</b>
			  <b className="error" id="newNickname_error" style={{display: 'none'}}>{lan.L('특수문자를 제외한 4자 이상만 가능합니다.')}</b>
			  <b className="error" id="newNickname_error2" style={{display: 'none'}}>{lan.L('이미 사용중인 닉네임입니다.')}</b>
			</div>
		  </li>
		</ul>
	  </div>{/* container(e) */}
	  <div className="sticky_button">
		<button type="button" className="btn_standard" id="btnAddition" onClick={goAddition}>{lan.L('다음')}</button>
	  </div>
	</div>
	)
}