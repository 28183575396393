import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';


export default function RegisterId(){

	const location = useLocation();
	const navigate = useNavigate();
	const [InfoData, SetInfoData] = useState({loginID : '', loginPass : '' , loginPass2 : ''});

	  // 체크된 아이템을 담을 배열
  const [checkItems, setCheckItems] = useState([]);

	//화면전환시 실행
	useEffect(() => {
		if(window.localStorage.getItem('join_loginID') === null) window.localStorage.setItem('join_loginID','');
		if(window.localStorage.getItem('join_loginPass') === null) window.localStorage.setItem('join_loginPass','');
		if(window.localStorage.getItem('join_loginPass2') === null) window.localStorage.setItem('join_loginPass2','');
		if(window.localStorage.getItem('join_loginID') != '')
		{
			SetInfoData(
				{
				  loginID : window.localStorage.getItem('join_loginID'),
				  loginPass : window.localStorage.getItem('join_loginPass'),
				  loginPass2 : window.localStorage.getItem('join_loginPass2'),
				}
			);
			$("#newloginID").val(InfoData.loginID);
			$("#newloginpass").val(InfoData.loginPass);
			$("#newloginpass2").val(InfoData.loginPass2);
		}
		checkAllPossible();	
    },[location]);

	const onChange = (e) => {
		  var id = e.target.id;
		  var value = e.target.value;
		  var data = InfoData;

		if(id == "newLoginID")
		{
			data.loginID = value.toLowerCase();
			setCheckItems(data);
			if(value != '') $("#newLoginIDDel").show();
			else $("#newLoginIDDel").hide();

			$("#newLoginID_basic").hide();
			$("#newLoginID_error").hide();
			$("#newLoginID_error2").hide();
			$("#newLoginID_success").hide();
			
			if(value == '') $("#newLoginID_basic").show();
			else if(!common.vaildChkData('id',value)) $("#newLoginID_error").show();
			else {
				axios({
					method: 'post',
					url: process.env.REACT_APP_SERVER + '/api',
					data: {id: InfoData.loginID},
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'language':sessionStorage.getItem('Language'),
						'pUrl' : encodeURIComponent('회원 아이디 중복확인')
					}
				}).then(function (response) {
					console.log(response);
					if(response.data.isInquiry == false)
					{
						$("#newLoginID_basic").hide();
						$("#newLoginID_error").hide();
						$("#newLoginID_error2").hide();
						$("#newLoginID_success").show();
						checkAllPossible();
					}
					else if(response.data.isInquiry == true)
					{
						$("#newLoginID_basic").hide();
						$("#newLoginID_error").hide();
						$("#newLoginID_error2").hide();
						$("#newLoginID_success").hide();
						$("#newLoginID_error2").show();
						checkAllPossible();
					}
					else
					{
						//에러 시 처리 필요
					}
				}).catch(error => {
					console.log(error.response)
				});
			}
		}
		else if(id == "newLoginpass")
		{
			data.loginPass = value;
			setCheckItems(data);
			
			if(value != '') $("#newLoginpassDel").show();
			else $("#newLoginpassDel").hide();
			
			$("#newLoginpass_basic").hide();
			$("#newLoginpass_error").hide();
			$("#newLoginpass_success").hide();
			
			$("#newLoginpass2_error").hide();
			$("#newLoginpass2_success").hide();
			
			if(value == '') $("#newLoginpass_basic").show();
			else if(!common.vaildChkData('pw',value)) $("#newLoginpass_error").show();
			else $("#newLoginpass_success").show();
			
			if(data.loginPass2 == ''){}
			else if(data.loginPass2 != data.loginPass) $("#newLoginpass2_error").show();
			else $("#newLoginpass2_success").show();
		}
		else if(id == "newLoginpass2")
		{
			data.loginPass2 = value;
			setCheckItems(data);
			
			if(value != '') $("#newLoginpass2Del").show();
			else $("#newLoginpass2Del").hide();
			
			$("#newLoginpass2_error").hide();
			$("#newLoginpass2_success").hide();
			
			if(data.loginPass2 == ''){}
			else if(data.loginPass2 != data.loginPass) $("#newLoginpass2_error").show();
			else $("#newLoginpass2_success").show();
		}
		checkAllPossible();
	}
	  
	const onDelete = (e) => {
		var id = e.target.id;
	    var data = InfoData;
		
		if(id == "newLoginIDDel")
		{
			$("#newLoginID_basic").hide();
			$("#newLoginID_error").hide();
			$("#newLoginID_error2").hide();
			$("#newLoginID_success").hide();

			data.loginID = '';
			$("#newLoginID").val('');
			SetInfoData(data);
			$("#newLoginIDDel").hide();
			
			$("#newLoginID_basic").show();
		}
		else if(id == "newLoginpassDel")
		{
			data.loginPass = '';
			$("#newLoginpass").val('');
			SetInfoData(data);
			$("#newLoginpassDel").hide();
			
			$("#newLoginpass_basic").show();
			$("#newLoginpass_error").hide();
			$("#newLoginpass_success").hide();
		}
		else if(id == "newLoginpass2Del")
		{
			data.loginPass2 = '';
			$("#newLoginpass2").val('');
			SetInfoData(data);
			$("#newLoginpass2Del").hide();
			
			$("#newLoginpass2_error").hide();
			$("#newLoginpass2_success").hide();
		}
		checkAllPossible();
	}

	// 체크박스 단일 선택
	const goNickname = (e) => {

		if(!common.vaildChkData('id',InfoData.loginID))
		{
			common.alertpop(lan.L('회원가입'),lan.L("로그인은 영문, 숫자 4자 ~ 20자 이하만 입력가능합니다."));
			return false;
		}

		if(!common.vaildChkData('pw',InfoData.loginPass))
		{
			common.alertpop(lan.L('회원가입'),lan.L("비밀번호는 숫자 6자리만 입력 가능합니다."));
			return false;
		}

		if(InfoData.loginPass != InfoData.loginPass2)
		{
			common.alertpop(lan.L('회원가입'),lan.L("비밀번호가 일치하지 않습니다."));
			return false;
		}

		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {id: InfoData.loginID},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 아이디 중복확인')
			}
		}).then(function (response) {
			console.log(response);
			if(response.data.isInquiry == false)	//중복없음
			{
				window.localStorage.setItem('join_loginID',InfoData.loginID);
				window.localStorage.setItem('join_loginPass',InfoData.loginPass);
				window.localStorage.setItem('join_loginPass2',InfoData.loginPass2);

				window.localStorage.setItem('join_nickname','');
				navigate('/join/nickname',{replace:true});
			}
			else if (response.data.isInquiry == false) //중복인 경우
			{
				common.alertpop(lan.L('회원가입'),lan.L("이미 사용중인 아이디입니다."));
				return false;
			}
			else
			{

			}
		}).catch(error => {
			console.log(error.response)
		});
	}

	return (
	<div>
	  <div className="container">
		<div className="process">
		  <span><b>2</b>/5</span>
		  <em>{lan.L('아이디 등록하기')}</em>
		</div>
		<ul className="write_form">
		  <li>
			<span>
			  <input type="text" id="newLoginID" onChange={(e) => onChange(e)} placeholder={lan.L("아이디 입력")} />
			  <em>{lan.L('아이디')}</em>
			  <a id="newLoginIDDel" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
			</span>
			<div className="display_text">
			  <b className="basic" id="newLoginID_basic">{lan.L('4자 이상 20자 이하 영문, 숫자만 가능합니다.')}</b>
			  <b className="error" id="newLoginID_error" style={{display: 'none'}}>{lan.L('4자 이상 20자 이하 영문, 숫자만 가능합니다.')}</b>
			  <b className="error" id="newLoginID_error2" style={{display: 'none'}}>{lan.L('이미 사용중인 아이디입니다.')}</b>
			  <b className="success"id="newLoginID_success" style={{display: 'none'}}>{lan.L('사용가능한 아이디입니다.')}</b>
			</div>
		  </li>
		  <li>
			<span>
			  <input type="password" id="newLoginpass" onChange={(e) => onChange(e)} placeholder={lan.L("비밀번호 입력")} />
			  <em>{lan.L('비밀번호')}</em>
			  <a id="newLoginpassDel" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
			</span>
			<div className="display_text">
			  <b className="basic" id="newLoginpass_basic">{lan.L('숫자 6자리만 입력 가능합니다.')}</b>
			  <b className="error" id="newLoginpass_error" style={{display: 'none'}}>{lan.L('숫자 6자리만 입력 가능합니다.')}</b>
			  <b className="success"id="newLoginpass_success" style={{display: 'none'}}>{lan.L('사용가능한 비밀번호입니다.')}</b>
			</div>
		  </li>
		  <li>
			<span>
			  <input type="password" id="newLoginpass2" onChange={onChange} placeholder={lan.L("비밀번호 재입력")} />
			  <em>{lan.L('비밀번호 확인')}</em>
			  <a id="newLoginpass2Del" style={{display: 'none'}} herf='/' onClick={(e) => onDelete(e)} >delete</a>
			</span>
			<div className="display_text">
			  <b className="success" id="newLoginpass2_success" style={{display: 'none'}}>{lan.L('비밀번호가 일치합니다.')}</b>
			  <b className="error" id="newLoginpass2_error" style={{display: 'none'}}>{lan.L('비밀번호가 일치하지 않습니다.')}</b>
			</div>
		  </li>
		</ul>
	  </div>
	  <div className="sticky_button">
		<button type="button" id="btnNickname" className="btn_standard" onClick={goNickname}>{lan.L('다음')}</button>
	  </div>
	</div>

	)
}

function checkAllPossible(){
	if($("#newLoginID_success").css('display') == 'none')
	{
		$("#btnNickname").attr('disabled',true);
		return false;
	}
	else if($("#newLoginpass_success").css('display') == 'none')
	{
		$("#btnNickname").attr('disabled',true);
		return false;
	}
	else if($("#newLoginpass2_success").css('display') == 'none')
	{
		$("#btnNickname").attr('disabled',true);
		return false;
	}

	$("#btnNickname").attr('disabled',false);
}