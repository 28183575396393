import React from 'react';
import { useDispatch } from "react-redux";
import * as lan from '../common/script/common_lan.js';

export default function BottomMatchAward(prop){
	const dispatch = useDispatch();
    
	const goSelect = (e, value) => {
		dispatch({type:'match_awardNum', value: value});
		prop.slideExit();
	}
    
	return (
		<>
        <div className="layerView" id="pop_awardId">
            <div className="bottom_sheet">
                <a onClick={prop.slideExit}>close</a>
                <div className="bs_body">
                <em>{lan.L('시상명')}</em>
                <div className="scroll_container">
					<div className="list_selection">
						<a key={"allb"}>
							<input type="radio" value="" id={"awardIdall"}
								   name={"bottomawardId"}
								   onClick={(e) => goSelect(e, "all")} defaultChecked={true}/>
							<label htmlFor={"awardIdall"}>{lan.L("전체보기")}</label>
						</a>
						{prop.InfoAwardData?.awardItems?.map((item, i) =>
						<a key={i + "b"}>
							<input type="radio" value={i} id={"awardId" + i}
								   name={"bottomawardId"}
								   onClick={(e) => goSelect(e, i)}/>
							<label htmlFor={"awardId" + i}>{lan.L(item.awardName)}</label>
						</a>
						)}
					</div>
				</div>
				</div>
			</div>
		</div>
		</>
	)
}