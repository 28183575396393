import * as React from 'react';
import { ResponsiveLine } from '@nivo/line';

const Barchart = (props) => {
    const handle = {
        barClick: (data: any) => {
            console.log(data);
        },

        legendClick: (data: any) => {
            console.log(data);
        },
    };

    return (
        // chart height이 100%이기 때문이 chart를 덮는 마크업 요소에 height 설정
        <div style={{ width: '100%', height: '240px', margin: '0 auto', left:'5%' }}>
            <ResponsiveLine
        data={[
            {
                id: 'A',
                data : props.data
                // data: [
                //     { x: '08.15', y: 120.5 },
                //     { x: '08.15', y: 112.1 },
                //     { x: '08.15', y: 129.3 },
                //     { x: '08.15', y: 110.3 },
                //     { x: '08.15', y: 133.5 },
                //     { x: '08.20', y: 123.12 },
                //     { x: '08.21', y: 143.3 },
                // ],
            },
        ]}
        margin={{ top: 25, right: 15, bottom: 50, left: 15 }}
        xScale={{type: 'point'}}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 0,
            tickPadding: 20,
            tickRotation: 0,
        }}
        axisLeft={null}
        enableGridX={false}
        gridXValues={4}
        colors={{ scheme: 'dark2' }}
        pointSize={5}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor', modifiers: [] }}
        enablePointLabel={true}
        pointLabel="y"
        pointLabelYOffset={-12}
        enableArea={true}
        areaBaselineValue={props.min - Math.abs(props.min)  * 0.05} //106.1 최소값 -5
        areaOpacity={0.1}
        isInteractive={true}
        useMesh={true}
        legends={[]}
    />
        </div>
    );
};

export default Barchart;