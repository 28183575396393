import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common'
import $ from 'jquery'
import axios from 'axios';

export function slideOn(){
	$("html").addClass("overflowHidden");
	$("#country").addClass("on");
}

export function slideExit(){
	$("html").removeClass("overflowHidden");
	$(".layerView").removeClass("on");
}

export default function BottomCountryPhone(prop) {
	
	const [countryhtmllist, setcountryhtmllist] = useState([]);
	
	
	useEffect(() => {
		var htmllist = [];
		for(let i =0; i < common.commonPhoneDic.length; i++)
		{
			htmllist.push(
				<a key={i+"a"} >
				<input type="radio" key={i} data-name={common.commonPhoneDic[i].settingName} data-pvalue={common.commonPhoneDic[i].phoneValue} data-key={common.commonPhoneDic[i].settingValue} id={common.commonPhoneDic[i].settingValue} name="country" />
				<label htmlFor={common.commonPhoneDic[i].settingValue}>
				<strong>{common.commonPhoneDic[i].settingName}</strong>
				</label>
			</a>);
		}
		setcountryhtmllist(htmllist);

		// axios({
		// 	method: 'post',
		// 	url: process.env.REACT_APP_SERVER + '/api',
		// 	data: {},
		// 	headers: {
		// 		'Content-Type': 'application/x-www-form-urlencoded',
		// 		'language':sessionStorage.getItem('Language'),
		// 		'pUrl' : encodeURIComponent('국가 코드 목록 조회')
		// 	}
		// }).then(function (response) {
		// 	console.log(response);
		// 	var list = response.data.list;
		// 	var htmllist = [];
		// 	for(let i =0; i < list.length; i++)
		// 	{
		// 		htmllist.push(
		// 			<a key={i+"a"} >
		// 			<input type="radio" key={i} data-name={list[i].settingName} data-key={list[i].settingValue} id={list[i].settingValue} name="country" />
		// 			<label htmlFor={list[i].settingValue}>
		// 			  <strong>{list[i].settingName}</strong>
		// 			</label>
		// 		  </a>);
		// 	}
		// 	setcountryhtmllist(htmllist);
		// }).catch(error => {
		// 	console.log(error.response)
		// });
    },[]);
	
	const goSelect = (e, returntype, returnid, returnvalueid, setPhoneCountryValue) => {
		if(returntype == 'html')
		{
			$("#"+returnid).html($("input[name=country]:checked").data("name"));
			slideExit();
		}
		$("#"+returnvalueid).val($("input[name=country]:checked").data("key"));
		window.localStorage.setItem('phoneCountryNumber',$("input[name=country]:checked").data("pvalue"));
		setPhoneCountryValue($("input[name=country]:checked").data("key"));
	}

	return (
	<div id="country" className="layerView">
	  <div className="bottom_sheet">
		<a onClick={(e) => slideExit()}>close</a>
		<div className="bs_body">
		  <em>{lan.L('국가 선택')}</em>
		  <div className="scroll_container">
			<div className="list_country">
				{countryhtmllist}
			</div>
		  </div>
		</div>
		<div className="bottom_sheet_button">
		  <button type="button" className="btn_cancel" onClick={(e) => slideExit()}>{lan.L('취소')}</button>
		  <button type="button" className="btn_standard" onClick={(e) => goSelect(e, prop.returntype, prop.returnid, prop.returnvalueid, prop.setPhoneCountryValue)}>{lan.L("확인")}</button>
		</div>
	  </div>
	</div>
	)
}