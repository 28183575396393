import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

import BottomRoundset from '../popup/bottom_roundset';
import * as slide from '../popup/bottom_roundset';

var navigate;
var is_submit = false;

export default function Roundset(){

	const location = useLocation();
	navigate = useNavigate();
	const [InfoData, SetInfoData] = useState({difficulty : '', teeboxtype : '', teeheight : ''});
	
	//화면전환시 실행
	useEffect(() => {
		checkAllPossible(InfoData);

		//input change가 html이 뿌려진 이후에 적용가능
		setTimeout(function(){
			$("input").change(function(){
				InfoData.difficulty = $("#newDifficultyValue").val();
				InfoData.teeboxtype = $("#newTeeBoxTypeValue").val();
				InfoData.teeheight = $("#newTeeHeightValue").val();

				checkAllPossible(InfoData);
			});
		}, 500);
		
	$('body').on('click',function(e){
		if(!$(e.target).hasClass('tooltipcon'))
		{
			$("#tooltip1").removeClass("on");
			$("#tooltip2").removeClass("on");
			$("#tooltip3").removeClass("on");
		}
	});

    },[location]);


	const onTooltip = (e) => {
		var id = e.target.id;

		$("#tooltip1").removeClass("on");
		$("#tooltip2").removeClass("on");
		$("#tooltip3").removeClass("on");

		if(id == "help1")
		{
			$("#tooltip1").addClass("on");
		}
		else if(id == "help2")
		{
			$("#tooltip2").addClass("on");
		}
		else if(id == "help3")
		{
			$("#tooltip3").addClass("on");
		}
	}
	

	const goJoinSave = (e) => {
		var id = e.target.id;
		
		InfoData.difficulty = $("#newDifficultyValue").val();
		InfoData.teeboxtype = $("#newTeeBoxTypeValue").val();
		InfoData.teeheight = $("#newTeeHeightValue").val();
		
		window.localStorage.setItem('join_difficulty',InfoData.difficulty);
		window.localStorage.setItem('join_teeboxtype',InfoData.teeboxtype);
		window.localStorage.setItem('join_teeheight',InfoData.teeheight);

		if(is_submit)
		{
			return false;
		}

		is_submit = true;
		goMembermodify();
	}
	
	return (
	<>
	<BottomRoundset returntype="html" returnid={{"difficulty":"newDifficulty","teeboxtype":"newTeeBoxType","teeheight":"newTeeHeight"}} 
		returnvalueid={{"difficulty":"newDifficultyValue","teeboxtype":"newTeeBoxTypeValue","teeheight":"newTeeHeightValue"}}
		defaultvalue={{"difficulty":null,"teeboxtype":null,"teeheight":null}}></BottomRoundset>
	<div>
	  <div className="container">
		<div className="process">
		  <span><b>2</b>/2</span>
		  <em>{lan.L('라운드 설정')}</em>
		</div>
		<ul className="write_form">
		  <li>
			<span>
			  <input type="hidden" id="newDifficultyValue"/>
			  <label onClick={(e) => slide.slideDifficulty()} id="newDifficulty">{lan.L('선택')}</label>
			  <em>
				{lan.L('난이도')}
				<i id="help1" className="tooltipcon" onClick={onTooltip}>help</i>
				<div id="tooltip1" className="tooltip tooltipcon">
				  <a className="tooltipcon" onClick={onTooltip}>close</a>
				  <p className="new-line tooltipcon">{lan.L('라운드 콘텐츠 중 사용자의 샷에 대한 보정을 얼마나 받을지 선택 할 수 있습니다. \n투어프로로 이동하면 보정값이 감소합니다.')}</p>
				</div>
			  </em>
			</span>
		  </li>
		  <li>
			<span>
			  <input type="hidden" id="newTeeBoxTypeValue" value=""/>
			  <label onClick={(e) => slide.slideTeeBoxType()} id="newTeeBoxType">{lan.L('선택')}</label>
			  <em>
				{lan.L('티 위치')}
				<i id="help2" className="tooltipcon" onClick={onTooltip}>help</i>
				<div id="tooltip2" className="tooltip tooltipcon">
				  <a className="tooltipcon"  onClick={onTooltip}>close</a>
				  <p className="new-line tooltipcon" >{lan.L('라운드 콘텐츠에서 사용할 티그라운드를 선택합니다.')}</p>
				</div>
			  </em>
			</span>
		  </li>
		  <li>
			<span>
			  <input type="hidden" id="newTeeHeightValue" value=""/>
			  <label onClick={(e) => slide.slideTeeheight()} id="newTeeHeight">{lan.L('선택')}</label>
			  <em>
				{lan.L('티 높이')}
				<i id="help3"className="tooltipcon" onClick={onTooltip}>help</i>
				<div id="tooltip3" className="tooltip tooltipcon">
				  <a className="tooltipcon" onClick={onTooltip}>close</a>
				  <p className="new-line tooltipcon">{lan.L('라운드 내용에서 티샷할 때 사용할 티 높이를 선택합니다.\n라운딩 중에 계측기의 티 높이를 변경 할 수 있습니다.')}</p>
				</div>
			  </em>
			</span>
		  </li>
		</ul>
	  </div>{/* container(e) */}
	  <div className="sticky_button">
		<button type="button" className="btn_standard" id="btnJoinSave" onClick={goJoinSave}>{lan.L('저장')}</button>
	  </div>
	</div>
	</>
	)
}
	  
function checkAllPossible(InfoData) {
	$("#btnJoinSave").attr('disabled',true);
	if(InfoData.difficulty != '' && InfoData.teeboxtype != '' && InfoData.teeheight != '')
	{
		$("#btnJoinSave").attr('disabled',false);
	}
}

function goMembermodify() {

	//상세정보 체크
	if(sessionStorage.getItem('guid') === null||
		sessionStorage.getItem('access_token') === null||
		window.localStorage.getItem('join_zipcode') === null||
		window.localStorage.getItem('join_province') === null||
		window.localStorage.getItem('join_city') === null||
		window.localStorage.getItem('join_address1') === null||
		window.localStorage.getItem('join_address2') === null||
		window.localStorage.getItem('join_difficulty') === null||
		window.localStorage.getItem('join_teeboxtype') === null||
		window.localStorage.getItem('join_teeheight') === null
	)
	{
		common.alertpop(lan.L('회원가입'),lan.L('세션이 초기화되었습니다. 상세정보입력을 다시 확인하세요.'));
		is_submit = false;
		return false;
	}


	//라운드 변경
	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: {
			"difficulty": window.localStorage.getItem('join_difficulty'),
			"teeBoxType": window.localStorage.getItem('join_teeboxtype'),
			"teeHeight": window.localStorage.getItem('join_teeheight')
			},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('회원 게임 설정 정보 수정'),
			'guid' : sessionStorage.getItem('guid'),
			'token' : sessionStorage.getItem('access_token')
		}
	}).then(function (response) {
		var data = response.data;
		console.log(response);

		if(data.error !== undefined && data.error.code > 0)
		{
			common.alertByError('',data.error.code);
			is_submit = false;
			return false;      
		}
		//성공 시 2 실행
		else
		{
			goMembermodify2();
		}


	}).catch(error => {
		//console.log(error.response)
			common.alertpop(lan.L('회원가입'),lan.L('에러 발생'));
			is_submit = false;
			return false;
	});
}

//주소변경
function goMembermodify2() {
	var myinfoJson;

	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: {userGuid: sessionStorage.getItem('guid')},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('회원 정보 조회'),
			'guid' : sessionStorage.getItem('guid'),
			'token' : sessionStorage.getItem('access_token')
		}
	}).then(function (response) {
		console.log(response);
		myinfoJson = response.data;

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			common.alertByError('',response.data.error.code);
			is_submit = false;
			return false;
		}

		//저장
		myinfoJson.zipcode = window.localStorage.getItem('join_zipcode');
		myinfoJson.province = window.localStorage.getItem('join_province');
		myinfoJson.city = window.localStorage.getItem('join_city');
		myinfoJson.address1 = window.localStorage.getItem('join_address1');
		myinfoJson.address2 = window.localStorage.getItem('join_address2');
			
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: myinfoJson,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 정보 수정'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
			var data = response.data;
			console.log(response);

			if(data.error !== undefined && data.error.code > 0)
			{
				common.alertByError('',data.error.code);
				is_submit = false;
				return false;
			}
			else
			{
				window.localStorage.setItem('popupnavigate','/default/default');
				common.alertpop(lan.L('회원가입'),lan.L('저장되었습니다.'));
				is_submit = false;
			}

		}).catch(error => {
			is_submit = false;
			//console.log(error.response)
		});

	}).catch(error => {
		console.log(error.response)
		common.alertpop(lan.L('회원가입'),lan.L('error 발생'));
		is_submit = false;
		return false;
	});
}
