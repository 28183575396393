import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import * as common from '../common/script/common';
import $ from 'jquery';

export default function Agreement02(){

	const navigate = useNavigate();
	const location = useLocation();

	const gobackWithAgree = (e) => {
		window.localStorage.setItem('join_agree02',true);
		navigate(-1);
	}

	//화면 이동 시
	useEffect(() => {
		
	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: {},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('약관 조회'),
			'guid' : common.commonKeyDic[sessionStorage.getItem('Language')].term,
			'guid2' : 'UserPrivacyPolicy'
		}
	}).then(function (response) {
		var data = response.data;
		console.log(response);

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			common.alertByError('회원 이용약관',response.data.error.code);
			return false;
		}

		$(".terms").html(data.settingValue);

	}).catch(error => {
		//console.log(error.response)
	});
	},[location]);

	return (
	<>
	<div className="container">
		<div className="terms" style={{'margin-top': '-50px'}}>
		  {/*<dl>
			<dt>1. 총 칙</dt>
			<dd>① 개인정보란 생존하는 개인에 관한 정보로서 성명, 주민등록번호 등의 사항에 의하여 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다.</dd>
			<dd>② 포인트뱅크의 유무선 인터넷 서비스인 (주)케이골프(이하 (주)케이골프라 합니다)는 귀하의 개인정보보호를 매우 중요시하며, 『정보통신망이용촉진 및 정보보호에 관한 법률』상의 개인정보보호규정 및 정보통신부가 제정한 『개인정보보호지침』을 준수하고 있습니다. (주)케이골프는 개인정보보호정책을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.</dd>
			<dd>③ (주)케이골프는 개인정보보호정책을 홈페이지 첫 화면에 공개함으로써 귀하께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다.</dd>
			<dd>④ (주)케이골프는 개인정보보호정책의 지속적인 개선을 위하여 개인정보보호정책을 개정하는데 필요한 절차를 정하고 있습니다. 그리고 개인정보보호정책을 개정하는 경우 사이트 상에 공지하여 개정된 사항을 귀하께서 쉽게 알아볼 수 있도록 하고 있습니다.</dd>
		  </dl>
		  <dl>
			<dt>2. 개인정보의 수집범위</dt>
			<dd>(주)케이골프는 회원제로 운영되는 사이트로 (주)케이골프 회원제 서비스를 이용하시고자 할 경우 다음의 필수항목을 입력해 주셔야 합니다. 단, 선택항목은 입력하지 않으셔도 서비스 이용에 제한은 없습니다.</dd>
			<dd>
			  <span><b>가. 회원 가입 시 수집하는 개인정보의 범위</b>- 필수항목 : 이름, 전화번호 및 기타 회사가 필요하다고 인정하는 사항</span>
			  <span><b>나. 서비스 이용과정이나 사업 처리과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.</b>- 서비스 이용기록, 접속로그, 쿠키, 접속IP 정보, 결제기록</span>
			</dd>
		  </dl>
		  <dl>
			<dt>3. 개인정보의 수집에 대한 동의</dt>
			<dd>(주)케이골프는 회원의 개인 정보 제공, 수집에 대해 (주)케이골프 서비스 이용 신청 시 작성한 신청서에 의거하여 개인정보 수집에 대해 동의한 것으로 봅니다.</dd>
		  </dl>
		  <dl>
			<dt>4. 개인정보의 수집목적 및 이용목적</dt>
			<dd>① (주)케이골프는 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다.</dd>
			<dd>
			  <ol>
				<li>성명, 핸드폰번호 : 회원제 서비스 이용에 따른 본인 식별절차에 이용</li>
				<li>주소, 연락처, e-mail 주소 : 고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보, 새로운 서비스/신상품/이벤트 정보의 안내, 경품 등 물품 배송에 대한 정확한 배송지의 확보</li>
				<li>신용카드정보 : 유료정보 이용에 대한 요금 결제, 청구서</li>
				<li>그 외 선택항목 : 개인맞춤 서비스를 제공하기 위한 자료</li>
			  </ol>
			</dd>
			<dd>② 단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다.</dd>
		  </dl>
		  <dl>
			<dt>5. 쿠키에 의한 개인정보 수집 </dt>
			<dd>
			  ① 쿠키(cookie)란?<br />
			  (주)케이골프는 회원에 대한 정보를 저장하고 수시로 찾아내는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트가 회원님의 컴퓨터 브라우저로 전송하는 소량의 정보입니다. 회원이 웹사이트에 접속을 하면 (주)케이골프 사이트의 컴퓨터는 회원의 브라우저에 있는 쿠키의 내용을 읽고, 회원의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다. 쿠키는 회원의 컴퓨터는 식별하지만 회원을 개인적으로 식별하지는 않습니다. 또한 회원은 쿠키에 대한 선택권이 있습니다. 웹 브라우저 상단의 도구 &gt; 인터넷옵션 탭(option tab)에서 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.
			</dd>
			<dd>
			  ② (주)케이골프 사이트의 쿠키(cookie) 운용<br />
			  (주)케이골프는 이용자의 편의를 위하여 쿠키를 운영하며, 쿠키(cookie)를 통해 수집한 회원 정보는 다음의 목적을 위해 사용됩니다.
			</dd>
			<dd>
			  <ol>
				<li>개인의 관심 분야에 따라 차별화된 정보 제공</li>
				<li>회원과 비회원의 접속빈도 또는 이용시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟(target) 마케팅에 활용</li>
				<li>회원의 서비스 이용 습관을 분석하여 개인 맞춤 서비스를 제공 및 서비스 개편 척도로 활용</li>
			  </ol>
			</dd>
			<dd>③ 쿠키는 브라우저의 종료시나 로그아웃 시 자동 삭제됩니다.</dd>
		  </dl>
		  <dl>
			<dt>6. 개인정보의 열람/정정 </dt>
			<dd>회원은 언제든지 등록되어 있는 귀하의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 『개인정보변경』을 클릭하여 직접 열람 또는 정정하거나, 개인정보관리 책임자 및 담당자에게 서면, 전화 또는 e-mail로 연락하시면 지체 없이 조치하겠습니다.</dd>
		  </dl>
		  <dl>
			<dt>7. 개인정보의 수집, 이용, 제공에 대한 동의 철회(회원탈퇴)</dt> 
			<dd>① 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 회원께서 동의하신 내용은 언제든지 철회하실 수 있습니다.</dd>
			<dd>② 개인정보의 수집에 대한 동의철회(회원탈퇴)를 개인정보를 수집하는 방법보다 쉽게 할 수 있도록 필요한 조치를 취하겠습니다.</dd>
		  </dl>
		  <dl>
			<dt>8. 개인정보의 보유기간 및 이용기간 </dt>
			<dd>① 회원의 개인정보는 다음과 같이 개인정보의 수집 목적 또는 제공받은 목적이 종료되면 파기됩니다. 단, 상법 등 관련법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다.</dd>
			<dd>
			  <ol>
				<li>회원가입정보의 경우 회원탈퇴 또는 회원제명시 사전에 보유목적, 기간, 보유하는 개인정보 항목을 명시하고 동의를 구할 경우 보유 가능</li>
				<li>계약 또는 청약철회 등에 관한 기록 : 5년</li>
				<li>대금결제 및 재화 등의 공급에 관한 기록 : 5년</li>
				<li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
			  </ol>
			</dd>
			<dd>② 회원의 동의를 받아 보유하고 있는 거래정보 등을 귀하께서 열람을 요구하는 경우 지체 없이 그 열람/확인 할 수 있도록 조치합니다.</dd>
		  </dl>
		  <dl>
			<dt>9. 이용자의 권리와 의무 </dt>
			<dd>① 회원의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.</dd>
			<dd>② 회원께서는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다.귀하의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망이용촉진 및 정보보호 등에 관한 법률』등에 의해 처벌받을 수 있습니다.</dd>
		  </dl>
		  <dl>
			<dt>10. 개인정보관리 책임자 및 담당자 </dt>
			<dd>(주)케이골프는 회원의 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 회원께 고지한 사항들에 반하는 사고가 발생할 시에 개인정보관리책임자가 모든 책임을 집니다. 그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다. 귀하의 개인정보를 취급하는 책임자 및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다.</dd>
		  </dl>
		  <dl>
			<dt>11. 개인정보관리책임자 </dt>
			<dd>
			  <ol>
				<li>성 명 : 홍길동</li>
				<li>소속부서 : (주)케이골프 개발팀</li>
				<li>직 위 : 개발 이사</li>
				<li>전 화 : 1577-0564</li>
				<li>이메일 : help@kgolf.com</li>
				<li>팩 스 : 031-123-4567</li>
			  </ol>
			</dd>
		</dl>*/}
		</div>
	</div>{/* container(e) */}

	<div className="sticky_button">
	  <button type="button" className="btn_standard" onClick={gobackWithAgree}>{lan.L('확인')}</button>
	</div>
	</>

	)
}