import * as lan from '../common/script/common_lan.js';

import * as common from '../common/script/common.js';


import LinkButton from '../common/component/linkButton';
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function Completion() {

	const loginID = window.localStorage.getItem('join_loginID');
	const loginPass = window.localStorage.getItem('join_loginPass');
	const autoLogin = 'N';
	const navigate = useNavigate();

		console.log(loginID,loginPass);
	
	common.goLogin(null, null, loginID, loginPass, 'normal',  autoLogin);

	// 체크박스 단일 선택
	const goLoginWLogout = (e) => {

		common.goLogout();
		navigate('/login/login');
	}
	
	// 체크박스 단일 선택
	const goAddress = (e) => {
		window.localStorage.setItem('join_zipcode','');
		window.localStorage.setItem('join_province','');
		window.localStorage.setItem('join_city','');
		window.localStorage.setItem('join_address1','');
		window.localStorage.setItem('join_address2','');
		navigate('/join/address',{replace:true});
	}


	return (
	<div>
	  <div className="container">
		<div className="subscribe">
		  <em>{lan.L('스크린 골프의 한계를 넘다')}</em>
		  <span className="new-line">
			{lan.L('회원님의 상세정보를 추가로 등록 하시려면\n상세정보입력 버튼을 눌러주세요.\n로그인을 누르시면 회원가입이 완료되며\n로그인 화면으로 이동합니다.\n회원님의 상세정보는 로그인 후 회원정보수정을\n이용하여 등록하실 수 있습니다.')}
		  </span>
		</div>
	  </div>
	  <div className="sticky_button">
		<button type="button" className="btn_standard" onClick={goAddress}>{lan.L('상세정보입력')}</button>
		<div className="skip">
		  <span>{lan.L('회원가입을 완료 하시겠어요?')}</span>
		  <a onClick={goLoginWLogout}>{lan.L('로그인하기')}</a>
		</div>
	  </div>
	</div>
	)
}