import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common.js';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

import CommonGoogleMap from '../common/script/common_googlemap';

export default function DetailStoreLink(props) {
	const location = useLocation();
	const navigate = useNavigate();

	const [InfoJson, SetInfoJson] = useState({});
	const [googlemap, setgooglemap] = useState([]);
	
	var fulladdress = '';

	const params = new URLSearchParams(window.location.hash.replace("#/store/detail_store_link",""));

	let shopGuid = params.get("shopGuid");
	let language = params.get("lan");

	sessionStorage.setItem('Language', language);

	//화면전환시 실행
	useEffect(() => {
		
		$(".wrap").css("padding-top","0px");

		$('html, body').animate({scrollTop:0},0);

		var clientTypeLink = '';
		var vclientTypeLink = '';

		console.log(shopGuid, language);

		//매장 상세 조회
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':language,
				'pUrl' : encodeURIComponent('매장 상세 조회 공유'),
				'guid' : shopGuid
			}
		}).then(function (response) {
			console.log(response.data);

			setgooglemap(<CommonGoogleMap sendid="map" latitude={response.data.latitude} longitude={response.data.longitude}/>);
			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				common.alertByError(lan.L('매장찾기'),response.data.error.code);
				return false;
			}
			else
			{
				// $("#meta_og_title").attr("content", lan.L(response.data.shopName));
				// $("#meta_og_description").attr("content", lan.L(response.data.address1));
				
				fulladdress = response.data.province + " " + response.data.city + " " + response.data.address1 + " " + response.data.address2;
				SetInfoJson(Object.assign(response.data,
					{
					  screen : response.data.clientType.screen,
					  practice : response.data.clientType.practice,
					  lesson : response.data.clientType.lesson,
					  fulladdress : fulladdress,
					  googleaddress : response.data.province + " " + response.data.city + " " + response.data.address1,
					}
				));
			}
		}).catch(error => {
			console.log(error.response)
		});

		var wrap = $(".store_picture");
		var headeroffset = $(".detail_head").offset();

		$(window).scroll(function () {
			if ($(this).scrollTop() > headeroffset.top){
				$("#detailHeader").removeClass("turn");
				wrap.css("display","none");
			} else {
				$("#detailHeader").addClass("turn");
				wrap.css("display","block");
			}
		});
			
    },[location]);

	const goTel = () => {
		document.location.href = "tel:"+InfoJson.shopPhone;
	}
	
	const goPictureDetail = (option) => {
		if(option=='All')
		{
			navigate('/store/picture_view',{state:{shopViewImage : InfoJson.shopViewImage , CDN : InfoJson.CDN}});
		}
	}
	
	const goShareMap = () => {

			//"https://www.google.com/maps/dir/''/"+encodeURIComponent(InfoJson.googleaddress)
			if(common.isIOS()){
				common.iOSCallApp({appid:"openBrowser", params:"https://www.google.com/maps/search/"+encodeURIComponent(InfoJson.googleaddress)});
			}
			else
			{
				try{common.andriodCallApp('openBrowser',["https://www.google.com/maps/search/"+encodeURIComponent(InfoJson.googleaddress)]);}
				catch (ex){
					window.open("https://www.google.com/maps/search/"+encodeURIComponent(InfoJson.googleaddress));
				}
			}
        }

	const copyText = () => {
		const $textarea = document.createElement("textarea");

		// body 요소에 존재해야 복사가 진행됨
		document.body.appendChild($textarea);
		
		// 복사할 특정 텍스트를 임시의 textarea에 넣어주고 모두 셀렉션 상태
		$textarea.value = InfoJson.fulladdress;
		$textarea.select();
		
		// 복사 후 textarea 지우기
		document.execCommand('copy');
		document.body.removeChild($textarea);
		common.alertpop(lan.L('매장 위치'),lan.L('주소가 복사되었습니다.'));
	}

  return (
	<>
	<header id="detailHeader" className="convert turn">
		<em>{lan.L(InfoJson.shopName)}</em>
	</header>

	<div className="contents">
		
	{InfoJson.shopViewImage === undefined || InfoJson.shopViewImage.length == 0 ? 
	<div className="store_picture" style={{backgroundImage: "url("+require('../images/sub/store_picture_noimage02.jpg')+")"}}>{lan.L("매장대표사진")}</div>
	:
	<div className="store_picture" style={{backgroundImage: "url("+InfoJson.CDN + InfoJson.shopViewImage[0]+")"}}>{lan.L("매장대표사진")}</div>
	}
	  {/* noimage "/images/sub/store_picture_noimage02.jpg" 
		require( +"/images/sub/store_picture_noimage02.jpg"+ ): InfoJson.CDN + InfoJson.shopViewImage[0]*/}
	  <div className="store_detail">
		<div className="detail_head">
			{InfoJson.shopViewImage === undefined || InfoJson.shopViewImage.length == 0 ? 
		  	<a style={{backgroundImage: "url("+require('../images/sub/store_picture_noimage01.jpg')+")"}} >{lan.L("매장대표사진")}</a>
			:
			<a style={{backgroundImage: "url("+InfoJson.CDN + InfoJson.shopViewImage[0]+")"}} onClick={(e)=>goPictureDetail('All')}>{lan.L("매장대표사진")}</a>
			}
		  {/* noimage "/images/sub/store_picture_noimage01.jpg" */}
		  <div className="site_info">
			<div className="keyword">
			  <span style={InfoJson.screen ? {}:{display: 'none'}} className="screen" >{lan.L("스크린")}</span>
			  <span style={InfoJson.practice ? {}:{display: 'none'}} className="training">{lan.L("연습")}</span>
			  <span style={InfoJson.lesson ? {}:{display: 'none'}} className="lesson">{lan.L("레슨")}</span>
			</div>
			<em>{lan.L(InfoJson.shopName)}</em>
			<div className="distance">
			  <span>{lan.L(InfoJson.address1)}</span>
			</div>
			<div className="reserve" style={{"padding": "0 120px"}}>
			  <button type="button" onClick={(e)=>{goTel()}}>{lan.L("전화하기")}</button>
			  <button type="button" style={{"display": "none"}}>{lan.L("예약하기")}</button>
			</div>
		  </div>
		</div>
	  </div>
	  <ul className="section">
		<li>
		  <em>{lan.L("편의시설 및 서비스")}</em>
		  <div className="service">
			<i style={InfoJson.isSwingplate ? {}:{display: 'none'}} className="swing new-line">{lan.L("스윙\n플레이트")}</i>
			<i style={InfoJson.isLeftHanded ? {}:{display: 'none'}} className="seat new">{lan.L("좌타우타")}</i>
			<i style={InfoJson.isParking ? {}:{display: 'none'}} className="park new">{lan.L("주차가능")}</i>
			<i style={InfoJson.isEquipmentStorage ? {}:{display: 'none'}} className="storage new">{lan.L("장비보관")}</i>
			<i style={InfoJson.isProLesson ? {}:{display: 'none'}} className="prolesson new">{lan.L("프로레슨")}</i>
		  </div>
		</li>
		<li>
		  <em>{lan.L("운영시간")}</em>
		  <div className="explain">
			<p className="new-line">
				{InfoJson.operatingTime}<br /><br/>
			</p>
			<span>
				<img src={InfoJson.operatingTimeImage === undefined || InfoJson.operatingTimeImage.length == 0 ? "" : InfoJson.CDN + InfoJson.operatingTimeImage[0] } />
			</span>
		  </div>
		</li>
		<li>
		  <em>{lan.L("이용요금")}</em>
		  <div className="explain">
			<p>{InfoJson.pricing}</p>
		  </div>
		</li>
		<li>
		  <em>{lan.L("매장전경")}</em>
		  <div className="storeslider" onClick={(e)=>goPictureDetail('All')} >
			<ul className="slides">
				{InfoJson.shopViewImage === undefined || InfoJson.shopViewImage.length == 0 ? '' :
				InfoJson.shopViewImage.map((url, i) => (
					<li><img src={InfoJson.CDN + InfoJson.shopViewImage[i]} /></li>
				))}
			</ul>
			{/*
			<div className="control_paging">
			  <ol className="store-control-nav store-control-paging">
				{InfoJson.shopViewImage === undefined || InfoJson.shopViewImage.length == 0 ? '' :
					InfoJson.shopViewImage.map((url, i) => (
						<li><a className="store-active">{[i+1]}</a></li>
					))}
			  </ol>
			  <span>/{InfoJson.shopViewImage === undefined || InfoJson.shopViewImage.length == 0 ? '' : InfoJson.shopViewImage.length}</span>
			</div>*/}
		  </div>
		</li>
		<li>
		  <em>{lan.L("매장위치")}</em>
		  <div className="location_store">
			<span>
			  <b>{InfoJson.fulladdress}</b>
			</span>
			<span>{InfoJson.shopPhone}</span>
		  </div>
		  <div className="nav_store">
			<div className="shortcut eng">
			  <a className="copy" onClick={copyText}>{lan.L("주소복사")}</a>
			  <a className="way" onClick={goShareMap}>{lan.L("길찾기")}</a>
			</div>
			<div className="simplemap" id="map">
				{googlemap}
			</div>
		  </div>
		</li>
		<li>
		  <em>{lan.L("주차안내")}</em>
		  <div className="explain">
			<p className="new-line">{InfoJson.parkingInformation}<br /><br /></p>
			<span><img src={InfoJson.parkingInformationImage === undefined || InfoJson.parkingInformationImage.length == 0 ? "" : InfoJson.CDN + InfoJson.parkingInformationImage[0] } /></span>
		  </div>
		</li>
		<li>
		  <em>{lan.L("매장소개")}</em>
		  <div className="explain">
			<p className="new-line">{InfoJson.shopIntroduction}<br /><br />
			</p>
			<span><img src={InfoJson.shopIntroductionImage === undefined || InfoJson.shopIntroductionImage.length == 0 ? "" : InfoJson.CDN + InfoJson.shopIntroductionImage[0] } style={{width: '250px'}} /></span>
		  </div>
		</li>
		<ul>
		</ul></ul>
	</div>
	</>
  );
}