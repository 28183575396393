import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common'
import $ from 'jquery'
import axios from 'axios';

var bcplist;

export function slideOn(){
	$("html").addClass("overflowHidden");
	$("#country").addClass("on");

	for(let i=0;i<bcplist.length;i++)
	{
		if(bcplist[i].settingName == common.commonKeyDic[sessionStorage.getItem('Language')]['country'])
		{
			console.log($("#"+bcplist[i].settingValue).offset().top);
			document.getElementById(bcplist[i].settingValue).scrollIntoView(true);
		}
	}
}

export function slideExit(){
	$("html").removeClass("overflowHidden");
	$(".layerView").removeClass("on");
}

export default function BottomCountry(prop) {
	
	const [countryhtmllist, setcountryhtmllist] = useState([]);
	
	useEffect(() => {
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('국가 코드 목록 조회')
			}
		}).then(function (response) {
			console.log(response);
			bcplist = response.data.list;
			var bottomcountrypophtmllist = [];
			for(let i =0; i < bcplist.length; i++)
			{
				bottomcountrypophtmllist.push(
					<a key={i+"a"} >
					<input type="radio" key={i} data-name={bcplist[i].settingName} data-key={bcplist[i].settingValue} id={bcplist[i].settingValue} name="country"
					 defaultChecked={bcplist[i].settingName ==common.commonKeyDic[sessionStorage.getItem('Language')]['country'] ? true:false }/>
					<label htmlFor={bcplist[i].settingValue}>
					  <strong>{bcplist[i].settingName}</strong>
					</label>
				  </a>);
			}
			setcountryhtmllist(bottomcountrypophtmllist);
		}).catch(error => {
			console.log(error.response)
		});
    },[]);
	
	const goSelect = (e, returntype, returnid, returnvalueid) => {
		if(returntype == 'html')
		{
			$("#"+returnid).html($("input[name=country]:checked").data("name"));
			slideExit();
		}
		$("#"+returnvalueid).val($("input[name=country]:checked").data("key"));
	}

	return (
	<div id="country" className="layerView">
	  <div className="bottom_sheet">
		<a onClick={(e) => slideExit()}>close</a>
		<div className="bs_body">
		  <em>{lan.L('국가 선택')}</em>
		  <div className="scroll_container">
			<div className="list_country">
				{countryhtmllist}
			</div>
		  </div>
		</div>
		<div className="bottom_sheet_button">
		  <button type="button" className="btn_cancel" onClick={(e) => slideExit()}>{lan.L('취소')}</button>
		  <button type="button" className="btn_standard" onClick={(e) => goSelect(e, prop.returntype, prop.returnid, prop.returnvalueid)}>{lan.L("확인")}</button>
		</div>
	  </div>
	</div>
	)
}