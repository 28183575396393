import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as common from '../common/script/common.js';

export default function PictueView(){
	
	const location = useLocation();

	console.log(location.state);

	return (
		<div class="picture_view">
		{location.state.shopViewImage === undefined || location.state.shopViewImage == 0 ? '' :
		location.state.shopViewImage.map((url, i) => (
			<li><img src={location.state.CDN + location.state.shopViewImage[i]} /></li>
		))}
		</div>
	)
}

