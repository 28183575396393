
import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import * as common from '../common/script/common';
import $ from 'jquery';


export default function Agreement01Kor(){

	const navigate = useNavigate();
	const location = useLocation();

	const gobackWithAgree = (e) => {
		window.localStorage.setItem('join_agree01',true);
		navigate(-1);
	}
	
	//화면 이동 시
	useEffect(() => {
		
	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: {},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':'ko',
			'pUrl' : encodeURIComponent('약관 조회'),
			'guid' : common.commonKeyDic['ko'].term,
			'guid2' : 'UserTerms'
		}
	}).then(function (response) {
		var data = response.data;
		console.log(response);

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			common.alertByError('회원 이용약관',response.data.error.code);
			return false;
		}

		$(".terms").html(data.settingValue);

	}).catch(error => {
		//console.log(error.response)
	});
	},[location]);

	return (
	<>
	<header>
		<em>회원 이용약관</em>
	</header>
	<div className="container">
	<div className="terms" style={{'margin-top': '-50px'}}>
		{/*<em>제 1장 총칙</em>
		<dl>
			<dt>제 1조 (목적)</dt>
			<dd>이 서비스 약관은 회원이 (주)케이골프(이하 "회사")에서 제공하는 유무선 인터넷 서비스를 이용함에 따른 회원과 회사간의 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</dd>
		</dl>
		<dl>
			<dt>제 2조 (약관의 효력 및 변경)</dt>
			<dd>① 이 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다.</dd>
			<dd>② 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공시하고, 이에 동의한 회원이 서비스에 가입함으로써 효력이 발생합니다.</dd>
			<dd>③ 회사는 필요하다고 인정되는 경우 이 약관을 변경할 수 있으며, 약관이 변경된 경우에는 지체 없이 제2항과 같은 방법으로 공시합니다. 다만, 이용자의 권리 또는 의무에 관한 중요한 규정의 변경은 최소한 7일전에 공시합니다.</dd>
			<dd>④ 이용자는 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.</dd>
		</dl>
		<dl>
			<dt>제 3조 (약관 외 준칙)</dt>
			<dd>회사는 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진등에관한법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</dd>
		</dl>

		<em>제 2장 서비스 이용 계약</em>
		<dl>
			<dt>제 4조 (이용 계약)</dt>
			<dd>1. 가입신청자가 본 약관 제4조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우. 다만 제4조 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 합니다.</dd>
			<dd>2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</dd>
			<dd>3. 기타 회원으로 등록하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우</dd>
			<dd>4. 회사는 서비스 이용계약체결 신청이 다음 각 호에 해당하는 경우에는 그 신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.</dd>
			<dd>4-1. 회사의 설비 여유가 없는 경우</dd>
			<dd>4-2. 회사의 기술상 일시적 지장이 있는 경우</dd>
			<dd>4-3. 기타 회사의 귀책사유로 이용승낙이 곤란한 경우</dd>
			<dd>5. 회사는 이용계약체결 신청고객이 관계법령에서 규정하는 미성년자일 경우에 서비스 별 안내에서 정하는 바에 따라 승낙을 보류할 수 있습니다.</dd>
			<dd>6. 유무선 회원가입 및 이용계약 체결의 성립시기는 회사의 승낙이 가입신청자에게 도달한 시점으로 하며, 기타 이용계약의 성립 시기는 기 프로그램이 서비스 된 시점을 근거로 합니다.</dd>
			<dd>7. 회원은 제1항의 회원정보 기재 내용에 변경이 발생한 경우, 즉시 변경사항을 정정하여 기재하여야 합니다.</dd>
		</dl>
		<dl>
			<dt>제 5조 (이용 계약의 성립)</dt>
			<dd>① "위 약관에 동의하며 회원가입을 신청합니다." 라는 이용 신청시의 물음에 고객이 "동의" 체크 박스에 체크하면 약관에 동의하는 것으로 간주됩니다.</dd>
			<dd>② 이용 계약은 고객의 이용 신청에 대하여 회사가 승낙함으로써 성립합니다.</dd>
		</dl>
		<dl>
			<dt>제 6조 (이용 신청)</dt>
			<dd>이용 신청은 서비스의 이용자 등록 화면에서 고객이 다음 사항을 가입신청 양식에 기록하는 방식으로 행합니다.</dd>
			<dd>
				<ol>
					<li>성명</li>
					<li>핸드폰 번호</li>
					<li>기타 회사가 필요하다고 인정하는 사항</li>
				</ol>
			</dd>
		</dl>
		<dl>
			<dt>제 7조 (이용신청의 승낙)</dt>
			<dd>회사는 제6조에서 정한 사항을 정확히 기재하여 이용 신청한 고객에 대하여 서비스 이용 신청을 승낙합니다.</dd>
		</dl>
		<dl>
			<dt>제 8조 (계약 사항의 변경)</dt>
			<dd>회원은 이용 신청 시 기재한 사항이 변경되었을 경우 회사가 정한 별도의 이용 방법으로 정해진 양식 및 방법에 의하여 수정하여야 합니다.</dd>
		</dl>

		<em>제 3장 서비스 이용</em>
		<dl>
			<dt>제 9조 (서비스의 이용 개시)</dt>
			<dd>① 회사는 회원의 이용 신청을 승낙한 때부터 서비스를 개시합니다. 단, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시합니다.</dd>
			<dd>② 회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 사이트에 공시하거나 회원에게 이를 통지합니다.</dd>
		</dl>
		<dl>
			<dt>제 10조 (서비스의 이용시간)</dt>
			<dd>① 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의 업무상이나 기술상의 이유로 서비스가 일지 중지될 수 있고, 또한 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 이러한 경우 회사는 사전 또는 사후에 이를 공지합니다.</dd>
			<dd>② 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을 공지합니다.</dd>
		</dl>
		<dl>
			<dt>제 11조 (서비스의 변경 및 중지)</dt>
			<dd>① 회사는 변경될 서비스의 내용 및 제공일자를 제19조 제2항에서 정한 방법으로 회원에게 통지하고 서비스를 변경하여 제공할 수 있습니다.</dd>
			<dd>② 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.</dd>
			<dd>
				<ol>
					<li>서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
					<li>회원이 회사의 영업활동을 방해하는 경우</li>
					<li>정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</li>
					<li>서비스 제공업자와의 계약 종료 등과 같은 회사의 제반 사정으로 서비스를 유지할 수 없는 경우</li>
					<li>기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</li>
				</ol>
			</dd>
			<dd>③ 제2항에 의한 서비스 중단의 경우에는 회사가 제21조 제2항에서 정한 방법으로 이용자에게 통지합니다. 다만, 회사가 통제할 수 없는 사유로 인한 서비스의 중단(운영자의 고의, 과실이 없는 디스크 장애, 시스템 다운 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다.</dd>
			<dd>④ 회사는 서비스의 변경, 중지로 발생하는 문제에 대해서는 어떠한 책임도 지지 않습니다.</dd>
		</dl>
		<dl>
			<dt>제 12조 (정보의 제공 및 광고의 게재)</dt>
			<dd>① 회사는 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 e-mail 및 서신우편 등의 방법으로 회원에게 제공할 수 있습니다.</dd>
			<dd>② 회사는 서비스의 운영과 관련하여 홈페이지, 서비스 화면, SMS, e-mail등에 광고 등을 게재할 수 있습니다.</dd>
			<dd>③ 회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주 간의 문제입니다. 만약 회원과 광고주간에 문제가 발생할 경우에도 회원과 광고주가 직접 해결하여야 하며, 이와 관련하여 회사는 어떠한 책임도 지지 않습니다.</dd>
		</dl>
		<dl>
			<dt>제 13조 (게시물 또는 내용물의 삭제)</dt>
			<dd>① 회사는 회원이 게시하거나 전달하는 서비스 내의 모든 내용물(회원간 전달 포함)이 다음 각 호의 경우에 해당한다고 판단되는 경우 사전통지 없이 삭제할 수 있으며, 이에 대해 회사는 어떠한 책임도 지지 않습니다.</dd>
			<dd>
				<ol>
					<li>회사, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우</li>
					<li>공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등의 유포에 해당하는 경우</li>
					<li>범죄적 행위에 결부된다고 인정되는 내용인 경우</li>
					<li>회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
					<li>제2항 소정의 세부이용지침을 통하여 회사에서 규정한 게시기간을 초과한 경우</li>
					<li>회사에서 제공하는 서비스와 관련 없는 내용인 경우</li>
					<li>불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우</li>
					<li>기타 관계 법령 및 회사의 지침 등에 위반된다고 판단되는 경우</li>
				</ol>
			</dd>
			<dd>② 회사는 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침에 따라 각 종 게시물(회원간 전달 포함)을 등록하거나 삭제하여야 합니다.</dd>
		</dl>
		<dl>
			<dt>제 14조 (게시물의 저작권)</dt>
			<dd>① 회원이 서비스 내에 게시한 게시물(회원간 전달 포함)의 저작권은 회원이 소유하며 회사는 서비스 내에 이를 게시할 수 있는 권리를 갖습니다.</dd>
			<dd>② 회사는 게시한 회원의 동의 없이 게시물을 다른 목적으로 사용할 수 없습니다.</dd>
			<dd>③ 회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권,프로그램 저작권 등을 침해하더라도 이에 대한 민,형사상의 책임을 부담하지 않습니다. 만일 회원이 타인의 저작권, 프로그램저작권 등을 침해하였음을 이유로 회사가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 회사의 면책을 위하여 노력하여야 하며, 회사가 면책되지 못한 경우 회원은 그로 인해 회사에 발생한 모든 손해를 부담하여야 합니다.</dd>
			<dd>④ 회사는 회원이 해지하거나 적법한 사유로 해지 된 경우 해당 회원이 게시하였던 게시물을 삭제할 수 있습니다.</dd>
			<dd>⑤ 회사가 작성한 저작물에 대한 저작권은 회사에 귀속합니다.</dd>
			<dd>⑥ 회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 영리목적으로 이용하거나 제3자에게 이용하게 할 수 없으며, 게시물에 대한 저작권 침해는 관계 법령의 적용을 받습니다.</dd>
		</dl>

		<em>제 4장 계약당사자의 의무</em>
		<dl>
			<dt>제 15조 (회사의 의무)</dt>
			<dd>① 회사는 서비스 제공과 관련하여 알고 있는 회원의 신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 정보통신윤리위원회의 요청이 있는 경우 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 않습니다.</dd>
			<dd>② 제1항의 범위 내에서, 회사는 업무와 관련하여 회원의 사전 동의 없이 회원 전체 또는 일부의 개인정보에 관한 통계자료를 작성하여 이를 사용할 수 있고, 이를 위하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있으며, 쿠키의 설정 변경에 의해 서비스 이용이 변경되는 것은 회원의 책임입니다.</dd>
			<dd>③ 회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 서비스 화면에 게재하거나 e-mail 등 기타 통신방법을 통하여 동 회원에게 통지합니다.</dd>
			<dd>④ 회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 회사의 고의나 중과실에 기해 발생한 경우에 한하여 회사에서 책임을 부담하며, 그 책임의 범위는 통상손해에 한합니다.</dd>
			<dd>⑤ 회사는 정보통신망 이용촉진 및 정보보호에 관한 법률, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.</dd>
		</dl>
		<dl>
			<dt>제 16조 (회원의 의무)</dt>
			<dd>① 회원은 서비스를 이용할 때 다음 각 호의 행위를 하여서는 아니 됩니다.</dd>
			<dd>
				<ol>
					<li>이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 회원의 계정을 도용, 부정하게 사용하는 행위</li>
					<li>회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위</li>
					<li>타인의 명예를 손상시키거나 불이익을 주는 행위</li>
					<li>게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위</li>
					<li>회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위</li>
					<li>공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위</li>
					<li>서비스와 관련된 설비의 오 동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위</li>
					<li>서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는 정보 및 수신자의 명시적인 수신거부의사에 반하여 광고성 정보를 전송하는 행위</li>
					<li>타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위</li>
					<li>다른 회원의 개인정보를 수집, 저장, 공개하는 행위</li>
					<li>자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 정보를 유통시키는 행위</li>
					<li>재물을 걸고 도박하거나 사행행위를 하는 행위</li>
					<li>윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위</li>
					<li>수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는 행위</li>
					<li>서비스에 게시된 정보를 변경하는 행위</li>
					<li>관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 포함)의 전송 또는 게시 행위</li>
					<li>회사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위</li>
					<li>컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 e-mail로 발송하는 행위</li>
					<li>스토킹(stalking) 등 다른 회원을 괴롭히는 행위</li>
					<li>기타 불법적이거나 부당한 행위</li>
				</ol>
			</dd>
			<dd>① 회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.</dd>
			<dd>② 회원은 회사에서 공식적으로 인정한 경우를 제외하고는 서비스를 이용하여 상품을 판매하는 영업 활동을 할 수 없으며 특히 해킹, 광고를 통한 수익, 음란사이트를 통한 상업행위, 상용소프트웨어 불법배포 등을 할 수 없습니다. 이를 위반하여 발생한 영업 활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 회사가 책임을 지지 않으며, 회원은 이와 같은 행위와 관련하여 회사에 대하여 손해배상 의무를 집니다.</dd>
			<dd>③ 회원은 서비스 이용을 위해 등록할 경우 현재의 사실과 일치하는 완전한 정보(이하 "등록정보")를 제공하여야 합니다.</dd>
			<dd>④ 회원은 등록정보에 변경사항이 발생할 경우 즉시 갱신하여야 합니다. 회원이 제공한 등록정보 및 갱신한 등록정보가 부정확할 경우, 기타 회원이 본 조 제1항에 명시된 행위를 한 경우에 회사는 본 서비스 약관 제24조에 의해 회원의 서비스 이용을 제한 또는 중지 할 수 있습니다.</dd>
		</dl>
		<dl>
			<dt>제 17조 (회원 ID(고유번호)와 Password(비밀번호) 관리에 대한 의무와 책임)</dt>
			<dd>① 회사는 사이트 내에서 일부 서비스 신청 시 이용요금을 부과할 수 있으므로, 회원은 회원 ID(고유번호) 및Password(비밀번호) 관리를 철저히 하여야 합니다.</dd>
			<dd>② 회원 ID(고유번호)와 Password(비밀번호)의 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있으며, 회사의 시스템 고장 등 회사의 책임 있는 사유로 발생하는 문제에 대해서는 회사가 책임을 집니다.</dd>
			<dd>③ 회원은 본인의 ID(고유번호) 및 Password(비밀번호)를 제3자에게 이용하게 해서는 안되며, 회원 본인의 ID(고유번호) 및 Password(비밀번호)를 도난 당하거나 제3자가 사용하고 있음을 인지하는 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우 그에 따라야 합니다.</dd>
			<dd>④ 회원의 ID(고유번호)는 회사의 사전 동의 없이 변경할 수 없습니다.</dd>
		</dl>
		<dl>
			<dt>제 18조 (회원에 대한 통지)</dt>
			<dd>① 회원에 대한 통지를 하는 경우 회사는 회사가 발급한 e-mail 주소 또는 회원이 등록한 e-mail 주소 또는 SMS 등으로 할 수 있습니다.</dd>
			<dd>② 회사는 불특정 다수 회원에 대한 통지의 경우 서비스 게시판 등에 게시함으로써 개별 통지에 갈음할 수 있습니다.</dd>
		</dl>
		<dl>
			<dt>제 19조 (이용자의 개인정보보호)</dt>
			<dd>회사는 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관해서는 관련법령 및 회사가 정하는 "개인정보보호정책"에 정한 바에 의합니다.</dd>
		</dl>
		<dl>
			<dt>제 20조 (개인정보의 위탁)</dt>
			<dd>회사는 수집된 개인정보의 취급 및 관리 등의 업무(이하 "업무")를 스스로 수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를 회사가 선정한 회사에 위탁할 수 있습니다.</dd>
		</dl>

		<em>제 5장 계약당사자의 의무</em>
		<dl>
			<dt>제 21조 (계약해지 및 이용제한)</dt>
			<dd>① 회원이 서비스 이용계약을 해지하고자 할 경우에는 본인이 사이트 상에서 또는 회사가 정한 별도의 이용방법으로 회사에 해지신청을 하여야 합니다.</dd>
			<dd>② 회사는 회원이 제19조에 규정한 회원의 의무를 이행하지 않을 경우 사전 통지 없이 즉시 이용계약을 해지하거나 또는 서비스 이용을 중지할 수 있습니다.</dd>
			<dd>③ 회사는 회원이 이용계약을 체결하여 ID(고유번호)와 Password(비밀번호)를 부여 받은 후에라도 회원의 자격에 따른 서비스 이용을 제한할 수 있습니다.</dd>
			<dd>④ 회사는 회원 가입 후 6개월 동안 서비스 사용 이력이 없는 회원에 대해 사용의사를 묻는 고지를 하고, 회사가 정한 기한 내에 답변이 없는 경우 이용 계약을 해지할 수 있습니다.</dd>
			<dd>⑤ 본 조 제2항 및 제3항의 회사 조치에 대하여 회원은 회사가 정한 절차에 따라 이의신청을 할 수 있습니다.</dd>
			<dd>⑥ 본 조 제5항의 이의가 정당하다고 회사가 인정하는 경우, 회사는 즉시 서비스의 이용을 재개합니다.</dd>
		</dl>
		<dl>
			<dt>제 22조 (양도 금지)</dt>
			<dd>회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며 게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다.</dd>
		</dl>

		<em>제 6장 손해배상 등</em>
		<dl>
			<dt>제 23조 (손해 배상)</dt>
			<dd>① 회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 회사에 발생하는 모든 손해를 배상하여야 합니다.</dd>
			<dd>② 회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책 시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.</dd>
		</dl>
		<dl>
			<dt>제 24조 (면책사항)</dt>
			<dd>① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</dd>
			<dd>② 회사는 회원의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다.</dd>
			<dd>③ 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다. 회사는 회원이 사이트에 게재한 정보/자료/사실의 신뢰도 및 정확성 등 내용에 대하여는 책임을 지지 않습니다.</dd>
			<dd>④ 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.</dd>
		</dl>
		<dl>
			<dt>제 25조 (관할법원)</dt>
			<dd>① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다.</dd>
			<dd>② 본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.</dd>
		</dl>
		<dl>
			<dt>부 칙</dt>
			<dd>(시행일) 이 약관은 2015년 12월 21일부터 시행합니다.</dd>
	</dl>*/}
	</div>
	</div>{/* container(e) */}
	</>
	)
}