import React from 'react';
import * as lan from "../common/script/common_lan";

const CourseRoundsInformation = ({InfoData}) => {
    return (
        <li>
            <em>{lan.L('코스정보')}</em>
            <ol className="course">
                {InfoData.courseRounds === undefined || InfoData.courseRounds.length === 0 ? '' :
                InfoData.courseRounds.map((url, i) => (
                    <>
                    <li key={i}>
                        <strong>{InfoData.isNewRankingSystem && `${lan.L('코스')}${i + 1}: `}{InfoData.courseRounds[i].courseName}</strong>
                        <article>
                            <div className="level">
                                <span>
                                    <b>{lan.L('코스 난이도')}</b>
                                    <i className={"mark" + InfoData.courseRounds[i].courseDifficulty*10}>{lan.L('별점')}</i>
                                </span>
                                <span>
                                    <b>{lan.L('그린 난이도')}</b>
                                    <i className={"mark" + InfoData.courseRounds[i].greenDifficulty*10}>{lan.L('별점')}</i>
                                </span>
                            </div>
                            {i === 0 &&
                            <div className="hole">
                                <span>
                                    <b>{lan.L('롱기스트홀')}</b>
                                    <sup><sub>Hole</sub>&nbsp;{InfoData.courseRounds[i].longestHoleNumber}</sup>
                                </span>
                                <span>
                                    <b>{lan.L('니어핀홀')}</b>
                                    <sup><sub>Hole</sub>&nbsp;{InfoData.courseRounds[i].nearpinHoleNumber}</sup>
                                </span>
                            </div>
                            }
                        </article>
                    </li>
                    </>
                ))}
            </ol>
        </li>
    );
};

export default CourseRoundsInformation;