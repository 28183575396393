import React, {useState, Component} from 'react';
import { useNavigate } from "react-router-dom";

function Tab(props) {
	const tablist = [];
	const [activeNum, setactiveNum] = useState(props.defaultTopics);
	const parser = new DOMParser();

	const TabChange = (e,key) => {
		e.preventDefault();
		setactiveNum(key);
		sessionStorage.setItem('tabNum', key);
	}

	for(let i=0;i<props.topics.length;i++){
		let t = props.topics[i];

		tablist.push(<button key={i} type="button" className={activeNum==i ? 'active':''} onClick={(e)=>{TabChange(e,i)}}>{t.tabName}</button>);
	}

  return (
	<div className="tab_button">
		{tablist}
	</div>
  );
}

export default Tab 
