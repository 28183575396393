import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';

import BottomCountry from '../popup/bottom_country';
import * as slide from '../popup/bottom_country';

const maxbirthnum = 8;

export default function Addition(){
	
	const location = useLocation();
	const navigate = useNavigate();
	const [InfoData, SetInfoData] = useState({});

	//화면전환시 실행
	useEffect(() => {
		if(window.localStorage.getItem('join_gender') === null) window.localStorage.setItem('join_gender','');
		if(window.localStorage.getItem('join_birthday') === null) window.localStorage.setItem('join_birthday','');
		if(window.localStorage.getItem('join_countryValue') === null) window.localStorage.setItem('join_countryValue','');
		if(window.localStorage.getItem('join_country') === null) window.localStorage.setItem('join_country','');
		if(window.localStorage.getItem('join_birthday') != '') {}

		var nowlen = sessionStorage.getItem('Language');

		SetInfoData(
			{
			  gender : '0',
			  birthday : '',
			  countryValue : common.commonKeyDic[nowlen]['countryValue'],
			  country : common.commonKeyDic[nowlen]['country']
			}
		);
		checkAllPossible('');	
    },[location]);

	const onChange = (e) => {
		  var id = e.target.id;
		  var value = e.target.value;
		  var data = InfoData;

		if(id == "newbirthday")
		{
			data.birthday = value;
			SetInfoData(data);
		}
		
		checkAllPossible(InfoData.birthday);
	}
	
	const checkNumber = (e) => {
		var lastkey = e.target.value.slice(e.target.value.length-1, e.target.value.length);
		if(lastkey >= 0 && lastkey <= 9){	}
		else
		{
			e.target.value = e.target.value.slice(0, e.target.value.length-1);
		}

		if (e.target.value.length > e.target.maxLength)
		{
			e.target.value = e.target.value.slice(0, e.target.maxLength);
		}
	}
	  
	// 버튼
	const goPhonenumber = (e) => {
		InfoData.gender = $("input[name=gender]:checked").val();
		InfoData.country = $("#newCountry").html();
		InfoData.countryValue = $("#newCountryValue").val();

		window.localStorage.setItem('join_gender',InfoData.gender);
		window.localStorage.setItem('join_birthday',common.getStringToDate(InfoData.birthday,'-'));
		window.localStorage.setItem('join_countryValue',InfoData.countryValue);
		window.localStorage.setItem('join_country',InfoData.country);

		//console.log(InfoData);
		navigate('/join/phonenumber',{replace:true});
	}

	return (
	<>
	<BottomCountry returntype="html" returnid="newCountry" returnvalueid="newCountryValue"></BottomCountry>
	<div>
	  <div className="container">
		<div className="process">
		  <span><b>4</b>/5</span>
		  <em>{lan.L('추가정보입력')}</em>
		</div>
		<ul className="write_form">
		  <li>
			<span>
			  <div className="selection_tab">
				<a>
				  <input type="radio" id="male" name="gender" value="0" defaultChecked />
				  <label htmlFor="male">{lan.L('남성')}</label>
				</a>
				<a>
				  <input type="radio" id="female" name="gender" value="1" />
				  <label htmlFor="female">{lan.L('여성')}</label>
				</a>
			  </div>
			  <em>{lan.L('성별')}</em>
			</span>
		  </li>
		  <li>
			<span>
			  <input type="text" maxLength={maxbirthnum} id="newbirthday"  placeholder="ex) 19800101" onInput={(e) => checkNumber(e)}
			  onChange={onChange}/>
			  <em>{lan.L('생년월일')}</em>
			</span>
		  </li>
		  <li>
			<span>
			  <input type="hidden" id="newCountryValue" defaultValue={InfoData.countryValue === undefined ? '' : InfoData.countryValue}/>
			  <label onClick={(e) => slide.slideOn()} id="newCountry">{InfoData.country === undefined ? '' : InfoData.country}</label>
			  <em>{lan.L('국가')}</em>
			</span>
		  </li>
		</ul>
	  </div>
	  <div className="sticky_button">
		<button type="button" className="btn_standard" id="btnPhonenumber" onClick={goPhonenumber}>{lan.L('다음')}</button>
	  </div>
	</div>
	</>
	)
}

function checkAllPossible(birthday){
	if(birthday.length != maxbirthnum)
	{
		$("#btnPhonenumber").attr('disabled',true);
		return false;
	}

	if(birthday.slice(0, 4) > new Date().getFullYear() || birthday.slice(4, 6) > 12 || birthday.slice(6, 8) > 31)
	{
		$("#btnPhonenumber").attr('disabled',true);
		return false;
	}

	$("#btnPhonenumber").attr('disabled',false);
}
