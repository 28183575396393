import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as common from '../common/script/common';
import $ from 'jquery';
import axios from 'axios';

import { useDispatch, useSelector } from "react-redux";

export function slideMatch(){
	$("html").addClass("overflowHidden");
	$("#popmymatch").addClass("on");
}

export function slideExit(){
	$("html").removeClass("overflowHidden");
	$(".layerView").removeClass("on");
}

export default function BottomGame(prop){
    
	const selector = useSelector((state) => state);
	const dispatch = useDispatch();

	const [matchhtmllist, setmatchhtmllist] = useState([]);

	useEffect(() => {
        
		var htmllist = [];
		//대회 선택
		for(let i =0; i < Object.keys(common.commonKeyDic['match']).length; i++)
		{
			htmllist.push(
				<a key={i+"b"} >
				  <input type="radio" value={Object.keys(common.commonKeyDic['match'])[i]} id={"mymatch"+prop.type+Object.keys(common.commonKeyDic['match'])[i]}
					data-key={Object.keys(common.commonKeyDic['match'])[i]} name={"mybottommatch"+prop.type}
					onClick={(e) => goSelect(e, Object.keys(common.commonKeyDic['match'])[i])} defaultChecked={Object.keys(common.commonKeyDic['match'])[i] == Object.keys(common.commonKeyDic['match'])[prop.defaultvalue] ? true : false}/>
				  <label htmlFor={"mymatch"+prop.type+Object.keys(common.commonKeyDic['match'])[i]}>{lan.L(Object.values(common.commonKeyDic['match'])[i])}</label>
				</a>
			);
		}
		if(matchhtmllist.length == 0)
		{
			setmatchhtmllist(htmllist);
		}
    },[]);
    
	const goSelect = (e, value) => {
        dispatch({type:'mytournamentTypeCode', value: value});
        slideExit();
	}
    
	return (
        <div className="layerView" id={"popmymatch"}>
            <div className="bottom_sheet">
                <a onClick={slideExit}>close</a>
                <div className="bs_body">
                <em>{lan.L('대회 선택')}</em>
                <div className="scroll_container">
                    <div className="list_selection">
                    {matchhtmllist}
                    </div>
                </div>
                </div>
            </div>
        </div>
	)
}