import React from 'react';
import * as lan from "../common/script/common_lan";
import * as common from "../common/script/common";

const RoundSettingInformation = ({InfoData, groupedByGradeType}) => {
    return (
        <li>
            <em>{lan.L('라운드 설정 정보')}</em>
            <ul className="round_setup">
                <li>
                    <dl>
                        <dd>{lan.L('참가대상')}</dd>
                        <dt>{InfoData.isParticipationConditionsLimit === undefined || InfoData.isParticipationConditionsLimit === false ? lan.L('제한없음') : lan.L('제한')}</dt>
                    </dl>
                </li>
                <li>
                    <dl>
                        <dd>{lan.L('사용자 난이도')}</dd>
                        <dt>{InfoData.difficulty === undefined ? '' : lan.L(common.commonKeyDic['difficulty'][InfoData.difficulty])}</dt>
                    </dl>
                </li>
                <li>
                    <dl>
                        <dd>{lan.L('스윙 플레이트')}</dd>
                        <dt>{InfoData.swingPlate === undefined ? '' : lan.L(common.commonKeyDic['swingPlate'][InfoData.swingPlate])}</dt>
                    </dl>
                </li>
                <li>
                    <dl>
                        <dd>{lan.L('멀리건 횟수')}</dd>
                        <dt>{InfoData.mulliganCount === undefined ? '' : InfoData.mulliganCount + lan.L('회')}</dt>
                    </dl>
                </li>
                <li>
                    <dl>
                        <dd>{lan.L('바람세기')}</dd>
                        <dt>{InfoData.windSpeed === undefined ? '' : lan.L(common.commonKeyDic['windSpeed'][InfoData.windSpeed])}</dt>
                    </dl>
                </li>
                <li>
                    <dl>
                        <dd>{lan.L('홀컵 위치')}</dd>
                        <dt>{InfoData.holecupLocation === undefined ? '' : lan.L(common.commonKeyDic['holecupLocation'][InfoData.holecupLocation])}</dt>
                    </dl>
                </li>
                <li>
                    <dl>
                        <dd>{lan.L('그린 위치')}</dd>
                        <dt>{InfoData.greenLocation === undefined ? '' : lan.L(common.commonKeyDic['greenLocation'][InfoData.greenLocation])}</dt>
                    </dl>
                </li>
                <li>
                    <dl>
                        <dd>{lan.L('컨시드')}</dd>
                        <dt>{InfoData.concede === undefined ? '' : InfoData.concede + lan.L('m')}</dt>
                    </dl>
                </li>
                <li>
                    <dl>
                        <dd>{lan.L('그린 빠르기')}</dd>
                        <dt>{InfoData.greenSpeed === undefined ? '' : lan.L(common.commonKeyDic['greenSpeed'][InfoData.greenSpeed + 2])}</dt>
                    </dl>
                </li>
                <li>
                    <dl>
                        <dd>{lan.L('퍼팅라인횟수')}</dd>
                        <dt>{InfoData.puttingLineCount === undefined || InfoData.puttingLineCount === -1 ? lan.L('제한없음') : InfoData.puttingLineCount + lan.L('회')}</dt>
                    </dl>
                </li>
                <li className="whole">
                    <dl>
                        <dd>{lan.L('티위치')}</dd>
                        <dt>
                            {InfoData.participationTargets === undefined || InfoData.participationTargets.length === 0 ? '' :
                                InfoData.participationTargets.map((url, i) => (
                                    <>
                                        {i === 0 ? '' : ' / '}
                                        {InfoData.participationTargets[i].teeBoxType === -1 ? lan.L('제한없음') : lan.L(common.commonKeyDic['gender'][InfoData.participationTargets[i].gender])}:<b>{common.commonKeyDic['teeBoxType'][InfoData.participationTargets[i].teeBoxType]}</b>
                                    </>
                                ))}
                        </dt>
                    </dl>
                </li>
                <li className="whole">
                    <dl>
                        <dd>{lan.L('참가대상별 보정')}</dd>
                        <dt>
                            {InfoData.participationTargets === undefined || InfoData.participationTargets.length === 0 ? '' :
                                InfoData.participationTargets.map((url, i) => (
                                    <>
                                        {i === 0 ? '' : ' / '}
                                        {InfoData.participationTargets[i].teeBoxType === -1 ? lan.L('제한없음') : lan.L(common.commonKeyDic['gender'][InfoData.participationTargets[i].gender])}:<b>{InfoData.participationTargets[i].handicap}</b>
                                    </>
                                ))}
                        </dt>
                    </dl>
                </li>
                {InfoData.participationGrades === undefined || InfoData.participationGrades.length === 0 ? '' :
                <li className="whole participation">
                    <strong>{lan.L('참가대상 및 보정정보')}</strong>
                        <div className='grade_information'>
                            <div className='row row_head'>
                                <div>{lan.L('등급')}</div>
                                <div>{lan.L('보정치')}</div>
                            </div>
                            {groupedByGradeType.map((group, i) => (
                                <div className='row' key={i}>
                                    <div
                                        className="attribute title-column">{lan.L(common.commonKeyDic['participationGrade'][group.gradeType])}</div>
                                    <div className="attribute icon-column">
                                        {group.data.map((node) =>
                                            <span><img
                                                src={`${require(`/src/images/grade/ui_icon_grade_${group.gradeType}_${node.stepType}.png`)}`}
                                                alt="grade" className="icon_grade"/></span>
                                        )}
                                    </div>
                                    <div className="attribute icon-column">{group.data.map((node) =>
                                        <span>{node.handicap}</span>)}</div>
                                </div>
                            ))}
                        </div>
                </li>
                }
            </ul>
        </li>
    );
};

export default RoundSettingInformation;