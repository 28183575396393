import * as lan from '../common/script/common_lan.js';

import * as common from '../common/script/common';
import $ from 'jquery';

export function slide_share(templink){
    $("html").addClass("overflowHidden");
    $("#share").addClass("on");
    link = templink;
}
export function exit(){
    $("html").removeClass("overflowHidden");
    $(".layerView").removeClass("on");
}

var link;

export default function BottomShare(props){
    
	const goKakao = () => {
		if(common.isIOS()){
			common.iOSCallApp({appid:"goKakao", params: link});
		}
		else
		{
			try{common.andriodCallApp('goKakao',[link]);}catch (ex){
			}
		}
	}
    
	const goInstaUrl = () => {
		if(common.isIOS()){
			common.iOSCallApp({appid:"goInstaUrl", params: link});
		}
		else
		{
			try{common.andriodCallApp('goInstaUrl',[link]);}catch (ex){
			}
		}
	}
    
	const goFacebook = () => {
		if(common.isIOS()){
			common.iOSCallApp({appid:"goFacebook", params: link});
		}
		else
		{
			try{common.andriodCallApp('goFacebook',[link]);}catch (ex){
			}
		}
	}
	
	const goMessage = () => {
		if(common.isIOS()){
			common.iOSCallApp({appid:"goMessage", params: link});
		}
		else
		{
			try{common.andriodCallApp('goMessage',[link]);}catch (ex){
			}
		}
	}
    
	const copyText = () => {
        exit();
		const $textarea = document.createElement("textarea");

		// body 요소에 존재해야 복사가 진행됨
		document.body.appendChild($textarea);
		
		// 복사할 특정 텍스트를 임시의 textarea에 넣어주고 모두 셀렉션 상태
		$textarea.value = link;
		$textarea.select();
		
		// 복사 후 textarea 지우기
		document.execCommand('copy');
		document.body.removeChild($textarea);
		common.alertpop(lan.L('매장찾기'),lan.L('주소가 복사되었습니다.'));
	}

	const {...rest} = props
	return (
    <div id="share" className="layerView">
        <div className="bottom_sheet">
        <a onClick={exit}>close</a>
        <div className="bs_body">
            <em>{lan.L("매장정보 공유하기")}</em>
            <div className="store_share">
            <a onClick={goMessage}>
                <i className="message">image</i>
                <span>{lan.L("메시지")}</span>
            </a>
            <a onClick={goKakao}>
                <i className="kakaotalk">image</i>
                <span>{lan.L("카카오톡")}</span>
            </a>
            <a onClick={goInstaUrl}>
                <i className="instagram">image</i>
                <span>{lan.L("인스타그램")}</span>
            </a>
            <a onClick={goFacebook}>
                <i className="facebook">image</i>
                <span>{lan.L("페이스북")}</span>
            </a>
            <a onClick={copyText}>
                <i className="link">image</i>
                <span>{lan.L("URL 복사")}</span>
            </a>
            </div>
        </div>
        </div>
    </div>
  
	)
}