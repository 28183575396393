import React, {useState, Component} from 'react';
import { redirect, useNavigate } from "react-router-dom";

class Tab extends React.Component
{

	constructor(props){
		super(props);
		
		this.state = {activeNum:this.props.defaultTopics}
	}
	
	TabChange(key) {
			this.setState(prevState => ({
				activeNum :  key
			}));
			this.props.action(key);
		}

	render(){
		const tablist = [];
		const props = this.props;

		for(let i=0;i<props.topics.length;i++){
			let t = props.topics[i];

			tablist.push(<button key={i} type="button" className={this.state.activeNum==i ? 'active':''} onClick={(e)=>{this.TabChange(i)}}>{t.tabName}</button>);
		}

		return <div className="tab_button">{tablist}</div>
	}

}

export default Tab 
