import React from 'react';
import * as common from "../common/script/common";
import * as lan from "../common/script/common_lan";

const NewperioTotalRanking = ({InfoRankData, selectPlayerNum}) => {
    return (
        <table className="score_chart">
            <colgroup>
                <col style={{width: '40%'}}/>
                <col style={{width: '60%'}}/>
            </colgroup>
            <tbody>
            <tr>
                <th>{lan.L('라운딩 횟수')}</th>
                <td>{InfoRankData.list[selectPlayerNum].courseRoundPlayCount}</td>
            </tr>
            <tr>
                <th>{lan.L('마지막 라운드 시작 시간')}</th>
                <td>{common.UnixTime(InfoRankData.list[selectPlayerNum].recordTime,'년.월.일 시:분:초')}</td>
            </tr>
            </tbody>
        </table>
    );
};

export default NewperioTotalRanking;