import React, { useState } from "react";
import ReactPlayer from "react-player";
import Vidtest from "../images/9GBo-mountain-video.mp4";

export default function App() {
  return (
    <ReactPlayer
      url={Vidtest}
      playing={true}
      controls={true}
      loop={true}
      muted={true}
      playsinline={true}
    />
  );
}
