import * as lan from './common/script/common_lan.js';

import * as common from './common/script/common.js';

import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';

var navigate;
var isFirst = false;

export default function Intro(){
	const location = useLocation();
	navigate = useNavigate();

	//화면전환시 실행
	useEffect(() => {
            var timer=0;
            var objTimer;
            common.setisTestIP();
            // console.log(sessionStorage.getItem('isTestIP'));

            window.localStorage.setItem('getIntro',"N");

            objTimer = setInterval(function(){
                timer = timer +0.25;
                if(timer >= 2.75 && window.localStorage.getItem('getIntro') == "Y")
                {
                    clearInterval(objTimer);
                    window.localStorage.setItem('getIntro',"N");
                    timer = 0;

                    //아이폰에서만 처리
                    if(common.isIOS()){
                        common.iOSCallApp({appid:"goOutIntro", params:""});
                    }

                    if(sessionStorage.getItem('access_token') !== undefined && sessionStorage.getItem('access_token') != null
                    && sessionStorage.getItem('access_token') != '')
                    {
                        navigate('/default/default',{replace:true});

                        window.localStorage.setItem('intro_loginid','');
                        window.localStorage.setItem('intro_loginpass','');
                        window.localStorage.setItem('intro_autologin','');
                        window.localStorage.setItem('intro_acesstoken','');
                    }
                    else
                    {
                        navigate('/login/login',{replace:true});
                        window.localStorage.setItem('intro_loginid','');
                        window.localStorage.setItem('intro_loginpass','');
                        window.localStorage.setItem('intro_autologin','');
                        window.localStorage.setItem('intro_acesstoken','');
                    }
                }

                //작동을 안해서 5초이상 지난경우 인트로로 이동 (마켓을 여는 경우 작동안함)
                if(timer >= 5 && window.location.pathname == '/' && window.localStorage.getItem('popupOpenMarket') != 'Y' && window.localStorage.getItem('popupOpenMarket') != 'O')
                {
                    clearInterval(objTimer);
                    window.localStorage.setItem('getIntro',"N");
                    timer = 0;

                    //아이폰에서만 처리
                    if(common.isIOS()){
                        common.iOSCallApp({appid:"goOutIntro", params:""});
                    }

                    navigate('/login/login',{replace:true});
                    window.localStorage.setItem('intro_loginid','');
                    window.localStorage.setItem('intro_loginpass','');
                    window.localStorage.setItem('intro_autologin','');
                    window.localStorage.setItem('intro_acesstoken','');
                }
            }, 250);

        if(common.isIOS()){
            common.iOSCallApp({appid:"getLanguage", params:"", funcname:"window.NativeInterface.getLanguage"});
        }
        else
        {
            try{common.andriodCallApp('getLanguage','');}
            catch (ex){
                if(sessionStorage.getItem('Language') === null)  sessionStorage.setItem('Language', 'ko');}
        }

        if(common.isIOS()){
            common.iOSCallApp({appid:"getIntro", params:'', funcname:"window.NativeInterface.getIntro"});
        }
        else
        {
            try{common.andriodCallApp('getIntro','');}catch (ex){
                window.NativeInterface.getIntro('{"userName":"","password":"","accessToken":"","autoLoginYN":"","appVersion":"1.1.0"}');
            }
        }
    },[location]);

	const goSNSResult = (e) => {
        window.localStorage.setItem('join_joinType ',$("#isJoinSNS").val());
        common.goLogin(navigate, null, window.localStorage.getItem('join_snsToken'), window.localStorage.getItem('join_loginToken'), window.localStorage.getItem('join_joinType'), "Y");
	}

	useEffect(() => {
		window.dispatchEvent(new Event("storage"));
    });

    return (
        <div className="intro">
            <input name="isJoinSNS" id="isJoinSNS" type="hidden" onInput={(e)=>goSNSResult()} value="" />
            <div className="animate">
            <span>K</span>
            <span>G</span>
            <span>O</span>
            <span>L</span>
            <span>F</span>
            <span>{lan.L('(주)케이골프')}</span>
            </div>
        </div>
        )
}