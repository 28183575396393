import React from 'react';
import * as common from "../common/script/common";
import * as lan from "../common/script/common_lan";

const StrokeTotalRanking = ({InfoRankData, selectPlayerNum}) => {
    return (
        <table className="score_chart">
            <colgroup>
                <col style={{width: '30%'}}/>
                <col style={{width: '70%'}}/>
            </colgroup>
            <tbody>
            <tr>
                <th>{lan.L('라운딩 횟수')}</th>
                <td className='handicap'>{InfoRankData.list[selectPlayerNum].courseRoundPlayCount}</td>
            </tr>
            <tr>
            <th>{lan.L('마지막 라운드 시작 시간')}</th>
                <td className='handicap'>{common.UnixTime(InfoRankData.list[selectPlayerNum].recordTime,'년.월.일 시:분:초')}</td>
            </tr>
            <tr>
                <th>{lan.L('핸디캡')}</th>
                <td className='handicap'>
                    <div className='info'>
                        <span>{lan.L('성별')}: {InfoRankData.list[selectPlayerNum].genderHandicap}</span>
                        <span>{lan.L('등급')}: {InfoRankData.list[selectPlayerNum].handicap}</span>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    );
};

export default StrokeTotalRanking;