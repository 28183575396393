import * as lan from '../common/script/common_lan.js';


import React, {useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from 'axios';
import stringify from 'node-stringify';
import { withRouter } from 'react-router-dom';
import * as common from '../common/script/common';
import $ from 'jquery';

var navigate, loginID, loginPass;

export default function Withdrawal() {

	navigate = useNavigate();
	var location = useLocation();
	var state = 'on'
	
	//화면 이동 시
	useEffect(() => {
		$("#btnWithdrawal").attr('disabled',true);
	},[location]);
	
	const onChange = (e) => {
		
		if($("#agree").is(':checked'))
		{
			$("#btnWithdrawal").attr('disabled',false);
		}
		else
		{
			$("#btnWithdrawal").attr('disabled',true);
		}
	}

	return (
	<>
	<div className="container">
		<div className="leave">
		<strong>{lan.L('KGOLF 회원탈퇴안내')}</strong>
		<span>{lan.L('회원탈퇴 전에 유의사항을 꼭 확인해주세요.')}</span>
		<div className="caution">
			<em>{lan.L('유의사항')}</em>
			<ol>
			<li>{lan.L('회원탈퇴시, KGOLF 서비스 이용이 불가합니다.')}</li>
			<li>{lan.L('탈퇴한 아이디 및 닉네임은 다시 사용할 수 없습니다.')}</li>
			<li>{lan.L('참여중인 대회가 있는 경우 대회 종료 후 회원탈퇴가 가능합니다.')}</li>
			<li>{lan.L('회원정보 및 라운드기록, 샷정보, 대회정보 등 서비스 이용기록은 모두 삭제되며, 삭제된 데이터는 복구되지 않습니다.')}</li>
			<li>{lan.L('향후 재가입시 게임정보 등 회원정보는 복원되지 않습니다.')}</li>
			<li>{lan.L('개인정보 처리방침 제5조(개인정보의 보유 및 이용기간)에 따른 특별한 경우, 한시적으로 개인정보를 보존 유지 합니다.')}</li>
			<li>{lan.L('문의사항은 1577-0564 KGOLF 고객센터로 문의 주시기 바랍니다.')}</li>
			</ol>
		</div>
		</div>
	</div>
	
	<div className="sticky_button">
		<div className="wrap_check">
		<a>
			<input type="checkbox" id="agree" name="marketing"  onChange={onChange} />
			<label htmlFor="agree" className="rows new-line">{lan.L('위 내용을 확인 하였으며,\n회원탈퇴 시 모든 정보가 복구 불가능함을 동의합니다.')}</label>
		</a>
		</div>
	  <button type="button" id="btnWithdrawal" className="btn_standard" onClick={btnWithdrawal}>{lan.L('탈퇴하기')}</button>
	</div>
	<hr />
	</>
	)
}

function btnWithdrawal() {

	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: {},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('회원 탈퇴'),
			'guid' : sessionStorage.getItem('guid'),
			'token' : sessionStorage.getItem('access_token')
		}
	}).then(function (response) {
		var data = response.data;
		console.log(response);

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			common.alertByError('회원 탈퇴',response.data.error.code);
			return false;
		}
		
		common.goLogout();
        window.localStorage.setItem('popupnavigate','/login/login');
		common.alertpop(lan.L('회원 탈퇴'),lan.L('회원 탈퇴되었습니다.'));
		navigate(-1);
	}).catch(error => {
		//console.log(error.response)
	});
}